import React from 'react';
import { HeartbeatContext } from './HeartbeatContext';

export default function HeartbeatContextHOC(Component) {
  return React.forwardRef((props, ref) => (
    <HeartbeatContext.Consumer>
      {context => <Component ref={ref}  {...props} />}
    </HeartbeatContext.Consumer>
  ))
}
