import React from 'react';
import { ToastContext } from './ToastContext';

export default function ToastContextHOC(Component) {
  return React.forwardRef((props, ref) => (
    <ToastContext.Consumer>
      {context => <Component ref={ref} toast={context} {...props} />}
    </ToastContext.Consumer>
  ))
}
