import React, { Component } from "react"
import OrderDetailsSection from "./OrderDetailsSection"
import DinerDetailsSection from "./DinerDetailsSection"
import UserContextHOC from "../contexts/UserContextHOC"
import LoadingActionButton from '../Loading/LoadingActionButton'
import ButtonWithSpinner from "./ButtonWithSpinner"
import TabletSidebar from "./TabletSidebar"
import Warning from "./svg/Warning"
import classnames from "classnames"
import "./OrderCard.css"
import "./Adjust.css"
const Api = require("../api")
const Money =  require("../helpers/money") 

const REASONS = {
  refund:[
    'Tip refund',
    'Issue with order',
    'Missing item/modifier'
  ],
  upcharge:[
    'Special instructions request',
    'Item subtitution',
    'Customer added tip',
    'Additional delivery fee'
  ]
}

class AdjustOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile:window.innerWidth <= 599,
      isTablet:window.innerWidth > 599,
      ...props.location.state,
      amount:'',
      transactionType:'food'
    }
    this.close = this.close.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleEnterKey = this.handleEnterKey.bind(this)
    this.bottomRef = React.createRef()
    window.adjust = this
  }

  componentDidMount(){
    this.mounted = true
    window.addEventListener("keydown", this.handleEnterKey)
    this.refreshOrder()
  }

  componentWillUnmount(){
    window.removeEventListener("keydown", this.handleEnterKey)
    this.mounted = false
  }

  handleEnterKey(e) {
    if(e.key === 'Enter'){
      window.document.activeElement.blur()
    }
  }

  refreshOrder(){
    if(!this.mounted) return
    Api.callApi({
      endpoint:'refresh-order',
      order_id:this.state.order.order_id,
      success:(data)=>{
        this.setState({order:data.order})
        setTimeout(()=>this.refreshOrder(), 5000)
      },
      failure:()=>{
        setTimeout(()=>this.refreshOrder(), 5000)
      }
    })
  }

  // close all modals
  closeModal(e){
    if (e.target.className === "modal-background moda-background-from-right"){
      this.setState({ closing: true }, () => {
        setTimeout(() => {
          let path = this.props.location.pathname
          let parts = path.split("/")
          let newPath = "/"+parts[1] + "/" + parts[2] 
          this.props.history.replace({pathname:newPath})
        }, 200)
      })  
    }   
  }

  // go back one page
  close() {
    let that = this
    this.setState({ closing: true }, () => {
      setTimeout(() => {
        this.props.history.goBack()
      }, 200)
    })  
  }

  adjustOrder(callback){
    console.log("callback", callback)
    Api.callApi({
      endpoint:'adjust-order',
      data:{
        order_id: this.state.order.order_id,
        adjustment_type:this.state.type,
        amount:this.amount(),
        tax:this.tax(),
        transaction_type:this.state.transactionType
      },
      success:(data)=>{
        if(!data.success){
          callback()
          this.setState({error:data.message})
          return
        }
        // we want to go back to /orders/blah, so let's parse
        // that from the current pathname
        this.setState({ closing: true }, () => {
          setTimeout(() => {
            let path = this.props.location.pathname
            let parts = path.split("/")
            let newPath = "/"+parts[1] + "/" + parts[2] +"/" + parts[3]
            this.props.history.replace({
              pathname:newPath,
              state:{
                toast:{
                  type:this.state.type,
                  amount:this.state.amount,
                  order:this.state.order
                }
              }
            })         
          }, 200)
        })  
      },
      failure:()=>{
        if(callback) callback()
        this.setState({error:'Unknown error'})
      }
    })
  }

  // convert this.state.amount to cents
  amount(){
    let amount = parseFloat(this.state.amount)
    if (!amount) return 0
    return amount * 100
  }

  tax(){
    if(!this.props.user.rest) return 0

    let taxable = ['food']
    if(taxable.indexOf(this.state.transactionType) > -1){
      let amount = this.amount()
      return amount * this.props.user.rest.tax_rate
    } else{
      return 0
    }
  }
  
  total(){
    return this.amount() + this.tax()
  }

  taxRate(){
    if(this.props.user.rest && this.tax()){
      let tax_rate = this.props.user.rest.tax_rate
      tax_rate = tax_rate * 100
      return '(' + tax_rate.toString() + '%' + ')'
    }
  }

  // don't let anything past the second decimal
  filterInput(val){
    if(val){
      return val.match(/^-?\d+(?:\.\d{0,2})?/)[0]
    } else {
      return val
    }
  }

  scrollToBottom(){
    if(this.bottomRef.current){
      this.bottomRef.current.scrollIntoView()
    }     
  }

  onFocus(){
    if(window.innerWidth < 600){
      setTimeout(()=>this.scrollToBottom(), 300)
      setTimeout(()=>this.scrollToBottom(), 500)
      setTimeout(()=>this.scrollToBottom(), 700)
      setTimeout(()=>this.scrollToBottom(), 1000)
    }
  }

  renderBottomButton(){
    let type = this.state.type === 'upcharge' ? 'Upcharge' : 'Discount'
    return (
      <div className='adjust-bottom-bottom-container'>
        <div className='adjust-bottom-inner-container-adjust'>
          {!this.state.amount && (
            <div 
              className='full-page-card-mobile-bottom-button disabled-button'
            >
              Enter Amount & Reason for {type}
            </div>
          )}
          {this.state.amount && (
            <ButtonWithSpinner
              className='full-page-card-mobile-bottom-button'
              text={`Confirm ${type} of ${Money.formatCents(this.total())}` }
              spinnerColor='white'
              onClick={(cb)=>this.adjustOrder(cb)}
            />
          )}
        </div>
      </div>
    )
  }

  render(){
    return (
      <div 
        onClick={(e)=>this.closeModal(e)}
        className='modal-background moda-background-from-right'
        id={this.state.closing ? "modal-background-exit-right" : null}
      >
        <div 
          className={classnames('full-page-card', 'full-page-card-from-right')}
          id={this.state.closing ? "full-page-card-exit-right" : null}
        >
          <div className='full-page-card-header gray-header'>
            <ChevronLeft 
              onClick={()=>this.close()}
              className='full-page-card-header-back-chevron'
            />
            <div 
              className='full-page-card-header-text gray-header-text'
              onClick={this.adjust}
            >
              Adjust Order Amount
            </div>
            <ChevronLeft style={{opacity:0}}/>
          </div>


          <div
            className='full-page-card-content-container white-background'
            id='fuck'
          >
            <div 
              id='fuck2'
              className='full-page-card-content orders-content white-background'
            >
              <DinerDetailsSection 
                order={this.state.order} 
                style={this.state.isMobile ? {marginTop:'0px'} : {} }
              />

              <div className='adjust-buttons-container'>
                <div
                  onClick={()=>this.setState({type:'refund', reason:''})}
                  className={classnames('adjust-button',
                   {'adjust-button-selected':this.state.type === 'refund'})}
                >
                  <div>Discount</div>
                </div>
                <div
                  onClick={()=>this.setState({type:'upcharge', reason:''})}
                  className={classnames('adjust-button',
                   {'adjust-button-selected':this.state.type === 'upcharge'})}
                >
                  <div>Upcharge</div>
                </div>
              </div>  

              <div className='adjust-type'>
                {this.state.type === 'refund' 
                  ? <> Discount </>
                  : <> Upcharge </>
                }
              </div>

              <div className='adjust-dropdown-container'>
                <div className='adjust-amount-label'>
                  Select Transaction Type
                </div>
                <select 
                  onChange={(e)=>this.setState({transactionType:e.target.value})}
                  value={this.state.transactionType}
                >
                  <option value='food'>Food &amp; Beverage </option>
                  <option value='delivery_fee'>Delivery Fee</option>
                  <option value='tip'>Tip</option>
                  <option value='other'>Other</option>
                </select>
              </div>
              
              <div className='adjust-amount-container'>


                <div className='adjust-amount-inner-container'>
                  <div className='adjust-amount-label'>
                    Amount
                  </div>
                  <div className='adjust-amount-input-container'>
                    <input 
                      type='number' 
                      className={classnames('adjust-amount-input',
                        {'adjust-input-error':this.state.error})}
                      onChange={(e)=>this.setState({amount:this.filterInput(e.target.value)})}
                      onFocus={()=>this.onFocus()}
                      value={this.state.amount}
                    />
                    {this.state.error && (
                      <div className='adjust-error'>{this.state.error}</div>
                    )}
                  </div>
                </div>
              </div> {/* adjust-amount-container */}

              <div className='adjust-total-container'>
                <div className='adjust-total-row'>
                  <div className='adjust-total-label'>Tax {this.taxRate()}</div>
                  <div className='adjust-total-amount'>{Money.formatCents(this.tax())}</div>
                </div>

                <div className='adjust-total-row' ref={this.bottomRef}>
                  <div className='adjust-total-label'>Total</div>
                  <div className='adjust-total-amount'>{Money.formatCents(this.total())}</div>
                </div>
              </div>

              {this.state.isMobile && this.renderBottomButton()} 

           </div> {/* .full-page-card-content */}

          {this.state.isTablet && (
             <TabletSidebar
                close={()=>this.close(-1)}
                originalPage={this.state.originalPage}
                page='adjust'
                originalPage={this.state.page}
                {...this.props}
                order={this.state.order}
                action={(cb)=>this.adjustOrder(cb)}
                enableAdjustButton={!!this.state.amount}
                adjustType={this.state.type}
              />
            )}
          </div> {/*. full-page-card-content-container */}
        </div> {/* .full-page-card */}

      </div>
    )
  }
}
      
export default UserContextHOC(AdjustOrder) 

const OpenCircle = props => (<svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11z"/></svg>)

const ClosedCircle = props => (<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>)

const ChevronLeft = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
)

