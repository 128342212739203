import React, { Component } from "react"
import UserContextHOC from '../contexts/UserContextHOC'
import Cart from './Cart'
import OrderDetailsSection from './OrderDetailsSection'
import DinerDetailsSection from './DinerDetailsSection'
import ButtonWithSpinner from "./ButtonWithSpinner"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from "classnames"
import "./OrderCard.css"
import "./TabletSidebar.css"
import { Plugins } from '@capacitor/core'
const { PrintReceiptPlugin, ReceiptPrinter } = Plugins;
const Api = require("../api")

let DELIVERY_TIMES = [30,40,50,60,75,90]
let PICKUP_TIMES  = [5, 10,20,30,45, 60]
let SNOOZE_TIMES = [30, 60, 90, 120]


class TabletSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cancelReasonSelected:this.props.cancelReasonSelected,
      adjustType:this.props.adjustType,
      enableAdjustButton:this.props.enableAdjustButton,
      order:this.props.order,
    }

    if(this.props.user.rest){
      if (this.props.order.fulfill_order_at){
        if(this.props.order.pickup_or_delivery === 'pickup'){
          this.state['prep_time'] = this.props.user.rest.default_pickup_snooze_time 
        } else {
          this.state['prep_time'] = this.props.user.rest.default_delivery_snooze_time 
        }
      } else {
        if(this.props.order.pickup_or_delivery === 'pickup'){
          this.state['prep_time'] = this.props.user.rest.default_pickup_time 
        } else {
          this.state['prep_time'] = this.props.user.rest.default_delivery_time 
        }
      }
      if(this.props.updatePrepTime){
        this.props.updatePrepTime(this.state['prep_time'])
      }
    }

    this.listenForFinishedPrints = this.listenForFinishedPrints.bind(this)
    this.printButtonRef = React.createRef()
    window.side22 = this
    let a = {}
  }

  componentDidMount(){
    window.addEventListener('receiptPrinted', this.listenForFinishedPrints)
  }

  componentWillUnmount(){
    window.removeEventListener("receiptPrinted", this.listenForFinishedPrints)
  }

  componentDidUpdate(){
    if(this.props.cancelReasonSelected !== this.state.cancelReasonSelected){
      this.setState({cancelReasonSelected:this.props.cancelReasonSelected})
    }
    if(JSON.stringify(this.props.order) !== JSON.stringify(this.state.order)){
      this.setState({order:this.props.order})
    }
    
    if(this.props.page === 'adjust'){
      if(this.props.adjustType !== this.state.adjustType){
        this.setState({adjustType:this.props.adjustType})
      }
      if(this.props.enableAdjustButton !== this.state.enableAdjustButton){
        this.setState({enableAdjustButton:this.props.enableAdjustButton})
      }
    }
  }


  listenForFinishedPrints(data){
    if(data.unique_key == this.unique_printing_key){
      if(this.printButtonRef && this.printButtonRef.current){
        this.printButtonRef.current.setState({loading:false})
      }
    }
  }


  openAdjust(type){
    this.props.history.push({
      pathname:this.props.location.pathname+"/adjust",
      state:{
        order:this.state.order,
        page:this.props.page,
        type:type
      }   
    })  
  }

  printReceipt(cb){
    console.log("callback", cb)
    if(this.props.user.rest.fax){
      Api.callApi({
        endpoint:'fax',
        data:{order_id:this.state.order.order_id},
        success:()=>cb(),
        failure:(err)=>{
          this.props.history.push({
            pathname:this.props.location.pathname +'/error-modal',
            state:{
              message:'There was an error sending the fax.'
            }
          })
          cb()
        }
      })
      return
    }

    if(!this.props.user.printer || !this.props.user.printer.ipAddress){
      cb()
      this.props.history.push({
        pathname:this.props.location.pathname +'/error-modal',
        state:{
          message:'A printer is not set up.'
        }
      })
      return
    }
    let key = Math.floor(Math.random() * 100)
    key = key.toString()
    this.unique_printing_key = key
    let ipAddress = this.props.user.printer.ipAddress
    if(PrintReceiptPlugin){
      if(
        this.state.order.origin === 'MANUAL_ENTER_CC' ||
        this.state.order.origin === 'STRIPE_TERMINAL'
      ){
        this.unique_printing_key = this.state.order.order_id
        PrintReceiptPlugin.printInPersonReceipt({
          order_id:this.state.order.order_id.toString(),
          api_key:window.API_KEY,
          unique_key:this.state.order.order_id.toString(),
          ip_address:this.props.user.printer.ipAddress
        }) 
      } else {
        PrintReceiptPlugin.printReceipt({
          order_id:this.state.order.order_id.toString(),
          source:'reprint',
          copies:"1",
          unique_key:key,
          api_key:window.API_KEY,
          ip_address:ipAddress
        })
      }
    } else {
      ReceiptPrinter.printReceipt({
        order_id:this.state.order.order_id.toString(),
        source:'reprint',
        copies:"1",
        unique_key:key,
        api_key:window.API_KEY,
        ip_address:ipAddress
      })
    }

  }

  renderCancel(){
    return (
      <>
        <div 
          className='full-page-card-tablet-light-button mt-15'
          onClick={()=>this.props.close()}
        >
          No, keep this order
        </div>

        <div className='hr-grey'></div>
            
        {this.state.cancelReasonSelected && (
          <ButtonWithSpinner
            className='full-page-card-tablet-action-button'
            text='Cancel Order'
            spinnerColor='white'
            onClick={(cb)=>this.props.cancel(cb)}
          />
        )}
 
        {!this.state.cancelReasonSelected && (
          <div className='full-page-card-tablet-gray-button'>
            Select a Reason
          </div>
        )}
         
      </>
    )
  }

  renderUnconfirmed(){
    if(!this.props.user.rest) return null
    let times
    let buttonText
     
    // scheduled orders 
    if(this.state.order.fulfill_order_at){
      buttonText = 'Queue Order'
      times = JSON.parse(JSON.stringify(SNOOZE_TIMES))
      if(this.state.order.pickup_or_delivery === 'pickup'){
        if(times.indexOf(this.props.user.rest.default_pickup_snooze_time) == -1){
          if(this.props.user.rest.default_pickup_snooze_time){
            times.push(this.props.user.rest.default_pickup_snooze_time)
          }
        }
      } else if (this.state.order.pickup_or_delivery === 'delivery'){
        if(times.indexOf(this.props.user.rest.default_delivery_snooze_time) == -1){
          if(this.props.user.rest.default_delivery_snooze_time){
            times.push(this.props.user.rest.default_delivery_snooze_time)
          }
        }
      }
    }
    // non scheduled orders
    else {
      buttonText = 'Accept Order'
      if(this.state.order.pickup_or_delivery === 'pickup'){
        times = JSON.parse(JSON.stringify(PICKUP_TIMES))
        if(times.indexOf(this.props.user.rest.default_pickup_time) == -1){
          if(this.props.user.rest.default_pickup_time){
            times.push(this.props.user.rest.default_pickup_time)
          }
        }
      } else if (this.state.order.pickup_or_delivery === 'delivery'){
        times = JSON.parse(JSON.stringify(DELIVERY_TIMES))
        if(times.indexOf(this.props.user.rest.default_delivery_time) == -1){
          if(times.indexOf(this.props.user.rest.default_delivery_time)){
            times.push(this.props.user.rest.default_delivery_time)
          }
        }
      }
    }
    times = times.sort(function(a, b){return a-b});
    
    return (
      <>

        {this.state.order.fulfill_order_at ? (
          <div className='select-ready-time-text'>
            Snooze Until
          </div>
        ) : (
          <div className='select-ready-time-text'>
            Select Ready Time
          </div>
        )}

        <div className='full-page-card-tablet-times'>
          {times.map(time => (
            <div 
              onClick={()=>this.setState({prep_time:time}, ()=>this.props.updatePrepTime(time))}
              className={classnames('full-page-card-tablet-light-button', 'ready-time-button',
              {'full-page-card-tablet-time-unselected':time !== this.state.prep_time})}
            >
              <div>{time} minutes</div>
            </div>
          ))}
        </div>

        <div style={{marginTop:'20px'}}>
          <ButtonWithSpinner
            className='full-page-card-tablet-action-button'
            text={buttonText}
            spinnerColor='white'
            onClick={(cb)=>this.props.action(cb)}
          />
        </div>

      </>
    )
  }

  renderAdjust(){
    let type = this.state.adjustType === 'refund' ? 'Discount' : 'Upcharge' 
    if (this.state.enableAdjustButton){
      return(
        <ButtonWithSpinner
          className='full-page-card-tablet-action-button mt-15'
          text={`Confirm ${type}`}
          spinnerColor='white'
          onClick={(cb)=>this.props.action(cb)}
        />
      )
    } else {
      return(
        <>
          <div className='full-page-card-tablet-gray-button mt-15'>
            Confirm {type}
          </div>
          <div className='tablet-disabled-subtext'>
            Enter {type.toLowerCase()} amount &amp; select a reason
          </div>
        </>
      )
    }
  }

  render(){
    let heightOffset = window.innerWidth < 1000 ? 68 : 0
    return (
        <div 
          className='full-page-card-sidebar'
          style={{height:window.innerHeight-76 - heightOffset  + 'px'}}
        >
          <div>
            <OrderDetailsSection 
              order={this.state.order} 
              page={this.props.page}
              originalPage={this.props.originalPage}
            />

            {this.props.page !== 'cancel' && this.props.page !== 'adjust' && (
             <ButtonWithSpinner
                className='full-page-card-tablet-light-button'
                style={{marginTop:'15px'}}
                text={this.props.user.rest && this.props.user.rest.fax ? 'Resend Fax' : 'Print Receipt'}
                svg={<Printer/>}
                ref={this.printButtonRef}
                spinnerColor='blue'
                stopSpinning={this.state.receiptPrinted}
                onClick={(cb)=>this.printReceipt(cb)}
              />
            )}

            

            {this.props.page !== 'cancel' && this.props.page !== 'adjust' && (
              <div className='hr-grey'></div>
            )}

           {this.props.page == 'kitchen' &&  ( 
              <ButtonWithSpinner
                className='full-page-card-tablet-action-button'
                text='Order Ready'
                spinnerColor='white'
                onClick={(cb)=>this.props.action(cb)}
              />
           )}
            
            {this.props.page === 'adjust' && this.renderAdjust()}
            {this.props.page === 'unconfirmed' && this.renderUnconfirmed()}
            {this.props.page === 'cancel' && this.renderCancel()}
            
          </div>
        </div>
    )
  }
}
        

export default withRouterAndRef(UserContextHOC(TabletSidebar))

const Printer = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 18h-8v-1h8v1zm-2 1h-6v1h6v-1zm10-14v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 10h-12v7h12v-7zm4-8h-20v9h2v-3h16v3h2v-9zm-1.5 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z"/></svg>)

const UpCaret = props => (<svg {...props} viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" ><path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z"/></svg>)

const DownCaret = props => (<svg {...props} viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" ><path d="M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z"/></svg>)
