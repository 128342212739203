import React, { Component } from "react"
import classnames from "classnames"
import UserContextHOC from "../contexts/UserContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import { Capacitor, Plugins } from '@capacitor/core'
import Loading from "../Loading/Loading"
import Warning from "../orders/svg/Warning"
import Refresh from "../orders/svg/Refresh"
import "./Choose.css"
const {StripeReaderPlugin} = Plugins;
const Api = require("../api")


class ChooseTerminal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:true
    }
  }

  componentDidMount(){
    this.getReaders()
  }

  async getReaders(){
    this.setState({loading:true})
    await StripeReaderPlugin.discoverReaders()
    setTimeout(async ()=>{
      let results = await StripeReaderPlugin.getReaders()
      let readers = []
      for(let i in results){
        readers.push(i)
      }   
      this.setState({readers:readers, loading:false})
    }, 5000)
  }

  postToSlack(msg){
    Api.callApi({
      endpoint:'/stripe-pos/slack-message',
      method:'post',
      data:{
        msg:msg
      },  
      success:(data)=>{
      },  
      failure:(err)=>{
      }   
    })  
  }

  async connect(name){
    this.setState({loading:true})
    let result = await StripeReaderPlugin.connectToReader({name:name})
    if (result['success'] == 0){
      this.postToSlack('In settings - could not connect to reader ('+result.reason+')')
      alert('Error connecting to reader')
      this.setState({loading:false})
    } else{
      this.postToSlack('In settings - clicked connect to reader, going to connection settings page') 
      this.props.user.saveStripeTerminal(name)
      this.props.history.replace({
        pathname:'/stripe-pos/connected'
      }) 
    }
  }

  render(){
    return (
      <div className='orders-content'>
        <div className='choose-reader-title'>
          Available Card Readers
        </div>

        <div className='choose-reader-subtitle'>
          Turn on Stripe Terminal reader and place near your device. Make sure Bluetooth is enabled. Do not pair in settings.
        </div>

        {this.state.loading === true  ? (
          <Loading/>
        ) : (
          <>
            {this.state.readers.length === 0 ? (
              <div className='choose-reader-no-readers'>
                <Warning />
                <div className='choose-reader-no-reader-text'>
                  We could not find any bluetooth card readers. Please make sure 
                  bluetooth is enabled on the tablet and that a nearby card reader 
                  is powered on and refresh the list.
                </div>
              </div>
            ) : ( 
              <div className='choose-reader-list-container'>
                {this.state.readers.map(r => (
                  <div className='choose-reader-reader'>
                    <div className='choose-reader-name'>{r}</div>
                    <div
                      className='choose-reader-connect'
                      onClick={()=>this.connect(r)}
                    >
                      <div>Connect</div>
                    </div>
                  </div>
                ))}
              </div>
            
            )}


            <div className='choose-reader-refresh-list-container'>
              <div onClick={()=>this.getReaders()} className='choose-reader-refresh-list'>
                <div> Refresh List</div>
                <Refresh />
              </div>
            </div>
          </>
        )}

      </div>
    )
  }

}
      
export default withRouterAndRef(UserContextHOC(ChooseTerminal))


