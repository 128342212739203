import React, { Component } from "react"
import classnames from 'classnames'
import UserContextHOC from "../contexts/UserContextHOC"
import ChevronLeft from "../orders/svg/ChevronLeft"
import "./Login.css"
const Api = require("../api")

class ChooseRest extends Component {
  constructor(props) {
    super(props)
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.rests
    ){
      this.state = {...this.props.history.location.state}
    } else {
      this.props.history.replace({
        pathname:'/login'
      })
    }
    window.c=this
  }

  chooseRest(rest){
    if(this.props.history.location.state.member){
      this.props.user.loginAsMember(this.props.history.location.state.api_key, rest.restaurant_id)
    } else {
      this.props.user.login(rest.api_key)
    }
  }
  
  render(){
    return (
      <div style={{paddingTop:'35px'}} className='login-page content'>
        <div className='choose-rest-header'>
          Welcome <br/>
          {this.state.name}
        </div>

        <div className='choose-rest-subheader'>
          Please select the restaurant you would like to sign into.
        </div>
       
        <div className='choose-rest-rests-container'>
          {this.state.rests.map(rest => (
            <div
              className='choose-rest-rest-card'
              onClick={()=>this.chooseRest(rest)}
            >
              <img className='choose-rest-logo' src={rest.logo} />
              <div className='choose-rest-right-side'>
                <div className='choose-rest-right-side-row choose-rest-name'>{rest.name}</div>
                <div className='choose-rest-right-side-row'>{rest.address1}</div>
                <div className='choose-rest-right-side-row'>
                  {rest.city}, {rest.state} {rest.zipcode}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
      

export default UserContextHOC(ChooseRest)


