import React, { Component } from "react"
import classnames from "classnames"
import UserContextHOC from "../contexts/UserContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import { Capacitor, Plugins } from '@capacitor/core'
import Loading from "../Loading/Loading"
import "./Home.css"
const Api = require("../api")
const {StripeReaderPlugin} = Plugins;


class StripePOSHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded:false
    }
  }

  componentDidMount(){
    this.checkIfConnected()
  }

  async checkIfConnected(){
    let ret = await StripeReaderPlugin.isConnected()
    let connected = ret['connected']
    if(connected == 1){
      this.props.history.replace({
        pathname:'/stripe-pos/connected'
      })
    } else{
      // try to reconnect to one if saved 
      if(this.props.user.stripeTerminal){
        let result = await StripeReaderPlugin.connectToReader({name:this.props.user.stripeTerminal})
        if (result['success'] == 1){
          this.props.history.replace({
            pathname:'/stripe-pos/connected'
          })
          return
        }
      }


      this.setState({loaded:true})
    }
  }

  render(){
    if(!this.state.loaded){
      return (
        <div>
          <Loading/>
        </div>
      )
    }
    return (
      <div>
        <div className='stripe-reader-image-container'>
          <img 
            className='stripe-reader-big-image'
            src='https://cdn.hngr.co/captain/stripem2resized.png'
          />
        </div>
        <div className='orders-content'>
          <div className='connect-to-terminal-title'>
            Connect to a Stripe Terminal
          </div>
          <div className='connect-to-terminal-blurb'>
            Process credit cards on site.
          </div>

          <div 
            className='connect-to-terminal-button'
            onClick={async () => {
              await StripeReaderPlugin.discoverReaders()
              this.props.history.push({
                pathname:'/stripe-pos/choose'
              })
            }}
          >
            <div>Connect to Terminal</div>
          </div>
        </div>
      </div>

    )
  }

}
      
export default withRouterAndRef(UserContextHOC(StripePOSHome))


