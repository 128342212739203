const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

let shitDayOfWeekToZeroStart = function (hours) {
  let shifted = {}
  for (var i in hours) {
    shifted[i - 1] = hours[i]
  }
  return shifted
}

const round5 = (x) => {
  return Math.ceil(x / 15) * 15
}

// this shifts the hours into bar chart time. That means that these values
// are for 5am to 4:59am daays, 5:01 shows up as 00:01 since it's the beginning
// of the bar chart
let shiftHours = function (hours) {
  let shifted = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] }
  let sunday_unshift = []
  for (let dow in hours) {
    for (let i in hours[dow]) {
      let open = hours[dow][i].open_at_min - 300
      let close = hours[dow][i].close_at_min - 300
      // stsying same day
      // this is pushing sunday when sunday already has its late at night hours set 
      if (open >= 0) {
        shifted[dow].push({
          open_at_min: open,
          close_at_min: close,
        })
      }

      // both move to yesterday
      else if (close <= 0) {
        let yesterday = dow - 1
        if (yesterday === 0) {
          // for sunday, we can't add this directly to sunday otherwise
          // it ends up being before the normal daytime sunday hours
          // so we need to add it at the end
          sunday_unshift.push({
            open_at_min: open + 1440,
            close_at_min: close + 1440,
          })
        } else {
          shifted[yesterday].push({
            open_at_min: open + 1440,
            close_at_min: close + 1440,
          })
        }
      }

      // hours now straddle midnight between yesterday and today
      else if (open <= 0 && close >= 0) {
        let yesterday = dow - 1
        // for sunday, we can't add this directly to sunday otherwise
        // it ends up being before the normal daytime sunday hours
        // so we need to add it at the end
        if (yesterday === 0) {
          sunday_unshift.push({
            open_at_min: 1440 + open,
            close_at_min: 1439,
          })
        } else {
          shifted[yesterday].push({
            open_at_min: 1440 + open,
            close_at_min: 1439,
          })
        }
        shifted[dow].unshift({
          open_at_min: 0,
          close_at_min: close,
        })
      }
    }
  }

  shifted[7].push(...sunday_unshift)

  // we need to go back and combine all the hours sets that straddle
  // midnight
  // these are the 5 hours shifted values
  const MIDNIGHT = 1440 - 300
  const ELEVEN59 = MIDNIGHT - 1
  for (let i in shifted) {
    if (shifted[i].length) {
      var previousHourSet = null
      let indexesToDelete = []
      for (let j in shifted[i]) {
        let currentHourSet = shifted[i][j]
        if (previousHourSet) {
          if (
            previousHourSet.close_at_min === ELEVEN59 &&
            currentHourSet.open_at_min === MIDNIGHT
          ) {
            previousHourSet.close_at_min = currentHourSet.close_at_min
            indexesToDelete.push(j)
          }
        }
        previousHourSet = shifted[i][j]
      }
      for (let j = indexesToDelete.length; j > 0; j--) {
        shifted[i].splice(j)
      }
    }
  }

  return shifted
}

let unshiftHours = function (hours) {
  let shifted = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] }
  for (let dow in hours) {
    for (let i in hours[dow]) {
      let open = hours[dow][i].open_at_min + 300
      let close = hours[dow][i].close_at_min + 300
      // stsying same day
      if (open < 1440 && close < 1440) {
        shifted[dow].push({
          open_at_min: open,
          close_at_min: close,
        })
      }

      // both move to tomorrow
      else if (open > 1440 && close > 1440) {
        let tomorrow = parseInt(dow) + 1
        if (tomorrow === 8) {
          tomorrow = 1
        }
        shifted[tomorrow].unshift({
          open_at_min: open - 1440,
          close_at_min: close - 1440,
        })
      }

      // hours now straddle midnight between yesterday and today
      else if (open <= 1440 && close > 1440) {
        let tomorrow = parseInt(dow) + 1

        if (tomorrow === 8) {
          tomorrow = 1
        }
        shifted[dow].push({
          open_at_min: open,
          close_at_min: 1439,
        })
        
        //make 4:59am to 5am
        let c = close - 1440
        if (c === 299) c = 300

        shifted[tomorrow].unshift({
          open_at_min: 0,
          close_at_min: c 
        })
      }
    }
  }

  // go through and clean up any 4:59->5:00am
  for (let dow in shifted) {
    let past_close = -999
    let past_open = -3432
    let index_to_delete = false
    for (let i in shifted[dow]) {
      //combine the two
      if(past_close === shifted[dow][i].open_at_min){
        shifted[dow][i].open_at_min = past_open
        index_to_delete = i - 1
      }
      past_close = shifted[dow][i].close_at_min
      past_open = shifted[dow][i].open_at_min
    }
    if(index_to_delete !== false) shifted[dow].splice(index_to_delete, 1)
  }
  return shifted
}

module.exports = {
  shiftHours: shiftHours,
  unshiftHours: unshiftHours,
  shitDayOfWeekToZeroStart: shitDayOfWeekToZeroStart,
  DAYS: DAYS,
  round5: round5,
}
