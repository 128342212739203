import React, { Component } from "react"
import classnames from "classnames"
//import ScrollLock, { TouchScrollable } from "react-scrolllock"
import "./Modal.css"

class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      closing: false,
    }
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  closeModal(e, method) {
    if (e.target.id === "Background" || e.target.id === "Close") {
      this.setState({ closing: true }, () => {
        window.setTimeout(() => {
          this.props.close()
        }, 500)
      })
    }
  }

  render() {
    return (
        <div
          className={classnames("Background", {
            [this.props.bgClassName]: this.props.bgClassName,
          })}
          id="Background"
          onClick={(e) => this.closeModal(e, "background")}
        >
          <div
            className={classnames({
              Modal: !this.props.large,
              ModalLarge: this.props.large,
              [this.props.className]: this.props.className,
            })}
            id={this.state.closing ? "Exit" : null}
            style={{ ...this.props.style }}
            onClick={null}
          >
            <img
              src="/close.png"
              className="Close"
              id="Close"
              onClick={(e) => this.closeModal(e, "x")}
              alt="close"
            />
            {this.props.children}
          </div>
        </div>
    )
  }
}

export default Modal
