import React from 'react';
import { ModifierModalContext } from './ModifierModalContext';

export default function ModifierModalContextHOC(Component) {
  return React.forwardRef((props, ref) => (
    <ModifierModalContext.Consumer>
      {context => <Component ref={ref} modal={context} {...props} />}
    </ModifierModalContext.Consumer>
  ))
}
