import React, { Component } from "react"
import ToastContextHOC from "../contexts/ToastContextHOC"
import Calendar from "../orders/svg/Calendar"
import Clock from "../orders/svg/Clock"
import withRouterAndRef from "../withRouterAndRef"
import ButtonWithSpinner from "../orders/ButtonWithSpinner"
import * as Sentry from "@sentry/browser";
import "./DeactivateModal.css"

const Api = require("../api")

class AreYouSureModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount(){
    window.addEventListener('mousedown', this.handleClickBackground)
  }

  componentWillUnmount(){
    window.removeEventListener('mousedown', this.handleClickBackground)
  }

  close(){
    this.props.history.goBack()
  }

  handleClickBackground(e){
   if (e.target.className === 'modal-background'){
    this.close()
   }
  }

  render() {
    return  (
      <div 
        className='modal-background' 
      >
        <div className='deactivate-modal-container'>
          <div className='deactivate-modal-title'>
            You have unsaved changes
          </div>

          <div className='deactivate-buttons-container'>
            <div 
              onClick={()=>this.props.cancel()}
              className='deactivate-button deactivate-cancel'
            >
              <div>Cancel</div>
            </div>
            
            <ButtonWithSpinner
              className='deactivate-button deactivate-yes'
              spinnerColor='white'
              onClick={this.props.onClick}
              text='Save &amp; Contine'
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouterAndRef(ToastContextHOC(AreYouSureModal))
