import React, { Component } from "react"
import classnames from 'classnames'
import ChevronLeft from "../orders/svg/ChevronLeft"
import Owner from "../orders/svg/Owner"
import withRouterAndRef from "../withRouterAndRef"
import "./Login.css"
import "./LoginBackHeader.css"

class LoginBackHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render(){
    return (
      <div className='login-back-header'>
        <div onClick={()=>this.props.history.goBack()} className='login-back-button'>
          <ChevronLeft/>
          <div>Back</div>
        </div>
      </div>
    )
  }
}
      

export default withRouterAndRef(LoginBackHeader)


