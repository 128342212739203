import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import DesktopSidebar from "../menu/DesktopSidebar"
import Loading from "../Loading/Loading"
import classnames from 'classnames'
import "./SwitchRests.css"
const Api = require("../api")

class SwitchRest extends Component {
  constructor(props) {
    super(props)
    this.state={
      brands:null
    }
    this.loadRests()
  }

  loadRests(){
    Api.callApi({
      endpoint:'/tablet/master-rest-list',
      method:'get',
      success:(data)=>{
        this.setState({brands:data.brands})
      },
      failure:(err)=>{
        alert('Error getting rests')
      }
    })
  }

  renderBrands(){
    let brands = Object.keys(this.state.brands)
    return (
      <div className='choose-rest-brand-dropdown-container'>
        <select
          onChange={(e)=>this.setState({brand:e.target.value})}
        >
          <option value={false}>Choose Brand</option>
          {brands.map(brand => (
            <option value={brand}>{brand}</option>
          ))}
        </select>
      </div>
    )
  }

  chooseRest(rest){
    this.props.user.switchRest(rest)
  }

  renderRests(){
    if(!this.state.brand) return null
    let rests = this.state.brands[this.state.brand]
    return (
      <div className='choose-rests-rests-container'>
        {rests.map(rest => (
          <div
            className={classnames('choose-rests-rests',
              {'choose-rests-selected':rest.restaurant_id === window.MEMBER_REST_ID})}
            onClick={()=>this.chooseRest(rest)}
          >
            {rest.name}
          </div>
        ))}
      </div>
    )
  }

  render(){
    return (
      <div className='desktop-menu-item-page-container'>
        <DesktopSidebar 
        />
        <div className='desktop-choose-rest-container'>
          {this.state.brands === null ? (
            <Loading />
          ) : (
            <>
              {this.renderBrands()}
            </>
          )}
          {this.renderRests()}
        </div>
      </div>
    )
  }
}
      

export default UserContextHOC(SwitchRest)


