import { Capacitor, Plugins } from '@capacitor/core';
import localforage from "localforage"
const { Storage } = Plugins;

async function LoadValue(key){
  if(Capacitor.isNative){
    let val = await Storage.get({key:key})
    if(val && val.value){
      return JSON.parse(val.value)
    } else{
      return null
    }
  } else{
    return await localforage.getItem(key)
  }
}

async function SaveValue(key, value){
  if(Capacitor.isNative){
    await Storage.set({
      key:key, 
      value:JSON.stringify(value)
    })
  }
  else {
    await localforage.setItem(key, value)
    return
  }
}
export {
  SaveValue,
  LoadValue
}
