import React, { Component } from "react"
import classnames from "classnames"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import withRouterAndRef from "../withRouterAndRef"
import "./DesktopTabs.css"

const Copy = require("../helpers/copy")

class DesktopTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTab:this.getPageFromPath(),
      pathname:this.props.location.pathname,
      snoozedOrders:Copy.copy(this.props.orders.snoozedOrders),
      activeOrders:Copy.copy(this.props.orders.activeOrders)
    }
  }

  componentDidUpdate(prevProps){
    if(this.state.pathname !== this.props.location.pathname){
      this.setState({
        currentTab:this.getPageFromPath(),
        pathname:this.props.location.pathname
      })  
    }   
    if(JSON.stringify(this.state.snoozedOrders) !== JSON.stringify(this.props.orders.snoozedOrders)){
      this.setState({snoozedOrders:Copy.copy(this.props.orders.snoozedOrders)})
    }   
    if(JSON.stringify(this.state.activeOrders) !== JSON.stringify(this.props.orders.activeOrders)){
      this.setState({activeOrders:Copy.copy(this.props.orders.activeOrders)})
    }
  }

  // these routes are /orders/blah .... we want to get the blah
  getPageFromPath(){
    return this.props.location.pathname.split("/")[2]
  }

  totalActiveOrders(){
    let total = 0
    total += this.state.activeOrders['pastdue'].length
    total += this.state.activeOrders['zero-15'].length
    total += this.state.activeOrders['fifteen-30'].length
    total += this.state.activeOrders['thirty-45'].length
    total += this.state.activeOrders['45-plus'].length
    return total
  }

  totalScheduledOrders(){
    let total = 0
    for(var i in this.state.snoozedOrders){
      total += this.state.snoozedOrders[i].length
    }
    return total
  }

  render(){
    return (
      <div className='desktop-tabs-container'>

        <div className='desktop-tab'>
          <div 
            onClick={()=>this.props.history.push({pathname:'/orders/kitchen'})}
            className={classnames('desktop-tab-inner', 
              {'desktop-tab-selected':this.state.currentTab === 'kitchen'})}
            >
            <div> In Kitchen </div>
            <div className='desktop-tab-circle-count'>
              <div>{this.totalActiveOrders()}</div>
            </div>
          </div>
        </div>

        <div className='desktop-tab'>
          <div 
            onClick={()=>this.props.history.push({pathname:'/orders/scheduled'})}
            className={classnames('desktop-tab-inner', 
              {'desktop-tab-selected':this.state.currentTab === 'scheduled'})}
            >
            <div> Scheduled </div>
            <div className='desktop-tab-circle-count'>
              <div>{this.totalScheduledOrders()}</div>
            </div>
          </div>
        </div>


       <div className='desktop-tab'>
         <div 
          onClick={()=>this.props.history.push({pathname:'/orders/history'})}
          className={classnames('desktop-tab-inner', 
            {'desktop-tab-selected':this.state.currentTab === 'history'})}
          >
            <div> History </div>
            <History
              className='desktop-tab-history-svg'
            />
          </div>
        </div>

      </div>
      )
    }
  }

export default NewOrdersContextHOC(withRouterAndRef(DesktopTabs))

const History = props => (<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z"/></svg>)

