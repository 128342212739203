import React, { Component } from "react"
import classnames from 'classnames'
import LoginBackHeader from "./LoginBackHeader"
import ButtonWithSpinner from "../orders/ButtonWithSpinner"
import SMS from "../orders/svg/SMS"
import "./Login.css"
const Api = require("../api")

class LoginOwner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phone:''
    }
    this.buttonRef = React.createRef()
    this.onPhoneInput = this.onPhoneInput.bind(this)
    this.handleEnterKey = this.handleEnterKey.bind(this)
  }

  componentDidMount(){
    this.mounted = true
    window.addEventListener("keydown", this.handleEnterKey)
  }

  componentWillUnmount(){
    window.addEventListener("keydown", this.handleEnterKey)
    this.mounted = false
  }

  handleEnterKey(e) {
    if(e.key === 'Enter'){
      if( 
        this.buttonRef &&  
        this.buttonRef.current &&
        this.state.phone.length === 10
      ){  
        window.document.activeElement.blur()
        this.buttonRef.current.click()
      }   
    }   
  }



  onPhoneInput(e){
    let val = e.target.value
    if(val){
      val = val.replace(/\D/g,'')
      if(val.length <= 10){
        this.setState({phone:val})
      }
    } else{
      this.setState({phone:''})
    }
  }

  // we want to add the dashes into the phone number
  formatPhone(val){
    val = val.toString()
    if(val.length <= 3){
      return val
    } else if (val.length >3 && val.length < 6){
      return val.slice(0,3) + '-' + val.slice(3,val.length)
    } else if (val.length >=7 ){
      return val.slice(0,3) + '-' + val.slice(3,6) + '-' + val.slice(6, val.length)
    }

  }

  getCode(cb){
    Api.callApi({
      endpoint:'get-login-code',
      data:{phone:this.state.phone},
      success:(data)=>{
        if(data.success){
          this.props.history.push({
            pathname:'/login/confirm',
            state:{
              name:data.name,
              phone:this.state.phone,
              auth_uuid:data.auth_uuid
            }
          })
        } else{
          cb()
          this.setState({error:data.error})
        }
      },
      failure:(err)=>{
        cb()
        this.setState({error:'There was an unexpected error'})
      }

    })
  }


  render(){
    return (
      <div className='login-page'>
        <LoginBackHeader />
        <div className='content login-content'> 
          <div className='login-sms-icon-container'>
            <SMS/>
          </div>
          <div className='login-header'>
            Sign In with Text Message
          </div>
          <div className='login-subheader'>
            We’ll send a 4-digit code sent to your phone
          </div>
          <div className='login-sub-subheader'>
            Standard text messaging rates apply
          </div>

          <div className='login-phone-row'>
            <div className='login-plus-1'>+1</div>
            <input 
              type='tel'
              className='login-phone-input'
              placeholder='Mobile phone number'
              value={this.formatPhone(this.state.phone)}
              onChange={this.onPhoneInput}
            />
          </div>

          {this.state.error && (
          <div className='login-phone-error'>
            {this.state.error}
          </div>

          )}

          {this.state.phone.length !== 10 && (
            <div className='login-phone-button login-phone-button-disabled'>
              <div>Enter phone number to continue</div>
            </div>
          )}

          {this.state.phone.length === 10 && (
            <ButtonWithSpinner
              ref={this.buttonRef}
              onClick={(cb)=>this.getCode(cb)}
              className='login-phone-button login-phone-button-enabled'
              text='Send Code'
            />
          )}
        </div>
      </div>
    )
  }
}
      

export default LoginOwner 


