import React, { Component, useRef } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import ToastContextHOC from "../contexts/ToastContextHOC"
import ModifierModalContextHOC from "../contexts/ModifierModalContextHOC"
import DesktopSidebar from "./DesktopSidebar"
import FloppyDisk from "../orders/svg/FloppyDisk"
import MenuItemBox from "./MenuItemBox"
import Breadcrumbs from "./Breadcrumbs"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import OrderToast from "../orders/OrderToast"
import Deactivate from "../orders/svg/Deactivate"
import Edit from "../orders/svg/Edit"
import Plus from "../orders/svg/PlusMedium"
import Warning from "../orders/svg/Warning"
import Delete from "../orders/svg/DeleteTag"
import Check from "../orders/svg/CheckThick"
import Grid from "../orders/svg/Grid"
import CirclePlus from "../orders/svg/CirclePlus"
import ButtonWithSpinner from "../orders/ButtonWithSpinner"
import Thumbtack from "../orders/svg/Thumbtack"
import { DndProvider, useDrag, useDrop } from "react-dnd";
//import { usePreview } from 'react-dnd-preview';
import {Capacitor} from '@capacitor/core';

import "./Menu.css"
import "./ModifierClassEdit.css"
import "./MenuItemEdit.css"

const Api = require("../api")
const Money =  require("../helpers/money")


function formatPrice(price){
  if(!price) return ''
  price = price/100
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  price = formatter.format(price).substr(1)
  return price
}

function filterMoneyInput(val){
  try {
    if(val && val.substr(0,1) === '$') val = val.substr(1)
    if(val){
      return val.match(/^-?\d+(?:\.\d{0,2})?/)[0]
    } else {
      return val
    }
  } catch (err){
    return ''
  }
}

const MobileModifierGrid = ({modifier, showPrice, that, index})  => {
  let initialPrice = modifier.item.updated_price ? modifier.item.updated_price : modifier.item.price 
  // hackyness for "attached" modifiers
  if(modifier.isNew && modifier.updated_price) initialPrice = modifier.updated_price


  const [price, setPrice] = React.useState(formatPrice(initialPrice));
  const [name, setName] = React.useState(modifier.name);

  const ref = useRef(null)

  const [{isOver, draggedItem}, drop] = useDrop({
      accept: 'modifier',
      hover:(item, monitor) => {
      },
      collect:(monitor)=>{
        console.log("is over")
        return {
          isOver : monitor.isOver(),
          draggedItem:monitor.getItem()
        }
      },
      drop: () => ({index:modifier.newRowOrder}),
  });

  const [{isDragging}, drag] = useDrag({
      type:'modifier',
      item: modifier,
      end: (item, monitor) => {
        let dropResult = monitor.getDropResult()
        if(dropResult){
          let droppedIndex = dropResult.index
          let oldIndex = modifier.newRowOrder
          that.setState({a:'b'})
          that.reorderModifiers(oldIndex, droppedIndex)          
        }
       },
       collect: (monitor) => ({
          isDragging: monitor.isDragging(),
      }),
  });


    let topBorder = false
    let bottomBorder = false
    if(isOver && draggedItem.newRowOrder > modifier.newRowOrder){
      topBorder = true
    }
    
    // bottom
    if(isOver && draggedItem.newRowOrder < modifier.newRowOrder){
      bottomBorder=true
    }

  /*
  if(isDragging){
    that.setState({draggingGridIndex:index})
  }*/

  drop(drag(ref))

  return (
    <div 
      ref={ref}  
      style={{
        padding:'20px 10px', 
        display:'flex', 
        alignItems:'center', 
        boxSizing:'border-box',
        opacity:isDragging ? 0 : 1,
        position:'relative',
      }}
    >

      {topBorder && (
        <div className='grid-top-border'></div>
      )}

      {bottomBorder  && (
        <div className='grid-bottom-border'></div>
      )}
      
      <Grid  style={{height:'20px', width:'20px'}} />
      {isDragging && (
          <div style={{width:'calc(100vw - 40px)', positon:'absolute', top:'0px', left:'0px', height:'20px', background:'white', zIndex:9999}}></div>
      )}
    </div>
  )
}



const MobileModifier = ({modifier, showPrice, that})  => {
  let initialPrice = modifier.item.updated_price ? modifier.item.updated_price : modifier.item.price 
  // hackyness for "attached" modifiers
  if(modifier.isNew && modifier.updated_price) initialPrice = modifier.updated_price


  const [price, setPrice] = React.useState(formatPrice(initialPrice));
  const [name, setName] = React.useState(modifier.name);

  const ref = useRef(null)
  
  const [{isOver, draggedItem}, drop] = useDrop({
      accept: 'modifier',
      hover:(item, monitor) => {
      },
      collect:(monitor)=>{
      return {
        isOver : monitor.isOver(),
        draggedItem:monitor.getItem()
        // we really need to know 
      }},
      drop: () => ({index:modifier.newRowOrder}),
  });

  drop(ref)


  let topBorder = false
  let bottomBorder = false
  if(isOver && draggedItem.newRowOrder > modifier.newRowOrder){
    topBorder = true
  }
  
  // bottom
  if(isOver && draggedItem.newRowOrder < modifier.newRowOrder){
    bottomBorder=true
  }

  console.log("dragged item", draggedItem)
  return (
    <div 
      ref={ref}  
      style={{position:'relative'}}
      className={classnames('modifier-class-edit-modifier-row-mobile')}
    >

      {topBorder && (
        <div className='mobile-top-border'></div>
      )}

      {bottomBorder  && (
        <div className='mobile-bottom-border'></div>
      )}

      {modifier.newMenuItemUUID !== undefined && (
        <input 
          key={modifier.newMenuItemUUID}
          className='modifier-class-edit-name' 
          value={name}
          onChange={(e)=>{
            setName(e.target.value)
            that.updateName(modifier, e.target.value)
          }}
        />
      )}
      
      {modifier.newMenuItemUUID == undefined && (
        <div className='modifier-class-edit-name'><div>{modifier.item.name}</div></div>
      )}
      {showPrice && (
        <input 
          className='modifier-class-edit-price'
          tyoe="number"
          placeholder='$0.00'
          onChange={(e)=>{
            that.updatePrice(modifier, filterMoneyInput(e.target.value))
            if(!modifier.newMenuItemUUID){
              that.setState({edited:true})
            }
            setPrice(filterMoneyInput(e.target.value))
          }}
          value={price.length > 0 ? '$'+price :  null}
        />
      )}
      <Delete 
        className='modifier-delete-svg'
        onClick={()=>{
          // dont prompt to delete a newly created guy that hasn't been filled out yet
          if(!modifier.item.name && modifier.createNewMenuItem){
            that.deleteModifier(modifier)
          } else {
            if(window.confirm('Are you sure you want to delete ' + modifier.item.name + ' from this modifier group?')){
              that.deleteModifier(modifier)
            }
          }
        }}
      />

    </div>
  )
}

const DesktopModifier = ({modifier, showPrice, that})  => {
  let initialPrice = modifier.item.updated_price ? modifier.item.updated_price : modifier.item.price 
  // hackyness for "attached" modifiers
  if(modifier.isNew && modifier.updated_price) initialPrice = modifier.updated_price


  const [price, setPrice] = React.useState(formatPrice(initialPrice));
  const [name, setName] = React.useState(modifier.name);

  const ref = useRef(null)
  
  const [{isDragging}, drag] = useDrag({
      type:'modifier',
      item: modifier,
      end: (item, monitor) => {
        let dropResult = monitor.getDropResult()
        if(dropResult){
          let droppedIndex = dropResult.index
          let oldIndex = modifier.newRowOrder
          that.reorderModifiers(oldIndex, droppedIndex)          
        }
      },
     collect: (monitor) => ({
          isDragging: monitor.isDragging(),
      }),
  });

  const [{isOver, draggedItem}, drop] = useDrop({
      accept: 'modifier',
      hover:(item, monitor) => {
      },
      collect:(monitor)=>{
      return {
        isOver : monitor.isOver(),
        draggedItem:monitor.getItem()
        // we really need to know 
      }},
      drop: () => ({index:modifier.newRowOrder}),
  });

  drag(drop(ref))

  return (
    <div 
      ref={ref}  
      className={classnames('modifier-class-edit-modifier-row', 
        {'modifier-class-edit-dragged':isDragging},
        {'modifier-class-edit-hovered-top':isOver && draggedItem.newRowOrder > modifier.newRowOrder},
        {'modifier-class-edit-hovered-bottom':isOver && draggedItem.newRowOrder < modifier.newRowOrder}
      )}
    >
      <Grid />
      {modifier.newMenuItemUUID !== undefined && (
        <input 
          key={modifier.newMenuItemUUID}
          className='modifier-class-edit-name' 
          value={name}
          onChange={(e)=>{
            setName(e.target.value)
            that.updateName(modifier, e.target.value)
          }}
        />
      )}
      
      {modifier.newMenuItemUUID == undefined && (
        <div className='modifier-class-edit-name'><div>{modifier.item.name}</div></div>
      )}
      {showPrice && (
        <input 
          className='modifier-class-edit-price'
          placeholder='$0.00'
          onChange={(e)=>{
            that.updatePrice(modifier, filterMoneyInput(e.target.value))
            if(!modifier.newMenuItemUUID){
              that.setState({edited:true})
            }
            setPrice(filterMoneyInput(e.target.value))
          }}
          value={price.length > 0 ? '$'+price :  null}
        />
      )}
      <Delete 
        className='modifier-delete-svg'
        onClick={()=>{
          // dont prompt to delete a newly created guy that hasn't been filled out yet
          if(!modifier.item.name && modifier.createNewMenuItem){
            that.deleteModifier(modifier)
          } else {
            if(window.confirm('Are you sure you want to delete ' + modifier.item.name + ' from this modifier group?')){
              that.deleteModifier(modifier)
            }
          }
        }}
      />

    </div>
  )
}

const MyPreview = () => {
  return null
  /*
  const {display, itemType, item, style} = usePreview();
  if (!display) {
    return null;
  }

  if (Capacitor.getPlatform() === 'web') return null
  return (
    <div 
      className='modifier-dragged'
      style={style}
    >
      {item.item.name}
    </div>
  )
  */
};


class ModifierClassEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile:window.innerWidth <= 599,
      isTablet:window.innerWidth > 599 && window.innerWidth < 1000,
      isDesktop:window.innerWidth >= 1000,
      deletedModifiers:[],
      loading:true,
      draggingGridIndex:null
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.menu_item_id){
      this.menu_item_id = params.menu_item_id
    }  

    if(this.props.edit){
      this.modifier_class_id = this.props.match.params.modifier_class_id
    } else if(this.props.create){
      this.menu_uuid = this.props.match.params.menu_uuid
      // hack to strip out query string
      if(this.menu_uuid.indexOf('?') > -1){
        let idx = this.menu_uuid.indexOf('?')
        this.menu_uuid = this.menu_uuid.substr(0, idx)
      }
    }
    window.chainsaw = this
  }

  componentDidMount(){
    this.props.modal.reset()
    if(this.props.edit){
      this.loadModifierClass()
    } else{
      this.initializeNewModifierClass()
    }
  }

  componentDidUpdate(prevProps){
    if(
      this.props.modal.modifier && 
      JSON.stringify(prevProps.modal.modifier) !== JSON.stringify(this.props.modal.modifier)
    ){
      let modifiers = this.state.modifiers
      let modifier = JSON.parse(JSON.stringify(this.props.modal.modifier))
      modifier['isNew'] = true
      modifiers.push(modifier)
      for(var i in  modifiers){
        modifiers[i].newRowOrder = parseInt(i) 
      }
      this.setState({modifiers:modifiers, edited:true}, 
       ()=>this.props.modal.modifier = null)
    }

    if(prevProps.modal.confirmed === false && this.props.modal.confirmed === true){
      this.props.modal.reset()
      this.save(true)
    }

  }

  initializeNewModifierClass(){
    this.setState({
      displayText:'',
      internalName:'',
      priceOverride:'',
      hasPriceOverride:false,
      loaded:true, 
      modifierClass:null,
      modifiers:[],
      modifierRequired:false,
      loading:false,
      maximumNumSelections:null,
      multipleModifiersChecked:false, 
      maximumNumSelectionsType:'unlimited'
    })
  }

  loadModifierClass(){
    Api.callApi({
      endpoint:'/api/menu/modifier-class/'+this.modifier_class_id,
      success:(data)=>{
        this.menu_uuid = data.menu_uuid
        let modifiers = data.modifiers
        for(let i in modifiers){ 
          if(modifiers[i].item){
            let item = modifiers[i].item 
            if(
              item.blackout_until &&
              item.blackout_until_timestamp > this.state.centralTimestamp
            ) {
              item.blacked_out = true
              item.blackout_until_local_ts = item.blackout_until_timestamp - this.props.user.rest.central_offset_seconds
            }
          }
        }

        modifiers = this.initialSortModifiers(modifiers)
        for(var i in  modifiers){
          modifiers[i].newRowOrder = parseInt(i) 
        }
        let priceOverride = data.modifier_class.price_override
        if(priceOverride){
          priceOverride = formatPrice(priceOverride)
        } else {
          priceOverride = ''
        }
        this.setState({
          displayText:data.modifier_class.display_text,
          internalName:data.modifier_class.internal_name,
          priceOverride:priceOverride,
          hasPriceOverride:!!data.modifier_class.price_override,
          loaded:true, 
          modifierClass:data.modifier_class,
          modifiers:modifiers,
          modifierRequired:data.modifier_class.minimum_num_selections,
          loading:false,
          maximumNumSelections:data.modifier_class.maximum_num_selections,
          multipleModifiersChecked: data.modifier_class.maximum_num_selections !== 1,
          maximumNumSelectionsType: data.modifier_class.maximum_num_selections   ? 'limited': 'unlimited',
        })
      },
      failure:(err)=>{
        console.log("error", err)
        alert('Error')
      }
    })
  }

  deleteModifier(modifier){
    // okay we need to delete this modifier from state AND keep track of it
    // as deleted
    let mods = []
    for(let i in this.state.modifiers){
      // if we're deleteing a newly minted item
      if(modifier.createNewMenuItem){
        if(this.state.modifiers[i].newMenuItemUUID !== modifier.newMenuItemUUID){
          mods.push(this.state.modifiers[i])
        }
      } else {
        if(this.state.modifiers[i].menu_item_id !== modifier.menu_item_id){
          mods.push(this.state.modifiers[i])
        }
      }
    }
    let deletedModifiers = this.state.deletedModifiers
    if(modifier.modifier_id){
      deletedModifiers.push(modifier.modifier_id)
    }
    for(var i in  mods){
      mods[i].newRowOrder = parseInt(i) 
    }
    this.setState({
      edited:true,
      modifiers:mods,
      deletedModifiers:deletedModifiers
    })  
  }

  updateName(modifier, value){
    let mods = this.state.modifiers
    for(let i in mods){
      if(modifier.newMenuItemUUID === mods[i].newMenuItemUUID){
        modifier.name = value
      }
    }
  }

  updatePrice(modifier, value){
    if(value){
      value = value.match(/^-?\d+(?:\.\d{0,2})?/)[0]
    } else {
      value = 0
    }
    value = parseInt(parseFloat(value)*100)
    // find the modifier in our list of modifiers
    let modifiers = this.state.modifiers
    for(var i in modifiers){
      if(modifier.modifier_id){
        if(modifiers[i].modifier_id === modifier.modifier_id){
          modifiers[i]['item']['updated_price'] = value
          this.setState({modifiers:modifiers})
          return
        }
      
     } else if (modifier.isNew){
        if(modifier.menu_item_id === modifiers[i].menu_item_id){
          modifiers[i]['updated_price'] = value
          modifiers[i]['price'] = value
        }
     } else{
        if(modifiers[i].newMenuItemUUID === modifier.newMenuItemUUID){
          modifiers[i]['price'] = value
          //this.setState({modifiers:modifiers})
          return
        }
      }
    }
  }

  sortModifiers(modifiers){
    function sort_modifiers(a, b) {
      if (a.newRowOrder < b.newRowOrder) return -1; 
      else if (a.newRowOrder > b.newRowOrder) return 1;
      return 0;
    }   
    modifiers = modifiers.sort(sort_modifiers);
    return modifiers
  }


  initialSortModifiers(modifiers){
    function sort_modifiers(a, b) {
      if (a.row_order < b.row_order) return -1; 
      else if (a.row_order > b.row_order) return 1;
      else if (a.created_at < b.created_at) return -1; 
      else if (a.created_at > b.created_at) return 1;
      return 0;
    }   
    modifiers = modifiers.sort(sort_modifiers);
    return modifiers
  }

  saveEnabled(){
    if(this.state.edited) return true
    else return false
  }

  save(confirmed){
    if(this.props.create){
      return this.saveNewModifierClass(confirmed)
    } else{
      this.updateModifierClass(confirmed)
    }
  }

  saveNewModifierClass(confirmed){
    this.setState({loading:true})

    let modifiers = this.sortModifiers(this.state.modifiers)

    //strip any modifiers without name
    modifiers = modifiers.filter((m)=>m.name !== '')

    for(let i in modifiers){
      modifiers[i].row_order = i
      if(modifiers[i]['updated_price'] !== null && !modifiers[i]['newMenuItemUUID']){
        modifiers[i]['price'] = modifiers[i]['updated_price']
      }
    }

    let data  = {
      menu_uuid:this.menu_uuid,  
      internal_name:this.state.internalName,
      display_text:this.state.displayText,
      modifiers:modifiers,
      confirm_change_ok:confirmed
    }
    let minimum_num_selections
    if(!this.state.modifierRequired){
      data['minimum_num_selections'] = null
    } /*else if (this.state.maximumNumSelections && this.state.multipleModifiersChecked){
      data['minimum_num_selections'] = this.state.maximumNumSelections 
    } */else{
      data['minimum_num_selections'] = 1 
    }


    if(!this.state.multipleModifiersChecked){
      data['maximum_num_selections'] = 1
    } else if(this.state.maximumNumSelectionsType === 'limited'){
      data['maximum_num_selections'] = this.state.maximumNumSelections 
    } else {
      data['maximum_num_selections'] = null
    }

    if(this.state.hasPriceOverride){
      data['price_override'] = parseInt(parseFloat(this.state.priceOverride)*100)
    } else {
      data['price_override'] = null
    }

    if(this.menu_item_id){
      data['menu_item_id'] = this.menu_item_id
    }


    Api.callApi({
      endpoint:'/api/tablet/create-modifier-class',
      method:'post',
      data:data,
      success:(data)=>{
        if(data.needs_confirmation){
          this.props.modal.reset()
          this.setState({loading:false})
          this.props.history.push({
            pathname:this.props.location.pathname +'/modifier-class-confirm-modal',
            state:{
              items:data.impacted_items,
              orderableChangedItems:data.orderable_changed_items
            }
          })
          return
        }

        // if we got here from the menu item page
        if(this.menu_item_id){
          let path
          if(this.state.isDesktop){
            path = '/menu/menu-item-edit/' + this.menu_item_id
          } else {
            path = '/menu/menu-item/'+this.menu_uuid +'/' + this.menu_item_id
          }
          let toast = {
            type:'generic',
            title:'Modifier Group Created',
            dontReloadItem:true
          }
          let state = null
          this.props.toast.navigateThenToast(path,toast)
        } else {

          if(this.state.isDesktop){
            let path = '/menu/menu-item-create/' + this.menu_uuid
            let toast = {
              type:'generic',
              title:'Modifier Group Created',
              dontReloadItem:true
            }
            this.props.toast.navigateThenToast(path,toast)
          } else {
            this.props.history.replace({
              pathname:'/menu/modifier-class/'+data.modifier_class_id,
              state:{toast:{
                type:'generic',
                title:'Modifier Group Created',
              }}
            })
          }
        }
      },
      failure:(err)=>{
        alert('An unexpected error occured')
        this.setState({loading:false})
      }

    })
  }

  updateModifierClass(confirmed){
    this.setState({loading:true})
    let modifiers_price = []
    if(!this.state.hasPriceOverride){
      for(let i in this.state.modifiers){
        if(this.state.modifiers[i].item && !this.state.modifiers[i].newMenuItemUUID){
          if (this.state.modifiers[i]['item'].updated_price){
            modifiers_price.push({
              modifier_id:this.state.modifiers[i].modifier_id,
              price:this.state.modifiers[i]['item'].updated_price
            })
          }
        }
      }
    }
    let data  = {
        modifier_class_id:this.state.modifierClass.modifier_class_id,
        internal_name:this.state.internalName,
        display_text:this.state.displayText,
        modifiers_price:modifiers_price,
        deleted_modifiers:this.state.deletedModifiers,
        confirm_change_ok:confirmed
    }
    let minimum_num_selections
    if(!this.state.modifierRequired){
      data['minimum_num_selections'] = null
    } /*else if (this.state.maximumNumSelections && this.state.multipleModifiersChecked){
      data['minimum_num_selections'] = this.state.maximumNumSelections
    } */else{
      data['minimum_num_selections'] = 1 
    }


    if(!this.state.multipleModifiersChecked){
      data['maximum_num_selections'] = 1
    } else if(this.state.maximumNumSelectionsType === 'limited'){
      data['maximum_num_selections'] = this.state.maximumNumSelections 
    } else {
      data['maximum_num_selections'] = null
    }

    if(this.state.hasPriceOverride){
      data['price_override'] = parseInt(parseFloat(this.state.priceOverride)*100)
    } else {
      data['price_override'] = null
    }

    // we gotta get the row order going on here. 
    // order by newRowOrder
    // return value needs to be pairs of {'modifier_id':<>, 'row_order':<>} 
    let modifiers = []
    let modifiersChanged = false
    for(let i in this.state.modifiers){
      if(this.state.modifiers[i].newRowOrder !== undefined) modifiersChanged = true
    }
    if(modifiersChanged){
      for(let i in this.state.modifiers){
        //is new means new modifier not new menu item
        if(this.state.modifiers[i].isNew){
          modifiers.push({
            price:this.state.modifiers[i].updated_price,
            menu_item_id:this.state.modifiers[i].menu_item_id,
            row_order:i,
            create:true
          })
        } else if (this.state.modifiers[i].newMenuItemUUID){
          modifiers.push({
            create_new_item:true,
            row_order:i,
            price:this.state.modifiers[i].price,
            name:this.state.modifiers[i].name
          })
        } else{
          modifiers.push({
            modifier_id:this.state.modifiers[i].modifier_id,
            row_order:i
          })
        }
      }
    }
    modifiers = modifiers.filter((m)=>m.name !== '')
    data['modifiers'] = modifiers

    Api.callApi({
      endpoint:'save-modifier-class',
      data:data,
      success:(data)=>{
        if(data.needs_confirmation){
          this.props.modal.reset()
          this.setState({loading:false})
          this.props.history.push({
            pathname:this.props.location.pathname +'/modifier-class-confirm-modal',
            state:{
              items:data.impacted_items,
              orderableChangedItems:data.orderable_changed_items
            }
          })
          return
        }

        this.props.toast.goBackThenToast({
          type:'modifier-class-edit'
        })

        /*
        this.props.history.replace({
          pathname:'/menu/modifier-class/'+this.modifier_class_id,
          state:{toast:{
            type:'modifier-class-edit'
          }}
        })*/
      },
      failure:(err)=>{
        alert('An unexpected error occured')
        this.setState({loading:false})
      }

    })
  }

  insertBlankModifier(){
    let mods = this.state.modifiers
    mods.push({
      name:'',
      price:0,
      item:{},
      createNewMenuItem:true,
      newMenuItemUUID:Math.floor(Math.random() * 100000)
    })
    for(var i in  mods){
      mods[i].newRowOrder = parseInt(i) 
    }
    this.setState({modifiers:mods})
  }

  reorderModifiers(oldIndex, droppedIndex){
    let modifiers = JSON.parse(JSON.stringify(this.sortModifiers(this.state.modifiers)))
    let newModifiers = [] 
    if(oldIndex < droppedIndex){
      for(let i in modifiers){
        i = parseInt(i)
        if(i <= droppedIndex && i !== oldIndex && i > oldIndex){
          let old = modifiers[i].newRowOrder
          modifiers[i].newRowOrder = modifiers[i].newRowOrder - 1 
          let new2 = modifiers[i].newRowOrder
          console.log(`changed ${old} to ${new2}`)
        } else if (i === oldIndex){
          modifiers[i].newRowOrder = droppedIndex
        }
      }
    } else if(droppedIndex < oldIndex){
      for(let i in modifiers){
        i = parseInt(i)
        if(i === oldIndex){
          modifiers[i].newRowOrder = droppedIndex
        } else if (i >= droppedIndex && i < oldIndex){
          let old =  modifiers[i].newRowOrder
          let new2 = old + 1
          modifiers[i].newRowOrder = modifiers[i].newRowOrder + 1
          console.log(`changed ${old} to ${new2}`)
        }

      }
    }
    this.setState({modifiers:modifiers, edited:true})
  }


  renderContent(){
    let that = this
    if(this.state.loading) return (<Loading />)
    return (
      <>
        <div className={classnames('orders-content', 'modifier-class-edit-page',
            {'menu-page-paddiing-top':!this.state.isDesktop})}
        >
          {this.state.isDesktop && (
            <div style={{paddingLeft:'0px', paddingRight:'0px'}} className='desktop-menu-item-header-row'>
              <div className='desktop-modifier-class-edit-title-container'>
                <>
                  {this.props.edit == true && (
                    <>
                      {this.state.internalName != false && (
                        <div className='desktop-modifier-class-internal-name'>
                          {this.state.internalName}
                        </div>
                      )}
                      <div className='desktop-modifier-class-title'>
                        {this.state.displayText}
                      </div>
                    </>
                  )}
                  
                  {this.props.create == true && (
                    <div className='desktop-modifier-class-title'>
                      New Modifier Group
                    </div>
                  )}

                 </> 
              </div>


              <div className='desktop-menu-item-buttons-row'>
                {this.saveEnabled() ? ( 
                  <ButtonWithSpinner
                    style={{marginRight:'15px'}}
                    text='Save'
                    svg={<FloppyDisk/>}
                    svgLeft={true}
                    className='white-text-desktop-button'
                    onClick={(cb)=>{
                      this.save()
                    }}
                    spinnerColor='white'
                  />
                ) : ( 
                  <div 
                    className='white-text-desktop-button disabled-button-opaque'
                    style={{marginRight:'15px'}}
                  >
                    <FloppyDisk />
                    <div>Save</div>
                  </div>
                )}
    
                <div 
                  className='blue-text-desktop-button'
                  onClick={()=>this.props.history.goBack()}
                >
                  <div>Cancel</div>
                </div>
    
              </div>

            </div>
          )}
          <MyPreview />
          {this.state.toast && (
            <OrderToast
              toast={this.state.toast}
              message={this.state.toast.item.name}
              type={this.state.toast.type}
            />
          )}

          <div className='modifier-class-edit-content-container'>
            <div className='modfifier-class-edit-left-side'>
              
              <div className='modifier-class-edit-title'>
                Modifier Group Names
              </div>

              <div className='edit-item-field-wrapper'>
                <div className='edit-item-label'>Instructions for customer*</div>
                <input 
                  className='edit-item-input'
                  value={this.state.displayText}
                  onChange={(e)=>this.setState({displayText:e.target.value, edited:true})}
                />
              </div>

              <div className='edit-item-field-wrapper'>
                <div className='edit-item-label'>Internal name (optional)</div>
                <input 
                  className='edit-item-input'
                  value={this.state.internalName}
                  onChange={(e)=>this.setState({internalName:e.target.value, edited:true})}
                />
              </div>

              <div className='modifier-class-edit-hr'></div>

              <div className='modifier-class-edit-title'>
                Modifier Options 
              </div>
              <div className='modifier-class-price-override-row'>
                <div className='modifier-class-price-override-row-left'>
                  <div 
                    onClick={()=>this.setState({hasPriceOverride:!this.state.hasPriceOverride, edited:true})}
                    className={classnames('checkbox', 
                      {'checkbox-checked':this.state.hasPriceOverride},
                      {'checkbox-unchecked':!this.state.hasPriceOverride})}
                  >
                    <Check/>
                  </div>
                  <div className='modifier-class-prive-override-label'>
                    Set same price for all modifiers
                  </div>
                  {this.state.hasPriceOverride && (
                    <input 
                      className='modifier-class-edit-price-override'
                      value={'$'+this.state.priceOverride}
                      onChange={(e)=>this.setState({priceOverride:filterMoneyInput(e.target.value), edited:true})}
                    />
                  )}
                </div>
              </div>

              <div className='modifier-class-modifiers-list'>
                {this.sortModifiers(this.state.modifiers).map((modifier) => {
                  if(modifier.modifier_type !== 'STRING'){
                    if(Capacitor.getPlatform() === 'web'){
                      return (
                        <>
                          <DesktopModifier 
                            key={modifier.newMenuItemUUID ? modifier.newMenuItemUUID : modifier.name} 
                            modifier={modifier} 
                            that={that} 
                            showPrice={!this.state.hasPriceOverride}
                          />
                        </>
                      )
                    } else {
                      return (
                        <div key={modifier.name} style={{width:'100%', display:'flex', alignItems:'center'}}>
                          <MobileModifierGrid
                            key={modifier.newMenuItemUUID ? modifier.newMenuItemUUID + 'grid' : modifier.name + 'grid'} 
                            modifier={modifier} 
                            that={that} 
                            showPrice={!this.state.hasPriceOverride}
                          />

                          <MobileModifier 
                            key={modifier.newMenuItemUUID ? modifier.newMenuItemUUID + 'grid2' : modifier.name + 'grid2'} 
                            modifier={modifier} 
                            that={that} 
                            draggingGridIndex={this.state.draggingGridIndex}
                            showPrice={!this.state.hasPriceOverride}
                          />
                        </div>
                      )
                    }
                  }
                })}
              </div>

              <div className='modifier-class-modifier-buttons-container'>
                <div
                  className='modifier-class-modifier-button'
                  style={{marginRight:'15px'}}
                  onClick={()=>{
                    if(this.props.create){
                      this.props.history.push({
                        pathname:this.props.location.pathname+"/modifier-modal-new-mc",
                        state:{
                          menu_uuid:this.menu_uuid,
                          deletedModifierIds:this.state.deletedModifiers
                        }
                      })
                    } else if (this.props.edit){
                      this.props.history.push({
                        pathname:this.props.location.pathname+"/modifier-modal-edit-mc",
                        state:{
                          modifierClass:this.state.modifierClass,
                          deletedModifierIds:this.state.deletedModifiers
                        }
                      })
                    }
                  }}
                >
                 <Thumbtack />
                 <div>Attach a modifier</div>
                </div>

                <div
                  className='modifier-class-modifier-button'
                  style={{marginLeft:'15px'}}
                  onClick={()=>{
                    this.insertBlankModifier()
                    this.setState({edited:true})
                  }}
                >
                 <CirclePlus/>
                 <div>Create new modifier</div>
                </div>
              
              </div>


              <div className='modifier-class-edit-hr'></div>

            </div> {/* modifier-class-left */}
            <div className='modifier-class-edit-min-max-container'>
              <div className='modifier-class-edit-title'>
                Settings
              </div>

              <div className='modifier-class-edit-question-title'>
                Is the customer required to choose a modifier?
              </div>

              <div className='modifier-class-radio-wrapper'>
                <input
                  onClick={()=>this.setState({modifierRequired:true, edited:true})} 
                  name='modifier-required'
                  type='radio'
                  checked={this.state.modifierRequired}
                />
                <div className='flexstart'>
                  <div>Yes</div>
                  {false && this.state.maximumNumSelectionsType === 'limited' && this.state.multipleModifiersChecked && (
                    <input
                      className='max-modifiers-input'
                      type='number'
                      value={this.state.maximumNumSelections}
                      onChange={(e)=>{
                        let val = e.target.value
                        if (!val){
                          this.setState({maximumNumSelections:null, edited:true})
                          return
                        } else{
                          val = val.replace(/\D/g,'');
                          this.setState({maximumNumSelections:val, edited:true})
                        }
                      }}
                    />
                  )}
                </div>


              </div>




              <div className='modifier-class-radio-wrapper'>
                <input
                  onClick={()=>this.setState({modifierRequired:false, edited:true})} 
                  name='modifier-required'
                  checked={!this.state.modifierRequired}
                  type='radio'
                />
                <div>No</div>
              </div>


              <div className='modifier-class-edit-question-title' style={{marginTop:'15px'}} >
                How many modifier options can the customer select?
              </div>

              <div className='modifier-class-radio-wrapper'>
                <input
                  name='how-many-modifiers'
                  type='radio'
                  onClick={()=>this.setState({multipleModifiersChecked:false, edited:true})}
                  checked={!this.state.multipleModifiersChecked}
                />
                <div>One option</div>
              </div>

              <div className='modifier-class-radio-wrapper' >
                <input
                  name='how-many-modifiers'
                  type='radio'
                  onClick={()=>this.setState({multipleModifiersChecked:true, edited:true})}
                  checked={this.state.multipleModifiersChecked}
                />
                <div>Multiple options</div>
              </div>
              
              <div className='modifier-class-radio-wrapper modifier-class-radio-indented' >
                <input
                  name='multiple-modifiers'
                  onClick={()=>this.setState({maximumNumSelectionsType:'unlimited', edited:true})}
                  checked={this.state.maximumNumSelectionsType==='unlimited' && this.state.multipleModifiersChecked}
                  type='radio'
                />
                <div>All available options</div>
              </div>

              <div className='modifier-class-radio-wrapper modifier-class-radio-indented' >
                <input
                  name='multiple-modifiers'
                  onClick={()=>this.setState({maximumNumSelectionsType:'limited', edited:true})}
                  checked={this.state.maximumNumSelectionsType==='limited' && this.state.multipleModifiersChecked}
                  type='radio'
                />
                <div className='flexstart'>
                  <div>Set a max limit</div>
                  {this.state.maximumNumSelectionsType === 'limited' && this.state.multipleModifiersChecked && (
                    <input
                      className='max-modifiers-input'
                      type='number'
                      value={this.state.maximumNumSelections}
                      onChange={(e)=>{
                        let val = e.target.value
                        if (!val){
                          this.setState({maximumNumSelections:null, edited:true})
                          return
                        } else{
                          val = val.replace(/\D/g,'');
                          this.setState({maximumNumSelections:val, edited:true})
                        }
                      }}
                    />
                  )}
                </div>
              </div>

              <div className='modifier-class-yellow-blurb'>
                <div className='modifier-class-yellow-blurb-top-row'>
                  <Warning />
                  <div>Looking for advanced modifiers?</div>
                </div>
                <div className='modifier-class-yellow-blurb-bottom'>
                  Contact our Captain team for modifiers like: pizza toppings, dozen bagels, etc
                </div>
              </div>

            </div> { /* min-max- container */ }

          </div> { /*modifier-class-edit-content-container */ }
  
        </div>
        {this.state.isDesktop !== true && (
          <div className='modifier-class-edit-footer-container'>
            <div 
              onClick={()=>this.props.history.goBack()}
              className='modifier-class-button'
            >
              <div>Cancel</div>
            </div>

            <div 
              onClick={()=>this.save()}
              className={classnames('modifier-class-button',
                {'modifier-class-button-save-disabled':!this.saveEnabled()},
                {'modifier-class-button-save-enabled':this.saveEnabled()}
              )}
            >
              <div>Save</div>
            </div>
          </div>
        )}
      </>
    )
  }

  renderBreadcrumbs(){
    if(this.props.edit && !this.state.modifierClass){
      return (
        <Breadcrumbs
          current=''
          crumbs={[]}
        />
      )
    }
    
    let current
    if(this.props.edit && this.state.modifierClass){
      current = 'Edit'
    }
    if(this.props.create){
      current = 'Create Modifier Group'
    }

    if(
      this.props.history && 
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.crumbs
    ){
      return (
        <Breadcrumbs
          current={current}
          crumbs={this.props.history.location.state.crumbs}
        />
      )
    } else{

      if(this.props.edit){
        let mc = this.state.modifierClass
        return (
          <Breadcrumbs
            current={current}
            crumbs={[
              {text:'Menus', path:'/menu/menus'},
              {text:'Modifier Groups', path:'/menu/modifiers/'+this.menu_uuid},
              {
                text:mc.internal_name ? mc.internal_name : mc.display_text,
                path:'/menu/modifier-class-from-menu-page/'+this.menu_uuid+'/'+mc.modifier_class_id
              }
            ]}
          />
        )
      } else if (this.props.create){
        return (
          <Breadcrumbs
            current={current}
            crumbs={[
              {text:'Menus', path:'/menu/menus'},
              {text:'Modifier Groups', path:'/menu/modifiers/'+this.menu_uuid}
            ]}
          />
        )
      }
    }
      
  }

  render(){
    if(this.state.isDesktop){
      return (
        <div className='desktop-menu-item-page-container'>
          <DesktopSidebar
            menu_uuid={this.menu_uuid}
          />
          <div className='desktop-content-with-crumbs'>
            {this.renderBreadcrumbs()}
            {this.renderContent()}
          </div>
        </div>
      )
    } else {
      return this.renderContent()
    }
  }

}
      

export default withRouterAndRef(ModifierModalContextHOC(ToastContextHOC(UserContextHOC(ModifierClassEdit)))) 


