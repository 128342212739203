import React, { Component } from 'react';
import { Capacitor, Plugins } from '@capacitor/core'
import withRouterAndRef from "../withRouterAndRef"
let { UpdatePlugin, AppVersion, AppVersionPlugin } = Plugins;

if(!AppVersion && AppVersionPlugin){
  AppVersion = AppVersionPlugin
}

const Api = require("../api")

export const HeartbeatContext = React.createContext()

class HeartbeatProvider extends Component {
  
  constructor(props){
    super(props)
    this.state = {
    }

  }

  componentDidMount(){
    this.mounted = true
    this.heartbeat()
  }

  componentWillUmount(){
    this.mounted = false
  }

  async heartbeat(){
    const _status = window.document.hidden ? 'hidden' : 'online';

    let java_version = await AppVersion.getVersion()
    // only heartbeat if they are logged in
    if(window.API_KEY){
      Api.callApi({
        endpoint:'heartbeat',
        data:{
          status: _status,
          battery_level: this.state.batteryLevel,
          battery_charging: this.state.batteryCharging,
          is_mounted:this.mounted,
          is_captain:true,
          captain_js_version:window.JS_VERSION,
          captain_java_version:java_version.version_code,
          captain_java_version_name:java_version.version_name,
          device_name:java_version.device,
          platform:Capacitor.getPlatform(),
          os_version:java_version.os_version
        },
        success:(data)=>{
         setTimeout(()=>this.heartbeat(), 60000)
        },
        failure:(err)=>{
          setTimeout(()=>this.heartbeat(), 60000)
        }
      })
    } else {
      setTimeout(()=>this.heartbeat(), 6000)
    }
  }

  battery() {
    let that = this
    if(window.navigator && window.navigator.getBattery){
      window.navigator.getBattery().then(battery => {
        battery.addEventListener('chargingchange', () => {
          that.setState({ batteryCharging: battery.charging })
        })

        battery.addEventListener('levelchange', () => {
          that.setState({ batteryLevel: battery.level * 100 });
        })
      });
    }
  }

  render() {
    return  (
      <HeartbeatContext.Provider value={this.state}>
        {this.props.children}
      </HeartbeatContext.Provider>
    )
  }
}

export default withRouterAndRef(HeartbeatProvider);
