import React, { Component } from "react"
import UserContextHOC from "./contexts/UserContextHOC"
import withRouterAndRef from "./withRouterAndRef"
import * as Sentry from "@sentry/browser";
import Warning from "./orders/svg/Warning"
import "./ErrorBoundary.css"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    Sentry.configureScope((scope) => {
      scope.setExtra("error", error)
      scope.setExtra("errorInfo", errorInfo) 
      Sentry.captureMessage(error)
    })
  }

  render() {
    if (this.state.hasError) {
      return  (
        <div className='error-boundary-outer-container'>
          <div className='error-boundary-container'>
            <Warning className='error-boundary-warning' />
            <div className='error-boundary-something-went-wrong'>
              Something went wrong.
            </div>
            <div 
              className='error-boundary-back-to-kitchen-button'
              onClick={()=>{
                this.setState({hasError:false}, ()=>{
                  this.props.history.replace({pathname:'/orders/kitchen'})
                })
              }}
            >
              <div>
                Back to Kitchen
              </div>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children; 
  }
}

export default withRouterAndRef(UserContextHOC(ErrorBoundary))
