import React, { Component } from "react"
import DesktopSidebar from "../../menu/DesktopSidebar"
import UserContextHOC from "../../contexts/UserContextHOC"
import Orders from "./Orders"
import Summaries from "./Summaries"
import Payouts from "./Payouts"
import Transactions from "./Transactions"
import Reconcilliation from "./Reconcilliation"
import Diners from "./Diners"
import Loading from "../../Loading/Loading"
import "./Finance.css"

class Finance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: "Orders",
      restLoaded:false,
    }
  }

  componentDidMount(){
    this.loadRest()
  }

  loadRest(){
    if(!this.props.user.rest || !window.API_KEY){
      setTimeout(()=>this.loadRest(), 100)
      return
    }
    this.setState({
      rest:this.props.user.rest
    })
  }

  renderToggles() {
    return ["Orders", "Summaries", "Transactions",  "Payouts", "Diners"].map(
      (t, i) => (
        <button
          key={i}
          style={{
            color: t === this.state.toggle ? "#fff" : "#4284f4",
            borderColor: t === this.state.toggle ? "#fff" : "#4284f4",
          }}
          className={
            t === this.state.toggle ? "button mh16" : "button secondary mh16"
          }
          onClick={() => this.setState({ toggle: t })}
        >
          {t}
        </button>
      )
    )
  }

  renderContent() {
    if (this.state.toggle === "Orders") return <Orders rest={this.state.rest} {...this.props} />
    if (this.state.toggle === "Summaries") return <Summaries rest={this.state.rest} {...this.props} />
    if (this.state.toggle === "Payouts") return <Payouts rest={this.state.rest} {...this.props} />
    if (this.state.toggle === "Transactions")
      return <Transactions rest={this.state.rest} {...this.props} />
    if (this.state.toggle === "Diners") return <Diners rest={this.state.rest} {...this.props} />
    if (this.state.toggle === "Reconcilliation") 
      return <Reconcilliation rest={this.state.rest} {...this.props} />
  }

  render() {
    if(!this.state.rest){
      return <Loading/>
    } 
    return (
      <div className='desktop-menu-item-page-container'>
        <DesktopSidebar/>
        <div className='AppBody' style={{backgroundColor:'rgb(222, 232, 243)', minHeight:'100vh'}}>
          <div className='fluid-content--body'>
            <div className="Finance">
              {this.renderToggles()}
              <br />
              {this.renderContent()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserContextHOC(Finance)
