import React from "react"
import { Tabs } from "../../components"
import ProfilePage from "./profilepage"
import BrandPage from "./brandpage"
import Loading from "../../../Loading/Loading"
import UserContextHOC from "../../../contexts/UserContextHOC"
import DesktopSidebar from "../../../menu/DesktopSidebar"
import "./profile.scss"

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this.loadRest()
  }

  loadRest(){
    if(!this.props.user.rest || !window.API_KEY){
      setTimeout(()=>this.loadRest(), 100)
      return
    }
    this.setState({rest:this.props.user.rest})
  }

  tm = () => ({
    Profile: <ProfilePage rest={this.state.rest} {...this.props} />,
    Brand: <BrandPage rest={this.state.rest} {...this.props} />,
  })

  render() {
    if(!this.state.rest){
      return <Loading/>
    }
    return (
      <div className='desktop-menu-item-page-container'>
        <DesktopSidebar
        />
        <div className='AppBody'>
          <div className='fluid-content--body'>
            <div>
              <Tabs
                tabMap={this.tm()}
                childClass="pv-6 ph-2 flex-col"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserContextHOC(Profile)
