import React, { Component } from "react"
import classnames from 'classnames'
import LoginBackHeader from "./LoginBackHeader"
import UserContextHOC from "../contexts/UserContextHOC"
import ButtonWithSpinner from "../orders/ButtonWithSpinner"
import Phone2 from "../orders/svg/PhoneIncoming"
import ChevronLeft from "../orders/svg/ChevronLeft"
import "./Login.css"
const Api = require("../api")
const Phone = require("../helpers/phone")

class EnterCodeEmployee extends Component {
  constructor(props) {
    super(props)
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.rest &&
      this.props.history.location.state.phone
    ){
      this.state = {...this.props.history.location.state}
      this.state.code = ''
    } else {
      this.props.history.replace({
        pathname:'/login'
      })
    }
    this.buttonRef = React.createRef()
    this.handleEnterKey = this.handleEnterKey.bind(this)
  }

  componentDidMount(){
    this.mounted = true
    window.addEventListener("keydown", this.handleEnterKey)
  }

  componentWillUnmount(){
    window.addEventListener("keydown", this.handleEnterKey)
    this.mounted = false
  }

  handleEnterKey(e) {
    if(e.key === 'Enter'){
      if(
        this.buttonRef && 
        this.buttonRef.current &&
        this.state.code.length === 4
      ){
        window.document.activeElement.blur()
        this.buttonRef.current.click()
      }
    }
  }
  
  checkCode(cb){
    Api.callApi({
      endpoint:'check-login-code-robocall',
      data:{
        auth_uuid:this.state.auth_uuid,
        code:this.state.code
      },
      success:(data)=>{
        if(data.success){
          this.props.user.login(data.api_key)
          cb()
        } else{
          cb()
          this.setState({error:data.error})
        }
      },
      failure:(err)=>{
        cb()
        this.setState({error:'There was an unexpected error'})
      }
    })
  }

   resendCode(cb){
    if(this.resendingCode) return
    
    this.resendingCode = true
    
    Api.callApi({
      endpoint:'get-login-code-robocall',
      data:{phone:this.state.phone},
      success:(data)=>{
        if(data.success){
          this.setState({auth_uuid:data.auth_uuid})
          alert('Calling...')
          this.resendingCode = false
        } else{
          alert('Error calling') 
          this.resendingCode = false
        }
      },
      failure:(err)=>{
        this.resendingCode = false
        alert('Unexpected error resending code')
      }

    })
  }

  render(){
    return (
      <div className='login-page'>
       <LoginBackHeader/>
        <div className='content login-content'> 
          <div className='login-phone-icon-container'>
            <Phone2 style={{height:'40px', width:'40px', fill:'#0B1246'}}/>
          </div>
          <div className='login-header'>
            Sign In to {this.state.rest}
          </div>
          <div className='login-subheader'>
            We are calling you at {Phone.format(this.state.phone)}. <br/>
            Please answer the phone and enter the provided code
          </div>

          <div className='login-phone-row'>
            <input 
              type='tel'
              className={classnames('login-phone-input',
                {'input-error':this.state.error})}
              placeholder='Enter 4-digit code'
              value={this.state.code}
              onChange={(e)=>this.setState({code:e.target.value})}
            />
          </div>
          
          {this.state.error && (
          <div className='login-phone-error'>
            {this.state.error}
          </div>

          )}

          {this.state.code.length !== 4 && (
            <div className='login-phone-button login-phone-button-disabled'>
              <div>Next</div>
            </div>
          )}

          {this.state.code.length === 4 && (
            <ButtonWithSpinner
              ref={this.buttonRef}
              onClick={(cb)=>this.checkCode(cb)}
              className='login-phone-button login-phone-button-enabled'
              text='Next'
            />
          )}

          <div 
            className='resend-code-link'
            onClick={()=>this.resendCode()}
          >
            Resend Code in :15
          </div>
        </div>
      </div>
    )
  }
}
      

export default UserContextHOC(EnterCodeEmployee) 


