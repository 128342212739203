import React, { Component } from "react"
import withRouterAndRef from "../withRouterAndRef"
import UserContextHOC from "../contexts/UserContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import CaptainLogo from "../orders/svg/CaptainLogoDesktopSidebar"
import classnames from 'classnames'
import "./DesktopSidebar.css"

const Copy = require("../helpers/copy")
const Api = require("../api")
const Money =  require("../helpers/money")
const Timestamp = require("../helpers/timestamps")

class DesktopSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unconfirmedOrders:this.props.orders.unconfirmedOrders
    }
    window.d = this
  }

  componentDidUpdate(prevProps){
    if(JSON.stringify(this.state.unconfirmedOrders) !== JSON.stringify(this.props.orders.unconfirmedOrders)){
      this.setState({unconfirmedOrders:Copy.copy(this.props.orders.unconfirmedOrders)})
    }
  }

  totalunconfirmedOrders(){
    let total = 0 
    total += this.state.unconfirmedOrders['pastdue'].length
    total += this.state.unconfirmedOrders['zero-15'].length
    total += this.state.unconfirmedOrders['fifteen-30'].length
    total += this.state.unconfirmedOrders['thirty-45'].length
    total += this.state.unconfirmedOrders['45-plus'].length
    return total
  }

  render(){
    let that = this
    return (
      <div className='desktop-sidebar'>     
        <CaptainLogo />
        {this.props.user.rest &&  (
          <div className='desktop-sidebar-rest-name'>
            {this.props.user.rest.name}
          </div>
        )}
        {this.props.user.rest && this.props.user.rest.secondary_name && (
          <div className='desktop-sidebar-rest-secondary-name'>
            {this.props.user.rest.secondary_name}
          </div>
        )}
        <div className='desktop-sidebar-hr'></div>

        {this.props.user.isCaptainAdmin ?  (
          <>
            <div style={{fontSize:'20px', fontWeight:'bold'}}>
               Admin Mode 
            </div>
            <div 
              onClick={()=>{
                this.props.history.push({pathname:'/captain-admin/choose-rest'})
              }}
              style={{marginBottom:'20px'}}
              className='desktop-sidebar-link'
            >
              Switch Rest
            </div>
          </>
        ) : null }

        {this.props.user.isMemberLogin && !this.props.user.isCaptainAdmin && (
          <>
            <div 
              onClick={()=>{
                this.props.history.push({pathname:'/switch-rests'})
              }}
              style={{marginBottom:'20px'}}
              className='desktop-sidebar-link'
            >
              Switch Restaurant
            </div>
          </>
        )}

        <div className='desktop-side-bar-link-header'>
          ORDERING
        </div>

        <div 
          onClick={()=>{
            this.props.history.push({pathname:'/orders/kitchen'})
          }}
          className={classnames('desktop-sidebar-link',
            {'desktop-sidebar-link-selected':window.location.href.indexOf('orders') > -1})}
        >
         Orders ({this.state.unconfirmedOrders.length})
        </div>

        <div 
          onClick={()=>{
            this.props.history.push({pathname:'/menu/menus'})
          }}
          className={classnames('desktop-sidebar-link',
            {'desktop-sidebar-link-selected':window.location.href.indexOf('/menu/menus') > -1})}
        >
          Menus 
        </div>


        {this.props.menu_uuid && (
          <>
            <div 
              onClick={()=>{
                this.props.history.push({pathname:'/menu/menu/'+this.props.menu_uuid})
              }}
            className={classnames('desktop-sidebar-link',
              {'desktop-sidebar-link-selected':this.props.page === 'menu-items'})}
            >
              Menu Items 
            </div>
            <div 
              onClick={()=>{
                this.props.history.push({pathname:'/menu/modifiers/'+this.props.menu_uuid})
              }}
              className={classnames('desktop-sidebar-link',
                {'desktop-sidebar-link-selected':this.props.page === 'modifiers'})}
            >
              Menu Modifiers 
            </div>
          </>
        )}

        <div 
          onClick={()=>{
            this.props.history.push({pathname:'/hours'})
          }}
          className={classnames('desktop-sidebar-link',
            {'desktop-sidebar-link-selected':window.location.href.indexOf('hours') > -1})}
        >
          Hours
        </div>

        <div 
          onClick={()=>{
            this.props.history.push({pathname:'/profile'})
          }}
          className={classnames('desktop-sidebar-link',
            {'desktop-sidebar-link-selected':window.location.href.indexOf('profile') > -1})}
        >
          Profile
        </div>

        <div 
          onClick={()=>{
            this.props.history.push({pathname:'/finance'})
          }}
          className={classnames('desktop-sidebar-link',
            {'desktop-sidebar-link-selected':window.location.href.indexOf('finance') > -1})}
        >
          Reports
        </div>

        <div 
          onClick={()=>{
            this.props.history.push({pathname:'/qr'})
          }}
          className={classnames('desktop-sidebar-link',
            {'desktop-sidebar-link-selected':window.location.href.indexOf('qr') > -1})}
        >
          QR Codes
        </div>


        <div style={{position:'absolute', bottom:'15px'}}>
          {this.props.user.rest && (
            <div 
              style={{marginBottom:'15px'}}
              className='desktop-sidebar-link'
            >
              <a 
                href={'https://'+this.props.user.rest.subdomain+'.hngr.co'}
                target="_blank"
                style={{fontSize:'10px'}}
              >
                https://{this.props.user.rest.subdomain}.hngr.co
              </a>
            </div>
          )}

          <div 
            onClick={()=>{
              this.props.user.logout()
            }}
            className='desktop-sidebar-link'
          >
            Logout
          </div>
        </div>


      </div>
    )
  }
}
      

export default NewOrdersContextHOC(UserContextHOC(withRouterAndRef(DesktopSidebar))) 
