import React, { Component } from "react"
import ToastContextHOC from "../contexts/ToastContextHOC"
import ModifierModalContextHOC from "../contexts/ModifierModalContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import TriangleUp from "../orders/svg/TriangleUp"
import TriangleDown from "../orders/svg/TriangleDown"
import MenuItemBox from "./MenuItemBox"
import "./Menu.css"
import "./ModalSearch.css"
import "./ConfirmModifierClassChangeModal.css"


class ConfirmModifierClassChangeModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      closing:false,
      dropdown:false
    }
    if(
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.items
    ){
      this.items = this.props.history.location.state.items
      this.orderableChangedItems = this.props.history.location.state.orderableChangedItems
    } else{
      this.items = []
    }
    window.con = this
  }

  componentDidMount(){
    window.addEventListener('mousedown', this.handleClickOutside)
    document.body.style.overflow = "hidden"
  }
  componentWillUnmount(){
    window.removeEventListener('mousedown', this.handleClickOutside)
    document.body.style.overflow = "auto"
  }

  maybeClose(e){
    if (e.target.className === "menu-modal-background"){
      this.props.history.goBack()
    }
  }

  orderableChangedItemsStr(){
    if(this.orderableChangedItems.length == 1){
      return this.orderableChangedItems[0].name
    } else{
      let to_ret = ''
      for(let i in this.orderableChangedItems){
        to_ret += this.orderableChangedItems[i]['name'] + ' '
        return to_ret
      }
    }
  }

  render(){
    return (
      <div 
        className='menu-modal-background'
        style={{justifyContent:'center'}}
        onClick={(e)=>this.maybeClose(e)}
        id={this.state.closing ? "modal-background-exit" : null}
      >
        <div className='modifier-modal-confirm-container'>
          <div className='modifier-modal-confirm-title'>
            Modifier changes will appear in other menu items
          </div>
          
          <div 
            className='confirm-modal-adjustments-to'
            onClick={()=>{
              this.setState({dropdown:!this.state.dropdown})
            }}
          >
            <div>Adjustments to <b>{this.items.length} menu items</b></div>
            {this.state.dropdown === false ? <TriangleUp /> : <TriangleDown /> }
          </div>

          {this.state.dropdown === true && (
            <div className='confirm-modal-items'>
              {this.items.map(item => (
                <div 
                  key={item.menu_item_id}
                  className='confirm-modal-item'
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}

          {this.orderableChangedItems && this.orderableChangedItems.length > 0 && (
            <div 
              style={{marginTop:'25px'}}
              className='modifier-confirm-modal-orderable-changed-items'
            >
                Customers can order <b>{this.orderableChangedItemsStr()}</b> as an item. Changes will appear at the item level.
            </div>
          )}

          <div className='modifier-confirm-modal-button-row'>
            <div 
              className='modifier-confirm-modal-button modifier-confirm-modal-button-cancel'
              onClick={()=>{this.props.history.goBack(); this.props.modal.reset()}}
            >
              Cancel
            </div>

            <div 
              className='modifier-confirm-modal-button modifier-confirm-modal-button-save'
              onClick={()=>{
                this.props.modal.saveFunction ? 
                  this.props.modal.saveFunction() : 
                  this.props.modal.confirm()
              }}
            >
              Save
            </div>
          </div>
        </div>
      </div>
    )
    
  }
}
      

export default withRouterAndRef(ModifierModalContextHOC(ToastContextHOC(ConfirmModifierClassChangeModal))) 


