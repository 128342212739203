import React, { Component } from "react"
import OrderCard from "./OrderCard"
import classnames from "classnames"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import UserContextHOC from "../contexts/UserContextHOC"
import withRouterAndRef from "../withRouterAndRef"
import Bell from "./svg/Bell"
import OrderToast from "./OrderToast"
import Captain from "./svg/CaptainNoOrders"
import "./DesktopIncomingOrders.css"

const Api = require("../api")

class DesktopIncomingOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unconfirmedOrders:[]
    }
  }

  componentDidMount(){
    this.props.orders.checkForOrders()
  }

  componentDidUpdate(prevProps){
    if( 
      JSON.stringify(this.state.unconfirmedOrders) !== 
      JSON.stringify(this.props.unconfirmedOrders.unconfirmedOrders)
    ){  
      this.setState({unconfirmedOrders:JSON.parse(JSON.stringify(this.props.unconfirmedOrders.unconfirmedOrders))})
    } 
  }

 addToast(toast){
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }
  }

  buttonText(order){
    if(order.fulfill_order_at) return 'Queue'

    let prep_time
    if(order.pickup_or_delivery === 'pickup'){
      prep_time = this.props.user.rest.pickup_prep_time
    } else {
      prep_time = this.props.user.rest.delivery_prep_time
    }
    return `Accept - ${prep_time}m`
  }


  confirmOrder(order, callback){
    let order_id = order.order_id
    let ordersLoading = this.state.ordersLoading

    let prep_time = null
    if(order.pickup_or_delivery === 'pickup'){
      prep_time = this.props.user.rest.pickup_prep_time
    } else {
      prep_time = this.props.user.rest.delivery_prep_time
    }

    Api.callApi({
      endpoint:'confirm_order',
      data:{order_id:order_id},
      success:(data)=>{
        this.props.orders.checkForOrders()
        this.props.orders.checkForActiveOrders()
        
        this.addToast({
          order:order,
          type:'confirmed',
          prep_time:prep_time
        })
        callback()
      },
      failure:(err)=>{
        console.log("error", err)
        callback()
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error confirming this order.'
          }
        })
      }
    })
  }

  snoozeOrder(order, callback){
    let order_id = order.order_id
    let snooze_until = null
    if(order.pickup_or_delivery === 'pickup'){
      snooze_until = this.props.user.rest.default_pickup_snooze_time
    } else {
      snooze_until = this.props.user.rest.default_delivery_snooze_time
    }

    Api.callApi({
      endpoint:'snooze-order',
      data:{order_id:order_id, snooze_until:snooze_until},
      success:(data)=>{
        this.props.unconfirmedOrders.checkForOrders()
        this.props.orders.checkForSnoozedOrders()
        
        this.addToast({
          order:order,
          type:'snoozed'
        })
        if(callback){
          callback()
        }
      },
      failure:(err)=>{
        console.log("waffles", err)
        if(callback){
          callback()
        }
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error confirming this order.'
          }
        })
      }
    })
  }

  render(){
    return (
      <div className='desktop-incoming-orders-container'>

        <div className='desktop-incoming-orders-grey-bar'></div>

        {this.state.toast && (
          <OrderToast
            toast={this.state.toast}
            text={this.state.toast.text}
            type={this.state.toast.type}
            order={this.state.toast.order}
          />
        )}

        {this.state.unconfirmedOrders.length === 0 && (
          <div className='desktop-no-incoming-orders-container'>
            <div className='desktop-no-incoming-orders'>
              No Incoming Orders
            </div>

            <Captain style={{position:'absolute', top:'30vh'}}/>

          </div>
        )}

        {this.state.unconfirmedOrders && this.state.unconfirmedOrders.length > 0 && (
          <div className='desktop-yes-incoming-orders-container-outer'> 
            <div className='desktop-yes-incoming-orders-container'>
              <div className='desktop-yes-incoming-orders-header'>
                <Bell style={{fill:'white', marginRight:'15px'}}/>
                <div>{this.state.unconfirmedOrders.length} Incoming Orders</div>
              </div>

              <div className='desktop-yes-incoming-orders-cards-container'>
                  {this.state.unconfirmedOrders.map((order)=>(
                    <div className='desktop-incoming-orders-order-container'>
                      <div className='desktop-incoming-orders-order-inner-container'>
                        <OrderCard
                          page='unconfirmed'
                          key={order.order_id}
                          clock='expires'
                          order={order}
                          text={this.buttonText(order)}
                          loadingText='Accept'
                          action={order.fulfill_order_at ?
                            (callback) => this.snoozeOrder(order, callback) :
                            (callback)=>this.confirmOrder(order, callback)
                          }
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      )
    }
  }

export default UserContextHOC(NewOrdersContextHOC(withRouterAndRef(DesktopIncomingOrders)))

