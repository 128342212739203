import React, { Component } from "react"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import DesktopSidebar from "../menu/DesktopSidebar"
import DesktopTabs from "./DesktopTabs"
import DesktopIncomingOrders from "./DesktopIncomingOrders"
import UserContextHOC from "../contexts/UserContextHOC"
import OrderCard from "./OrderCard"
import OrderToast from "./OrderToast"
import classnames from "classnames"
import { withRouter } from "react-router-dom"
import "./Home.css"
import SearchBar from "./SearchBar"

const Copy = require("../helpers/copy")
const Api = require("../api")

class AwaitingOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      awaitingOrders:Copy.copy(this.props.orders.awaitingOrders),
      toast:null,
      isDesktop:window.innerWidth > 1000
    }
  }

  componentDidMount(){
    this.props.orders.checkForAwaitingOrders()
    if(
      this.props.history && 
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.toast
    ){
      this.addToast(this.props.history.location.state.toast)
    }
  }
  
  componentDidUpdate(prevProps){
    if(JSON.stringify(this.state.awaitingOrders) !== JSON.stringify(this.props.orders.awaitingOrders)){
      this.setState({awaitingOrders:Copy.copy(this.props.orders.awaitingOrders)})
    }
  }

  addToast(toast){
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }
  }

  totalAwaitingOrders(){
    let val = this.state.awaitingOrders.pickup + this.state.awaitingOrders.delivery
    if(!val) return 0
    else return val
  }

  completeOrder(callback, order){
    Api.callApi({
      endpoint:'/api/tablet/v2/complete-order',
      method:'post',
      data:{order_id:order.order_id},
      success:(data)=>{
        callback()
        this.props.orders.checkForAwaitingOrders()
        this.addToast({
          type:'generic',
          title:'Order Completed',
          message:order.hash_id
        })
      },  
      failure:()=>{
        callback()
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error updating this order.'
          }
        })
        this.props.orders.checkForAwaitingOrders(false)
      }
    })

  }
 
  
  renderAwaiting(){
    let pickup = null
    let delivery = null
    if(this.state.awaitingOrders.pickup.length){
      let orders = this.state.awaitingOrders.pickup
      pickup = (
        <div className='orders-bucket'>
          <div 
            className={classnames('orders-bucket-header')}
          >
            <div className='orders-bucket-header-left'> 
              Ready for Pickup
            </div>
            <div className='orders-bucket-header-right'>({orders.length})</div>
          </div>

          {orders.map(order => (
            <OrderCard
              page='awaiting'
              key={order.order_id}
              action={(cb)=>this.completeOrder(cb, order)}
              text='Complete Order'
              order={order}
            />
            ))}
        </div>
      )
    }
    
    if(this.state.awaitingOrders.delivery.length){
      let orders = this.state.awaitingOrders.delivery
      delivery = (
        <div className='orders-bucket'>
          <div 
            className={classnames('orders-bucket-header')}
          >
            <div className='orders-bucket-header-left'> 
              Out For Delivery
            </div>
            <div className='orders-bucket-header-right'>({orders.length})</div>
          </div>

          {orders.map(order => (
            <OrderCard
              page='awaiting'
              key={order.order_id}
              action={(cb)=>this.completeOrder(cb, order)}
              text='Complete Order'
              order={order}
            />
            ))}
        </div>
      )
    }

    return (
      <>
        {pickup}
        {delivery}
      </>
    )

  }

  renderContent(){
    return (
      <div className='orders-home-container'>
        {this.state.isDesktop && (
          <DesktopTabs />
        )}
        <div className='orders-content'>

          {this.state.isDesktop !== true && (
            <SearchBar />
          )}

          <div className='orders-home-current-page'>
            <div>Awaiting Completion</div>
            {this.state.isDesktop === true && (
              <SearchBar />
            )}
          </div>
          
          {this.state.toast && ( 
            <OrderToast
              toast={this.state.toast}
              text={this.state.toast.text}
              type={this.state.toast.type}
              order={this.state.toast.order}
            />
          )}

          {this.totalAwaitingOrders() === 0 && !this.state.toast && (
            <div className='no-orders-container'>
              <img
                className='no-orders-img-bottle'
                style={{width:'130px'}}
                src='https://cdn.hngr.co/hourglass2.png'
              />
              <div className='no-orders-top-text'>
                No Orders Awaiting Completion
              </div>
              <div className='no-orders-bottom-text'>
                Orders ready for pickup or out for delivery will appear here
              </div>
            </div>
        )}
          <div className='orders-home-active-orders-container'>
            {this.renderAwaiting()}
          </div>
        </div> 
      </div>
    )
  }

  render(){
    if(this.state.isDesktop){
      return (
        <div className='desktop-orders-page-container'>
          <DesktopSidebar />
          <div className='desktop-orders-container-container'>
            {this.renderContent()}
          </div>
          <div className='desktop-orders-incoming-orders-container'>
            <DesktopIncomingOrders />
          </div>
        </div>
      )
    } else {
      return this.renderContent()
    }
  }

  
}
      

export default withRouter(UserContextHOC(NewOrdersContextHOC(AwaitingOrders))) 


