import React, {Component} from 'react'
import "./LoadingActionButton.css"

class LoadingActionButton extends Component {

  getStyle(){
    if(this.props.color){
      let style = {
        borderColor: `${this.props.color} transparent transparent transparent`
      }
      return style
    }
  }

  render(){
    return(
    <div {...this.props} className="lds-ring">
      <div style={this.getStyle()}></div><div style={this.getStyle()}></div><div style={this.getStyle()}></div><div style={this.getStyle()}></div>
    </div>
    ) 
  }
}

export default LoadingActionButton
