import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import ToastContextHOC from "../contexts/ToastContextHOC"
import ModifierClassBox from "./ModifierClassBox"
import withRouterAndRef from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import OrderToast from "../orders/OrderToast"
import Reactivate from "../orders/svg/Reactivate"
import Deactivate from "../orders/svg/Deactivate"
import Edit from "../orders/svg/Edit"
import TriangleDown from "../orders/svg/TriangleDown"
import "./Menu.css"
import "./MenuItemPage.css"

const Api = require("../api")
const Money =  require("../helpers/money")
const Timestamp = require("../helpers/timestamps")

function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}

class MenuItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item:null
    }
    window.mi = this
    this.addModifierRef = React.createRef()
  }

  componentDidMount(){
    this.loadItem()
    // toasts coming from modifier modal
    if(
      this.props.toast.toast &&
      this.props.toast.toast.type === 'item-edit'
    ){
      this.addToast(this.props.toast.toast)
    }
  }

  componentDidUpdate(prevProps){
    if( 
      JSON.stringify(this.props.toast.toast) !== JSON.stringify(prevProps.toast.toast) &&
      this.props.toast.toast
    ){  
      if(!this.props.toast.toast.dontReloadItem){
        this.setState({item:null}, ()=>this.loadItem())
      }
      this.addToast(this.props.toast.toast)
    }  
  }

  loadItem(){
    if(!this.props.user.rest){
      return setTimeout(()=>this.loadItem(), 100)
    }
    let menu_item_id  = this.props.match.params.menu_item_id
    Api.callApi({
      endpoint:'reload-item',
      data:{menu_item_id:menu_item_id},
      success:(data)=>{
        let centralTimestamp = data.central_timestamp
        let item = data.item
        if(
          item.blackout_until &&
          item.blackout_until_timestamp > centralTimestamp 
        ) {
          item.blacked_out = true
          item.blackout_until_local_ts = item.blackout_until_timestamp - this.props.user.rest.central_offset_seconds 
        }
        let disableEdits = false
        if(data.external_source === 'CHOWLY') disableEdits = true
        if(data.external_source === 'SQUARE') disableEdits = true

        this.setState({
          disableEdits:disableEdits,
          item:item,
          modifierClasses:data.modifier_classes
        })
      },
      failure:(err)=>alert('An error occurred')
    })
  }


  addToast(toast, callback){
    if(callback) this.setState({toast:toast}, callback)
    else this.setState({toast:toast}, ()=>console.log("added toast", this.state.toast))
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }   
  }

  formatTimestamp(ts){
    let time = Timestamp.getTimeOfDay(ts)
    let mm = Timestamp.getMonthInt(ts)
    let dd = Timestamp.getDayOfMonth(ts)
    let yy  = Timestamp.getPartialYear(ts)
    return `${mm}/${dd}/${yy} ${time}`
  }

  deactivateModal(){
    this.props.history.push({
      pathname:this.props.location.pathname+"/deactivate-modal",
      state:{
        item:this.state.item
      }
    })
  }

  reactivate(){
    let item = this.state.item
    this.setState({item:null})
    Api.callApi({
      endpoint:'reactivate',
      data:{item_id:this.state.item.menu_item_id},
      success:(data)=>{
        this.addToast({
          type:'reactivated',
          item:item
        }, ()=>this.loadItem())
      },  
      failure:(err)=>{
        alert('An unexpected error occurred')
      }   
    })  
  }

  dropdownStyle(){
    let offset = {}
    if(this.addModifierRef && this.addModifierRef.current){
      offset = getOffset(this.addModifierRef.current)
      let t = offset.top + 30
      let l = offset.left - 100
      return {
        top:t+'px',
        left:l+'px'
      }
    } else {
      return {}
    }
  }


  render(){
    if(!this.state.item) return <Loading/>
    let that = this
    return (
      <div 
        className='menu-item-page'
        onClick={(e)=>{
          if(
            this.state.dropdownOpen && 
            e.target.className !== 'menu-item-dropdown'
          ){
            this.setState({dropdownOpen:false})
          }
        }}
      >

        {this.state.dropdownOpen === true && (
          <div className='menu-item-dropdown' style={this.dropdownStyle()}>
            <div 
              className='menu-item-dropdown-option'
              style={{borderBottom: '1px solid #F4F4F4'}}
              onClick={()=>{
                this.setState({dropdownOpen:false})
                this.props.history.push({
                  pathname:this.props.location.pathname + '/modifier-group-modal',
                  state:{
                    item:this.state.item
                  }
                })
              }}
            >
              <div>Attach Existing Group</div>
            </div>
            
            <div 
              className='menu-item-dropdown-option'
              onClick={()=>{
                this.props.history.push({
                  pathname:'/menu/modifier-group/create/'+this.state.item.menu_uuid +'?menu_item_id='+this.state.item.menu_item_id
                })
              }}
            >
              <div>Create a New Group</div>
            </div>
          </div>
        )}

        {this.state.toast && (
          <OrderToast
            toast={this.state.toast}
            message={this.state.toast.item ? this.state.toast.item.name : null}
            type={this.state.toast.type}
          />
        )}

        {this.state.item.blacked_out && (
          <div className='menu-item-deactivated-banner'>
            <Deactivate/>
            <div>
              Deactivated Until: {this.formatTimestamp(this.state.item.blackout_until_local_ts)}
            </div>
          </div>
        )}

        <div className='menu-item-page-top-button-container'>
          {this.state.item.blacked_out ? 
            (
              <div 
                onClick={()=>this.reactivate()}
                className='menu-item-page-top-button menu-item-page-top-button-reactivate'
              >
                <Reactivate />
                <div> Reactivate Item </div>
              </div>
            ) : (
              <div 
                onClick={()=>this.deactivateModal()}
                className='menu-item-page-top-button menu-item-page-top-button-deactivate'
              >
                <Deactivate  />
                <div> Deactivate Item </div>
              </div>

            )
          }

        </div>

        <div className='menu-item-blurb-container'>
          <div className='menu-item-blurb-top-row'>
            {this.state.item.image_url && (
              <div className='menu-item-blurb-image'>
                <img src={this.state.item.image_url} />
              </div>
            )}
            <div className='menu-item-blurb-item-info'>
              <div className='menu-item-blurb-name'>{this.state.item.name}</div>
              {this.state.item.category_name && (
                <div className='menu-item-blurb-category'>{this.state.item.category_name}</div>
              )}
              <div className='menu-item-blurb-price'>
                {Money.formatCents(this.state.item.price)}
              </div>
            </div>
          </div>
          {this.state.item.description && (
            <div className='menu-item-blurb-blurb'>
              {this.state.item.description}
            </div>
          )}


          {this.state.disableEdits !== true && (
            <div 
              className='menu-item-edit-button'
              onClick={()=>this.props.history.push({
                pathname:'/menu/menu-item-edit/'+this.state.item.menu_item_id,
                state:{
                  item:this.state.item
                }
              })}
            >
              <Edit />
              <div>Edit Item</div>
            </div>
          )}

        </div>
        <div className='menu-item-modifier-group-list'> 
          <div className='menu-item-modifier-groups-title-row' style={{marginTop:'35px'}}>
            <div className='menu-item-modifier-groups-title'>
              Modifier Groups
            </div>

            {this.state.disableEdits !== true && (
              <div 
                className='modifier-class-add-modifier'
                onClick={()=>{
                  /*
                  this.props.history.push({
                    pathname:this.props.location.pathname + '/modifier-group-modal',
                    state:{
                      item:this.state.item
                    }
                  })*/
                  this.setState({dropdownOpen:true})
                }}
              >
                <TriangleDown />
                <div ref={this.addModifierRef}>Add Modifier Group</div>
              </div>
            )}
          </div>
          {this.state.modifierClasses.map(mc => (
            <ModifierClassBox 
              menu_uuid={this.state.item.menu_uuid}
              disableEdits={this.state.disableEdits}
              page='item'
              mc={mc} 
              key={mc.modifier_class_id} 
              item={this.state.item}
            />
          ))}



        </div>


      </div>
    )
  }


}
      

export default withRouterAndRef(ToastContextHOC(UserContextHOC(MenuItem))) 


