import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import ToastContextHOC from "../contexts/ToastContextHOC"
import DesktopSidebar from "./DesktopSidebar"
import MenuItemBox from "./MenuItemBox"
import Breadcrumbs from "./Breadcrumbs"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import OrderToast from "../orders/OrderToast"
import Deactivate from "../orders/svg/Deactivate"
import Edit from "../orders/svg/Edit"
import Plus from "../orders/svg/PlusMedium"
import "./Menu.css"
import "./ModifierClass.css"

const Api = require("../api")
const Money =  require("../helpers/money")
const DOM = require("../helpers/dom")

class ModifierClass extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile:window.innerWidth <= 599,
      isTablet:window.innerWidth > 599 && window.innerWidth < 1000,
      isDesktop:window.innerWidth >= 1000,
      toast:null,
      modifierClass:null,
    }
    this.modifier_class_id = this.props.match.params.modifier_class_id
    this.addModifierRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount(){
    window.addEventListener('mousedown', this.handleClickOutside)
    this.getCentralTimestamp()
    if(this.props.match.params && this.props.match.params.menu_item_id){
      this.loadItem(this.props.match.params.menu_item_id)
    }
    if(
      this.props.history && 
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.toast
    ){
      this.addToast(this.props.history.location.state.toast)
    }
  }

  componentWillUnmount(){
   window.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps){
    if(
      JSON.stringify(this.props.toast.toast) !== JSON.stringify(prevProps.toast.toast) &&
      this.props.toast.toast
    ){
      this.setState({loaded:null})
      this.getCentralTimestamp()
      this.addToast(this.props.toast.toast)
    }
  }

  loadItem(){
    if(!this.props.user.rest){
      return setTimeout(()=>this.loadItem(), 100)
    }
    let menu_item_id  = this.props.match.params.menu_item_id
    Api.callApi({
      endpoint:'reload-item',
      data:{menu_item_id:menu_item_id},
      success:(data)=>{
        this.setState({item:data.item})
      },
      failure:(err)=>alert('An error occurred')
    })
  }

  addToast(toast){
    if(!this.loaded()){
      setTimeout(()=>this.addToast(toast), 100)
      return
    }
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }   
  }


  handleClickOutside(e){
    if(this.state.dropdown){
      if(
        e.target.className !== 'add-section-dropdown' &&
        e.target.className !== 'menu-item-dropdown-option' &&
        e.target.className !== 'in-dropdown'
      ){
        this.setState({dropdown:false})
      }
    }
  }

  loaded(){
    if(this.state.loaded && this.state.centralTimestamp) {
      return true
    } else {
      return false
    }
  }

  loadModifierClass(){
    Api.callApi({
      endpoint:'/api/menu/modifier-class/'+this.modifier_class_id + '?include_archived=1',
      success:(data)=>{
        this.menu_uuid = data.menu_uuid
        let modifiers = data.modifiers
        for(let i in modifiers){ 
          if(modifiers[i].item){
            let item = modifiers[i].item 
            if(
              item.blackout_until &&
              item.blackout_until_timestamp > this.state.centralTimestamp
            ) {
              item.blacked_out = true
              item.blackout_until_local_ts = item.blackout_until_timestamp - this.props.user.rest.central_offset_seconds
            }
          }
        }
        let disableEdits = false
        if(data.external_source === 'CHOWLY') disableEdits = true
        if(data.external_source === 'SQUARE') disableEdits = true

        modifiers = modifiers.filter(m => !m.disabled_at)
        let archivedModifiers = data.modifiers.filter(m => m.disabled_at)

        this.setState({
          loaded:true, 
          modifierClass:data.modifier_class,
          modifiers:modifiers,
          archivedModifiers:archivedModifiers,
          disableEdits:disableEdits
        })
      },
      failure:(err)=>{
        console.log("error", err)
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error loading the modifiers' 
          }
        })
      }
    })
  }

  getCentralTimestamp(){
    Api.callApi({
      endpoint:'central-timestamp',
      success:(data)=>{
        this.setState({
          centralTimestamp:data.ts,
          tomorrow5am:data.tomorrow_5am,
        }, ()=>this.loadModifierClass())
      }
    })
  }

  dropdownStyle(){
    let offset = {}
    if(this.addModifierRef && this.addModifierRef.current){
      offset = DOM.getOffset(this.addModifierRef.current)
      let l = offset.left - 130
      let t = offset.top + 35
      return {
        top:t + 'px',
        left:l+'px'
      }
    } else {
      return {}
    }
  }

  renderDropdown(){
    return (
      <div className='menu-item-dropdown add-section-dropdown' style={this.dropdownStyle()}>
        <div
          className='menu-item-dropdown-option'
          style={{borderBottom: '2px solid #F4F4F4'}}
          onClick={()=>{
            this.setState({dropdown:false})
            this.props.history.push({
              pathname:this.props.location.pathname + '/modifier-modal',
              state:{
                modifierClass:this.state.modifierClass
              }
            })
          }}
        >
          <div className='in-dropdown'>Attach Existing Modifier</div>
        </div>

        <div
          className='menu-item-dropdown-option'
            onClick={()=>{
              this.setState({dropdown:false})
              this.props.history.push({
                pathname:this.props.location.pathname + '/create-modifier-modal',
                state:{
                  modifierClass:this.state.modifierClass
                }
              })
            }}
        >
          <div className='in-dropdown'>Create a New Modifier</div>
        </div>
      </div>
    )
  }


  renderContent(){
    if(!this.loaded()) return (<Loading />)
    return (
      <div className='orders-content menu-page-paddiing-top'>
        {this.state.dropdown === true && (
          <>{this.renderDropdown()}</>
        )}

        {this.state.toast && (
          <OrderToast
            toast={this.state.toast}
            type={this.state.toast.type}
          />
        )}
        <div className='modifier-class-top-of-page'>
          <div className='modifier-class-top-of-page-left'>
            {this.state.modifierClass.internal_name && (
              <div className='modifier-class-internal-name-header'>
                {this.state.modifierClass.internal_name}
              </div>
            )}
            <div className='modifier-class-title' style={{marginBottom:'6px'}}>
              {this.state.modifierClass.display_text}
            </div>

            <div className='modifier-class-top-options-container'>
              <div>{this.state.modifiers.length} options</div>
            </div>
          </div>
            
        
          {this.state.disableEdits !== true && (
            <div className='modifier-class-top-of-page-right'>
              <div 
                onClick={()=>{
                  let state = {}
                  if(this.crumbs){
                    let mc = this.state.modifierClass
                    let crumbs = JSON.parse(JSON.stringify(this.crumbs))
                    crumbs.push({
                      text:mc.internal_name ? mc.internal_name : mc.display_text,
                      path:this.props.location.pathname
                    })
                    state['crumbs'] = crumbs
                  }
                  this.props.history.push({
                    pathname:'/menu/modifier-class-edit/'+this.modifier_class_id,
                    state:state
                  })
                }}
                className='modifier-class-edit-group'
              >
                <Edit/>
                <div style={{whiteSpace:'nowrap'}}>Edit Group</div>
              </div>
            </div>
          )}
        </div>

        <div className='modifier-class-small-hr'></div>
        <div className='modifier-options-title-row'>
          <div className='modifier-options-title'>Modifier Options</div>
          {this.state.disableEdits !== true && (
            <div 
              className='modifier-class-add-modifier'
              ref={this.addModifierRef}
              onClick={()=>this.setState({dropdown:true})}
            >
              <Plus />
              <div>Add Modifier</div>
            </div>
          )}
        </div>
        <div className='mnodifier-class-modifiers-container'>
          {this.state.modifiers.map((modifier, i) => {
            if(modifier.modifier_type === 'ITEM'){
              return (
                <MenuItemBox 
                  isModifier={true}
                  noArchive={this.state.disableEdits}
                  modifier={modifier}
                  priceOverride={this.state.modifierClass.price_override} 
                  clickable={false} 
                  item={modifier.item} 
                  modifierClasses={modifier.modifier_classes}
                /> 
              )
            }
          })}
        </div>

        
        {this.state.archivedModifiers && this.state.archivedModifiers.length > 0  && (
          <>
            <div className='modifier-options-title' style={{marginBottom:'15px'}}>
              Archived Modifiers
            </div>
            <div className='mnodifier-class-modifiers-container'>
              {this.state.archivedModifiers.map((modifier, i) => {
                if(modifier.modifier_type === 'ITEM'){
                  return (
                    <MenuItemBox 
                      isModifier={true}
                      modifier={modifier}
                      priceOverride={this.state.modifierClass.price_override} 
                      clickable={false} 
                      item={modifier.item} 
                      modifierClasses={modifier.modifier_classes}
                    /> 
                  )
                }
              })}
            </div>
          </>
        )}
      
      </div>
    )
  }

  renderBreadCrumbs(){
    let current = ''
    if(this.state.modifierClass){
      if(this.state.modifierClass.internal_name){
        current = this.state.modifierClass.internal_name
      } else{
        current = this.state.modifierClass.display_text
      }
    }
    if(this.props.fromMenu){
      return (
        <Breadcrumbs
          current={current}
          crumbs={[
            {text:'Menus', path:'/menu/menus'},
            {text:'Items', path:'/menu/menu/'+this.menu_uuid},
            {text:'Modifier Groups', path:'/menu/modifiers/'+this.menu_uuid}
          ]}
        />
      )
    }
    
    if(this.props.fromSearch){
      return (
        <Breadcrumbs
          current={current}
          crumbs={[
            {text:'Search', path:'/menu/search'},
          ]}
        />
      )
    }

    if(this.props.fromMenu){
      return (
        <Breadcrumbs
          current={current}
          crumbs={[
            {text:'Menus', path:'/menu/menus'},
            {text:'Items', path:'/menu/menu/'+this.menu_uuid},
            {text:'Modifier Groups', path:'/menu/modifiers/'+this.menu_uuid}
          ]}
        />
      )
    }
    
    if(this.props.fromItem){
      if(!this.state.item){
        return (
          <Breadcrumbs
            current=''
            crumbs={[]}
          />
        )
      } else {
        let crumbs = [
          {text:'Menus', path:'/menu/menus'},
          {text:'Items', path:'/menu/menu/'+this.menu_uuid},
          {text:this.state.item.name, path:'/menu/menu-item-edit/'+this.state.item.menu_item_id}
        ]
        this.crumbs = crumbs
        return (
          <Breadcrumbs
            current={current}
            crumbs={crumbs}
          />
        )
      }
    }

  }

  render(){
    if(this.state.isDesktop){
      return (
        <div className='desktop-menu-item-page-container'>
          <DesktopSidebar 
            menu_uuid={this.menu_uuid}
          />
          <div className='desktop-content-with-crumbs'>
            {this.renderBreadCrumbs()}
            <div style={{width:'700px'}}>
              {this.renderContent()}
            </div>
          </div>
        </div>
      )   
    } else {
      return this.renderContent()
    }   
  }

}

export default withRouterAndRef(ToastContextHOC(UserContextHOC(ModifierClass))) 


