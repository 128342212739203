import React, { Component } from "react"
import ToastContextHOC from "../contexts/ToastContextHOC"
import Calendar from "../orders/svg/Calendar"
import Clock from "../orders/svg/Clock"
import withRouterAndRef from "../withRouterAndRef"
import * as Sentry from "@sentry/browser";
import "./DeactivateModal.css"

const Api = require("../api")

class DeactivateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item:{},
      date:null,
      time:null,
    }
    if(
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.item
    ){
      this.state.item = this.props.history.location.state.item
    }
    this.dateRef = React.createRef()
    this.timeRef = React.createRef()
    this.todayRef = React.createRef()
    this.handleClickBackground = this.handleClickBackground.bind(this)
    window.d = this
  }

  componentDidMount(){
    window.addEventListener('mousedown', this.handleClickBackground)
  }

  componentWillUnmount(){
    window.removeEventListener('mousedown', this.handleClickBackground)
  }

  close(){
    this.props.history.goBack()
    /*
    let path = this.props.location.pathname
    let split = path.split("/")
    let newPath = "/" + split[1] + "/"+ split[2] + "/" + split[3]
    this.props.history.replace({pathname:newPath})
    */
  }

  handleClickBackground(e){
   if (e.target.className === 'modal-background'){
    this.close()
   }
  }

  deactivate(){
    Api.callApi({
      endpoint:'deactivate',
      data:{
        date:this.state.date,
        time:this.state.time,
        item_id:this.state.item.menu_item_id
      },
      success:(data)=>{
        // we need to go back and toast. 
        this.props.toast.setToast({
          type:'deactivated',
          item:this.state.item
        }, true)
      },
      failure:(err)=>{
        console.log("error", err)
        alert('An unexpected error occured.')
      }
    })
  }

  render() {
    return  (
      <div 
        className='modal-background' 
      >
        <div className='deactivate-modal-container'>
          <div className='deactivate-modal-title'>
            Deactivate {this.state.item.name}
          </div>
          <div className='deactivate-item-until'>
            Deactivate Item Until
          </div>
          <div className='deactivate-input-wrapper'>
            {!this.state.date && (
              <div
                className='deactivate-today-hack ' 
              >
                <div>Today</div>
              </div>
            )}
            <input 
              ref={this.dateRef}
              style={{marginBottom:'15px'}} 
              className='deactivate-input' type='date' 
              placeholder='Today'
              onChange={(e)=>{
                let time = this.state.time
                if(!time) time = '23:59'
                this.setState({
                  date:e.target.value,
                  time:time
                })
              }}
              value={this.state.date}
            />
            <Calendar className='deactivate-input-icon' />
          </div>
          <div className='deactivate-input-wrapper'>
            {!this.state.time && (
               <div className='deactivate-today-hack ' >
                <div>End of Day</div>
              </div>
            )}
            <input 
              className='deactivate-input' 
              value={this.state.time}
              type='time' 
              onChange={(e)=>{
                let date = this.state.date
                if(!date) {
                  let yourDate = new Date()
                  const offset = yourDate.getTimezoneOffset()
                  yourDate = new Date(yourDate.getTime() - (offset*60*1000))
                  date = yourDate.toISOString().split('T')[0]
                }
                this.setState({
                  date:date,
                  time:e.target.value
                })
              }}
            />
            <Clock className='deactivate-input-icon' />
          </div>

          <div className='deactivate-buttons-container'>
            <div 
              onClick={()=>{
                this.close()
              }}
              className='deactivate-button deactivate-cancel'
            >
              <div>Cancel</div>
            </div>
            <div 
              className='deactivate-button deactivate-yes'
              onClick={()=>this.deactivate()}
            >
              <div>Yes, Deactivate</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouterAndRef(ToastContextHOC(DeactivateModal))
