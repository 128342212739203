import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import ButtonWithSpinner from "./ButtonWithSpinner"
import Loading from "../Loading/Loading"
import Warning from "./svg/Warning"
import { Plugins } from '@capacitor/core'
import "./ChoosePrinter.css"
const { ChoosePrinter, ReceiptPrinter } = Plugins;
const { PrintReceiptPlugin } = Plugins;


class ChoosePrinterPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page:this.props.user.printer ? 'settings' : 'welcome',
      loading:true,
      copies:this.props.user.receiptCopies,
      printTrigger:this.props.user.printTrigger
    }
    this.printButtonRefs = [React.createRef(), React.createRef(),
      React.createRef(), React.createRef()]  
    this.desktopButtonRef = React.createRef()
    this.listenForFinishedPrints = this.listenForFinishedPrints.bind(this)
    this.onPrintRadioChange = this.onPrintRadioChange.bind(this)
  }

  
  componentDidMount(){
    window.addEventListener('testReceiptPrinted', this.listenForFinishedPrints)
    window.addEventListener("keydown", this.handleEnterKey)
    this.searchForPrinters()
  }

  componentWillUnmount(){
    window.removeEventListener("testReceiptPrinted", this.listenForFinishedPrints)
    window.removeEventListener("keydown", this.handleEnterKey)
  }

  handleEnterKey(e) {
    if(e.key === 'Enter'){
      window.document.activeElement.blur()
    }   
  }

  listenForFinishedPrints(data){
    if(!data.success){
      this.props.history.push({
        pathname:this.props.location.pathname +'/error-modal',
        state:{
          message:'There was an error printing the test receipt' 
        }
      })
    }
    if(this.desktopButtonRef && this.desktopButtonRef.current){
      this.desktopButtonRef.current.setState({loading:false})
    }
    for(let i in this.printButtonRefs){
      if(this.printButtonRefs[i] && this.printButtonRefs[i].current){
        this.printButtonRefs[i].current.setState({loading:false})
      }
    }
  }


  async searchForPrinters(){
    let resp = null
    try{
      resp = await ChoosePrinter.search()
    } catch (err){
      resp = null
    }

    if (!resp){
      try {
        resp = await ReceiptPrinter.search()
      } catch (err){
        resp = null
      }
    }

    if(!resp){
      alert('Error')
      return
    }
    // resp is an object where the keys are ip addresses
    // and the values are printer models. Let's transform
    // this to a list of {modal:<blah>, ipAddress:<blah>"
    let printers = []
    for(let ipAddress in resp){
      let model = resp[ipAddress]
      printers.push({
        model:model,
        ipAddress:ipAddress
      })
    }
    this.setState({
      loading:false,
      printers:printers
    })
  }

  choosePrinter(printer){
    this.props.user.setPrinter(printer, ()=>this.setState({page:'settings'}))
  }

  onPrintRadioChange(e){
    this.setState({printTrigger:e.target.value})
    this.props.user.setPrintTrigger(e.target.value)
  }

  renderWelcome(){
    return (
      <div className='orders-content'>
        <div className='choose-printer-blob'>
          <Printer/> 
        </div>

        <div className='choose-printer-title'>
          Connect to a printer
        </div>

        <div className='choose-printer-paragraph'>
          You can print receipts from Captain by connecting to a STAR
          printer.
        </div>

        <div className='choose-printer-paragraph'>
          Please make sure your STAR printer is connected to the same Wi-Fi
          network as your tablet.
        </div>

        <div 
          className='choose-printer-big-button'
          onClick={()=>this.setState({page:'printer-list'})}
        >
          Choose Printer
        </div>
      </div>
    )
  }

  printTestReceipt(printer){
    if(!printer) printer = this.props.user.printer
    if (PrintReceiptPlugin){
      PrintReceiptPlugin.printTestReceipt({
        ip_address:printer.ipAddress
      })
    } else {
      ReceiptPrinter.printTestReceipt({
        ip_address:printer.ipAddress
      })
    }
  }

  setCopies(copies){
    var numberPattern = /\d+/g;
    let matches = copies.match(numberPattern)
    if (matches && matches.length){
      let num = matches[0]
      if(parseInt(num)){
        num = parseInt(num)
        if(num > 5){
          alert('The maximum amount of receipt copies is 5')
          return
        }
        this.setState({copies:num})
        this.props.user.setReceiptCopies(num)
      }
    } else {
      this.setState({copies:''})
    }
  }

  renderPrinterList(){
    if(this.state.loading){
      return (<Loading />)
    }
    return (
      <div className='orders-content'>
        <div className='choose-printer-title' style={{marginBottom:'35px'}}>
          Available Printers
        </div>

        {this.state.printers.length === 0 && (
          <div className='choose-printer-no-printers'>
            <Warning/>
            <div>
              We couldn't find any receipt printers on this Wi-Fi network. Please
              make sure your printer is online and is connected to the same
              Wi-Fi network as this tablet.
            </div>
          </div>
        )}

        {this.state.printers.map((printer, index) => (
          <div className='choose-printer-printer-container'>
            <div className='choose-printer-printer-model'>
              <span className='choose-printer-model-name'>{printer.model}</span>
              {printer.model.indexOf("TSP143") > -1 && (
                <div className='preferred-printer'>
                  Preferred Printer
                </div>
              )}
            </div>
            

            <div className='choose-printer-button-container'>
              <ButtonWithSpinner
                className='choose-printer-connect-button'
                text='Print Test Receipt'
                ref={this.printButtonRefs[index]}
                onClick={()=>this.printTestReceipt(printer)}
                style={{marginBottom:'20px'}}
              />
              
              <ButtonWithSpinner
                className='choose-printer-connect-button'
                text='Connect'
                onClick={()=>this.choosePrinter(printer)}
              />
            </div>
          </div>
        ))}
      
        <div className='choose-printer-printer-container'>
          <div className='choose-printer-printer-model'>
            <span className='choose-printer-model-name'>Desktop Network Printer</span>
            <br/>
            <div className='desktop-printer-warning'>
              <i>This connects to a desktop printer on your network. It is preferred that you use a receipt printer. </i>
            </div>
          </div>

          <div className='choose-printer-button-container'>
            <ButtonWithSpinner
              className='choose-printer-connect-button'
              text='Print Test Receipt'
              ref={this.desktopButtonRef}
              onClick={()=>this.printTestReceipt({ipAddress:"Android Default"})}
              style={{marginBottom:'20px'}}
            />
            
            <ButtonWithSpinner
              className='choose-printer-connect-button'
              text='Connect'
              onClick={()=>this.choosePrinter({model:'Desktop Printer', ipAddress:'Android Default'})}
            />
          </div>

        </div>
      </div>
    )
  }

  renderPrinterSettings(){
    return (
      <div className='orders-content'>
        <div className='choose-printer-title'>
          Printer Settings
        </div>


        <div className='choose-printer-sub-settig-container'>
          <div className='choose-printer-connnected-printer'>
            Connected Printer
          </div>
          {this.props.user.printer && (
            <div className='choose-printer-printer-model'>
              {this.props.user.printer.model} ({this.props.user.printer.ipAddress})
            </div>
          )}
        </div>
        
        <div className='choose-printer-sub-settig-container'>
          <div className='choose-printer-sub-setting'>
            Disconnect Printer
          </div>
          <div className='choose-printer-sub-sub-setting'>
            Unlink the connected printer from Captain
          </div>

          <div style={{display:'inline-block'}}>
            <ButtonWithSpinner
              className='choose-printer-settings-button choose-printer-disconnect-button' 
              onClick={(cb)=>{
                this.props.user.unsetPrinter(()=>this.setState({page:'welcome'}))
              }}
              text='Disconnect Printer'
            />
          </div>
        </div>

        <div className='choose-printer-sub-settig-container'>
          <div className='choose-printer-sub-setting'>
            Auto-Print Copies
          </div>
          <div className='choose-printer-sub-sub-setting'>
            Number of copies to auto-print
          </div>
          <div className='print-copies-settings'>
            <input
              className='copies-input'
              type='number'
              onChange={(e)=>this.setCopies(e.target.value)}
              value={this.state.copies}
            />
            <div> Copies </div>
          </div>
        </div>


        <div className='choose-printer-sub-settig-container'>
          <div className='choose-printer-sub-setting'>
            When to Auto-Print
          </div>
          <div className='choose-printer-sub-sub-setting'>
            Choose when new orders print automatically
          </div>

          <div className='printer-radio-container' onChange={this.onPrintRadioChange}>
            <div className='printer-radio-wrapper'>
              <input 
                type="radio" 
                value="transmit" 
                name="printwhen" 
                checked={this.state.printTrigger === 'transmit'}
              />
              <div className='printer-radio-label' >Print when a new order is receieved</div>
            </div>

            <div className='printer-radio-wrapper'>
              <input 
                type="radio" 
                value="confirm" 
                name="printwhen" 
                checked={this.state.printTrigger === 'confirm'}
              />
              <div className='printer-radio-label'>Print when a new order is accepted</div>
            </div>
          </div>

        </div>


        <div className='choose-printer-sub-settig-container'>
          <div className='choose-printer-sub-setting'>
            Send a Test Receipt
          </div>
          <div className='choose-printer-sub-sub-setting'>
            Send a test receipt to the printer
          </div>
          <div style={{display:'inline-block'}} className='connected-test-receipt'>
            <ButtonWithSpinner
              className='choose-printer-connect-button'
              text='Print Test Receipt'
              onClick={()=>this.printTestReceipt()}
            />      
          </div>
        </div>



      </div>
    )
  }


  render(){
    return (
      <div>
        {this.state.page === 'welcome' && this.renderWelcome()}       
        {this.state.page === 'printer-list' && this.renderPrinterList()}       
        {this.state.page === 'settings' && this.renderPrinterSettings()}       
      </div>
    )
  }
}
      
export default UserContextHOC(ChoosePrinterPage)

const Printer = props => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 17h-8v-1h8v1zm8-12v13h-4.048c-.404 2.423-3.486 6-6.434 6h-9.518v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 9h-12v8h6.691c3.469 0 2-3.352 2-3.352s3.309 1.594 3.309-2v-2.648zm4-7h-20v9h2v-4h16v4h2v-9zm-9 11h-5v1h5v-1zm7.5-10c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z"/></svg>
)
