import React, { Component } from "react"
import Loading from "../Loading/Loading"
import Modal from "../Modal/Modal"
import PayoutOrders from './OrdersModal'
const API = require("../api")
const formatPrice = require("../Helpers/formatPrice")

class Orders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:false,
      dates:null,
      date:null,
      payouts:null
    }
    this.closeModal = this.closeModal.bind(this)
    this.closePayoutModal = this.closePayoutModal.bind(this)
  }

  componentDidMount() {
    this.setState({rest:this.props.rest})
    this.loadRecons()
  }

  async loadRecons() {
    let rest = this.props.rest 
    API.call(
      "reconcilliations",
      (res) => this.setState({dates:res.dates }),
      (err) => alert('Error loading data'), 
      null,
      { rest_id: rest.restaurant_id }
    )
  }

  loadRecon(date, net){
    this.setState({date:date, payouts:null, loading:true, net:net})
    API.call(
      "reconcilliation",
      (res) => this.setState({payouts:res.payouts, loading:false}),
      (err) => {
        alert('Error loading data');
        this.setState({loading:false})
      },
      null,
      { rest_id: this.state.rest.restaurant_id , 
        date:date}
    )
  }

  closeModal(){
    this.setState({payouts:null})
  }


  closePayoutModal(){
    this.setState({openPayout:null})
  }

  renderModal(){
    let that = this

    if (this.state.openPayout){
      return (
        <PayoutOrders
          payout_id={this.state.openPayout}
          {...this.props}
          closeExpansion={this.closePayoutModal}
        />
      )

    } else if(this.state.payouts){
      return (
        <Modal 
          close={this.closeModal}
          style={
            window.innerWidth > 800 
              ? { maxWidth: "64%", padding: "16px" }
              : { maxWidth: "calc(92% - 32px)", padding: "16px" }
            }
          >
          <h3>{this.state.date}</h3>
          <table> 
            <thead className="thead">
              <tr>
                <th className='left' style={{ padding: "8px 24px" }}>Date</th>
                <th className='left' style={{ padding: "8px 24px" }} >Payout</th>
                <th className='left' style={{ padding: "8px 24px" }} >Amount</th>
              </tr>
            </thead>
            <tbody>
              {this.state.payouts.map(p => (
                <tr>
                  <td className='left'>{p.arrival_date}</td>
                  <td 
                    className='left'
                    style={{color:'blue', textDecoration:'underline'}}
                    onClick={()=>{
                      this.setState({
                        openPayout:p.stripe_payout_id,
                      })
                    }}
                  >
                    {p.stripe_payout_id}
                  </td>
                  <td className='left' style={{whiteSpace:'nowrap'}}>
                    {formatPrice.formatPrice(p.amount)} of {formatPrice.formatPrice(that.state.net)} 
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )
    }
  }
  
  render() {
    let that = this
    if (this.state.dates) {
      return (
        <div className="w100 inline-block center">
          <h1 className="left fw900 ph16 pv16 mv16">Dates</h1>
          <div
            style={{ width: "100%", overflowX: "auto", paddingBottom: "32px" }}
          >
            <table style={{maxWidth:'500px'}}>
              <thead className="thead">
                <tr>
                  <th className="left" style={{ padding: "8px 24px" }}>
                    <b>Date</b>
                  </th>
                  <th className="left" style={{ padding: "8px 24px" }}>
                    <b>Net</b>
                  </th>
                </tr>
              </thead> 
              <tbody>
                {this.state.dates.map(r => (
                  <>
                    <tr 
                      key={r.date}
                      onClick={()=>that.loadRecon(r.date, r.net)}
                    >
                      <td className="left">{r.date}</td>
                      <td className="left">{formatPrice.formatPrice(r.net)}</td>
                    </tr>
                    {r.date === that.state.date && that.state.loading ? (
                      <Loading />
                    ) : null}
                  </>
                ))}
              </tbody>
            </table>
            {this.renderModal()}
          </div>
        </div>
      )
    }
    return <Loading />
  }
}

export default Orders
