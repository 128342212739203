import React, { Component } from "react"
import classnames from "classnames"
import "./OrderToast.css"
const Timestamp = require("../helpers/timestamps")
const Money =  require("../helpers/money")

class OrderToast extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  formatOrderPlaced(ts){
    let time = Timestamp.getTimeOfDay(ts)
    let mm = Timestamp.getMonthInt(ts)
    let dd = Timestamp.getDayOfMonth(ts)
    let yy  = Timestamp.getPartialYear(ts)
    return `${mm}/${dd}/${yy} ${time}`
  }

  // Tuesday, Apr 13 2:00pm
  formatFulfillOrder(ts){
    let dow = Timestamp.getDayOfWeekFull(ts)
    let month = Timestamp.getMonthName(ts)
    let time = Timestamp.getTimeOfDay(ts)
    let dd = Timestamp.getDayOfMonth(ts)
    return `${dow}, ${month} ${dd} ${time}`
  }

  getType(){
    if(this.props.type) return this.props.type
    if(this.props.toast && this.props.toast.type) return this.props.toast.type
  }

  render(){
    return (
      <div className={`order-toast order-toast-${this.getType()}`}>
        <div className='order-toast-left'>
          {this.props.type === 'confirmed' &&  (<CheckMark />)}
          {this.props.type === 'ready' &&  (<CheckMark />)}
          {this.props.type === 'modifier-added' &&  (<CheckMark />)}
          {this.props.type === 'refund' &&  (<CheckMark />)}
          {this.props.type === 'upcharge' &&  (<CheckMark />)}
          {this.props.type === 'deactivated' &&  (<CheckMark />)}
          {this.props.type === 'reactivated' &&  (<CheckMark />)}
          {this.props.type === 'cancelled' &&  (<Warning />)}
          {this.props.type === 'expired' &&  (<Warning />)}
          {this.props.type === 'deleted' &&  (<Warning />)}
          {this.props.type === 'snoozed' &&  (<Calendar/>)}
        </div>
        <div className='order-toast-right'>
          <div className='space-between'>
            <div className='order-toast-order-type'>
              {this.props.type === 'confirmed' && (<span>Accepted</span>)}
              {this.props.type === 'snoozed' && (<span>Scheduled</span>)}
              {this.props.type === 'refund' && (<span>Refund</span>)}
              {this.props.type === 'upcharge' && (<span>Upcharge</span>)}
              {this.props.type === 'cancelled' && (<span>Cancelled</span>)}
              {this.props.type === 'Expired' && (<span>Expired</span>)}
              {this.props.type === 'expired' && (<span>Expired</span>)}
              {this.props.type === 'deleted' && (<span>Removed</span>)}
              {this.props.type === 'deactivated' && (<span>Item Deactivated</span>)}
              {this.props.type === 'reactivated' && (<span>Item Reactivated</span>)}
              {this.props.type === 'item-edit' && (<span>Edits Saved</span>)}
              {this.props.type === 'modifier-class-edit' && (<span>Edits Saved</span>)}
              {this.props.type === 'modifier-added' && (<span>Modifier Added</span>)}
              {this.props.toast && this.props.toast.type === 'generic' && (<span>{this.props.toast.title}</span>)}
              {this.props.text && (<span>{this.props.text}</span>)}
            </div>
          </div>
          {this.props.order && (
            <div className='order-toast-diner-name'>
              {this.props.order.diner}
            </div>
          )}

          {this.props.toast && this.props.toast.message && (
            <div className='order-toast-diner-name'>
              {this.props.toast.message}
            </div>
          )}
 
          {this.props.message && (
            <div className='order-toast-diner-name'>
              {this.props.message}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default OrderToast

const CheckMark = props => (<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z"/></svg>
)

const Warning = props => (<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg>)

const Clock = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.848 12.459c.202.038.202.333.001.372-1.907.361-6.045 1.111-6.547 1.111-.719 0-1.301-.582-1.301-1.301 0-.512.77-5.447 1.125-7.445.034-.192.312-.181.343.014l.985 6.238 5.394 1.011z"/></svg>)

const Calendar = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"/></svg>)

