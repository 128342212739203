let call = function (
  endpoint_name,
  success,
  failure = function () {},
  url_params = {},
  data = {}
) {
  let endpoint = ""
  let apiContent = ""
  let method = null

  if (endpoint_name === "login") {
    endpoint = `/member/login`
    method = "post"
  } else if (endpoint_name === "google_login") {
    endpoint = `/member/login/google`
    method = "post"
  } else if (endpoint_name === "refresh") {
    let ts = new Date().getTime()
    endpoint = '/member/refresh?ts=' + ts
    method = "get"
  } else if (endpoint_name === "qr_update_forward") {
    endpoint = "/member/captain/qr/update_forward"
    method = "post"
  } else if (endpoint_name === "qr_add_new") {
    endpoint = "/member/captain/qr/add"
    method = "post"
  } else if (endpoint_name === "qr_delete") {
    endpoint = "/member/captain/qr/delete"
    method = "post"
  } else if (endpoint_name === "brand") {
    endpoint = `/member/brand`
    method = "get"
  } else if (endpoint_name === "customers") {
    endpoint = `/member/customers/${url_params["rest_id"]}`
    method = "get"
  } else if (endpoint_name === "user_restaurants") {
    endpoint = `/member/user/restaurants`
    method = "get"
  } else if (endpoint_name === "change_restaurant") {
    endpoint = `/member/user/restaurants/change`
    method = "post"
  } else if (endpoint_name === "get_stories") {
    endpoint = `/member/stories`
    method = "get"
  } else if (endpoint_name === "grubhub") {
    endpoint = `/member/grubhub/${url_params["rest_id"]}`
    method = "get"
  } else if (endpoint_name === "add_integration") {
    endpoint = `/member/integration/add`
    method = "post"
  } else if (endpoint_name === "sales") {
    endpoint = `/member/captain/sales/${url_params["rest_id"]}`
    method = "post"
  } else if (endpoint_name === "receipt") {
    apiContent = "blob"
    endpoint = `/member/receipt/${url_params["order_id"]}`
    method = "get"
  } else if (endpoint_name === "summaries") {
    endpoint = `/member/captain/summaries/${url_params["rest_id"]}`
    method = "get"
  } else if (endpoint_name === "summary") {
    endpoint = `/member/captain/summary`
    method = "post"
  } else if (endpoint_name === "payouts") {
    endpoint = `/member/captain/payouts/${url_params["rest_id"]}`
    method = "get"
  } else if (endpoint_name === "payout-orders") {
    endpoint = `/member/captain/payout/orders`
    method = "post"
  } else if (endpoint_name === "diners") {
    endpoint = `/member/captain/diners`
    method = "post"
  } else if (endpoint_name === "transfers") {
    endpoint = "/member/transfers"
    method = "post"
  } else if (endpoint_name === "transfer") {
    endpoint = "/member/transfer"
    method = "post"
  } else if (endpoint_name === "adjustments") {
    endpoint = "/member/order-adjustments"
    method = "post"
  } else if (endpoint_name === "tax-rate") {
    endpoint = "/member/tax-rate"
    method = "get"
  } else if (endpoint_name === "adjust-order") {
    endpoint = "/member/adjust-order"
    method = "post"
  } else if (endpoint_name === "transactions") {
    endpoint = "/member/captain/transactions/orders"
    method = "post"
  } else if (endpoint_name === "menu") {
    endpoint = `/api/rest/menu/${url_params.subdomain}`
    method = "get"
  } else if (endpoint_name === "menu-modifiers") {
    endpoint = `/api/rest/menu-modifiers/${url_params.subdomain}`
    method = "get"
  } else if (endpoint_name === "blackout") {
    endpoint = `/api/rest/menu-item-blackout/${url_params.subdomain}`
    method = "get"
  } else if (endpoint_name === "blackout-item") {
    endpoint = "/member/menu/blackout-item/item"
    method = "post"
  } else if (endpoint_name === "reactivate-item") {
    endpoint = `/member/menu/activate-item/item`
    method = "post"
  } else if (endpoint_name === "activate-bulk") {
    endpoint = "/member/menu/activate-bulk"
    method = "post"
  } else if (endpoint_name === "reconcilliations") {
    endpoint = `/member/reconcilliations`
    method = "post"
  } else if (endpoint_name === "reconcilliation") {
    endpoint = `/member/reconcilliation`
    method = "post"
  } else if (endpoint_name === "hours") {
    endpoint = "/member/captain/hours"
    method = "post"
  } else if (endpoint_name === "save-hours") {
    endpoint = "/member/captain/save-hours"
    method = "post"
  } else if (endpoint_name === "is-closed-early") {
    endpoint = "/member/captain/is-closed-early"
    method = "post"
  } else if (endpoint_name === "close-early") {
    endpoint = "/member/captain/close-early"
    method = "post"
  } else if (endpoint_name === "undo-close-early") {
    endpoint = "/member/captain/undo-close-early"
    method = "post"
  } else if (endpoint_name === "special-hours") {
    endpoint = "/member/captain/special-hours"
    method = "post"
  } else if (endpoint_name === "special-hours-new") {
    endpoint = "/member/captain/special-hours-new"
    method = "post"
  } else if (endpoint_name === "special-hours-delete") {
    endpoint = "/member/captain/special-hours-delete"
    method = "post"
  } else if (endpoint_name === "default-prep-time-get") {
    endpoint = "/member/captain/default-prep-time-get"
    method = "post"
  } else if (endpoint_name === "temp-default-prep-time-set") {
    endpoint = "/member/captain/temp-default-prep-time-set"
    method = "post"
  } else if (endpoint_name === "default-prep-time-set") {
    endpoint = "/member/default-prep-time-set"
    method = "post"
  } else if (endpoint_name === "users") {
    endpoint = "/member/captain/users"
    method = "post"
  } else if (endpoint_name === "save-user") {
    endpoint = "/member/captain/save-user"
    method = "post"
  } else if (endpoint_name === "save-info") {
    endpoint = "/member/captain/save-info"
    method = "post"
  } else if (endpoint_name === "save-color") {
    endpoint = "/member/captain/save-color"
    method = "post"
  } else if (endpoint_name === 'tablet-api-key'){
    endpoint ='/member/tablet-api-code'
    method='post'
  } else {
    alert(endpoint_name + " is not a valid endpoint")
  }

  let url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}${endpoint}`
  let headers = null
  let api_key = localStorage.getItem("api_key")
  if (method === "post") {
    headers = {
      'X-HNGR-API-KEY': window.API_KEY,
      "Content-Type": "application/json",
    }
    
    if (window.IS_CAPTAIN_ADMIN){
      headers['X-CAPTAIN-ADMIN'] = 1
    }

    if (window.CAPTAIN_ADMIN_RESTAURANT_ID){
      headers['X-CAPTAIN-ADMIN-RESTAURANT-ID'] = window.CAPTAIN_ADMIN_RESTAURANT_ID
    }

    if (window.IS_MEMBER_LOGIN){
      headers['X-CAPTAIN-MEMBER_LOGIN'] = 1 
      headers['X-CAPTAIN-RESTAURANT-ID'] = window.MEMBER_REST_ID
    }  

    fetch(url, { method: "post", headers: headers, body: JSON.stringify(data) })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => success(json))
        } else {
          res.json().then((json) => failure(json))
        }
      })
      .catch((err) => {
        console.log(err)
        if (failure) {
          failure(err)
        }
      })
  }

  if (method === "patch") {
    headers = {
      "X-HNGR-API-KEY": api_key,
      "Content-Type": "application/json",
      "Clickstream-Session-Id": window.CLICKSTREAM_SESSION_ID,
      token_id: window.TOKEN_ID,
      "App-Version": window.APP_VERSION,
    }
    fetch(url, {
      method: "patch",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => success(json))
        } else {
          res.json().then((json) => failure(json))
        }
      })
      .catch((err) => {
        console.log(err)
        if (failure) {
          failure(err)
        }
      })
  }

  if (method === "delete") {
    headers = {
      "X-HNGR-API-KEY": api_key,
      "Content-Type": "application/json",
      "Clickstream-Session-Id": window.CLICKSTREAM_SESSION_ID,
      token_id: window.TOKEN_ID,
      "App-Version": window.APP_VERSION,
    }
    fetch(url, { method: "delete", headers: headers })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => success(json))
        } else {
          res.json().then((json) => failure(json))
        }
      })
      .catch((err) => {
        console.log(err)
        if (failure) {
          failure(err)
        }
      })
  }

  if (method === "get") {
    headers = {
      'X-HNGR-API-KEY': window.API_KEY
    }
    
    if (window.IS_CAPTAIN_ADMIN){
      headers['X-CAPTAIN-ADMIN'] = 1
    }

    if (window.CAPTAIN_ADMIN_RESTAURANT_ID){
      headers['X-CAPTAIN-ADMIN-RESTAURANT-ID'] = window.CAPTAIN_ADMIN_RESTAURANT_ID
    }

    if (window.IS_MEMBER_LOGIN){
      headers['X-CAPTAIN-MEMBER_LOGIN'] = 1
      headers['X-CAPTAIN-RESTAURANT-ID'] = window.MEMBER_REST_ID
    }

    if (apiContent === "blob") {
      fetch(url, { method: "get", headers: headers })
        .then((res) => {
          if (res.ok) {
            res.blob().then((blob) => success(blob))
          } else {
            res.blob().then((blob) => failure(blob))
          }
        })
        .catch((err) => {
          console.log(err)
          if (failure) {
            failure(err)
          }
        })
    } else {
      fetch(url, { headers: headers, method: "get" })
        .then((res) => {
          if (res.ok) {
            res.json().then((json) => success(json))
          } else {
            res.json().then((json) => failure(json))
          }
        })
        .catch((err) => {
          console.log(err)
          if (failure) {
            failure(err)
          }
        })
    }
  }
}

module.exports = {
  call: call,
}
