import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from "classnames"
import Search from "./svg/Search"
import "./Search.css"

class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidUpdate(prevProps){
  }


  render(){
    if(this.props.sticky){
      return (
        <div className='search-bar-container-sticky'>
          <div className='search-bar-inner-container-sticky'>
             <div 
              style={{marginTop:'0px'}}
              className={classnames('search-bar-container')}
            >
              <Search className='search-bar-icon'/>
              <input 
                onFocus={()=>{
                  this.props.history.push({pathname:'/orders/search'})
                }}
                placeholder='Search orders'
                className='search-bar-input'
              />
            </div>
          </div>
        </div>  
      )
    } else {
      return (
        <div 
          className={classnames('search-bar-container')}
        >
          <Search className='search-bar-icon'/>
          <input 
            onFocus={()=>{
              this.props.history.push({pathname:'/orders/search'})
            }}
            placeholder='Search orders'
            className='search-bar-input'
          />
        </div>
      )
    }
  }
}
      

export default withRouterAndRef(SearchBar) 


