import React from "react"
import { Text } from "../../../components"
import Logo from "./Logo"
import "./logos.scss"

const data = {
  primary: {
    header: "Primary Logo",
    usedIn:
      "Website, Ordering page, Map, Email confirmation, Online receipts & Favicon",
    field: "logo_color",
  },
  secondary: {
    header: "Secondary Logo",
    usedIn: "Ordering header; Please upload white logo for optimal results",
    field: "logo_url",
  },
  receipt: {
    header: "Receipt Logo",
    usedIn: "Paper Receipts",
    field: "receipt_logo_url",
  },
  // favicon: {
  //   header: "Favicon Logo",
  //   usedIn: "Search engines, URL tab, Browser address bar",
  //   field: "logo_color",
  // },
}

class Logos extends React.Component {
  render() {
    return (
      <div className="mt-12">
        <Text size="xl" weight="black">
          Logos
        </Text>
        <div className="logos__container">
          {Object.keys(data).map((type) => (
            <div className="logo-wrapper">
              <Logo
                key={data[type].field}
                {...data[type]}
                image={this.props.rest[data[type].field]}
                refreshData={this.props.refreshData}
              />
            </div>
          ))}

          {/* <div className="logos-wrapper">
            <Logo data={header:"Secondary Logo", usedIn:  />
          </div>
          <div className="logos-wrapper">
            <Logo header="Receipt Logo" />
          </div> */}
        </div>
      </div>
    )
  }
}

export default Logos
