import React from "react"
import { Text, Button } from "../../components"
import QRCodeData from "./QRCodeData"
import api from "../../api"

class QRCodeForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {forward_url: 'https://',
      qr: null}
    this.submitForm = this.submitForm.bind(this)
    this.changeURL = this.changeURL.bind(this)
    this.onSaveSuccess = this.onSaveSuccess.bind(this)
  }

  changeURL(e) {
    this.setState({forward_url: e.target.value})
  }

  onSaveSuccess(r) {
    this.props.appendNewQR(r)
  }

  onSaveFailure() {
    alert("Error saving URL")
  }

  submitForm() {
    let payload = {'forward_url': this.state.forward_url}
    api.call('qr_add_new', this.onSaveSuccess,
      this.onSaveFailure, {}, payload)
  }

  render() {
      return (<div style={styles.component}>      
        Points to: <input style={styles.inputForm} 
          onChange={this.changeURL}
          value={this.state.forward_url} /> <br />

        <br />
        <Button
          onClick={this.submitForm}
          type="primary"
          color="pink"
          size="l">
        Create QR
        </Button>
      </div>)
  }
}

const styles = {
  inputForm: {
    flex: '1',
    alignSelf: 'stretch',
    border: '1px solid black'
  },
  component: {
    textAlign: "left",
    width: '100%',
    padding: '4px',
    flex: '1'
  }
}

export default QRCodeForm
