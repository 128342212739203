import React, { Component } from "react"
import classnames from 'classnames'
import UserContextHOC from "../contexts/UserContextHOC"
import LogoWithText from "../orders/svg/LogoWithText"
import Employee from "../orders/svg/Employee"
import Owner from "../orders/svg/Owner"
import Phone from "../orders/svg/PhoneLogin"
import Google from "../orders/svg/Google"
import withRouterAndRef from "../withRouterAndRef"
import {Capacitor} from '@capacitor/core';
import { GoogleLogin } from "react-google-login"
import "./Login.css"
import "./LoginMember.css"
import "./LoginChooseType.css"

const Api = require("../api")

class LoginChooseType extends Component {
  constructor(props) {
    super(props)
    let platform = Capacitor.getPlatform()
    let initialPage = 'phone'
    if(platform === 'web' && !this.props.page){
      initialPage = 'member' 
    }
    this.state = {
      page:initialPage,
      password:null,
      email:null
    }
  }

  componentDidMount(){
    document.getElementsByTagName('body')[0].style.height = '100%';
    document.getElementsByTagName('html')[0].style.height = '100%';
    document.getElementById("root").style.height = '100%';
    document.getElementById("app-container").style.height = '100%';
  }

  componentWillUnmount(){
    document.getElementsByTagName('body')[0].style.height = null;
    document.getElementsByTagName('html')[0].style.height = null;
    document.getElementById("root").style.height = null
    document.getElementById("app-container").style.height = null;
  }

  signIn(){
    Api.callApi({
      endpoint:'/captain/email-login',
      method:'post',
      data:{
        email:this.state.email,
        password:this.state.password
      },
      success:(data)=>{
        console.log("data", data)
        if(data.message){
          alert(data.message)
        } else {
          if(data.is_admin){
            this.props.user.loginAsAdmin(data.api_key)
          } else {
            this.props.history.push({
              pathname:'/login/choose-rest',
              state:{
                member:1,
                api_key:data.api_key,
                name:data.user.email,
                rests:data.rests
              }
            })
          }
        }
      },
      failure:(err)=>{
        alert('Unknown error')
        console.log("error", err)
      }
    })
  }


  handleGoogleSuccess(resp) {
    Api.callApi({
      endpoint:'/captain/google-login',
      method:'post',
      data:{
        access_token:resp.accessToken
      },
      success:(data)=>{
        if(data.message){
          alert(data.message)
        } else {
          if(data.is_admin){
            this.props.user.loginAsAdmin(data.api_key)
          } else {
            this.props.history.push({
              pathname:'/login/choose-rest',
              state:{
                member:1,
                api_key:data.api_key,
                name:data.user.email,
                rests:data.rests
              }
            })
          }
        }
      },
      failure:(err)=>{
        alert('Unknown error')
        console.log("error", err)
      }
    })
  }

  handleGoogleFailure(err) {
    console.log(err)
  }


  renderMember(){
    return (
      <div className='login-member-container'>
        <div className='login-member-container-left'>
          <div className='login-member-container-left-inner'>
            <LogoWithText style={{height:'53px'}} />
            <div className='login-with-member-welcome-back'>
              Welcome Back
            </div>
            <div className='login-with-member-subheader'>
              Sign in to manage your restaurant
            </div>
            <div  style={{marginTop:'40px'}} className='login-with-member-input-label'> Email </div>
            <input
              className='login-with-member-input'
              placeholder='Enter your email'
              value={this.state.email}
              onChange={(e)=>this.setState({email:e.target.value})}
            />
            
            <div style={{marginTop:'20px'}} className='login-with-member-input-label'> Password </div>
            <input
              className='login-with-member-input'
              type='password'
              placeholder='*********'
              value={this.state.password}
              onChange={(e)=>this.setState({password:e.target.value})}
            />

            <div 
              className='login-with-member-signin'
              onClick={()=>this.signIn()}
            >
              <div>Sign In</div>
            </div>

            <GoogleLogin
              clientId='972462016047-ddgq42kpid7a30egbu6lf90abhemaebc.apps.googleusercontent.com'
              buttonText="Login with Google"
              className="LoginGoogle login-button"
              onSuccess={(res) => this.handleGoogleSuccess(res)}
              onFailure={(err) => this.handleGoogleFailure(err)}
              cookiePolicy={"single_host_origin"}
              render={renderProps => (
                <div 
                  onClick={renderProps.onClick}
                  style={renderProps.disabled ? {opacity:'0.5', pointerEvents:'none'} : {}}
                  className='login-with-google'
                >
                  <Google/>
                  Login With Google
                </div>
              )}
            />


            <div className='login-with-member-or-container'>
              <div className='login-with-member-or-line'></div>
              <div>or</div>
              <div className='login-with-member-or-line'></div>
            </div>


            <div 
              className='login-with-phone'
              onClick={()=>this.props.history.push({
                pathname:'/login-with-phone'
              })}
            >
              <Phone style={{marginRight:'5px'}}/>
              <div style={{marginLeft:'5px'}}>Sign in with Phone</div>
            </div>

            

          </div>
        </div>
        <div className='login-member-container-right'>
          <img 
            className='login-member-right-img' 
            src='https://cdn.hngr.co/captain/captainloginrest.png' 
          />
        </div>
      </div>
    )
  }

  renderPhone(){
    return (
      <div className='login-choose-type-container login-page'>
        <div className='login-choose-type-header'>
          <LogoWithText />
        </div>
        <div className='login-choose-type-subheader'>
          Trusted by local restaurant owners nationwide.
        </div>

        <div 
          className='login-choose-type-box'
          onClick={()=>{
            this.props.history.push({pathname:'/login-employee'})  
          }}
        >
          <div className='login-choose-type-box-top-row'>
            <Employee />
            <div>Log In as Employee</div>
          </div>
          <div className='login-choose-type-box-bottom-row'>
            Recieve code via phone call at restaurant location
          </div>
        </div>

        <div 
          className='login-choose-type-box' 
          onClick={()=>{
            this.props.history.push({pathname:'/login-owner'})  
          }}
          style={{marginTop:'25px'}}
        >
          <div className='login-choose-type-box-top-row'>
            <Owner/>
            <div>Log In as Owner</div>
          </div>
          <div className='login-choose-type-box-bottom-row'>
            Recieve code via text message on a registered cell phone
          </div>
        </div>
      </div>
    )
  }

  render(){
    if(this.state.page === 'phone') return this.renderPhone()
    else return this.renderMember()
  }
}
      

export default withRouterAndRef(UserContextHOC(LoginChooseType))


