import React, { Component } from "react"
import ToastContextHOC from "../contexts/ToastContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import ModifierClassBox from "./ModifierClassBox"
import "./Menu.css"
import Reactivate from "../orders/svg/Reactivate"
import Deactivate from "../orders/svg/Deactivate"
import Archive from "../orders/svg/Archive"
import Check from "../orders/svg/Check"

const Api = require("../api")
const Money =  require("../helpers/money")
const Timestamp = require("../helpers/timestamps")

const SubModifierClass = ({display_text, internal_name, modifier_count, deactivated_count}) => (
    <div className='sub-modifier-class'>
      {internal_name && (
        <div className='sub-modifier-class-internal-name'>
            {internal_name}
        </div>
      )}

      <div className='sub-modifier-class-display-text'>
        {display_text}
      </div>

      <div className='sub-modifier-class-options-container'>
        <div>{modifier_count} options</div>
      </div>

    </div>
)

class MenuItemBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded:false
    }
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.containerRef = React.createRef()
    window.mi = this
  }

  componentDidMount(){
   window.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount(){
   window.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event){
    if(!this.state.expanded) return
    if( 
      this.containerRef &&
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target)
    ){  
      this.setState({expanded:false})
    }   
  }

  openDeactivateModal(){
    this.props.history.push({
      pathname:this.props.location.pathname + "/deactivate-modal",
      state:{
        item:this.props.item
      }   
    })  
  }


  archive(){
    if(this.props.isModifier){
      this.props.history.push({
        pathname:this.props.location.pathname + "/archive-modal",
        state:{
          modifier:this.props.modifier,
          item:this.props.item
        }   
      })  
    } else {
      this.props.history.push({
        pathname:this.props.location.pathname + "/archive-modal",
        state:{
          item:this.props.item
        }   
      })  
    }
  }

  reactivate(){
    if(this.props.isModifier){
      Api.callApi({
        endpoint:'/api/tablet/reactivate-modifier',
        method:'post',
        data:{modifier_id:this.props.modifier.modifier_id},
        success:(data)=>{
          this.props.toast.setToast({
            type:'generic',
            title:'Reactivated'
          })
        },
        failure:(err)=>{
          alert('An unexpected error occurred')
        }
      })

    } else {
      Api.callApi({
        endpoint:'reactivate',
        data:{item_id:this.props.item.menu_item_id},
        success:(data)=>{
          this.props.toast.setToast({
            type:'reactivated',
            item:this.props.item
          })
        },
        failure:(err)=>{
          alert('An unexpected error occurred')
        }
      })
    }
  }

  formatTimestamp(ts){
    let time = Timestamp.getTimeOfDay(ts)
    let mm = Timestamp.getMonthInt(ts)
    let dd = Timestamp.getDayOfMonth(ts)
    let yy  = Timestamp.getPartialYear(ts)
    return `${mm}/${dd}/${yy} ${time}`
  }

  clickBox(e){
    if(this.props.clickable === false) return
    if(e.target.className === 'menu-item-elipses') return
    if(this.props.onClick){
      this.props.onClick(e)
      return
    }
    this.props.history.push({
      pathname:'/menu/menu-item/'+this.props.menu_uuid+ '/' + this.props.item.menu_item_id,
      state:{item:this.props.item}
    })
  }

  renderArchive(){
    if(window.innerWidth < 1000) return null
    if(this.props.noArchive) return null
    return (
      <div  
        className='menu-option-container'
        onClick={()=>{
          this.archive()
        }}
       > 
        <div 
          className='archive-x-container'
        >
          <Archive />
        </div>
        <div className='menu-option-label' style={{color:'purple'}}>
          Archive
        </div>
       </div>
    )
  }

  renderReactivate(){
    return (
       <div  
        className='menu-option-container'
        onClick={()=>{
          this.reactivate()
        }}
      > 
        <div 
          className='reactivate-x-container'
        >
          <Reactivate />
        </div>
        <div className='menu-option-label green'>
          Reactivate 
        </div>
      </div>
    )
  }

  renderDeactivate(){
    return (
      <div  
        className='menu-option-container'
        onClick={()=>{
          this.openDeactivateModal()
        }}
      > 
        <div 
          className='deactivate-x-container'
        >
          <Deactivate/>
        </div>
        <div className='menu-option-label'>
          Deactivate
        </div>
      </div> 
    )
  }

  renderActions(){
    if(this.props.item.disabled_at){
      return this.renderReactivate()
    }

    if(this.props.item.blacked_out){
      return (
        <>
          {this.renderArchive()}
          {this.renderReactivate()}
        </>
      )
    } else {
      return (
        <>
          {this.renderArchive()}
          {this.renderDeactivate()}
        </>
      )
    }
  }

  render(){
    if(this.state.expanded){
      return (
        <div style={this.props.style} key='expanded' className='menu-item-box-expanded'>

          <div className={classnames('menu-item-box-expanded-left',
            {'menu-item-box-deactivated':this.props.item.blacked_out},
            {'menu-item-box-deactivated':this.props.item.disabled_at})}
          >
            <div className='menu-item-name'>{this.props.item.name}</div>
            {this.props.priceOverride !== null && this.props.priceOverride !== undefined ? ( 
              <div className='menu-item-price'>
                {Money.formatCents(this.props.priceOverride)}
              </div>
            ) : (
              <div className='menu-item-price'>
                {Money.formatCents(this.props.item.price)}
              </div>
            )}


          {this.props.isSearchResult === true && this.props.modifierClassesTextList && this.props.modifierClassesTextList.length > 0 &&  (
            <div className='menu-item-box-appears-in'>
              Appears in: {this.props.modifierClassesTextList.join(', ')}
            </div>
          )}


          </div>



          <div ref={this.containerRef} style={{display:'flex'}}>
            {this.renderActions()}
           </div>
        </div>
      )
    } else {
      return (
        <div 
          className={classnames('menu-item-box',
            {'menu-item-box-deactivated':this.props.item.blacked_out},
            {'menu-item-box-deactivated':this.props.item.disabled_at})}
          key='not-expanded'
          style={this.props.style} 
          onClick={(e)=>this.clickBox(e)}
        >
          {this.props.hideElipses !== true && (
            <div 
              className='menu-item-elipses'
              onClick={()=>this.setState({expanded:true})}
            >
              •••
            </div>
          )}
          <div className='menu-item-name'>{this.props.item.name}</div>

          {this.props.isSearchResult === true && this.props.modifierClassesTextList && this.props.modifierClassesTextList.length > 0 &&  (
            <div className='menu-item-box-appears-in'>
              Appears in: {this.props.modifierClassesTextList.join(', ')}
            </div>
          )}

          <div className='menu-item-box-bottom-row'>

            {this.props.priceOverride !== null && this.props.priceOverride !== undefined ? ( 
              <div className='menu-item-price'>
                {Money.formatCents(this.props.priceOverride)}
              </div>
            ) : (
              <div className='menu-item-price'>
                {Money.formatCents(this.props.item.price)}
              </div>
            )}

            {this.props.item.blacked_out && (
              <div className='menu-item-deactivated-until'>
                Deactivated til {this.formatTimestamp(this.props.item.blackout_until_local_ts)}
              </div>
            )}

            {this.props.item.disabled_at && (
              <div className='menu-item-deactivated-until'>
                Archived
              </div>
            )}
          </div>

          {this.props.modifierClasses && this.props.modifierClasses.length > 0 && (
            <div className='sub-modifier-classes-container'>
              {this.props.modifierClasses.map(mc => (
                <div 
                  key={mc.modifier_class_id} 
                  style={{marginTop:'8px'}}
                >
                  <div
                    onClick={()=>{
                      this.props.history.push({pathname:'/menu/modifier-class/'+mc.modifier_class_id})
                    }}
                  >
                    <ModifierClassBox 
                      style={{marginBottom:'5px'}} 
                      insideBox={true} 
                      menu_uuid={this.props.menu_uuid}
                      mc={mc} 
                    />
                  </div>
                </div>
              ))}
            </div>

          )}
        </div>
      )
    }
  }
}
      

export default withRouterAndRef(ToastContextHOC(MenuItemBox))


