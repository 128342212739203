import React, { Component } from "react"
import LoadingActionButton from '../Loading/LoadingActionButton'
import classnames from "classnames"
import "./OrderCard.css"

class ButtonWithSpinner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:false
    }
    this.click = this.click.bind(this)
  }

  click(e){
    if(this.state.loading) return
    this.setState({loading:true})
    if(this.props.stopPropagation) e.stopPropagation()
    this.props.onClick(()=>this.setState({loading:false})) 
  }

  render(){
    return (
      <div 
        className={this.props.className} 
        style={this.props.style}
        onClick={this.click}
      >
        {this.state.loading && (
          <LoadingActionButton 
            style={{opacity:0, position:'static'}}
          />
        )}
        <div className='flex-center'>
          {this.props.svg && !this.state.loading && this.props.svgLeft && (
            <div className='button-with-spinnner-svg-container'>{this.props.svg}</div>
          )}
          <div>{this.props.text}</div>
          {this.props.svg && !this.state.loading && !this.props.svgLeft && (
            <div className='button-with-spinnner-svg-container'>{this.props.svg}</div>
          )}
        </div>
        {this.state.loading && (
          <LoadingActionButton 
            color={this.props.spinnerColor}
            style={{position:'relative', right:'-10px'}}
          />
        )}
      </div>
    )
  }
}
      
export default ButtonWithSpinner 
