import React from "react"
import { ReactComponent as PaintBrush } from "../../../assets/icons/paintbrush.svg"
import { ReactComponent as PencilEdit } from "../../../assets/icons/pencil.svg"
import { Text, ButtonWrapper } from "../../../components"

class Color extends React.Component {
  hasColor = () => {
    return !!this.props.color
  }
  render() {
    return (
      <div className="brand__color-container">
        <div className="flex-row flex-apart flex-align-start">
          <Text size="l" weight="black">
            {this.props.header}:
          </Text>

          <ButtonWrapper
            onClick={this.props.toggleOpen}
            id="opener"
            className="ml-2"
          >
            <PencilEdit height={22} width={22} fill="#D62E70" id="opener" />
          </ButtonWrapper>
        </div>
        {this.hasColor() ? (
          <div className="flex-col mt-2">
            <div
              style={{ backgroundColor: this.props.color }}
              className="brand__color"
            />
            <Text weight="bold" className="mt-4">
              {this.props.color}
            </Text>
          </div>
        ) : (
          <ButtonWrapper
            className="brand__color-pick"
            id="opener"
            onClick={this.props.toggleOpen}
          >
            <PaintBrush className="mr-2" id="opener" />
            <Text color="blue" weight="regular" id="opener">
              Choose a Color
            </Text>
          </ButtonWrapper>
        )}
      </div>
    )
  }
}
export default Color
