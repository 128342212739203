import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./text.css"

const sizes = ["xs", "s", "sm", "m", "l", "xl", "header"]
const weights = ["light", "regular", "bold", "extrabold", "black"]
const colors = ["blue", "pink", "grey", "white", "black"]
const alignments = ["left", "center", "right"]

const Text = ({
  as,
  children,
  size,
  weight,
  color,
  align,
  className,
  style,
  ...rest
}) => {
  const WrapperElement = as ? as : "div"
  return (
    <WrapperElement
      className={classnames({
        [`text--${size}`]: size,
        [`text--${weight}`]: weight,
        [`text--color-${color}`]: color,
        [`text--${align}`]: align,
        [className]: className,
      })}
      style={{ ...style }}
      {...rest}
    >
      {children}
    </WrapperElement>
  )
}

Text.defaultProps = {
  size: "m",
  align: "left",
}

Text.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.elementType,
  align: PropTypes.oneOf(alignments),
  size: PropTypes.oneOf(sizes),
  weight: PropTypes.oneOf(weights),
  color: PropTypes.oneOf(colors),
}

export default Text
