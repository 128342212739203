import React, { useState } from "react"
import api from "../../api"
import { ButtonWrapper, Text, SaveActions, Input } from "../../components"

function formmatPhone(phone) {
  phone = phone.replace(/-/g, "").replace("(", "").replace(")", "")
  phone =
    "(" +
    phone.slice(0, 3) +
    ") " +
    phone.slice(3, 6) +
    "-" +
    phone.slice(6, 15)
  return phone
}

const initialErrorState = {
  name: false,
  secondary_name: false,
  phone_number: false,
  website_url: false,
}

function BasicInfo(props) {
  const [isEditing, setIsEditing] = useState(false)
  const { name, secondary_name, phone_number, website_url } = props.rest
  const initialState = {
    name,
    secondary_name,
    phone_number: stripPhone(phone_number),
    website_url,
  }
  const [infoState, setInfoState] = useState(initialState)

  const [errors, setErrors] = useState(initialErrorState)
  const { rest } = props
  const handleChange = (field, value) => {
    setInfoState({
      ...infoState,
      [field]: value,
    })

    setErrors(initialErrorState)
  }
  const handleEnter = (e) => {
    if (!isEditing) {
      return
    }
    if (e.key === "Enter") {
      onSave()
    }

    if (e.key === "Escape") {
      cancelEdits()
    }
  }

  React.useEffect(() => {
    window.addEventListener("keydown", handleEnter)

    return () => {
      window.removeEventListener("keydown", handleEnter)
    }
  }, [infoState])

  const runValidations = () => {
    // validate phone number
    if (!infoState.phone_number || !validatePhone(infoState.phone_number)) {
      setErrors({ ...errors, phone_number: true })
      return true
    } else {
    }

    return false
  }

  const onSave = async () => {
    if (runValidations()) {
      //There was an error
      return
    }
    const formatted = formmatPhone(infoState.phone_number)
    api.call(
      "save-info",
      () => {
        //props.refreshData()
        alert('Updated!')
        setIsEditing(false)
      },
      null,
      null,
      {
        rest_id: rest.restaurant_id,
        ...infoState,
        phone_number: formatted,
      }
    )
  }

  const cancelEdits = () => {
    setIsEditing(false)
    setInfoState(initialState)
  }

  return (
    <div>
      <div className="flex-row flex-align-center flex-apart mt-2 pv-2 br-b br-blue">
        <Text weight="black" size="xl">
          Basic Information
        </Text>
        {!isEditing && (
          <ButtonWrapper onClick={() => setIsEditing(!isEditing)}>
            <Text color="pink" weight="bold">
              Edit
            </Text>
          </ButtonWrapper>
        )}
      </div>
      <div className="basic-info__container mt-4">
        <Rows
          rest={rest}
          infoState={infoState}
          errors={errors}
          isEditing={isEditing}
          handleChange={handleChange}
        />
      </div>

      {isEditing && <SaveActions onCancel={cancelEdits} onSave={onSave} />}
    </div>
  )
}

function stripPhone(number) {
  if(number){
    return number.replace(/[^\d]/g, "")
  } else {
    return ''
  }
}

function validatePhone(number) {
  const s = number.toString().match(/\d/g)
  if (!s) {
    return false
  }
  return s.length === 10
}

const fields = [
  { field: "name", fieldName: "Restaurant Name" },
  { field: "secondary_name", fieldName: "Secondary Name" },
  {
    field: "phone_number",
    fieldName: "Phone",
    placeholder: "e.g 123456789",
    errorMessage: "Not a valid phone number",
  },
  { field: "website_url", fieldName: "Website" },
]
function Rows({ rest, errors, isEditing, infoState, handleChange }) {
  return fields.map((row, i) => (
    <Row
      row={row}
      isEditing={isEditing}
      hasError={errors[row.field]}
      value={rest[row.field]}
      inputValue={infoState[row.field]}
      handleChange={handleChange}
    />
  ))
}

function Row(props) {
  const { row, hasError, value, inputValue, isEditing, handleChange } = props
  return (
    <div className="flex-row flex-align-center flex-apart mb-6">
      <Text weight="black" size="l" className="basic-info__row-field">
        {row.fieldName}:
      </Text>
      {isEditing ? (
        <Input
          errorMessage={row.errorMessage}
          placeholder={row.placeholder}
          validation={row.validation}
          hasError={hasError}
          label={row.fieldName}
          onChange={(val) => handleChange(row.field, val)}
          containerClassName="basic-info__row-value--active"
          value={!!inputValue ? inputValue : ""}
        />
      ) : (
        <Text size="m" className="basic-info__row-value">
          {value}
        </Text>
      )}
    </div>
  )
}

export default BasicInfo
