import React, { Component } from "react"
import DesktopSidebar from "./DesktopSidebar"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import Breadcrumbs from "./Breadcrumbs"
import "./Menu.css"

const Api = require("../api")

class Menus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded:false,
      isMobile:window.innerWidth <= 599,
      isTablet:window.innerWidth > 599,
      isDesktop:window.innerWidth >= 1000
    }
    window.menu = this
  }

  componentDidMount(){
    this.loadMenus()
  }

  loadMenus(){
    Api.callApi({
      endpoint:'get-menus',
      success:(data)=>this.setState({loaded:true, menus:data.menus}),
      failure:(err)=>{
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error loading the menus' 
          }
        })
      }
    })
  }

  openMenu(uniq_uuid){
    if(this.state.isTablet){
      this.props.history.push({
        pathname:'/menu/menu/'+uniq_uuid
      })
    } else {
      this.props.history.push({
        pathname:'/menu/menu-categories/'+uniq_uuid
      })
    }
  }

  renderContent(){
    if(!this.state.loaded) return (<Loading />)
    return (
      <div 
        className='page-content'
        style={this.state.isDesktop ? {width:'700px'} : null}
      
      >
        <div className='section-title'>All Menus</div>
        <div className='section-subtitle section-subtitle-margin'>
          Select a menu to edit items
        </div>
      
      <div className='menus-container'>
        {this.state.menus.map(menu => (
          <div 
            key={menu.uniq_uuid}
            onClick={()=>this.openMenu(menu.uniq_uuid)}
            className='menu-wrapper'
          >
            <div className='semibold-title'>
              {menu.name}
            </div>
          </div>
        ))}
      </div>
    </div>
   )
  }

  render(){
    if(this.state.isDesktop){
      return (
        <div className='desktop-menu-item-page-container'>
          <DesktopSidebar 
            menu_uuid={null}
          />
          <div className='desktop-content-with-crumbs'>
          <Breadcrumbs
            crumbs={[]}
            current='Menus'
          />
          {this.renderContent()}
          </div>
        </div>
      )
    } else {
      return this.renderContent()
    }
  }


}
      

export default withRouterAndRef(Menus) 


