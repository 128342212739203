import React, { Component } from 'react';
import withRouterAndRef from "../withRouterAndRef"

export const ModifierModalContext = React.createContext()

class ModifierProvider extends Component {

  constructor(props){
    super(props)
    this.state = {
      confirmed:false,
      confirm:()=>this.setState({confirmed:true}, ()=>this.props.history.goBack()),
      setSave:(func)=>this.setState({saveFunction:func}),
      save:()=>{this.saveFunction(); this.state.reset()},
      modifier:null,
      modifierClass:null,
      setModifier:(m, cb)=>this.setState({modifier:m}, cb),
      setModifierClass:(mc, cb)=>this.setState({modifierClass:mc}, cb),
      reset:()=>this.setState({modifier:null, modifierClass:null, confirmed:false, saveFunction:null})
    }
  }

  
  // if the Modifier is not logged in, render the <Login />
  render() {
    return  (
      <ModifierModalContext.Provider value={this.state}>
        {this.props.children}
      </ModifierModalContext.Provider>
    )
  }
}

export default withRouterAndRef(ModifierProvider);
