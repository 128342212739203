import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import DesktopSidebar from "../menu/DesktopSidebar"
import Loading from "../Loading/Loading"
import classnames from 'classnames'
import "./MemberSwitchRest.css"
const Api = require("../api")

class SwitchRest extends Component {
  constructor(props) {
    super(props)
    this.state={
      rest:this.props.user.rest ? this.props.user.rest : {},
      rests:null
    }
    this.loadRests()
  }

  componentDidUpdate(){
    if(this.props.user.rest && this.state.rest.restaurant_id != this.props.user.rest.restaurant_id){
      this.setState({rest:this.props.user.rest})
    }
  }

  loadRests(){
    Api.callApi({
      endpoint:'/tablet/rest-list',
      method:'get',
      success:(data)=>{
        console.log("rests", data)
        this.setState({rests:data.rests})
      },
      failure:(err)=>{
        alert('Error getting rests')
      }
    })
  }
  chooseRest(rest){
    this.props.user.switchRest(rest)
    this.setState({rest:rest})
  }

  renderRests(){
    let rests = this.state.rests
    return (
      <div style={{minWidth:'400px', cursor:'pointer'}} className='choose-rest-rests-container'>
        {this.state.rests.map(rest => (
          <div
            className={classnames('choose-rest-rest-card',
              {'choose-rest-card-selected':rest.restaurant_id === this.state.rest.restaurant_id})}
            onClick={()=>this.chooseRest(rest)}
          >
            <img className='choose-rest-logo' src={rest.logo} />
            <div className='choose-rest-right-side'>
              <div className='choose-rest-right-side-row choose-rest-name'>{rest.name}</div>
              <div className='choose-rest-right-side-row'>{rest.address1}</div>
              <div className='choose-rest-right-side-row'>
                {rest.city}, {rest.state} {rest.zipcode}
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  render(){
    return (
      <div className='desktop-menu-item-page-container'>
        <DesktopSidebar 
        />
        <div className='desktop-choose-rest-container'>
          <h3>Choose Restaurant</h3>
          {this.state.rests === null ? (
            <Loading />
          ) : (
            <>
              {this.renderRests()}
            </>
          )}
        </div>
      </div>
    )
  }
}
      

export default UserContextHOC(SwitchRest)


