import React, { Component } from "react"
import ToastContextHOC from "../contexts/ToastContextHOC"
import ModifierModalContextHOC from "../contexts/ModifierModalContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import SearchIcon from "../orders/svg/Search"
import MenuItemBox from "./MenuItemBox"
import "./Menu.css"
import "./ModalSearch.css"

const Api = require("../api")
const Money =  require("../helpers/money")
const Timestamp = require("../helpers/timestamps")

class AddListModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      closing:false,
      loading:true,
      items:[],
      itemsFiltered:[],
    }
    if(
      this.props.page === 'modifiers' || 
      this.props.page === 'modifier-class-create' ||
      this.props.page === 'modifier-class-edit'
    ){
      this.title = 'Modifiers'
    } else if (this.props.page === 'modifier-classes'){
      this.title = 'Modifier Groups'
    }
    window.add = this
  }

  componentDidMount(){
    window.addEventListener('mousedown', this.handleClickOutside)
    document.body.style.overflow = "hidden"
    this.loadItems()
  }
  componentWillUnmount(){
    window.removeEventListener('mousedown', this.handleClickOutside)
    document.body.style.overflow = "auto"
  }
  
  componentDidUpdate(prevProps){
    if(prevProps.modal.confirmed === false && this.props.modal.confirmed === true){
      this.props.modal.reset()
      this.addMofierToGroup(this.i, true)
    }
  }

  loadItems(){
    let endpoint 
    let responseKey
    let method = 'get'
    let data = {
    }
    if(this.props.page === 'modifier-classes' || this.props.page === 'modifier-class-edit-item-desktop'){
      endpoint = '/api/menu/modifier-groups-from-item/'+ this.props.history.location.state.item.menu_item_id
      responseKey = 'modifier_classes'
    } else if (this.props.page === 'modifiers' || this.props.page === 'modifier-class-edit'){
      endpoint = '/api/menu/modifiers-from-group/'+ this.props.history.location.state.modifierClass.modifier_class_id
      if(
        this.props.history.location &&
        this.props.history.location.state &&
        this.props.history.location.state.deletedModifierIds
      ){
        data['deleted_modifier_ids'] = this.props.history.location.state.deletedModifierIds
      }
      method = 'post'
      responseKey = 'modifiers'
    } else if (this.props.page === 'modifier-class-create'){
      endpoint = '/api/menu/modifiers-from-menu/'+ this.props.history.location.state.menu_uuid
      responseKey = 'modifiers'
    } else if (this.props.page === 'modifier-class-new-item'){
      endpoint = '/api/menu/modifier-groups/'+this.props.history.location.state.menu_uuid
      responseKey = 'modifier_classes'
    }
    Api.callApi({
      endpoint:endpoint,
      method:method,
      data:data,
      success:(data)=>{
        this.setState({
          loading:false,
          items:data[responseKey],
          itemsFiltered:data[responseKey]
        })
      },
      failure:(err)=>{
        console.log(err)
        alert('Unknown error')
      }
    })
  }

  close() {
    let that = this
    this.setState({ closing: true }, () => {
      setTimeout(() => {
        this.props.history.goBack()
      }, 200)
    })
  }


  maybeClose(e){
    if (e.target.className === "menu-modal-background"){
      this.close()
    }
  }

  search(query){
    if(this.props.page === 'modifier-classes' || this.props.page === 'modifier-class-new-item' || this.props.page === 'modifier-class-edit-item-desktop'){
      return this.searchModifierClasses(query)
    } else if (this.props.page === 'modifiers'  || this.props.page === 'modifier-class-create' || this.props.page == 'modifier-class-edit'){
      return this.searchModifiers(query)
    }
  }

  searchModifiers(query){
    query = query.toLowerCase()
    let items = this.state.items.filter((item)=>{
      if(item.name){
        let name = item.name.toLowerCase()
        if(name.indexOf(query) > -1) return true
      }
      return false
    })
    console.log("sorted", items)
    this.setState({itemsFiltered:items})
  }

  searchModifierClasses(query){
    query = query.toLowerCase()
    let mcs = this.state.items.filter((mc)=>{
      if(mc.internal_name){
        let internalName = mc.internal_name.toLowerCase()
        if(internalName.indexOf(query) > -1) return true
      }
      let displayText = mc.display_text.toLowerCase()
      if(displayText.indexOf(query) > -1) return true
      else return false
    })
    this.setState({itemsFiltered:mcs})
  }

  renderItem(item){
    if(this.props.page === 'modifier-classes' || this.props.page === 'modifier-class-new-item' || this.props.page === 'modifier-class-edit-item-desktop'){
      return this.renderModifierClass(item)
    } else if(
      this.props.page === 'modifiers' || 
      this.props.page === 'modifier-class-create' ||
      this.props.page === 'modifier-class-edit'
    ){
      return this.renderModifier(item)
    }
  }

  addModifierToGroup(i, confirm_change_ok=false,toastGoBack=true,modifier_class_id=null){
    this.setState({loading:true})
    Api.callApi({
      endpoint:'add-modifier-to-modifier-group',
      data:{
        menu_item_id:i.menu_item_id,
        modifier_class_id:modifier_class_id ? modifier_class_id : this.props.history.location.state.modifierClass.modifier_class_id,
        confirm_change_ok:confirm_change_ok
      },
      success:(data)=>{
        this.setState({loading:false})
        if(data.needs_confirmation){
          let pathname = this.props.location.pathname
          pathname = pathname.replace('/modifier-modal', '')
          let modifier_id = JSON.parse(JSON.stringify(this.props.history.location.state.modifierClass.modifier_class_id))
          this.props.modal.setSave(()=>{
            this.addModifierToGroup(i, 
              true, 
              true,
              modifier_id
            )
          })
          this.props.history.replace({
            pathname:pathname + '/modifier-class-confirm-modal',
            state:{
              items:data.impacted_items
            }
          })
          return
        }
        this.props.toast.setToast({
          item:i,
          message:i.name,
          type:'modifier-added'
        }, toastGoBack)
      },
      failure:(err)=>{
        alert('Unknown Error')
        this.setState({loading:false})
      }
    })
  }

  // put the modifier in the modal context, then go back
  addModifierToNewGroup(i){
    this.props.modal.setModifier(i, ()=>this.props.history.goBack())
  }

  renderModifier(i){
    return (
      <MenuItemBox 
        onClick={()=>{
          if(
            this.props.page === 'modifier-class-create' || 
            this.props.page === 'modifier-class-edit'
          ){
            this.addModifierToNewGroup(i)
          } else{
            this.addModifierToGroup(i)
          }
        }}
        hideElipses={true}
        item={i} 
      />
    )
  }

  renderModifierClass(mc){
    return(
      <div
        key={mc.modifier_class_id}
        onClick={()=>{
          if(this.props.page === 'modifier-classes'){
            Api.callApi({
              endpoint:'/api/tablet/add-modifier-group-to-item',
              method:'post',
              data:{
                modifier_class_id:mc.modifier_class_id,
                menu_item_id:this.props.history.location.state.item.menu_item_id
              },
              success:(data)=>{
                this.props.toast.setToast({
                  item:this.props.history.location.state.item,
                  message:mc.internal_name ? mc.internal_name : mc.display_text,
                  title:'Modifier Group Added',
                  type:'generic'
                }, true)
   
              },
              failure:(err)=>{
                alert('Unknown error')
              }
            })
          } else if (this.props.page === 'modifier-class-new-item' || this.props.page === 'modifier-class-edit-item-desktop'){
            this.props.modal.setModifierClass(mc, ()=>this.props.history.goBack())
          }
        
        }}
        className={classnames('menu-item-box',
          {'menu-item-box-with-internal-name':mc.internal_name})}

      >
        {mc.internal_name && (
          <div className='menu-box-internal-name'>
            {mc.internal_name}
          </div>
        )}
        <div className='menu-box-title'>{mc.display_text}</div>
        <div className='flexstart'>
          <div className='modifier-class-options-circle'>
            <div>{mc.modifier_count} options</div>
          </div>
          {mc.deactivated_count > 0 && (
            <div className='modifier-class-options-circle-deactivated'>
              <div>{mc.deactivated_count} deactivated </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  render(){
    return (
      <div 
        className='menu-modal-background'
        onClick={(e)=>this.maybeClose(e)}
        id={this.state.closing ? "modal-background-exit" : null}
      >
        <div className='menu-modal-container'>
          {this.state.loading === true ? (
            <Loading />
          ) : (
            <>
              <div className='menu-modal-title'>{this.title}</div>
              <div className='search-bar-container'>
                <SearchIcon className='search-bar-icon'/>
                <input
                  ref={this.inputRef}
                  value={this.state.query}
                  onChange={(e)=>this.search(e.target.value)}
                  placeholder='Search'
                  style={{width:'100%'}}
                  className='search-bar-input-search-page search-bar-input'
                />
              </div>

              <div className='menu-modal-items-container'>
                {this.state.itemsFiltered.map(item => (
                  this.renderItem(item)
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    )
    
  }
}
      

export default withRouterAndRef(ModifierModalContextHOC(ToastContextHOC(AddListModal))) 


