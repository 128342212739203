import React, { Component } from "react"
import classnames from "classnames"
import "./OrderCard.css"
import "./OrderDetails.css"
const Timestamp = require("../helpers/timestamps")

class OrderDetailsSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile:window.innerWidth < 600,
      isTablet:window.innerWidth >= 600,
      order:this.props.order
    }
    window.poo = this
  }

  componentDidUpdate(){
    if(JSON.stringify(this.props.order) !== JSON.stringify(this.state.order)){
      this.setState({order:this.props.order})
    }
  }

  formatOrderPlaced(ts){
    let time = Timestamp.getTimeOfDay(ts)
    let mm = Timestamp.getMonthInt(ts)
    let dd = Timestamp.getDayOfMonth(ts)
    let yy  = Timestamp.getPartialYear(ts)
    return `${mm}/${dd}/${yy} ${time}`
  }

  renderMobile(){
    let page = this.props.page
    if (page === 'cancel') page = this.props.originalPage 
    if (page === 'adjust') page = this.props.originalPage 


    if (page === 'unconfirmed'){
      return (
        <div className='full-page-card-order-info-container'>
          <div className='space-between'>
            <div className='full-page-card-order-placed'>
              Placed {this.formatOrderPlaced(this.state.order.created_at)}
            </div>
            <div className='full-page-card-due'>
              Expires In
            </div>
          </div>
          <div className='space-between'>
            <div className='full-page-card-order-info-order-type'>
              {this.state.order.pickup_or_delivery.toUpperCase()}
            </div>
            <div className='full-page-card-order-info-time'>
              {this.state.order.expires}
            </div>
          </div>
        </div> 
      )          
    }

    else if(page === 'kitchen'){
      if(this.state.order.past_due){
        return (
          <div className='full-page-card-order-info-container'>
            <div className='space-between'>
              <div className='full-page-card-order-placed'>
                Placed {this.formatOrderPlaced(this.state.order.created_at)}
              </div>
              <div className='full-page-card-due'>
                Past Due
              </div>
            </div>
            <div className='space-between'>
              <div className='full-page-card-order-info-order-type'>
                {this.state.order.pickup_or_delivery.toUpperCase()}
              </div>
              <div className='full-page-card-order-info-time'>
                {this.state.order.due}
              </div>
            </div>
          </div> 
        )
      } else {
        return (
           <div className='full-page-card-order-info-container'>
            <div className='space-between'>
              <div className='full-page-card-order-placed'>
                Placed {this.formatOrderPlaced(this.state.order.created_at)}
              </div>
              <div className='full-page-card-due'>
                Due In
              </div>
            </div>
            <div className='space-between'>
              <div className='full-page-card-order-info-order-type'>
                {this.state.order.pickup_or_delivery.toUpperCase()}
              </div>
              <div className='full-page-card-order-info-time'>
                {this.state.order.due}
              </div>
            </div>
          </div> 
        )
      }
    } else {
      return (
         <div className='full-page-card-order-info-container'>
          <div className='space-between'>
            <div className='full-page-card-order-placed'>
              Placed {this.formatOrderPlaced(this.state.order.created_at)}
            </div>
          </div>
          <div className='space-between'>
            <div className='full-page-card-order-info-order-type'>
              {this.state.order.pickup_or_delivery.toUpperCase()}
            </div>
          </div>
        </div> 
      )
    }
  }


  renderTabletClock(){
    // if we 're on cancel, then use the page that cancel came from
    let page = this.props.page
    if (page === 'cancel') page = this.props.originalPage 
    if (page === 'adjust') page = this.props.originalPage 

    if (page === 'unconfirmed'){
      return ( 
       <div className='order-details-tablet-bottom-border-container'>
          <div className='order-details-tablet-due-in'>
            Expires In 
          </div>
          <div className='order-details-tablet-timer'>
            {this.state.order.expires}
          </div>
        </div>
      )
    }

    if (page === 'kitchen'){
      if(this.state.order.past_due){
        return ( 
         <div className='order-details-tablet-bottom-border-container'>
            <div className='order-details-tablet-due-in error-red-text'>
              Past Due
            </div>
            <div className='order-details-tablet-timer error-red-text'>
              {this.state.order.due}
            </div>
          </div>
        )
      }
      else {
        return ( 
         <div className='order-details-tablet-bottom-border-container'>
            <div className='order-details-tablet-due-in'>
              Due in
            </div>
            <div className='order-details-tablet-timer'>
              {this.state.order.due}
            </div>
          </div>
        )
      }
    }
  }

  renderTablet(){
    return(
      <div className='order-details-tablet-container'>
        
        <div className='order-details-tablet-bottom-border-container'>
          <div className='order-details-placed-tablet'>
              Placed {this.formatOrderPlaced(this.state.order.created_at)}
          </div>
          <div className='order-details-order-type-tablet'>
            {this.state.order.pickup_or_delivery.toUpperCase()}
          </div>
        </div>

        {this.renderTabletClock()} 

      </div>
    )
  }

  render(){
    if(this.state.isMobile) return this.renderMobile()
    if(this.state.isTablet) return this.renderTablet()
  }

}
      

export default OrderDetailsSection

const Clock = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.848 12.459c.202.038.202.333.001.372-1.907.361-6.045 1.111-6.547 1.111-.719 0-1.301-.582-1.301-1.301 0-.512.77-5.447 1.125-7.445.034-.192.312-.181.343.014l.985 6.238 5.394 1.011z"/></svg>)

const Close = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>)

