import React, { Component } from "react"
import Loading from "../Loading/Loading"
import Modal from "../Modal/Modal"
const API = require("../api")
const formatPrice = require("../Helpers/formatPrice")


class Orders extends Component {
  constructor(props) {
    super(props)
    this.openAdjustOrder = this.openAdjustOrder.bind(this)
    this.submitOrderAdjustment = this.submitOrderAdjustment.bind(this)
    this.state = {
      filter_name: null,
      filter_address: null,
      orderAdjustmentAction: null,
      adjustmentFoodAndBev: 0,
      adjustmentTip: 0,
      adjustmentNote: null,
      adjustmentDeliveryFee: 0,
      adjustmentOther: 0,
      adjustmentTax: 0,
      adjustmentTotal: 0,
      taxRate: null,
      rest:this.props.rest
    }
  }

  componentDidMount() {
    this.loadOrders()
    this.loadTaxRate()
  }

  formatDate(datetime) {
    if(!datetime) return ''
    return datetime.split(" ")[0]
  }

  formatTime(datetime) {
    if(!datetime) return ''
    return datetime.split(" ")[1]
  }

  formatPrice(num) {
    return "$" + (num / 100).toFixed(2)
  }

  async loadOrders() {
    let rest = this.state.rest 
    API.call(
      "sales",
      (res) => {
        let splitTips = false
        for(let i in res){
          if(res[i].restaurant_tip || res[i].delivery_tip){
            splitTips = true
          }
        }
        this.setState({ orders: res, splitTips:splitTips })
      },
      (err) => console.log("Error loading sales", err),
      { rest_id: rest.restaurant_id }
    )
  }

  loadOrdersWithFilter(){
    API.call(
      "sales",
      (res) => this.setState({ orders: res }),
      (err) => console.log("Error loading sales", err),
      { rest_id: this.state.rest.restaurant_id},
      { filter_name: this.state.filter_name,
        filter_address:this.state.filter_address }
    )
  }

  async loadTaxRate() {
    API.call(
      "tax-rate",
      (res) => {
        if(res.success){
          this.setState({ taxRate: res.tax_rate })
        } else{
          this.setState({ taxRate: false })
        }
      },
      (err) => console.log("Error loading tax rate", err),
      null,
      null
    )
  }

  openAdjustOrder() {
    this.setState({
      //receipt:null,
      adjustOrder: true,
    })
    this.getAdjustments()
  }

  getAdjustments() {
    this.setState({ adjustments: [] })
    API.call(
      "adjustments",
      (res) => this.setState({ adjustments: res.adjustments }),
      (err) => console.log("Error loading adjustments", err),
      null,
      { order_id: this.state.modalOrder.order_id }
    )
  }

  getReceipt(order) {
    this.setState({ modalOrder: order })
    API.call(
      "receipt",
      (res) => this.handleReceipt(res),
      (err) => console.log(err),
      { order_id: order.order_id },
      null
    )
  }

  handleReceipt(res) {
    var that = this
    var reader = new FileReader()
    reader.readAsDataURL(res)
    reader.onloadend = function () {
      let base64data = reader.result
      that.setState({ receipt: base64data })
    }
  }

  renderOrders() {
    return this.state.orders.map((order, i) => {
      return (
        <tr key={i} onClick={() => this.getReceipt(order)}>
          <td className="left member-first-cell">{order.hash_id}</td>
          <td className="left">{this.formatDate(order.localtime_order_at)}</td>
          <td className="left">{this.formatTime(order.localtime_order_at)}</td>
          <td className='left' style={order.status=='cancelled' ? {color:'red'} : null}>{order.status}</td>
          <td className="left">{order.diner_name} </td>
          <td className="left">{order.email}</td>
          <td className="left" style={{maxWidth:'50px'}}>{order.address1 ? order.address1 : "Pickup"}</td>
          <td className="right">{this.formatPrice(order.product_total)}</td>
          <td className="right">{this.formatPrice(order.promo_amount)}</td>
          <td className="right">{this.formatPrice(order.subtotal)}</td>
          <td className="right">{this.formatPrice(order.delivery_fee)}</td>
          <td className="right">{this.formatPrice(order.tax)}</td>
          {this.state.splitTips && (
            <>
              <td className="right">{this.formatPrice(order.driver_tip)}</td>
              <td className="right">{this.formatPrice(order.restaurant_tip)}</td>
            </>
          )}
          <td className="right">{this.formatPrice(order.tip)}</td>
          <td className="right member-last-cell">{this.formatPrice(order.total)}</td>
        </tr>
      )
    })
  }

  validateDollarInput(value) {}

  twoDecimals(num) {
    if (isNaN(num) === false) {
      return (Math.round(num * 100) / 100).toFixed(2)
    } else {
      return 0
    }
  }

  submitOrderAdjustment() {
    if(this.state.taxRate === false){
      alert('Tax rate not set up')
      return
    }

    let product = Math.round(this.state.adjustmentFoodAndBev * 100)
    let tip = Math.round(this.state.adjustmentTip * 100)
    let delivery_fee = Math.round(this.state.adjustmentDeliveryFee * 100)
    let tax = Math.round(this.state.adjustmentTax * 100)
    let other = Math.round(this.state.adjustmentOther * 100)
    let total = Math.round(this.state.adjustmentTotal * 100)
    let params = {
      order_id: this.state.modalOrder.order_id,
      product: product,
      tip: tip,
      delivery_fee: delivery_fee,
      tax: tax,
      other: other,
      total: total,
      adjustment_type: this.state.orderAdjustmentAction,
      note: this.state.adjustmentNote,
    }
    if(total === 0){
      alert('Please choose a non-zero amount')
      return
    }
    this.setState({ adjustOrderLoading: true, orderAdjustmentAction: null })
    API.call(
      "adjust-order",
      (res) => {
        if (!res.success) {
          alert(res.message)
          this.setState({ adjustOrderLoading: false })
        } else {
          this.getAdjustments()
          this.setState({
            adjustOrderLoading: false,
            adjustmentFoodAndBev: 0,
            adjustmentTip: 0,
            adjustmentDeliveryFee: 0,
            adjustmentOther: 0,
            adjustmentTax: 0,
            adjustmentTotal: 0,
            adjustmentTaxFormatted: 0,
            adjustmentTotalFormatted: 0,
            orderAdjustmentAction: null,
            adjustmentNote: null,
          })
        }
      },
      (err) => {
        alert("Error adjusting order")
        this.setState({ adjustOrderLoading: false })
      },
      null,
      params
    )
  }

  // on blur we want to make sure to format the value
  // as dollars. If
  adjustmentBlur(variable) {
    let formatted = this.twoDecimals(this.state[variable])
    let newState = {}
    newState[variable] = formatted
    this.state["variable"] = formatted
    this.setState(newState, () => this.calculateAdjustmentTaxAndTotal())
  }

  calculateAdjustmentTaxAndTotal() {
    let tax =
      parseFloat(this.state.adjustmentFoodAndBev) * this.state.taxRate + 0.0001
    let total =
      tax +
      parseFloat(this.state.adjustmentFoodAndBev) +
      parseFloat(this.state.adjustmentOther) +
      parseFloat(this.state.adjustmentTip) +
      parseFloat(this.state.adjustmentDeliveryFee)
    this.setState({
      adjustmentTax: tax,
      adjustmentTotal: total,
      adjustmentTaxFormatted: this.formatPrice(tax * 100),
      adjustmentTotalFormatted: this.formatPrice(total * 100),
    })
  }

  renderModal() {
    if (this.state.receipt && !this.state.adjustOrder) {
      return (
        <Modal
          close={() =>
            this.setState({
              receipt: null,
              adjustOrder: null,
              modalOrder: null,
            })
          }
          style={{ background: "#eee" }}
        >
          <div className="p-4">
            <button
              className="button"
              onClick={this.openAdjustOrder}
              style={{ marginBottom: "15px" }}
            >
              Adjust Order
            </button>
            <img
              style={{ width: "80%", margin: "auto" }}
              src={this.state.receipt}
              alt="receipt"
            />
          </div>
        </Modal>
      )
    } else if (this.state.adjustOrder) {
      //Subtotal	Delivery Fee	Tip	Tax	Other	Total	User	Note
      var adjustmentColor = null
      if (this.state.orderAdjustmentAction == "upcharge") {
        adjustmentColor = "#46b34a"
      } else if (this.state.orderAdjustmentAction == "refund") {
        adjustmentColor = "#d85050"
      }
      return (
        <Modal
          large={true}
          close={() =>
            this.setState({
              receipt: null,
              adjustOrder: null,
              adjustmentFoodAndBev: 0,
              adjustmentTip: 0,
              adjustmentDeliveryFee: 0,
              adjustmentNote: null,
              adjustmentOther: 0,
              adjustmentTax: 0,
              adjustmentTotal: 0,
              adjustmentTaxFormatted: 0,
              adjustmentTotalFormatted: 0,
              orderAdjustmentAction: null,
              modalOrder: null,
              orderAdjustmentAction: null,
              adjustments: [],
            })
          }
          style={{ background: "#eee" }}
        >
          <h3>Order Adjustment</h3>
          <div style={{ display: "inline-block", verticalAlign: "top" }}>
            <img
              style={{ width: "80%", margin: "auto" }}
              src={this.state.receipt}
              alt="receipt"
            />
          </div>
          <div style={{ display: "inline-block", verticalAlign: "top" }}>
            <table>
              <thead>
                <tr>
                  <th>Subtotal</th>
                  <th>Delivery Fee</th>
                  <th>Tip</th>
                  <th>Tax</th>
                  <th>Other</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {this.state.adjustments.map((a) => (
                  <tr>
                    <td>{formatPrice.formatPrice(a.subtotal)}</td>
                    <td>{formatPrice.formatPrice(a.delivery_fee)}</td>
                    <td>{formatPrice.formatPrice(a.tip)}</td>
                    <td>{formatPrice.formatPrice(a.tax)}</td>
                    <td>{formatPrice.formatPrice(a.other)}</td>
                    <td>{formatPrice.formatPrice(a.total)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              className="button"
              style={{ backgroundColor: "#46b34a", margin: "10px" }}
              onClick={() =>
                this.setState({ orderAdjustmentAction: "upcharge" })
              }
            >
              Upcharge
            </button>
            <button
              className="button"
              style={{ backgroundColor: "#d85050", margin: "10px" }}
              onClick={() => this.setState({ orderAdjustmentAction: "refund" })}
            >
              Refund
            </button>
            {this.state.adjustOrderLoading ? <Loading /> : null}
            {this.state.orderAdjustmentAction !== null ? (
              <div>
                <table>
                  <tbody>
                    <tr style={{ backgroundColor: adjustmentColor }}>
                      <td>Food &amp; Beverage</td>
                      <td>
                        <input
                          value={this.state.adjustmentFoodAndBev}
                          onChange={(e) =>
                            this.setState({
                              adjustmentFoodAndBev: e.target.value,
                            })
                          }
                          onBlur={() =>
                            this.adjustmentBlur("adjustmentFoodAndBev")
                          }
                        />
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: adjustmentColor }}>
                      <td>Delivery Fee</td>
                      <td>
                        <input
                          value={this.state.adjustmentDeliveryFee}
                          onChange={(e) =>
                            this.setState({
                              adjustmentDeliveryFee: e.target.value,
                            })
                          }
                          onBlur={() =>
                            this.adjustmentBlur("adjustmentDeliveryFee")
                          }
                        />
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: adjustmentColor }}>
                      <td>Tip</td>
                      <td>
                        <input
                          value={this.state.adjustmentTip}
                          onChange={(e) =>
                            this.setState({ adjustmentTip: e.target.value })
                          }
                          onBlur={() => this.adjustmentBlur("adjustmentTip")}
                        />
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: adjustmentColor }}>
                      <td>Other</td>
                      <td>
                        <input
                          value={this.state.adjustmentOther}
                          onChange={(e) =>
                            this.setState({ adjustmentOther: e.target.value })
                          }
                          onBlur={() => this.adjustmentBlur("adjustmentOther")}
                        />
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: adjustmentColor }}>
                      <td>Tax</td>
                      <td>
                        <input
                          value={this.state.adjustmentTaxFormatted}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: adjustmentColor }}>
                      <td>Total</td>
                      <td>
                        <input
                          value={this.state.adjustmentTotalFormatted}
                          disabled
                        />
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: adjustmentColor }}>
                      <td>Note</td>
                      <td>
                        <input
                          value={this.state.adjustmentNote}
                          onChange={(e) =>
                            this.setState({ adjustmentNote: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  className="button"
                  style={{
                    marginBottom: "10px",
                    backgroundColor: adjustmentColor,
                  }}
                  onClick={this.submitOrderAdjustment}
                >
                  Submit {this.state.orderAdjustmentAction}
                </button>
              </div>
            ) : null}
          </div>
        </Modal>
      )
    }
  }

  renderFilters() {
    return (
      <div className="w100 mv8 pv8 left">
        <span className="fw900" style={{ marginRight: "16px" }}>
          Filters:
        </span>
        {window.innerWidth < 800 && <br />}
        <input
          className="w40"
          style={{ maxWidth: "1000px" }}
          placeholder="Name..."
          onChange={(e) =>{ 
            this.setState({ filter_name: e.target.value },
              ()=>this.loadOrdersWithFilter())
          }}
        />
        <input
          className="w40"
          style={{ maxWidth: "1000px" }}
          placeholder="Address..."
          onChange={(e) => {
            this.setState({ filter_address: e.target.value },
              ()=>this.loadOrdersWithFilter())
          }}
        />
      </div>
    )
  }

  render() {
    if (this.state.orders && this.state.rest) {
      return (
        <div className="w100 inline-block center">
          <h1 className="left fw900 ph16 pv16 mv16">Orders</h1>
          {this.renderModal()}
          {this.renderFilters()}
          <div
            style={{ width: "100%", overflowX: "auto", paddingBottom: "32px" }}
          >
            <table className='member-orders-table'>
              <thead className="thead">
                <tr>
                  <th className="left member-first-cell" >
                    <b>Order ID</b>
                  </th>
                  <th className="left">
                    <b>Date</b>
                  </th>
                  <th className="left">
                    <b>Time</b>
                  </th>
                  <th className="left">
                    <b>Status</b>
                  </th>
                  <th className="left">
                    Name
                  </th>
                  <th className="left">
                    Email
                  </th>
                  <th className="left">
                    <b>Address</b>
                  </th>
                  <th className="right">
                    <b>Product</b>
                  </th>
                  <th className="right">
                    <b>Promo</b>
                  </th>
                  <th className="right">
                    <b>Subtotal</b>
                  </th>
                  <th className="right" >
                    <b>Delivery</b>
                  </th>
                  <th className="right" >
                    <b>Tax</b>
                  </th>
                  {this.state.splitTips && (
                    <>
                      <th className="right">
                        <b>Driver Tip</b>
                      </th>
                      <th className="right" >
                        <b>Restaurant Tip</b>
                      </th>
                    </>
                  )}
                  <th className="right" >
                    <b>Tip {this.state.splitTips && <span> Total </span> }</b>
                  </th>
                  <th className="right member-last-cell" >
                    <b>Total</b>
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderOrders()}</tbody>
            </table>
          </div>
        </div>
      )
    }
    return <Loading />
  }
}

export default Orders
