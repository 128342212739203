import React, { Component } from 'react';
import Loading from '../Loading/Loading'
import Transfers from '../Transfers/Transfers'
import Orders from './OrdersModal'

const API = require('../api')

class Payouts extends Component {

  constructor(props){
    super(props)
    this.state = {
      payouts:[],
      rest:{},
      expanded: null,
      loaded:false
    }
  }

  componentDidMount(){
    this.setState({rest:this.props.rest})
    this.loadPayouts()
  }

  async loadPayouts(){
    let rest = this.props.rest 
    API.call(
      'payouts',
      (res)=>{this.setState({payouts:res.payouts, loaded:true})},
      (err)=>{alert('Error loading payouts')},
      {rest_id:rest.restaurant_id}   
    )   
  }

  renderExpansion(payout_id){
    if (this.state.expanded === payout_id){
      return (
        <Orders
          payout_id={this.state.expanded}
          closeExpansion={()=>this.setState({expanded:null})}
          {...this.props}
        />
      )

      //return <Transfers payout={this.state.expanded} {...this.props} closeExpansion={()=>this.setState({expanded:null})}/>
    } else return null
  }

  render(){
    if (this.state.loaded){
      return (
        <div className='w100 inline-block center'>
          <h1 className='left fw900 ph16 pv16 mv16'>Payouts</h1>
          <table cellSpacing='0' className='w100 center' style={{tableLayout:'fixed'}}>
            <tbody>
              {this.state.payouts.map((payout,i) => {
                let months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
                let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
                let d = new Date(payout.created)
                var minutes = d.getMinutes()
                if (minutes < 10){
                  minutes = '0' + minutes
                }
                let  created = days[d.getDay()] + ' ' + months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear() + ' ' + d.getUTCHours() + ':' + minutes
                let d2 = new Date(payout.arrival_date)
                let arrival = days[d2.getDay()] + ' ' + months[d2.getMonth()] + ' ' + d2.getDate() + ', ' + d2.getFullYear() 
                return(
                  <>
                    <tr
                    key={i}
                    className='w100'
                    onClick={()=>this.setState({expanded: this.state.expanded === payout.id ? null : payout.id})}
                    style={this.state.expanded && this.state.expanded !== payout.id ? {opacity:'.2'} : {}}>
                        <td className='left c222 fw300 fs14'>
                          {arrival} 
                        </td>
                      <td className='cgreen fw500 fs14 right'>{payout.amount}</td>
                    </tr>
                    {this.renderExpansion(payout.id)}
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    } else return <Loading/>
  }
}

export default Payouts;
