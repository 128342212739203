import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import ToastContextHOC from "../contexts/ToastContextHOC"
import ButtonWithSpinner from "../orders/ButtonWithSpinner"
import MenuItemBox from "./MenuItemBox"
import DesktopSidebar from "./DesktopSidebar"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import OrderToast from "../orders/OrderToast"
import Deactivate from "../orders/svg/Deactivate"
import Plus from "../orders/svg/PlusMedium"
import FloppyDisk from "../orders/svg/FloppyDisk"
import Breadcrumbs from "./Breadcrumbs"
import "./Menu.css"
import { memo, useCallback, useState } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { DesktopSection } from './DesktopSection';
import {DesktopMenuItemDraggable} from "./DesktopMenuItemDraggable"
import update from 'immutability-helper';


const ItemTypes = {
  CARD: 'card',
}

const style = {
    width: 400,
};

const Api = require("../api")
const Money =  require("../helpers/money")

const DesktopSectionsContainer = memo(function Container(props) {
    const [cards, setCards] = useState(props.categories);
    const findCard = useCallback((id) => {
        const card = cards.filter((c) => `${c.id}` === id)[0];
        return {
            card,
            index: cards.indexOf(card),
        };
    }, [cards]);
    const moveCard = useCallback((id, atIndex) => {
        const { card, index } = findCard(id);
        setCards(update(cards, {
            $splice: [
                [index, 1],
                [atIndex, 0, card],
            ],
        }));
        props.parent.reorderCategories(cards, index, atIndex, card)
    }, [findCard, cards, setCards]);
    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
    return (<div ref={drop} style={style}>
			{cards.map((card) => (
        <DesktopSection
          disableEdits={props.disableEdits}
          itemCount={card.itemCount}
          key={card.id} 
          id={`${card.id}`} 
          text={card.text} 
          moveCard={moveCard} 
          menuCategoryId={card.menu_category_id}
          findCard={findCard}
          scrollToSection={props.scrollToSection}
        />
      ))}
      {props.parent.state.blackedOutItems.length > 0 && (
        <div 
          className='menu-section-box-desktop'
          onClick={()=>props.scrollToSection('Deactivated Items')}
        >   
          <div className='menu-section-box-title-desktop' >
            Deactivated Items
          </div>
          <div className='menu-section-box-subtitle-desktop'>
            {props.parent.state.blackedOutItems.length} items
          </div>
        </div>
      )}

      {props.parent.state.archivedItems.length > 0 && (
        <div 
          className='menu-section-box-desktop'
          onClick={()=>props.scrollToSection('Archived Items')}
        >   
          <div className='menu-section-box-title-desktop' >
            Archived Items
          </div>
          <div className='menu-section-box-subtitle-desktop'>
            {props.parent.state.archivedItems.length} items
          </div>
        </div>
      )}



		</div>);
});

const DesktopItemsContainer = memo(function Container(props) {
    const [cards, setCards] = useState(props.items);
    const findCard = useCallback((id) => {
        const card = cards.filter((c) => `${c.id}` == id)[0];
        return {
            card,
            index: cards.indexOf(card),
        };
    }, [cards]);
    const moveCard = useCallback((id, atIndex) => {
        const { card, index } = findCard(id);
        setCards(update(cards, {
            $splice: [
                [index, 1],
                [atIndex, 0, card],
            ],
        }));
        props.parent.reorderItems(cards, index, atIndex, card, props.menu_category_id)
    }, [findCard, cards, setCards]);
    const [, drop] = useDrop(() => ({ accept: 'item'+props.menu_category_id}));
    let rows = cards.reduce(function (rows, key, index) { 
        return (index % 2 == 0 ? rows.push([key]) 
          : rows[rows.length-1].push(key)) && rows;
      }, []);

    
    let rows2 = rows.filter(row => row)
    return(
      <div ref={drop} style={{width:'100%'}}>
          {rows2.map((pair, idx) => (

            <>
              {pair !== undefined && (
              <div className='desktop-menu-box-row'>
                 
                 <DesktopMenuItemDraggable
                    id={pair[0].menu_item_id}
                    key={pair[0].menu_item_id}
                    {...props}
                    item={pair[0]}
                    moveCard={moveCard} 
                    findCard={findCard}
                    disableEdits={props.disableEdits}
                  />
                
                {pair.length > 1 && (

                 <DesktopMenuItemDraggable
                    id={pair[1].id}
                    key={pair[1].menu_item_id}
                    {...props}
                    item={pair[1]}
                    moveCard={moveCard} 
                    findCard={findCard}
                    disableEdits={props.disableEdits}
                  />

                  )}
              </div>
            )}
            </>
          ))}
      </div>
    )
});




function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };  
}


class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile:window.innerWidth <= 599,
      isTablet:window.innerWidth > 599 && window.innerWidth < 1000,
      isDesktop:window.innerWidth >= 1000,
      tab:'items',
      reorderedCategories:false,
      sectionDropdownCategoryId:null,
      menu:null,
      toast:null,
      blackedOutItems:[],
      blackedOutModifiers:[],
      categoriesWithEditedItems:[],
    }


    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.tab){
      this.state['tab'] = params.tab
    }

    this.menu_uuid = this.props.match.params.menu_uuid
    this.sectionRefs = {} 
    this.sectionElipsesRefs = {}
    this.sectionRefs['Deactivated Items'] = React.createRef()
    this.sectionRefs['Archived Items'] = React.createRef()

    this.modifierSectionRefs = {} 
    this.modifierSectionRefs['Deactivated Items'] = React.createRef()

    this.addSectionRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
    window.menu = this
  }

  componentDidMount(){
    this.getCentralTimestamp()
    window.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount(){
   window.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps){
    if(
      JSON.stringify(this.props.toast.toast) !== JSON.stringify(prevProps.toast.toast) &&
      this.props.toast.toast
    ){
      this.setState({menu:null})
      this.getCentralTimestamp()
      this.addToast(this.props.toast.toast)
    }
  }

  handleClickOutside(e){
    if(this.state.addSectionDropdownOpen){
      if(
        e.target.className !== 'add-section-dropdown' &&
        e.target.className !== 'menu-item-dropdown-option' &&
        e.target.className !== 'in-dropdown'
      ){
        this.setState({addSectionDropdownOpen:false})
      }
    }
    
    if(this.state.sectionDropdownCategoryId !== null){
      if(
        e.target.className !== 'add-section-dropdown' &&
        e.target.className !== 'menu-item-dropdown-option' &&
        e.target.className !== 'in-dropdown' &&
        e.target.className.indexOf('in-dropdown') < 1
      ){
        this.setState({sectionDropdownCategoryId:null})
      }
    }

  }

  addToast(toast){
    if(!this.loaded()){
      setTimeout(()=>this.addToast(toast), 100)
      return
    }
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }   
  }

  loaded(){
    if(this.state.menu && this.state.centralTimestamp) {
      return true
    } else {
      return false
    }
  }

  loadMenu(){
    Api.callApi({
      endpoint:'/api/menu-with-empty-categories/'+this.menu_uuid,
      success:(data)=>{
        for(let category in data.menu){
          this.sectionRefs[category] = React.createRef()
          this.sectionElipsesRefs[data.menu[category].menu_category_id] = React.createRef()
          this.modifierSectionRefs[category] = React.createRef()
        }
        this.modifierSectionRefs['No Category'] = React.createRef()
        let blackedOutItems = []
        // loop over the menu and indicate if an item is blacked out
        // or not.... also compile the list of blacked out items this
        // way

        data.menu = this.sortCategories(data.menu)

        for(let category in data.menu){
          for(let i in data.menu[category].items){
            let item = data.menu[category].items[i]
            item.category_name = category
            if(
              item.blackout_until &&
              item.blackout_until_timestamp > this.state.centralTimestamp 
            ) {
              item.blacked_out = true
              item.blackout_until_local_ts = item.blackout_until_timestamp - this.props.user.rest.central_offset_seconds 
              blackedOutItems.push(JSON.parse(JSON.stringify(item)))
            }
          }
        }

        let blackedOutModifiers = []
        for(let i in data.menu_items){
          let item = data.menu_items[i]
          if(!item.is_orderable){
            let item = data.menu_items[i]
            if(
              item.blackout_until &&
              item.blackout_until_timestamp > this.state.centralTimestamp 
            ) {
              item.blacked_out = true
              item.blackout_until_local_ts = item.blackout_until_timestamp - this.props.user.rest.central_offset_seconds 
              blackedOutModifiers.push(JSON.parse(JSON.stringify(item)))
            }
          }
        }

        // we need to group modifiers by category
        let modifiersByCategory = {}
        for(let i in data.menu_items){
          let item = data.menu_items[i]
          if(!item.is_orderable){
            let category = item.category_name
            if(!category) category = 'No Category'
            if (!(category in modifiersByCategory)){
              modifiersByCategory[category] =  {'items':[], 'name':category}
            }
            modifiersByCategory[category].items.push(JSON.parse(JSON.stringify(item)))
          }
        }

        let disableEdits = false
        if(data.external_source === 'CHOWLY') disableEdits = true
        if(data.external_source === 'SQUARE') disableEdits = true


        // we want to sort data.menu in the same way we do on hngr
        // this code is copy pasta'd
        let initialCategoryIndexes= Object.values(data.menu).map((cat, idx) => ({
          id:idx, 
          text:cat.name, 
          menu_category_id:cat.menu_category_id, 
          itemCount:cat.items.length
        }))
        let categoryIndexes= Object.values(data.menu).map((cat, idx) => ({id:idx, text:cat.name, menu_category_id:cat.menu_category_id}))
        let categoryInitialItems = {}
        for(let i in data.menu){
          let cat = data.menu[i]
          categoryInitialItems[cat.menu_category_id] = JSON.parse(JSON.stringify(cat.items))
          for(let z in categoryInitialItems[cat.menu_category_id]){
            categoryInitialItems[cat.menu_category_id][z].id = categoryInitialItems[cat.menu_category_id][z].menu_item_id
          }
        }

        // this is where we store unsaved changed
        let categoryItemsRecord = JSON.parse(JSON.stringify(categoryInitialItems))

        this.setState({
          initialCategoryIndexes:initialCategoryIndexes,
          categoryIndexes:categoryIndexes,
          categoryInitialItems:categoryInitialItems,
          categoryItemsRecord:categoryItemsRecord,
          menu:data, 
          blackedOutItems:blackedOutItems,
          blackedOutModifiers:blackedOutModifiers,
          modifiersByCategory:modifiersByCategory,
          disableEdits:disableEdits,
          archivedItems:data.archived_items
        }, () => {
          if(
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.section
          ){
            this.scrollToSection(this.props.history.location.state.section)
          }
        })
      },
      failure:(err)=>{
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error loading the menu' 
          }
        })
      }
    })
  }

  getCentralTimestamp(){
    Api.callApi({
      endpoint:'central-timestamp',
      success:(data)=>{
        this.setState({
          centralTimestamp:data.ts,
          tomorrow5am:data.tomorrow_5am,
        }, ()=>this.loadMenu())
      }
    })
  }

  saveChanges(cb){
    let cats = []
    
    if(this.state.reorderedCategories){
      for(let i in this.state.categoryIndexes){
        cats.push({
          menu_category_id:this.state.categoryIndexes[i].menu_category_id,
          row_order:parseInt(i)+1
        })
      }
    }
    
    let cat_items = {}

    for(let i in this.state.categoriesWithEditedItems){
      let category_id = this.state.categoriesWithEditedItems[i]
      cat_items[category_id] = JSON.parse(JSON.stringify(this.state.categoryItemsRecord[category_id]))
      for(let j in cat_items[category_id]){
        cat_items[category_id][j].row_order = parseInt(j) + 1
      }
    }

    let params = {
      reordered_categories:cats,
      reordered_category_ites:cat_items
    }

    Api.callApi({
      endpoint:'/api/tablet/reorder-categories-and-items',
      method:'post',
      data:{
        menu_uuid:this.menu_uuid,
        categories:cats,
        reordered_category_items:cat_items
      },
      success:(data)=>{cb()},
      failure:(err)=>{
        cb()
        alert('Error saving reordered categories')
      }
    })
  }

  reorderCategories(cards, index, atIndex, card){
    let reOrdered = update(cards, {
            $splice: [
                [index, 1],
                [atIndex, 0, card],
            ],
        });
    // let's reorder all the categories
    this.setState({categoryIndexes:reOrdered}) 
    let menu = this.state.menu.menu
    // we want to reorder menu so that categories are moved around
    let newMenu = {}
    for(let i in reOrdered){
      let menu_category_id = reOrdered[i].menu_category_id
      let category_name = reOrdered[i].text
      for(let j in menu){
        if(menu[j].menu_category_id === menu_category_id) {
          newMenu[category_name] = JSON.parse(JSON.stringify(menu[j]))
        }
      }
    }
    let m = this.state.menu
    m.menu = newMenu
    this.setState({menu:m, reorderedCategories:true})
  }
  
  reorderItems(cards, index, atIndex, card, menu_category_id){
    let reOrdered = update(cards, {
            $splice: [
                [index, 1],
                [atIndex, 0, card],
            ],
        });
    this.state.categoryItemsRecord[menu_category_id] = JSON.parse(JSON.stringify(reOrdered))


    this.state.categoryInitialItems[menu_category_id] = JSON.parse(JSON.stringify(reOrdered))

    this.state.categoriesWithEditedItems.push(menu_category_id)
    this.setState({
      categoryInitialItems:this.state.categoryInitialItems,
      categoryItemsRecord:this.state.categoryItemsRecord,
      categoriesWithEditedItems:this.state.categoriesWithEditedItems
    })
  }

  goToModifiers(){
    this.props.history.push({
      pathname:'/menu/modifiers/'+this.menu_uuid
    })
  }

  scrollToSection(section){
    let yOffset = -150
    if(this.state.isDesktop) yOffset = -130
    let sectionRefs = this.state.tab === 'items' ? this.sectionRefs:
      this.modifierSectionRefs


    if(
      sectionRefs[section] &&
      sectionRefs[section].current
    ){
      let el = sectionRefs[section].current
      let y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'})
      return
    }
  }

  // change query string, state and scroll to top
  updateTab(tab){
    this.props.history.replace({
      pathname:this.props.location.pathname,
      search:`?tab=${tab}`
    })  
    this.setState({tab:tab}, ()=>window.scrollTo(0,0))
  }

  sortCategories(categories){
    //put the categories into a list
    var list = []
    for (var i in categories){
      list.push(categories[i])
    }

    //sort the list by row order
    list.sort(function(a,b){
      return a.row_order - b.row_order
    })
    return list
  }



  renderDesktop(){
    let that = this
    let blackedOutItems = this.state.blackedOutItems
    let categories = this.sortCategories(this.state.menu.menu) 


    return (
      <div className='desktop-wrapper'>
        {this.state.sectionDropdownCategoryId !== null && (
          <> {this.renderSectionDropdown()} </>
        )}
        
        {this.state.toast && (
          <OrderToast
            toast={this.state.toast}
            message={this.state.toast.item ? this.state.toast.item.name : null}
            type={this.state.toast.type}
          />
        )}

        <DesktopSidebar 
          menu_uuid={this.menu_uuid}
          page='menu-items'
        />
        <div className='desktop-menu-container'>
          <Breadcrumbs
            crumbs={[
              {text:'Menus', path:'/menu/menus'}
            ]}
            current={this.state.menu.name}
          />


          {this.state.disableEdits !== true && (
            <div className='desktop-menu-top-row'>
              <div className='menu-title-desktop'>
                {this.state.menu.name}
              </div>
              <div style={{display:'flex'}}> 
                <div 
                  style={{marginRight:'20px'}} 
                  className='menu-title-desktop-button'
                  onClick={()=>{
                    this.props.history.push({
                      pathname:'/menu/menu-section-create/' + this.menu_uuid
                    })
                  }}
                >
                  <Plus />
                  <div>New Section</div>
                </div>
                
                <div 
                  className='menu-title-desktop-button'
                  style={{marginRight:'20px'}} 
                  onClick={()=>{
                    this.props.history.push({pathname:'/menu/menu-item-create/'+this.menu_uuid})
                  }}
                >
                  <Plus />
                  <div>New Item</div>
                </div>

                {(this.state.reorderedCategories === true || this.state.categoriesWithEditedItems.length > 0 ) ? ( 
                  <ButtonWithSpinner
                    text='Save'
                    className='menu-title-desktop-button'
                    onClick={(cb)=>this.saveChanges(cb)}
                    svg={<FloppyDisk/>}
                    svgLeft={true}
                    spinnerColor='white'
                  />
                ) : ( 
                  <div 
                    className='menu-title-desktop-button disabled-save-button'
                  >
                    <FloppyDisk/>
                    <div>Save</div>
                  </div>
                )}
              </div>
            </div>
          )}
          {this.renderMenuItems()} 
        </div>
        <div className='desktop-sections-container'>
          <div className='desktop-menu-sections-title'>
            Menu Sections
          </div>
            <DesktopSectionsContainer
              disableEdits={this.state.disableEdits}
              parent={this} 
              categories={this.state.initialCategoryIndexes}
              scrollToSection={(section) => this.scrollToSection(section)}
            />
        </div>
      </div>
    )
  }

  sectionDropdownStyle(){
    let offset = {}
    let sectionDropdown = this.sectionElipsesRefs[this.state.sectionDropdownCategoryId]
    if(sectionDropdown && sectionDropdown.current){
      offset = getOffset(sectionDropdown.current)
      let t  = offset.top + 50
      let l = offset.left - 210
      return {
        top:t+'px',
        left:l+'px'
      }
    } else {
      console.log("no style")
      return {}
    }
  }



  addSectionDropdownStyle(){
    let offset = {}
    if(this.addSectionRef && this.addSectionRef.current){
      offset = getOffset(this.addSectionRef.current)
      let l = offset.left - 130
      let t = '170px'
      if(this.state.isMobile) t = '140px'
      return {
        position:'fixed',
        top:t,
        left:l+'px'
      }
    } else {
      return {}
    }
  }

  renderSectionDropdown(){
    return (
      <div className='menu-item-dropdown add-section-dropdown in-dropdown' style={this.sectionDropdownStyle()}>
        <div
          className='menu-item-dropdown-option in-dropdown'
          style={{borderBottom: '2px solid #F4F4F4'}}
          onClick={()=>{
            this.props.history.push({
              pathname:'/menu/menu-section-edit/'+this.state.sectionDropdownCategoryId
            })
          }}
        >
          <div className='in-dropdown'>Edit Section</div>
        </div>

        <div
          className='menu-item-dropdown-option in-dropdown'
            onClick={()=>{
              this.props.history.push({
                pathname:'/menu/menu-item-create/'+this.menu_uuid,
                state:{
                  menu_category_id:this.state.sectionDropdownCategoryId
                }
              })
            }}
        >
          <div className='in-dropdown'>Add Item</div>
        </div>
      </div>
    )
  }

  renderMenuItemBoxes(category){
    let that = this

    if(this.state.isDesktop){
      let items = category.items
      return (
        <DesktopItemsContainer 
          disableEdits={this.state.disableEdits}
          parent={this}
          menu_category_id={category.menu_category_id}
          key={category.menu_category_id}
          items={this.state.categoryInitialItems[category.menu_category_id]} 
          menu_uuid={this.menu_uuid}
        />
      )


      if(!items) return null
      const rows = items.reduce(function (rows, key, index) { 
          return (index % 2 == 0 ? rows.push([key]) 
            : rows[rows.length-1].push(key)) && rows;
        }, []);

      return (
        <>
          {rows.map((pair, idx) => (
            <div className='desktop-menu-box-row'>
              <MenuItemBox 
                onClick={()=>{
                  this.props.history.push({
                    pathname:'/menu/menu-item-edit/'+pair[1].menu_item_id
                  })
                }}
                menu_uuid={this.menu_uuid} 
                parent={that} 
                item={pair[0]} 
                tab={this.state.tab}
              />
              {pair.length > 1 && (
                <MenuItemBox 
                  onClick={()=>{
                    this.props.history.push({
                      pathname:'/menu/menu-item-edit/'+pair[1].menu_item_id
                    })
                  }}
                  menu_uuid={this.menu_uuid} 
                  parent={that} 
                  item={pair[1]} 
                  tab={this.state.tab}
                />
              )}
            </div>
          ))}
        </>
      )
    } else {
      return (
        <>
          {category.items.map(item => (
            <MenuItemBox menu_uuid={this.menu_uuid} parent={that} item={item} tab={this.state.tab}/>
          ))}
        </>
      )
    }
  }


  renderMenuItems(){
    let that = this

    let categories = this.state.tab === 'items' ?
      this.state.menu.menu :
      this.state.modifiersByCategory

    let blackedOutItems = this.state.tab === 'items' ?
      this.state.blackedOutItems :
      this.state.blackedOutModifiers

    let sectionRefs = this.state.tab === 'items' ?
      this.sectionRefs :
      this.modifierSectionRefs

    //categories = this.sortCategories(categories)

    return (
      <div className='menu-items-container'>
        {this.state.disableEdits !== true && this.state.isDesktop !== true && (
          <div className='menu-section-add-section-wrapper'>
            <div 
              ref={this.addSectionRef}
              className='menu-section-add-section-header'
              onClick={()=>this.setState({addSectionDropdownOpen:true})}
             >
              <Plus/>
              <div>Add New</div>
            </div>
          </div>
        )}

          {Object.values(categories).map(category => (
            <div style={{paddingTop:'25px'}} className='menu-section-container'>
              <div className='menu-section-title' ref={sectionRefs[category.menu_category_id]} >
                <div>{category.name}</div>
                {this.state.disableEdits !== true && (
                  <div
                    className='menu-item-elipses'
                    onClick={()=>{
                      this.setState({sectionDropdownCategoryId:category.menu_category_id})
                    }}
                    ref={this.sectionElipsesRefs[category.menu_category_id]}
                  >
                    •••
                  </div>
                )}

              </div>
              {category.description && (
                <div className='menu-section-description'>
                  {category.description}
                </div>
              )}

              {this.renderMenuItemBoxes(category)}

            </div>
          ))}

          {this.renderBlackedOutItems()}
          {this.renderArchivedItems()}
      </div>
    )    
  }

  renderBlackedOutItems(){
    let that = this
    let sectionRefs = this.sectionRefs
    if(!this.state.blackedOutItems.length) return null
    if(this.state.isDesktop){
      let items = this.state.blackedOutItems
      let rows = items.reduce(function (rows, key, index) { 
          return (index % 2 == 0 ? rows.push([key]) 
            : rows[rows.length-1].push(key)) && rows;
        }, []);

      return (
        <div style={{paddingTop:'25px'}} className='menu-section-container'>
          <div className='menu-section-title' ref={sectionRefs['Deactivated Items']} >
            Deactivated Items
          </div>
          {rows.map((pair, idx) => (
            <div className='desktop-menu-box-row'>
              <MenuItemBox 
                onClick={()=>{
                  this.props.history.push({
                    pathname:'/menu/menu-item-edit/'+pair[1].menu_item_id
                  })
                }}
                menu_uuid={this.menu_uuid} 
                style={{width:'48%'}}
                parent={that} 
                item={pair[0]} 
                tab={this.state.tab}
              />
              {pair.length > 1 && (
                <MenuItemBox 
                  style={{width:'48%'}}
                  onClick={()=>{
                    this.props.history.push({
                      pathname:'/menu/menu-item-edit/'+pair[1].menu_item_id
                    })
                  }}
                  menu_uuid={this.menu_uuid} 
                  parent={that} 
                  item={pair[1]} 
                  tab={this.state.tab}
                />
              )}
            </div>
          ))}
        </div>
      )
    } else{
      return (
        <div className='menu-section-container'>
          <div className='menu-section-title' ref={sectionRefs['Deactivated Items']}>
            Deactivated Items
          </div>
          {this.state.blackedOutItems.map(item => (
            <MenuItemBox menu_uuid={this.menu_uuid} item={item} />
          ))}
        </div>
      )
    }
  }


  renderArchivedItems(){
    let that = this
    let sectionRefs = this.sectionRefs
    if(!this.state.archivedItems.length) return null

    if(this.state.isDesktop){
      let items = this.state.archivedItems
      let rows = items.reduce(function (rows, key, index) { 
          return (index % 2 == 0 ? rows.push([key]) 
            : rows[rows.length-1].push(key)) && rows;
        }, []);

      return (
        <div style={{paddingTop:'25px'}} className='menu-section-container'>
          <div className='menu-section-title' ref={sectionRefs['Archived Items']} >
            Archived Items
          </div>
          {rows.map((pair, idx) => (
            <div className='desktop-menu-box-row'>
              <MenuItemBox 
                onClick={()=>{
                  this.props.history.push({
                    pathname:'/menu/menu-item-edit/'+pair[1].menu_item_id
                  })
                }}
                menu_uuid={this.menu_uuid} 
                style={{width:'48%'}}
                parent={that} 
                item={pair[0]} 
                tab={this.state.tab}
              />
              {pair.length > 1 && (
                <MenuItemBox 
                  style={{width:'48%'}}
                  onClick={()=>{
                    this.props.history.push({
                      pathname:'/menu/menu-item-edit/'+pair[1].menu_item_id
                    })
                  }}
                  menu_uuid={this.menu_uuid} 
                  parent={that} 
                  item={pair[1]} 
                  tab={this.state.tab}
                />
              )}
            </div>
          ))}
        </div>
      )
 

    }
  }

  renderAddSectionDropdown(){
    if(this.state.disableEdits) return null

    return (
      <div className='menu-item-dropdown add-section-dropdown' style={this.addSectionDropdownStyle()}>
        <div
          className='menu-item-dropdown-option'
          style={{borderBottom: '2px solid #F4F4F4'}}
          onClick={()=>{
            this.props.history.push({
              pathname:'/menu/menu-item-create/'+this.menu_uuid
            })
          }}
        >
          <div className='in-dropdown'>Add Item</div>
        </div>

        <div
          className='menu-item-dropdown-option'
            onClick={()=>{
              this.props.history.push({
                pathname:'/menu/menu-section-create/'+this.menu_uuid
              })
            }}
        >
          <div className='in-dropdown'>Add Section</div>
        </div>
      </div>
    )
  }

  renderMobile(){
    return (
      <>
        {this.state.addSectionDropdownOpen === true && (
          <> {this.renderAddSectionDropdown()}</>      
        )}


        {this.state.sectionDropdownCategoryId !== null && (
          <> {this.renderSectionDropdown()} </>
        )}
          {this.state.toast && (
            <OrderToast
              toast={this.state.toast}
              message={this.state.toast.item ? this.state.toast.item.name : null}
              type={this.state.toast.type}
            />
          )}


        <div className='menu-tabs'>
          <div 
            className={classnames('menu-tab', 
              {'menu-tab-selected':this.state.tab === 'items'})} 
            onClick={()=>this.updateTab('items')}
          >
            <div>Items</div>
          </div>
          <div 
            className={classnames('menu-tab', 
              {'menu-tab-selected':this.state.tab === 'modifiers'})} 
            onClick={()=>this.goToModifiers()}
          >
            <div>Modifiers</div>
          </div>
        </div>


        {this.renderMenuItems()}
      </>
    )
  }

  renderTablet(){
    let that = this

    let categories = this.state.tab === 'items' ?
      this.state.menu.menu :
      this.state.modifiersByCategory

    let blackedOutItems = this.state.tab === 'items' ?
      this.state.blackedOutItems :
      this.state.blackedOutModifiers

    let sectionRefs = this.state.tab === 'items' ?
      this.sectionRefs :
      this.modifierSectionRefs


    return (
      <>
        {this.state.addSectionDropdownOpen === true && (
          <> {this.renderAddSectionDropdown()}</>      
        )}

        {this.state.sectionDropdownCategoryId !== null && (
          <> {this.renderSectionDropdown()} </>
        )}

        <div className='menu-tabs'>
          <div 
            className={classnames('menu-tab', 
              {'menu-tab-selected':this.state.tab === 'items'})} 
            onClick={()=>this.updateTab('items')}
          >
            <div>Items</div>
          </div>
          <div 
            className={classnames('menu-tab', 
              {'menu-tab-selected':this.state.tab === 'modifiers'})} 
            onClick={()=>this.goToModifiers()}
          >
            <div>Modifiers</div>
          </div>
        </div>
        <div className='full-menu-container'>
          {this.state.toast && (
            <OrderToast
              toast={this.state.toast}
              message={this.state.toast.item ? this.state.toast.item.name : null}
              type={this.state.toast.type}
            />
          )}

          <div className='full-menu-left'>
            <div className='section-title'>{this.state.menu.name}</div>
            <div 
              style={{marginTop:'30px'}}
              className='poppins-title'
            >
              Menu Sections
            </div>
            
            <div className='menu-sections-list-container'>
              {Object.values(categories).map(category => (
                <div 
                  onClick={()=>this.scrollToSection(category.menu_category_id)}
                  style={{backgroundColor: '#FCFCFC'}}
                  className='menu-section-box'
                >
                  <div className='menu-box-title' >
                    {category.name}
                  </div>
                  <div className='menu-box-subtitle'>
                    {category.items.length} items
                  </div>
                </div>
              ))}
              {blackedOutItems.length > 0 && (
                <>
                  <div className='menu-section-hr'></div>
                  <div 
                    onClick={()=>this.scrollToSection('Deactivated Items')}
                    style={{backgroundColor: '#FCFCFC'}}
                    className='menu-section-box'
                  >
                    <div className='space-between'>
                      <div className='menu-box-title' >
                        Deactivated
                      </div>
                      <Deactivate style={{width:'25px', height:'25px', position:'relative', top:'-2px'}}/>
                    </div>
                    <div className='menu-box-subtitle'>
                      {blackedOutItems.length} items
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {this.renderMenuItems()}      

        </div>
      </>
    )
  }



  render(){
    if(!this.loaded()) return (<Loading />)
    if(this.state.isDesktop) return this.renderDesktop()
    if(this.state.isTablet) return this.renderTablet()
    if(this.state.isMobile) return this.renderMobile()
    
  }
}
      

export default withRouterAndRef(ToastContextHOC(UserContextHOC(Menu))) 

