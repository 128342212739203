import React, { Component } from "react"
import classnames from "classnames"
import { Cluster, clusterApiUrl, Connection, PublicKey, Keypair } from '@solana/web3.js';
import { encodeURL, createQR , findTransactionSignature, FindTransactionSignatureError} from '@solana/pay';
import BigNumber from 'bignumber.js';
import UserContextHOC from "../contexts/UserContextHOC"
import CardCryptoSelector from "../stripe-pos/CardCryptoSelector"
import CheckCircle from "../orders/svg/CheckCircleBig"
import Solana from "../orders/svg/Solana"
import USDC from "../orders/svg/USDC"
import Foodcoin from "../orders/svg/Foodcoin"
import "./SolanaPay.css"

const Money =  require("../helpers/money")
const Api = require("../api")


class SolanaPay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:false,
      amount:null,
    }
    window.t = this
    this.dropdownRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount(){
    this.getSolPrice()
    window.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount(){
   window.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(e){
    if(this.state.dropdownOpen){
      if(!e.target.className){
        this.setState({dropdownOpen:false})
      } else if (e.target.className.indexOf('in-dropdown') == -1){
        this.setState({dropdownOpen:false})
      }
    }
  }

  getSolPrice(){
    Api.callApi({
      endpoint:'/api/sol-price',
      success:(data)=>{
        this.setState({solPrice:data.cents})
      },
      failure:(err)=>{
        alert('Error getting SOL price')
      }

    })
  }

  async generateQR(){
    let that = this
    const connection = new Connection(clusterApiUrl('mainnet-beta'), 'confirmed');
    this.connection = connection 
    const recipient = new PublicKey('caPsEFqXeu4upjqorwqhhTjmnhtob9EBUsAx7HbXpqw');
    const reference = new Keypair().publicKey;
    this.reference = reference
    //const amount = new BigNumber(parseFloat(this.state.amount));
    const label = this.props.user.rest.name 
    const message = 'I am a message';
    const memo = 'I am a memo';

    let splToken
    let amount

    if(this.state.coin === 'usdc'){
      splToken = new PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v")
      amount = parseFloat(this.state.amount)
    } else if (this.state.coin === 'food'){
      splToken = new PublicKey("B7vkWNFo2aYcYddtwR8fvDwY1Uc54wHfdTXULjrX7KeL")
      amount = parseFloat(this.state.amount)
    } else {
      amount = (parseFloat(this.state.amount)*100 ) / this.state.solPrice 
    }
    console.log("amount", amount)
    amount = parseFloat(amount.toFixed(5))
    this.setState({coinAmount:amount})
    amount = new BigNumber(amount)
    let url
    if(splToken){
      url = encodeURL({ recipient, amount,  splToken, reference, label });
    } else {
      url = encodeURL({ recipient, amount, reference, label });
    }
    const qrCode = createQR(url, 350);
    const element = document.getElementById('qr-code');
    qrCode.append(element);
    //const signatureInfo = await findTransactionSignature(connection, reference, undefined, 'confirmed');
    let signatureInfo;
    const { signature } = await new Promise((resolve, reject) => {
        /**
         * Retry until we find the transaction
         *
         * If a transaction with the given reference can't be found, the `findTransactionSignature`
         * function will throw an error. There are a few reasons why this could be a false negative:
         *
         * - Transaction is not yet confirmed
         * - Customer is yet to approve/complete the transaction
         *
         * You can implement a polling strategy to query for the transaction periodically.
         */
        const interval = setInterval(async () => {
            console.count('Checking for transaction...');
            try {
                signatureInfo = await findTransactionSignature(that.connection, that.reference, undefined, 'confirmed');
                console.log('\n 🖌  Signature found: ', signatureInfo.signature);
                clearInterval(interval);
                resolve(signatureInfo);
            } catch (error) {
                if (!(error instanceof FindTransactionSignatureError)) {
                    console.error(error);
                    clearInterval(interval);
                    reject(error);
                }
            }
        }, 2000);
    });  
    this.setState({
      signature:signature,
      page:'waiting'
    }, ()=>this.checkForConfirmation())
  }

  async checkForConfirmation(){
    let amount = Math.round(parseFloat(this.state.amount) * 100)
    let checkForSolanaSuccess = function(signature, iteration, resolve, reject){
      if(iteration >= 60){ 
        resolve({success:false, error:'timeout'})
        return
      }

      let payload = {
        signature:signature,
        amount:amount
      }

      Api.callApi({
        endpoint:"/api/check-if-solana-txn-ready-and-create-order",
        method:'post',
        data:payload,
        success:(resp)=>{
          console.log("check for resp", resp)
          if(resp.success){
            resolve({success:true})
          } else{
            setTimeout(()=>checkForSolanaSuccess(signature, iteration+1, resolve, reject), 2000)
          }    
        },  
        failure:(err)=>{
          setTimeout(()=>checkForSolanaSuccess(signature, iteration+1, resolve, reject), 5000)
          console.log(err)
          //reject(err) 
        }   
      }) 
    }
    let waitForTxnToConfirm = function(signature){
      return new Promise(function(resolve, reject) {
        checkForSolanaSuccess(signature, 0, resolve, reject)
      })
    }
    try{
      let result = await waitForTxnToConfirm(this.state.signature)
      if(!result.success){
        return {
          'success':0,
          'error':result.error
        }
      } else{
        this.setState({page:'paid'})
      }
    } catch (err) {
      console.log("err2", err)
      return {
        'success': 0,
        'error':'unknown'
      }
    }
  }

  filterInput(val){
    if(val){
      if(val.substr(0,1) === '.'){
        val = '0'+val
      }
      return val.match(/^-?\d+(?:\.\d{0,2})?/)[0]
    } else {
      return val
    }
  }

  amount(){
    let amount = parseFloat(this.state.amount)
    if (!amount) return 0
    return amount * 100
  }

  renderDropdown(){
    if(!this.state.dropdownOpen) return null
    return (
      <div className='crypto-dropdown-container'>


        <div 
          onClick={()=>this.setState({coin:'food', dropdownOpen:false})}
          className='crypto-option in-dropdown'
        >
          <div className='cryptop-option-svg-container in-dropdown   crypto-option-border'>
            <Foodcoin className='in-dropdown'/>
          </div>
          <div className='in-dropdown'>FOOD</div>
        </div>
          
        <div 
          onClick={()=>this.setState({coin:'usdc', dropdownOpen:false})}
          className='crypto-option in-dropdown'
        >
          <div className='cryptop-option-svg-container in-dropdown crypto-option-border'>
            <USDC className='in-dropdown'/>
          </div>
          <div className='in-dropdown'>USDC</div>
        </div>

        <div 
          onClick={()=>this.setState({coin:'sol', dropdownOpen:false})}
          className='crypto-option in-dropdown'
        >
          <div className='cryptop-option-svg-container in-dropdown crypto-option-border'>
            <Solana className='in-dropdown'/>
          </div>
          <div className='in-dropdown'>SOL</div>
        </div>

      </div>
    )
  }

  render(){
    if(this.state.page === 'paid'){
        return (
          <div className='create-charge-paid-page'>
            <div className='create-page-top-portion-final'>
              <CheckCircle />
              <div className='create-charge-final-page-text'>
                Approved
              </div>
            </div>
            <div className='create-charge-pay-container'>
              <div className='create-charge-paid-toppest-row'>
                <div className='create-charge-pay-amount'>
                  {Money.formatCents(this.amount())}
                  <br/>
                  {this.state.coinAmount} {this.state.coin.toUpperCase()}
                </div>
                
                {/*
                <div className='create-charge-paid-name-amount-container'>
                  {this.state.name && (
                    <div>{this.state.name}</div>
                  )}
                  <div>
                    {Money.formatCents(this.amount())}
                  </div>
                </div>
                */}

                <div className='create-charge-payment-successful'>
                  <CheckCircle/>
                  <div>Payment Successful</div>
                </div>
              </div>
    
              <div style={{marginTop:'95px'}} className='paid-bottom-button-row'>
                <div 
                  className='paid-done-button'
                  onClick={()=>this.props.history.goBack()}
                >
                  <div>Done</div>
                </div>

              </div>

            </div>
          </div>
       )
    }
    if(this.state.page === 'waiting'){
        return (
          <div className='create-charge-pay-page' key='waiting'>
            <div className='create-charge-m2-image-container'>
              <div className='crypto-wait-big-svg-container'>
                {this.state.coin === 'sol' && <Solana/>} 
                {this.state.coin === 'usdc' && <USDC/>} 
                {this.state.coin === 'food' && <Foodcoin/>} 
              </div>
            </div>
            <div className='create-charge-pay-container'>
              <div className='create-charge-pay-top-row'>
                <div className='create-charge-pay-amount'>
                  {Money.formatCents(this.amount())}
                  <br/>
                  {this.state.coinAmount} {this.state.coin.toUpperCase()}
                </div>
                <div
                  className='create-charge-pay-cancel'
                  onClick={()=>this.setState({coin:undefined, page:undefined})}
                >
                </div>
              </div>

              <div className='create-charge-tap-or-swipe-loading-container'>
                <img src='https://cdn.hngr.co/captain/roller3.svg' />
              </div>
              <div className='create-charge-tap-or-swipe'>
                Verifying transaction on the Blockchain
              </div>
            </div>
          </div>
        )
    }



    if(this.state.page === 'qr'){
        return (
          <div className='create-charge-pay-page'>
            <div className='create-charge-m2-image-container'>
              <div
                className='solana-pay-qr-code-container'
                id='qr-code'
              >
              </div>
            </div>
            <div className='create-charge-pay-container'>
              <div className='create-charge-pay-top-row'>
                <div className='create-charge-pay-amount'>
                  {Money.formatCents(this.amount())}
                  <br/>
                  {this.state.coinAmount} {this.state.coin.toUpperCase()}
                </div>
                <div
                  className='create-charge-pay-cancel'
                  onClick={()=>this.setState({coin:undefined, page:undefined})}
                >
                  Cancel
                </div>
              </div>

              <div className='create-charge-tap-or-swipe-loading-container'>
                <img src='https://cdn.hngr.co/captain/roller3.svg' />
              </div>
              <div className='create-charge-tap-or-swipe'>
                Scan QR Code with Phantom Wallet
              </div>
            </div>
          </div>
        )
    }

    return (
      <div className='orders-content'>
        <div className='create-charge-title'>
          Customer Payment
        </div>
        <CardCryptoSelector page='crypto'/>
      </div>
    )

  }
}
      
export default UserContextHOC(SolanaPay) 


const ChevronDown = props => (<svg {...props} width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.468875 0.717658C0.538544 0.647813 0.621307 0.5924 0.712425 0.554589C0.803543 0.51678 0.901225 0.497317 0.999875 0.497317C1.09853 0.497317 1.19621 0.51678 1.28733 0.554589C1.37844 0.5924 1.46121 0.647813 1.53088 0.717658L9.99988 9.18816L18.4689 0.717657C18.6097 0.576827 18.8007 0.49771 18.9999 0.49771C19.199 0.49771 19.39 0.576827 19.5309 0.717657C19.6717 0.858487 19.7508 1.04949 19.7508 1.24866C19.7508 1.44782 19.6717 1.63883 19.5309 1.77966L10.5309 10.7797C10.4612 10.8495 10.3784 10.9049 10.2873 10.9427C10.1962 10.9805 10.0985 11 9.99988 11C9.90122 11 9.80354 10.9805 9.71243 10.9427C9.62131 10.9049 9.53854 10.8495 9.46888 10.7797L0.468876 1.77966C0.399031 1.70999 0.343616 1.62723 0.305807 1.53611C0.267997 1.44499 0.248535 1.34731 0.248535 1.24866C0.248535 1.15001 0.267997 1.05233 0.305807 0.961208C0.343616 0.87009 0.399031 0.787327 0.468875 0.717658Z" fill="#000CCF"/>
</svg>)
