import React, { Component } from "react"
import DesktopSidebar from "./DesktopSidebar"
import UserContextHOC from "../contexts/UserContextHOC"
import ToastContextHOC from "../contexts/ToastContextHOC"
import Breadcrumbs from "./Breadcrumbs"
import withRouterAndRef from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import ButtonWithSpinner from "../orders/ButtonWithSpinner"
import "./Menu.css"
import "./MenuItemEdit.css"

const Api = require("../api")
const Money =  require("../helpers/money")
const Timestamp = require("../helpers/timestamps")

class SectionEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name:null,
      description:null,
      edited:false,
      isDesktop:window.innerWidth >= 1000
    }
    this.handleEnterKey = this.handleEnterKey.bind(this)
    window.s = this
  }

  componentDidMount(){
    window.addEventListener("keydown", this.handleEnterKey)
    if(this.props.page === 'edit') this.loadSection()
  }

  componentWillUnmount(){
    window.removeEventListener("keydown", this.handleEnterKey)
    this.mounted = false
  }

  handleEnterKey(e) {
    if(e.key === 'Enter'){
      window.document.activeElement.blur()
    }
  }

  filledOut(){
    return (
      this.state.name
    ) 
  }

  createSection(cb){
    Api.callApi({
      endpoint:'/api/tablet/create-section',
      method:'post',
      data:{
        name:this.state.name,
        description:this.state.description,
        menu_uuid:this.props.match.params.menu_uuid
      },
      success:(data)=>{
        let path = '/menu/menu/'+this.props.match.params.menu_uuid
        let toast = {
          type:'generic',
          title:'Section Created',
          message:this.state.name
        }
        this.props.toast.navigateThenToast(path,toast)  
      },
      failure:(err)=>{
        cb()
        alert('Error')
      }
    })
  }

  loadSection(){
    let menu_category_id = this.props.match.params.menu_category_id
    Api.callApi({
      endpoint:'/api/tablet/load-section',
      method:'post',
      data:{menu_category_id:menu_category_id},
      failure:()=>{
        alert('Unexpected error loading section')
      },
      success:(data)=>{
        this.setState({
          menu_uuid:data.menu_uuid,
          name:data.section.name,
          description:data.section.description
        })
      }
    })
  }

  saveEdits(){
    this.setState({loading:true})
    Api.callApi({
      endpoint:'/api/tablet/edit-section',
      method:'post',
      data:{
        name:this.state.name,
        description:this.state.description,
        menu_category_id:this.props.match.params.menu_category_id
      },
      failure:(err)=>{
        alert('Unexepcted error saving section')
        this.setState({loading:false})
      },
      success:(data)=>{
        let path = '/menu/menu/'+data.menu_uuid
        let toast = {
          type:'generic',
          title:'Section Edited',
          message:this.state.name
        }
        this.props.toast.navigateThenToast(path,toast)  
      }
    })
  }

  loaded(){
    if(this.props.page === 'edit'){
      if(this.state.loading)  return false
      if(this.state.name) return true
      else return false
    } else if (this.props.page === 'create'){
      return true
    }
  }


  renderContent(){
    if(!this.loaded()) return <Loading/>
    let that = this
    return (
      <div 
        className='edit-item-page content'
        style={this.state.isDesktop ? {paddingLeft:'35px', paddingTop:'15px', width:'700px'} : null}
      >
        {this.props.page === 'edit' && (
          <div className='edit-item-title'>Edit Menu Section</div>
        )}
        
        {this.props.page === 'create' && (
          <div className='edit-item-title'>Create a New Section</div>
        )}

        <div className='edit-item-field-wrapper'>
          <div className='edit-item-label'>Name*</div>
          <input 
            className='edit-item-input'
            value={this.state.name}
            onChange={(e)=>this.setState({name:e.target.value, edited:true})}
          />
        </div>

        <div className='edit-item-field-wrapper'>
          <div className='edit-item-label'>Description</div>
          <textarea
            rows="5"
            className='edit-item-textarea'
            onChange={(e)=>this.setState({description:e.target.value, edited:true})}
            value={this.state.description}
          />
        </div>

        {this.props.page === 'create' && (
          <>
            {this.filledOut() ? (
              <ButtonWithSpinner
                text='Create New Section'
                className='edit-item-button'
                onClick={(cb)=>this.createSection(cb)}
                spinnerColor='white'
              />
            ) : (
              <div className='edit-item-button edit-item-button-disabled'>
                <div>Create New Section</div>
              </div>
            )}
          </>
        )}

        {this.props.page === 'edit' && (
          <>
            {this.state.edited ? (
              <ButtonWithSpinner
                text='Save Edits'
                className='edit-item-button'
                onClick={(cb)=>this.saveEdits(cb)}
                spinnerColor='white'
              />
            ) : (
              <div className='edit-item-button edit-item-button-disabled'>
                <div>Save Edits</div>
              </div>
            )}
          </>
        )}

     </div>
    )
  }

  render(){
    if(this.state.isDesktop){
      let breadcrumbs = null
      if(this.props.page === 'create'){
         breadcrumbs = (
          <Breadcrumbs 
            crumbs={[
              {text:'Menus',path:'/menu/menus'},
              {text:'Items', path:'/menu/menu/'+this.props.match.params.menu_uuid}
            ]}
            current='Create Section'
          />  
        )
      } else if (this.props.page === 'edit'){
        if(!this.state.name){
          breadcrumbs = (
            <Breadcrumbs 
              crumbs={[
              ]}
              current=''
            />  
          )
        } else {
          breadcrumbs = (
            <Breadcrumbs 
              crumbs={[
                {text:'Menus', path:'/menu/menus'},
                {text:'Items', path:'/menu/menu/'+this.state.menu_uuid}
              ]}
              current={this.state.name}
            />  
          )
        }
      
      }

      return (
        <div className='desktop-menu-item-page-container'>
          <DesktopSidebar 
            menu_uuid={this.props.match.params.menu_uuid ? this.props.match.params.menu_uuid : this.state.menu_uuid  }
          />
          <div className='desktop-content-with-crumbs'>
            {breadcrumbs}
            {this.renderContent()}
          </div>
        </div>
      )
    } else {
      return this.renderContent()
    }
  }
}
      

export default withRouterAndRef(ToastContextHOC(UserContextHOC(SectionEdit))) 
