import React from "react"
import { Text, Button } from "../../components"
import AdjustHours from "./AdjustHours"
import HoursBarChart from './HoursBarChart'

const Helpers = require('./helpers')

class DeliveryHours extends React.Component {
  constructor(props){
    super(props)
    let hours = JSON.parse(JSON.stringify(this.props.hours))
    console.log("deliv hour props", hours)
    for(let h in hours){
      hours[h].hours = Helpers.shiftHours(hours[h].hours)
    }
    this.state = {
      hours:hours
    }
  }

  render() {
    return (
      <>
        <Text size="header" weight="black" color="pink">
          Delivery Hours
        </Text>
        {this.state.hours.map((h,i) => (
          <div key={h.delivery_zone_id} style={{marginBottom:'100px'}}>
            <Text weight="black" size="header">
              {h.name}
            </Text>
            <HoursBarChart 
              hours={Helpers.shitDayOfWeekToZeroStart(h.hours)}
              labels={Helpers.DAYS}
            />
            <div className="mt-10 left">
              <AdjustHours
                hours={h.hours}
                getSchedules={this.props.getSchedules}
                schedule={h.name}
                delivery={true}
                updateHours={
                  (hours)=>{
                    let copy = this.state.hours
                    copy[i].hours = hours
                    this.setState({hours:copy})
                  }
                }
                saveHours={
                  (callback)=>this.props.saveHours(h.hours, h.delivery_zone_id, callback)
                }
                handleToggle={this.handleToggle}
              />
            </div>
          </div>
        ))}
      </>
    )
  }
}
export default DeliveryHours 
