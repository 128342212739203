import React, { Component } from "react"
import DesktopSidebar from "./DesktopSidebar"
import UserContextHOC from "../contexts/UserContextHOC"
import ToastContextHOC from "../contexts/ToastContextHOC"
import MenuItemBox from "./MenuItemBox"
import ModifierClassBox from "./ModifierClassBox"
import Breadcrumbs from "./Breadcrumbs"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import OrderToast from "../orders/OrderToast"
import Deactivate from "../orders/svg/Deactivate"
import Plus from "../orders/svg/PlusMedium"
import "./Menu.css"

const Api = require("../api")
const Money =  require("../helpers/money")

class MenuModifiers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile:window.innerWidth <= 599,
      isTablet:window.innerWidth > 599 && window.innerWidth < 1000,
      isDesktop:window.innerWidth>=1000,
      tab:'items',
      menu:null,
      toast:null,
    }
    this.menu_uuid = this.props.match.params.menu_uuid
    window.mm = this
  }

  componentDidMount(){
    this.getCentralTimestamp()
  }

  componentDidUpdate(prevProps){
    if(
      JSON.stringify(this.props.toast.toast) !== JSON.stringify(prevProps.toast.toast) &&
      this.props.toast.toast
    ){
      this.setState({menu:null})
      this.getCentralTimestamp()
      this.addToast(this.props.toast.toast)
    }
  }

  addToast(toast){
    if(!this.loaded()){
      setTimeout(()=>this.addToast(toast), 100)
      return
    }
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }   
  }

  loaded(){
    if(this.state.loaded && this.state.centralTimestamp) {
      return true
    } else {
      return false
    }
  }

  loadMenu(){
    Api.callApi({
      endpoint:'/api/menu/modifier-groups/'+this.menu_uuid,
      success:(data)=>{
        let disableEdits = false
        if(data.external_source === 'CHOWLY') disableEdits = true
        if(data.external_source === 'SQUARE') disableEdits = true
        this.setState({
          loaded:true, 
          modifierClasses:data.modifier_classes,
          disableEdits:disableEdits
        })
      },
      failure:(err)=>{
        console.log("error", err)
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error loading the modifiers' 
          }
        })
      }
    })
  }

  getCentralTimestamp(){
    Api.callApi({
      endpoint:'central-timestamp',
      success:(data)=>{
        this.setState({
          centralTimestamp:data.ts,
          tomorrow5am:data.tomorrow_5am,
        }, ()=>this.loadMenu())
      }
    })
  }


  renderContent(){
    if(!this.loaded()) return (<Loading />)
    return (
      <div 
        className=''
        style={this.state.isDesktop ? {width:'700px'} : null}
      >
        {this.state.isDesktop != true && (
          <div className='menu-tabs'>
            <div 
              className='menu-tab'
              onClick={()=>{
                this.props.history.push({
                  pathname:'/menu/menu/'+this.menu_uuid
              })}}
            >
              <div>Items</div>
            </div>
            <div 
              className='menu-tab menu-tab-selected'
            >
              <div>Modifiers</div>
            </div>
          </div> 
        )}
        <div className='orders-content menu-page-paddiing-top'>
          <div className='modifier-page-title-row'>
            <div className="menu-section-title">Modifier Groups</div>

            {this.state.disableEdits !== true && (
              <div 
                className='modifier-class-add-modifier'
                onClick={()=>{
                  this.props.history.push({
                    pathname:'/menu/modifier-group/create/'+this.menu_uuid
                  })
                }}
              >
                <Plus />
                <div>Add Modifier Group</div>
              </div>
            )}
        </div>


          <div className=''>
            {this.state.modifierClasses.map(mc => (
              <ModifierClassBox
                hideElipses={true}
                disableEdits={this.state.disableEdits}
                page='menu'
                menu_uuid={this.menu_uuid}
                mc={mc}
                key={mc.modifier_class_id}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }

  render(){
    if(this.state.isDesktop){
      return (
        <div className='desktop-menu-item-page-container'>
          <DesktopSidebar 
            menu_uuid={this.props.match.params.menu_uuid}
            page='modifiers'
          />
          <div className='desktop-content-with-crumbs'>
            <Breadcrumbs
              crumbs={[
                {text:'Menus', path:'/menu/menus'},
                {text:'Items', path:'/menu/menu/'+this.menu_uuid}
              ]}
              current='Modifier Groups'
            />
            {this.renderContent()}
          </div>
        </div>
      )
    } else {
      return this.renderContent()
    }
  }
}
      

export default withRouterAndRef(ToastContextHOC(UserContextHOC(MenuModifiers))) 


