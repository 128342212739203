import React, { Component } from "react"
import LoadingActionButton from '../Loading/LoadingActionButton'
import "./OrderCard.css"

class ActionButton extends Component {
  constructor(props){
    super(props)
    this.state = {
      loading:props.loading,
      text:props.text
    }
    window.a = this
  }

  componentDidUpdate(prevProps){
    if(this.state.text !== this.props.text){
      this.setState({text:this.props.text})
    }
    if(this.state.loading !== this.props.loading){
      this.setState({loading:this.props.loading})
    }
  }

  style(){
    if (!this.props.color) return

    return {
      borderColor:this.props.color,
      color:this.props.color
    }
  }

  render(){
    return (
      <div
        style={this.style()}
        className='order-card-action-button order-card-confirm-order'
        onClick={this.props.onClick}
      >
        {this.state.text}
        {!this.state.loading && this.props.svg} 
        {this.state.loading && (<>  &nbsp;&nbsp; <LoadingActionButton color='#239C44'/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </>)}
      </div>
    )
  }
}
      

export default ActionButton
