import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import DesktopSidebar from "./DesktopSidebar"
import ToastContextHOC from "../contexts/ToastContextHOC"
import ModifierModalContextHOC from "../contexts/ModifierModalContextHOC"
import ModifierClassBox from "./ModifierClassBox"
import withRouterAndRef from "../withRouterAndRef"
import classnames from 'classnames'
import FloppyDisk from "../orders/svg/FloppyDisk"
import Plus from "../orders/svg/PlusMedium"
import Loading from "../Loading/Loading"
import Breadcrumbs from "./Breadcrumbs"
import ButtonWithSpinner from "../orders/ButtonWithSpinner"
import AreYouSureModal from "./AreYouSureModal"
import AttachTag from "../orders/svg/AttachTag"
import { memo, useCallback, useState } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import update from 'immutability-helper';
import "./Menu.css"
import "./MenuItemEdit.css"

const Api = require("../api")
const Money =  require("../helpers/money")
const Timestamp = require("../helpers/timestamps")

const DesktopModifierGroupsContainer = memo(function Container(props) {
    const [cards, setCards] = useState(props.modifierClasses);
    const findCard = useCallback((id) => {
        const card = cards.filter((c) => `${c.modifier_class_id}` == id)[0];
        console.log("zuess cards", cards)
        console.log("zuess id", id)
        console.log("zuess found card", card)
        return {
            card,
            index: cards.indexOf(card),
        };
    }, [cards]);
    const moveCard = useCallback((id, atIndex) => {
        const { card, index } = findCard(id);
        setCards(update(cards, {
            $splice: [
                [index, 1],
                [atIndex, 0, card],
            ],
        }));
        props.parent.reorderModifierClasses(cards, index, atIndex, card)
    }, [findCard, cards, setCards]);
    const [, drop] = useDrop(() => ({ accept: 'modifier_class'}));
    return (
      <div ref={drop}> 
        {cards.map((card) => (
          <DesktopModifierClassDraggable
            parent={props.parent}
            item={props.parent.state.item}
            findCard={findCard}
            moveCard={moveCard}
            key={card.modifier_class_id}
            id={card.modifier_class_id}
            mc={card}
            menu_uuid={props.menu_uuid}
            clickModifierClassBox={props.clickModifierClassBox}
            disableEdits={props.disableEdits}
          />
        ))}
    </div>);
});

const DesktopModifierClassDraggable = withRouterAndRef(memo(function DesktopModifierClassDraggable({ mc, history, id,  item, parent, menu_uuid, disableEdits, clickModifierClassBox, moveCard, findCard}) {
    const originalIndex = findCard(id).index;
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'modifier_class', 
        item: { id, originalIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const { id: droppedId, originalIndex } = item;
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                moveCard(droppedId, originalIndex);
            }
        },
    }), [id, originalIndex, moveCard]);
    const [, drop] = useDrop(() => ({
        accept: 'modifier_class',
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id);
                moveCard(draggedId, overIndex);
            }
        },
    }), [findCard, moveCard]);
    const opacity = isDragging ? 0 : 1;
    

    return (
      <div ref={(node) => drag(drop(node))} style={{opacity}}> 
        <ModifierClassBox
          onClick={()=>clickModifierClassBox(mc)}
          page='item'
          item={item}
          menu_uuid={menu_uuid}
          disableEdits={disableEdits}
          remove={()=>parent.removeModifierClass(mc.modifier_class_id)}
          mc={mc}
          key={mc.modifier_class_id}
        />
      </div>
    )
}));




class MenuItemEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item:null,
      price:null,
      edited:false,
      newItemModifierClasses:[],
      modifierClasses:[],
      modifierClassesInitial:[],
      menu_category_id:this.preselectedCategory(),
      sections:[],
      isDesktop:window.innerWidth >= 1000
    }
    this.handleEnterKey = this.handleEnterKey.bind(this)
    this.fileInput = React.createRef();

    
    if(
      this.props.match && 
      this.props.match.params &&
      this.props.match.params.menu_uuid
    ){
      this.menu_uuid = this.props.match.params.menu_uuid
      this.state.menu_uuid = this.menu_uuid
    }

    if(  
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.unsavedState
    ) { 
      let s = JSON.parse(this.props.history.location.state.unsavedState)
      this.state = s 
      this.state.edited = true
      this.menu_uuid = s.menu_uuid
      this.dontload = true
    }

    window.edit = this
  }

  componentDidMount(){
    window.addEventListener("keydown", this.handleEnterKey)
    if(this.props.page === 'edit' && !this.dontload) this.loadItem()
    this.loadSections()
  }

  componentWillUnmount(){
    window.removeEventListener("keydown", this.handleEnterKey)
    this.mounted = false
  }

  componentDidUpdate(prevProps){
    if(this.state.isDesktop){
      let mcs = this.props.page === 'create' ? this.state.newItemModifierClasses :
        this.state.modifierClasses
      let mc = {modifier_class_id:-1}
      if(mcs.length){
        mc = mcs[mcs.length - 1]
      }
      if(
        this.props.modal.modifierClass && 
        prevProps.modal.modifierClass !== this.props.modal.modifierClass
        && mc.modifier_class_id !== this.props.modal.modifierClass.modifier_class_id
      ){
        mcs.push(JSON.parse(JSON.stringify(this.props.modal.modifierClass)))
        if(this.props.page === 'create'){
          this.setState({newItemModifierClasses:mcs},
            ()=>this.props.modal.reset())
        } else if (this.props.page === 'edit'){
          this.setState({modifierClasses:mcs, edited:true},
            ()=>this.props.modal.reset())
        }
      }
    }

  }

  handleEnterKey(e) {
    if(e.key === 'Enter'){
      window.document.activeElement.blur()
    }
  }

  loadSections(){
    let endpoint
    if(this.props.page === 'edit'){
      let menu_item_id  = this.props.match.params.menu_item_id
      endpoint = '/api/menu/categories-from-menu-item/'+menu_item_id
    } else if (this.props.page === 'create'){
      let menu_uuid = this.props.match.params.menu_uuid
      endpoint = '/api/menu/categories-from-menu/'+menu_uuid
    }
    Api.callApi({
      endpoint:endpoint,
      success:(data)=>{
        this.setState({sections:data.categories, sectionsLoaded:true})
      },
      failure:(err)=>{
        alert('Error getting menu sections')
      }
    })
  }

  loadItem(){
    let menu_item_id  = this.props.match.params.menu_item_id
    Api.callApi({
      endpoint:'reload-item',
      data:{menu_item_id:menu_item_id},
      success:(data)=>{
        let item = data.item
        let price = ''
        if(item.price){
          console.log("price cents", item.price)
          price = item.price/100
          var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          price = formatter.format(price).substr(1)
        }
        this.menu_uuid = data.menu_uuid
        let modifierClassesInitial = JSON.parse(JSON.stringify(data.modifier_classes))
        modifierClassesInitial = modifierClassesInitial.map(mc => {
          mc.id = mc.modifier_class_id
          return mc
        })

        this.setState({
          modifierClasses:modifierClassesInitial,
          modifierClassesInitial:modifierClassesInitial,
          item:item,
          name:item.name,
          menu_category_id:item.menu_category_id,
          menu_uuid:data.menu_uuid,
          price:price,
          description:item.description,
          imageUrl:item.image_url,
        })
      },
      failure:(err)=>alert('An error occurred')
    })
  }

  navigateToModifierClass(mc){
    if(this.props.page === 'edit'){
      let crumbs = [ 
        {text:'Menus', path:'/menu/menus'},
        {text:'Items', path:'/menu/menu/'+this.menu_uuid},
        {text:this.state.item.name, path:this.props.location.pathname}
      ]
      let url = '/menu/modifier-class-from-menu-item/' + this.menu_uuid +'/'+ this.state.item.menu_item_id + '/' + mc.modifier_class_id
      this.props.history.push({
        pathname:url,
        state:{
          crumbs:crumbs
        }
      })
    }
  }

  navigateToCreateModifierClass(menu_item_id){
    if(this.props.page === 'edit'){
      let crumbs = [ 
        {text:'Menus', path:'/menu/menus'},
        {text:'Items', path:'/menu/menu/'+this.menu_uuid},
        {text:this.state.item.name, path:this.props.location.pathname}
      ]
      let url = '/menu/modifier-group/create/'+this.state.item.menu_uuid +'?menu_item_id='+this.state.item.menu_item_id
      this.props.history.push({
        pathname:url,
        state:{
          crumbs:crumbs
        }
      })
    } else if(this.props.page === 'create'){
      let crumbs = [ 
        {text:'Menus', path:'/menu/menus'},
        {text:'Items', path:'/menu/menu/'+this.menu_uuid},
        {text:this.state.name, path:'/menu/menu-item-edit/'+menu_item_id}
      ]
      let url = '/menu/modifier-group/create/'+this.menu_uuid +'?menu_item_id='+menu_item_id
      this.props.history.push({
        pathname:url,
        state:{
          crumbs:crumbs
        }
      })
    }
  }

  clickModifierClassBox(mc){
    if(this.props.page === 'edit'){
      let url = '/menu/modifier-class-from-menu-item/' + this.menu_uuid +'/'+this.state.item.menu_item_id
      if(this.state.edited){
        this.setState({confirmChangesBeforeModifierClassNavigation:true, mc:mc})
      } else {
        this.navigateToModifierClass(mc)
      }
    } else if (this.props.page === 'create'){
      this.setState({confirmChangesBeforeModifierClassNavigation:true, mc:mc})
    }

  }

  reorderModifierClasses(cards, index, atIndex, card){
    let reOrdered = update(cards, {
            $splice: [
                [index, 1],
                [atIndex, 0, card],
            ],
        });
    if(this.props.page === 'edit'){
      this.setState({
        modifierClasses:reOrdered,
        edited:true
      })
    } else if (this.props.page === 'create'){
      this.setState({
        newItemModifierClasses:reOrdered
      })
    }
  }


  filterMoneyInput(val){
    if(val){
      return val.match(/^-?\d+(?:\.\d{0,2})?/)[0]
    } else {
      return val
    }
  }

  price(){
    let price = parseInt(parseFloat(this.state.price)*100)
    if (!price) return 0
    return price
  }


  saveEdits(cb, params={}){ 
    let image_url = params.image_url
    let navigateFunction = params.navigateFunction
    
    let menu_item_id  = this.props.match.params.menu_item_id
    let data = {
      menu_item_id:menu_item_id,
      name:this.state.name,
      description:this.state.description,
      menu_category_id:this.state.menu_category_id,
      price:this.price(),
      page:this.props.page
    }
    if(image_url){
      data.image_url = image_url
    }
    let mc_ids = this.state.modifierClasses.map(mc => mc.modifier_class_id)
    data['modifier_classes'] = mc_ids
    
    Api.callApi({
      endpoint:'update-item',
      data:data,
      // this needs to do something else in desktop..... toast back to the menu
      success:(data)=> {
        if(this.state.isDesktop){
          let toast = {
            item:data.item,
            type:'item-edit'
          }
          let path = '/menu/menu/'+this.menu_uuid
          if(!navigateFunction){
            this.props.toast.navigateThenToast(path,toast)  
          } else{
            navigateFunction()
          }
        } else {
          this.props.toast.setToast({
            item:data.item,
            type:'item-edit'
          }, true)
        }
        cb()
      },
      failure:(err)=>{
        cb()
        alert('An error occurred')
      }
    })
  }

  readyToSave(){
    if(this.props.page === 'create') return this.filledOut()
    if(this.props.page === 'edit') return this.state.edited
  }

  filledOut(){
    return (
      this.state.menu_category_id &&
      this.state.name &&
      this.state.price !== null
    ) 
  }

  createItem(cb, params={}){
    let image_url = params.image_url

    let mc_ids = this.state.newItemModifierClasses.map(mc => mc.modifier_class_id)
    let navigateFunction = params.navigateFunction

    let data = {
        price:this.price(),
        menu_uuid:this.props.match.params.menu_uuid,
        name:this.state.name,
        description:this.state.description,
        menu_category_id:this.state.menu_category_id,
        modifier_classes:mc_ids
    }
    if(image_url){
      data['image_url'] = image_url
    }

    Api.callApi({
      endpoint:'/api/tablet/create-item',
      method:'post',
      data:data,
      success:(data)=>{
        let path = '/menu/menu/'+this.props.match.params.menu_uuid
        let toast = {
          type:'generic',
          title:'Item Saved',
          message:this.state.name
        }
        if(navigateFunction){
          this.navigateToCreateModifierClass(data.item.menu_item_id)
        } else {
          this.props.toast.navigateThenToast(path,toast)  
        }
      },
      failure:(err)=>{
        cb()
        alert('Error')
      }
    })
  }

  loaded(){
    if(this.props.page === 'edit'){
      if(this.state.item && this.state.sectionsLoaded) return true
      else return false
    } else if (this.props.page === 'create'){
      return !!this.state.sectionsLoaded
    }
  }

  preselectedCategory(){
    if(
      this.props.history.location && 
      this.props.history.location.state && 
      this.props.history.location.state.menu_category_id
    ){
      return this.props.history.location.state.menu_category_id
    } else {
      return null
    }
  }

  removeModifierClass(modifier_class_id){
    let mcs = this.props.page === 'create' ? this.state.newItemModifierClasses 
      : this.state.modifierClasses
    let filtered = []
    for(let i in mcs){
      if (mcs[i].modifier_class_id !== modifier_class_id){
        filtered.push(mcs[i])
      }
    }
    if(this.props.page === 'create'){
      this.setState({newItemModifierClasses:filtered})
    } else if (this.props.page === 'edit'){
      this.setState({modifierClasses:filtered, edited:true})
    }
  }

  uploadImage(cb, params={}){
    let url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/api/tablet/upload-img`
    const formData = new FormData();
		formData.append('file', this.fileInput.current.files[0]);
    if(this.props.page === 'edit'){
      formData.append('menu_item_id', this.state.item.menu_item_id)
    }

    let headers = {}
    if (window.IS_MEMBER_LOGIN){
      headers['X-CAPTAIN-MEMBER_LOGIN'] = 1 
      if(window.MEMBER_REST_ID){
        headers['X-CAPTAIN-RESTAURANT-ID'] = window.MEMBER_REST_ID
      }   
    }   

		formData.append('key', window.API_KEY) 
		fetch(
      url,
			{
				method: 'POST',
				body: formData,
        headers:headers
			}
		)
			.then((response) => response.json())
			.then((result) => {
        if(this.props.page === 'create'){
          params.image_url = result.image_url
          this.createItem(cb, params)
        } else {
          params.image_url = result.image_url
          this.saveEdits(cb, params)
        }
			})
			.catch((error) => {
        console.log("error", error)
        alert('Unknown error')
			});
  }

  readURI(e){
    if(e.target.files && e.target.files[0]){
      let reader = new FileReader();
      reader.onload = function(ev){
        this.setState({imageURI:ev.target.result, edited:true});
      }.bind(this);
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  handleFileUpload(e){
    this.readURI(e); // maybe call this with webworker or async library?
  }

  renderItem(){

    if(!this.loaded()) return <Loading/>
    let that = this
    return (
      <div className={classnames('edit-item-page', {'content':!this.state.isDesktop})}>

        {this.state.isDesktop !== true && (
          <>
            {this.props.page === 'edit' && (
              <div className='edit-item-title'>Edit Item</div>
            )}
            
            {this.props.page === 'create' && (
              <div className='edit-item-title'>Create Item</div>
            )}
          </>
        )}

        <div className='edit-item-field-wrapper'>
          <div className='edit-item-label'>Name*</div>
          <input 
            className='edit-item-input'
            value={this.state.name}
            onChange={(e)=>this.setState({name:e.target.value, edited:true})}
          />
        </div>

        <div className='edit-item-field-wrapper'>
          <div className='edit-item-label'>Price*</div>
          <input 
            type='number'
            className='edit-item-input'
            onChange={(e)=>this.setState({price:this.filterMoneyInput(e.target.value), edited:true})}
            value={this.state.price}
          />
        </div>

        <div className='edit-item-field-wrapper'>
          <div className='edit-item-label'>Menu Section*</div>
          <select 
            className='menu-section-select'
            onChange={(e)=>{
              this.setState({menu_category_id:e.target.value, edited:true})
            }}
            value={this.state.menu_category_id}
          >
            {this.props.page === 'create' && !this.preselectedCategory() && (
              <option value={null}>Select a Category</option>
            )}
            {this.state.sections.map(section => (
              <option 
                value={section.menu_category_id}
              >
                {section.name}
              </option>
            ))}
          </select> 
        </div>


        <div className='edit-item-field-wrapper'>
          <div className='edit-item-label'>Description</div>
          <textarea
            rows="5"
            className='edit-item-textarea'
            onChange={(e)=>this.setState({description:e.target.value, edited:true})}
            value={this.state.description}
          />
        </div>


        {this.state.isDesktop === true && (
          <div className='edit-item-field-wrapper'>
            <div className='edit-item-label'>Image</div>

            <input
              ref={this.fileInput}
              type="file"
              onChange={this.handleFileUpload.bind(this)}
            />
            {this.state.imageURI && (
              <img className='edit-item-image' src={this.state.imageURI} />
            )}
            {this.state.imageUrl && !this.state.imageURI && (
              <img className='edit-item-image' src={this.state.imageUrl} />
            )}
          </div>
        )}

        {this.props.page === 'create' && this.state.isDesktop !== true && (
          <>
            {this.filledOut() ? (
              <ButtonWithSpinner
                text='Create Item'
                className='edit-item-button'
                onClick={(cb)=>this.createItem(cb)}
                spinnerColor='white'
              />
            ) : (
              <div className='edit-item-button edit-item-button-disabled'>
                <div>Create Item</div>
              </div>
            )}
          </>
        )}

        {this.props.page === 'edit' && this.state.isDesktop !== true &&  (
          <>
            {this.state.edited ? (
              <ButtonWithSpinner
                text='Save Edits'
                className='edit-item-button'
                onClick={(cb)=>this.saveEdits(cb)}
                spinnerColor='white'
              />
            ) : (
              <div className='edit-item-button edit-item-button-disabled'>
                <div>Save Edits</div>
              </div>
            )}
          </>
        )}

     </div>
    )
  }

  getBreadcrumbName(){
    if(this.props.page === 'create') return 'Create Item'
    if(this.props.page === 'edit'){
      if(this.state.item) return this.state.item.name
    }
  }

  render(){
    if(this.state.isDesktop){
      let text = this.props.page == 'edit' ? 'Save Item' : 'Create Item'
      let modifierClasses = this.props.page === 'edit' ? 
        this.state.modifierClasses :
        this.state.newItemModifierClasses

      return (
        <>
          {this.state.confirmChangesBeforeModifierClassNavigation === true && (
            <AreYouSureModal 
              cancel={()=>this.setState({confirmChangesBeforeModifierClassNavigation:false})}
              onClick={(cb)=>{
                if(this.props.page === 'edit'){
                  let navigateFunction = () =>{
                    this.navigateToModifierClass(this.state.mc)
                  }
                  if(this.state.imageURI){
                    this.uploadImage(cb, {fromConfirmModal:true, navigateFunction:navigateFunction}) 
                  } else{
                    this.saveEdits(cb, {fromConfirmModal:true, navigateFunction:navigateFunction})
                  }
                } else if (this.props.page === 'create'){
                  let navigateFunction = true
                  if(this.state.imageURI){
                    this.uploadImage(cb, {fromConfirmModal:true, navigateFunction:navigateFunction}) 
                  } else {
                    this.createItem(cb, {fromConfirmModal:true, navigateFunction:navigateFunction} )
                  }
                }
              }}
            />
          )}

          {this.state.confirmChangesBeforeCreateModifierClassNavigation === true && (
            <AreYouSureModal 
              cancel={()=>this.setState({confirmChangesBeforeCreateModifierClassNavigation:false})}
              onClick={(cb)=>{
                if(this.props.page === 'edit'){
                  let navigateFunction = () =>{
                    this.navigateToCreateModifierClass(this.state.mc)
                  }
                  if(this.state.imageURI){
                    this.uploadImage(cb, {fromConfirmModal:true, navigateFunction:navigateFunction}) 
                  } else{
                    this.saveEdits(cb, {fromConfirmModal:true, navigateFunction:navigateFunction})
                  }
                } else if (this.props.page === 'create'){
                  let navigateFunction = true
                  if(this.state.imageURI){
                    this.uploadImage(cb, {fromConfirmModal:true, navigateFunction:navigateFunction}) 
                  } else {
                    this.createItem(cb, {fromConfirmModal:true, navigateFunction:navigateFunction} )
                  }
                }
              }}
            />
          )}




          <div className='desktop-menu-item-page-container'>
            <DesktopSidebar 
              page='menu-items'
              menu_uuid={this.menu_uuid}
            />
            <div className='dekstop-menu-item-content-container' style={{paddingTop:'50px'}}>
              <Breadcrumbs
                crumbs={[
                  {text:'Menus', path:'/menu/menus'},
                  {text:'Items', path:'/menu/menu/'+this.menu_uuid}
                ]}
                current={this.getBreadcrumbName()}
              />

              <div className='desktop-menu-item-header-row'>
                {this.props.page === 'edit' && (
                  <div className='desktop-menu-item-title'>Edit Item</div>
                )}
                {this.props.page === 'create' && (
                  <div className='desktop-menu-item-title'>Create Item</div>
                )}
                <div className='desktop-menu-item-buttons-row'>
                  {this.readyToSave() ? (
                    <ButtonWithSpinner
                      style={{marginRight:'15px'}}
                      text={text}
                      svg={<FloppyDisk/>}
                      svgLeft={true}
                      className='white-text-desktop-button'
                      onClick={(cb)=>{
                        if(this.props.page === 'create') {
                          if(this.state.imageURI){
                            this.uploadImage(cb) 
                          } else {
                            this.createItem(cb)
                          }
                        } else {
                          if(this.state.imageURI){
                            this.uploadImage(cb) 
                          } else{
                            this.saveEdits(cb)
                          }
                        }
                      }}
                      spinnerColor='white'
                    />
                  ) : (
                  <div 
                    className='white-text-desktop-button disabled-button-opaque'
                    style={{marginRight:'15px'}}
                  >
                    <FloppyDisk />
                    <div>{text}</div>
                  </div>

                  )}
                

                  <div 
                    className='blue-text-desktop-button'
                    onClick={()=>{
                      this.props.history.replace({
                        pathname:'/menu/menu/'+this.menu_uuid
                      })
                    }}
                  >
                    <div>Cancel</div>
                  </div>
                
                </div>



              </div>
              <div className='desktop-menu-item-bottom-content'>
                <div className='desktop-menu-item-content-inner-container'>
                  {this.renderItem()}
                </div>
                <div className='desktop-menu-item-content-modifier-groups'>
                  <div className='desktop-menu-item-modifier-groups-title'>
                    Modifier Groups
                  </div>
                  
                  

                  <div className='menu-item-edit-modifier-button-container'>

                    <div 
                      className='desktop-menu-item-add-modifier-group menu-item-edit-modifier-button'
                      onClick={()=>{
                        if(this.props.page === 'create'){
                          this.props.history.push({
                            pathname:this.props.location.pathname+'/modifier-group-new-item-modal',
                            state:{
                              menu_uuid:this.props.match.params.menu_uuid
                            }
                          })
                        } else if (this.props.page === 'edit') {
                          this.props.history.push({
                            pathname:this.props.location.pathname+'/modifier-group-edit-item-desktop-modal',
                            state:{
                              item:this.state.item
                            }
                          })
                        }
                      }}
                    >
                      <AttachTag />
                      <div>Attach Existing</div>
                    </div>

                    <div 
                      className='desktop-menu-item-add-modifier-group menu-item-edit-modifier-button'
                      style={{marginLeft:'30px'}}
                      onClick={()=>{
                        if(this.props.page === 'edit'){
                          if(this.state.edited){
                            this.setState({confirmChangesBeforeCreateModifierClassNavigation:true})
                          } else {
                            this.navigateToCreateModifierClass()
                          }
                        } else if (this.props.page === 'create'){
                            if(!this.filledOut()){
                              alert('Please make sure name, price and section are filled out')
                              return
                            }
                            this.setState({confirmChangesBeforeCreateModifierClassNavigation:true})
                          /*
                          this.props.history.push({
                            pathname:'/menu/modifier-group-from-new-item/create/'+this.menu_uuid,
                            state:{
                              unsavedState:JSON.stringify(this.state)
                            }
                          })*/
                        }
                      }}
                    >
                      <Plus />
                      <div>Create New</div>
                    </div>
                  </div>

                  <div className='desktop-menu-item-modifier-classes-container'>
                      {modifierClasses.length > 0 && (
                        <DesktopModifierGroupsContainer
                          clickModifierClassBox={this.clickModifierClassBox.bind(this)}
                          key={modifierClasses.length}
                          menu_uuid={this.menu_uuid}
                          parent={this}
                          modifierClasses={modifierClasses}
                          disableEdits={this.state.disableEdits}
                        />
                      )}

                  </div>

                </div>
              </div>
            </div>


          </div>
        </>
      )
    } else{
      return this.renderItem()
    }
  }
}
      

export default withRouterAndRef(ModifierModalContextHOC(ToastContextHOC(UserContextHOC(MenuItemEdit)))) 
