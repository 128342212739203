import React, { Component } from "react"
import classnames from "classnames"
import withRouterAndRef  from "../withRouterAndRef"
import "./CardCryptoSelector.css"
import QRCode from "react-qr-code";

const Api = require("../api")
const DRINK_TICKET = 'EGgfWx3FaELycMCwsv5tYxRwm5Tdts8avvwqabHy7kSF'
const FOODCOIN = 'B7vkWNFo2aYcYddtwR8fvDwY1Uc54wHfdTXULjrX7KeL'
const SWAG = '6MPqywS46dCoVUz8yHKo8syV7APUscHVDgGfXAvg4bc'
const PRIZE = '3rxAkxXsL8QBcbXxdtFmp3yvo6YfALXfCLXgJpRLiYBw'

class CardCryptoSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showQR:false
    }
    window.pp = this
    this.handleEnterKey = this.handleEnterKey.bind(this)
  }


  componentDidMount(){
    this.startQRSession()
    window.addEventListener("keydown", this.handleEnterKey)
  }

  componentWillUnmount(){
    window.addEventListener("keydown", this.handleEnterKey)
  }


  handleEnterKey(e) {
    if(e.key === 'Enter'){
      window.document.activeElement.blur()
    } 
  }


  refresh(){
    this.setState({
      qr_uuid:null,
      amount:null,
      foodcoin:'',
      item:null,
      paid:false,
      showQR:false
    })
    this.clicked = false
  }

  startQRSession(mint, amount){
    Api.callApi({
      endpoint:'/api/start-qr-session',
      data:{
        mint:mint,
        amount:amount
      },
      method:'post',
      success:(data)=>{
        this.setState({qr_uuid:data.uuid}, ()=>this.poll())
      },
      failure:(err)=>{console.log(err)}
    })
  }
  

  poll(){
    if(!this.state.qr_uuid) return
    Api.callApi({
      endpoint:'/api/qr-session-check-txn-drinks',
      method:'post',
      success:(resp)=>{
        if(resp.success){
          this.clicked = false
          this.setState({paid:true})
        } else{
          setTimeout(()=>this.poll(), 5000)
        }
      },
      failure:(err)=>{
        setTimeout(()=>this.poll(), 5000)
      },
      data:{uuid:this.state.qr_uuid}
    })
  }
  
  
  renderQR(){
    if(!this.state.showQR) return null
    if(this.state.paid){
      return (
        <div className='qr-container'>
          <div className='food-paid'> 
            PAID!!!
          </div>
        </div>
      )
    }

    let url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/supperclub/pay-drink-ticket?uuid=${this.state.qr_uuid}`
    url = 'solana:' + encodeURIComponent(url)


    return (
      <div className='qr-container'> 
        {this.state.qr_uuid ? (
          <>
            <div style={{marginBottom:'15px', fontWeight:900, fontSize:'60px'}}>{this.state.amount} {this.state.item}</div>
            <QRCode size='250' value={url} />
          </>
        ) : (
          <div className='qr-container'> 
            <img style={{width:'100px', height:'100px'}} src='https://cdn.hngr.co/supperclub/roller2.gif'/>
          </div>
        )}
      </div>
    )
  }

  render(){
    return (
      <div className='' style={{flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
        <div>
          <div 
            className='foodfight-row' 
            onClick={()=>this.refresh()}
          > 
            <div style={{fontWeight:500, color:'blue'}}>REFRESH</div>
          </div>
          
          <div 
            className={classnames('foodfight-row', {'foodfight-row-disabled':this.state.showQR})} 
            onClick={()=>{
              if(this.clicked) return null
              this.clicked = true
              this.startQRSession(DRINK_TICKET, 1)
              this.setState({item:'Drink Ticket', amount:1, showQR:true})
            }}
          >
            <div>DRINK TICKET</div>
          </div>

          <div 
            className={classnames('foodfight-row', {'foodfight-row-disabled':this.state.showQR})} 
         >
            <input
              type='number'
              value={this.state.foodcoin}
              onChange={(e)=>this.setState({foodcoin:e.target.value})}
            />
            <div
              onClick={()=>{
                if(this.clicked) return null
                if(!this.state.foodcoin){
                  alert("enter a foodcoin integer amount")
                  return
                }
                this.clicked = true
                this.startQRSession(FOODCOIN, this.state.foodcoin*100)
                this.setState({item:'FOODCOIN', amount:this.state.foodcoin, showQR:true})
              }}
            >
              FOODCOIN
            </div>

          </div>

          <div 
            className={classnames('foodfight-row', {'foodfight-row-disabled':this.state.showQR})} 
            onClick={()=>{
              if(this.clicked) return null
              this.clicked = true
              this.startQRSession(SWAG, 1)
              this.setState({item:'Swag Item', amount:1, showQR:true})
            }}
          >
            <div>SWAG ITEM</div>
          </div>
          <div 
            className={classnames('foodfight-row', {'foodfight-row-disabled':this.state.showQR})} 
            onClick={()=>{
              if(this.clicked) return null
              this.clicked = true
              this.startQRSession(PRIZE, 1)
              this.setState({item:'GRAND PRIZE!!!', amount:1, showQR:true})
            }}
          >
            <div>GRAND PRIZE</div>
          </div>





        </div>
        
        {this.renderQR()}

      </div>
    )
  }

}
      
export default withRouterAndRef(CardCryptoSelector)


