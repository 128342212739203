import React, { Component } from "react"
import Loading from "../Loading/Loading"
import Modal from "../Modal/Modal"

const API = require("../api")

class Transfer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      rest: {},
      apiKey: localStorage.getItem("api_key"),
    }
    this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this)
  }

  componentDidMount() {
    this.loadTransfer()
    this.props.loadRest().then((rest) => this.setState({ rest: rest }))
  }

  async loadTransfer() {
    let rest = await this.props.loadRest()
    API.call(
      "transfer",
      (res) => {
        this.setState({ rows: res.rows })
      },
      (err) => {
        alert("Error loading transfers")
      },
      null,
      { transfer_id: this.props.transfer_id, rest_id: rest.restaurant_id }
    )
  }

  async downloadSpreadsheet() {
    let rest = await this.props.loadRest()
    API.call(
      "transfer_csv",
      (res) => {
        console.log(res)
      },
      (err) => {
        alert("Error Downloading Spreadsheet")
      },
      {
        transfer_id: this.props.transfer_id,
        rest_id: rest.restaurant_id,
        csv: 1,
      }
    )
  }

  render() {
    if (this.state.rows.length) {
      return (
        <Modal
          close={() => this.props.closeExpansion()}
          style={
            window.innerWidth > 800
              ? { maxWidth: "64%", padding: "16px" }
              : { maxWidth: "calc(92% - 32px)", padding: "16px" }
          }
        >
          <form
            method="POST"
            action={`${process.env.REACT_APP_FOODCOIN_API_LOCATION}/member/transfer`}
          >
            <input type="hidden" name="csv" value="1" />
            <input
              type="hidden"
              name="transfer_id"
              value={this.props.transfer_id}
            />
            <input
              type="hidden"
              name="rest_id"
              value={this.state.rest.restaurant_id}
            />
            <input
              type="hidden"
              name="X-HNGR-API-KEY"
              value={this.state.apiKey}
            />
            <button className="button mv8">EXPORT</button>
          </form>
          <div
            style={{ width: "100%", overflowX: "auto", paddingBottom: "32px" }}
          >
            <table>
              <thead>
                <tr>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Date
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Type
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Description
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Restaurant
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Order ID
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Payment Type
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Product Total
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Promo
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Subtotal
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Delivery Fee
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Tax
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Tip
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Total
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    HNGR Fee
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    CC Processing Fee
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Net
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Diner Name
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Diner Email
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.rows.map((r, i) => (
                  <tr key={i}>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.datetime}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.type}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.description}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.restaurant}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.order_id}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.payment_type}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.product_total}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.promo}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.subtotal}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.delivery_total}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.tax}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.tip}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.total}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.hngr_fee}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.cc_processing_fee}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.net}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.diner_name}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.diner_email}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      )
    } else return <Loading />
  }
}

export default Transfer
