import React, { Component } from "react"
import DesktopSidebar from "./DesktopSidebar"
import UserContextHOC from "../contexts/UserContextHOC"
import ToastContextHOC from "../contexts/ToastContextHOC"
import MenuItemBox from "./MenuItemBox"
import Breadcrumbs from "./Breadcrumbs"
import withRouterAndRef from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import OrderToast from "../orders/OrderToast"
import ButtonWithSpinner from "../orders/ButtonWithSpinner"
import SearchIcon from "../orders/svg/Search"
import ModifierClassBox from "./ModifierClassBox"
import "./Search.css"

const Api = require("../api")
const Money =  require("../helpers/money")
const Timestamp = require("../helpers/timestamps")

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDesktop:window.innerWidth >= 1000,
      modifiers:[],
      items:[],
      modifierClasses:[]
    }
    this.inputRef = React.createRef()
  }

  componentDidMount(){
    this.inputRef.current.focus()
    this.getCentralTimestamp()
  }

  componentDidUpdate(prevProps){
    if(
      JSON.stringify(this.props.toast.toast) !== JSON.stringify(prevProps.toast.toast) &&
      this.props.toast.toast
    ){
      this.addToast(this.props.toast.toast)
      this.input(this.state.query)
    }
  }

  getCentralTimestamp(){
    Api.callApi({
      endpoint:'central-timestamp',
      success:(data)=>{
        this.setState({
          centralTimestamp:data.ts,
          tomorrow5am:data.tomorrow_5am,
        })
      }
    })
  }

  addToast(toast){
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null})
    }
  }

  input(query){
    if(query === ''){
      this.setState({
        items:[],
        loading:false,
        modifierClasses:[],
        modifiers:[],
        loading:false,
        query:''
      })
      return
    }

    this.query_id = Math.random()

    this.setState({query:query, loading_query:query, loading:true})
    Api.callApi({
      endpoint:'/api/tablet/menu-search',
      method:'post',
      data:{
        query:query,
        query_id:this.query_id
      },
      success:(data)=>{
        if(data.query_id !== this.query_id) return

        // see if item is blacked out
        for(let i in data.items){
          let item = data.items[i]
          if(
              item.blackout_until &&
              item.blackout_until_timestamp > this.state.centralTimestamp
            ) {
              item.blacked_out = true
              item.blackout_until_local_ts = item.blackout_until_timestamp - this.props.user.rest.central_offset_seconds
          }         
        }

        for(let i in data.modifiers){
          let item = data.modifiers[i]
          if(
              item.blackout_until &&
              item.blackout_until_timestamp > this.state.centralTimestamp
            ) {
              item.blacked_out = true
              item.blackout_until_local_ts = item.blackout_until_timestamp - this.props.user.rest.central_offset_seconds
          }         
        }


        this.setState({
          modifiers:data.modifiers,
          items:data.items,
          modifierClasses:data.modifier_classes,
          loading:false,
        })
      },
      failure:(err)=>{
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error searching menus.'
          }
        })
      }
    })

  }

  numResults(){
      return this.state.items.length +
        this.state.modifiers.length +
        this.state.modifierClasses.length
  }

  noMatches(){
    if(
      this.state.query &&
      this.state.modifiers.length === 0 &&
      this.state.items.length === 0 &&
      this.state.modifierClasses.length === 0 
    ){
      return true
    } else{
      return false
    }
  }

    
  renderContent(){
    let that = this
    return (
      <div 
        className='content'
        style={this.state.isDesktop ? {paddingLeft:'35px', paddingTop:'15px', width:'700px'} : null}
      >

        <div className='search-bar-container'>
          <SearchIcon className='search-bar-icon'/>
          <input 
            ref={this.inputRef}
            value={this.state.query}
            onChange={(e)=>this.input(e.target.value)}
            placeholder='Search Items, Modifiers and Modifier Groups'
            className='search-bar-input-search-page search-bar-input'
          />
          <div 
            className='search-cancel'
            onClick={()=>this.props.history.goBack()}
          >
            Cancel
          </div>
        </div>

        {!this.state.query && (
          <div className='search-what-you-looking-for'>
            <div className='search-what-you-looking-for-header'>
              What are you looking for?
            </div>
            <div className='search-what-you-looking-for-text'>
              Search for menu items, modifiers or modifier groups 
            </div>
          </div>
        )}
        

        {this.state.loading ? (
          <Loading />
        ): (
          <>

            {this.noMatches() && (
              <div className='search-what-you-looking-for input-error'>
                <div className='search-what-you-looking-for-header'>
                  No Items, Modifiers or Modifier Groups found 
                </div>
                <div className='search-what-you-looking-for-text'>
                  We coundn’t find what you were looking for
                </div>
              </div>
            )}

            {this.numResults() !==0 && (
              <>
                <div className='menu-search-num-results'>
                  {this.numResults()} results found
                </div>
              </>
            )}

            {this.state.items.length !== 0 && (
              <div className='menu-search-result-section'>
                <div className='menu-search-result-section-title'>Menu Items</div>

                {this.state.items.map(item => (
                  <MenuItemBox 
                    onClick={()=>{
                      this.props.history.push({
                        pathname:'/menu/menu-item-edit/'+item.menu_item_id
                      })
                    }}
                    clickable={item.external_source !== 'CHOWLY' && item.external_source !== 'SQUARE'}
                    noArchive={!(item.external_source !== 'CHOWLY' && item.external_source !== 'SQUARE')}
                    menu_uuid={this.menu_uuid} 
                    parent={that} 
                    item={item} 
                    tab='wat'
                  />
                ))}

              </div>
            )}
            {this.state.modifiers.length !== 0 && (
              <div className='menu-search-result-section'>
                <div className='menu-search-result-section-title'>Modifiers</div>

                {this.state.modifiers.map(modifier => (
                  <MenuItemBox 
                    isModifier={true}
                    clickable={modifier.external_source !== 'CHOWLY' && modifier.external_source !== 'SQUARE'}
                    noArchive={!(modifier.external_source !== 'CHOWLY' && modifier.external_source !== 'SQUARE')}
                    /*priceOverride={this.state.modifierClass.price_override}*/
                    clickable={false} 
                    isSearchResult={true}
                    modifierClassesTextList={modifier.modifier_classes ? modifier.modifier_classes : []}
                    item={modifier} 
                    /*modifierClasses={modifier.modifier_classes}*/
                    modifierClasses={null}
                  /> 
                ))}
              </div>
            )}
      
             {this.state.modifierClasses.length !== 0 && (
              <div className='menu-search-result-section'>
                <div className='menu-search-result-section-title'>Modifier Groups</div>

                {this.state.modifierClasses.map(mc=> (
                  <ModifierClassBox 
                    mc={mc} 
                    page='search'
                    key={mc.modifier_class_id} 
                    disableEdits={true}
                    /*item={this.state.item}*/
                  /> 
                ))}
              </div>
            )}
       
          
          
          
          </>
        )}


        
     </div>
    )
  }

  render(){
    if(this.state.isDesktop){
      let breadcrumbs = (
        <Breadcrumbs 
          hideSearch={true}
          crumbs={[
            {text:'Menus',path:'/menu/menus'},
          ]}
          current='Search'
        />  
      )
      console.log("TOAST", this.state.toast)
      return (
        <div className='desktop-menu-item-page-container'>
          {this.state.toast && (
            <OrderToast
              toast={this.state.toast}
              message={this.state.toast.item ? this.state.toast.item.name : null}
              type={this.state.toast.type}
            />
          )}

          <DesktopSidebar 
          />
          <div className='desktop-content-with-crumbs'>
            {breadcrumbs}
            {this.renderContent()}
          </div>
        </div>
      )
    } else {
      return this.renderContent()
    }
  }
}
      

export default withRouterAndRef(ToastContextHOC(UserContextHOC(Search))) 
