import React, { Component } from "react"
import NewOrdersContextHOC from "./contexts/NewOrdersContextHOC"
import ChefHat from "./orders/svg/ChefHat"
import withRouterAndRef  from "./withRouterAndRef"
import classnames from 'classnames'
import "./Footer.css"
import {Capacitor} from '@capacitor/core';

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pathname:this.props.location.pathname,
      unconfirmedOrders:[],
      isDesktop:window.innerWidth > 1000,
    }
  }

  componentDidUpdate(){
    if(this.state.pathname !== this.props.location.pathname){
      this.setState({pathname:this.props.location.pathname})
    }
    
    if(
      this.state.unconfirmedOrders !== 
      this.props.unconfirmedOrders.unconfirmedOrders
    ){
      this.setState({unconfirmedOrders:this.props.unconfirmedOrders.unconfirmedOrders})
    }
  }

  render(){
    if(this.state.isDesktop) return null

    if(this.state.pathname.indexOf('login') > -1){
      return null
    }

    return (
      <div className="fixed-footer">
        
        <div className='fixed-footer-top'>
          
          <div 
            className={classnames('fixed-footer-section',
              {'fixed-footer-section-selected':this.state.pathname.indexOf('orders') > -1})}
            onClick={()=>this.props.history.push({pathname:'/orders/kitchen'})}
          >
            <List/>
            <div className='fixed-footer-section-label'>Orders</div>
            {this.state.unconfirmedOrders.length > 0  && (
              <div className='footer-order-alert'>
                <div className='white' style={{color: '#FCFCFC'}}>{this.state.unconfirmedOrders.length}</div>
              </div>
            )}
          </div>

          <div 
            className={classnames('fixed-footer-section',
              {'fixed-footer-section-selected':this.state.pathname.indexOf('menu') > -1})}
            onClick={()=>this.props.history.push({pathname:'/menu/menus'})}
          >
            <ChefHat/>
            <div className='fixed-footer-section-label'>Menu</div>
          </div>

          <div 
            className={classnames('fixed-footer-section',
              {'fixed-footer-section-selected':this.state.pathname.indexOf('settings') > -1})}
            onClick={()=>this.props.history.push({pathname:'/settings'})}
          >
            <Wrench/>
            <div className='fixed-footer-section-label'>Manage</div>
          </div>

        </div>

        {Capacitor.getPlatform() === 'ios' && (  
          <div className='fixed-footer-bottom'>
          </div>
        )}

      </div>
    )
          
  }
}
      

export default NewOrdersContextHOC(withRouterAndRef(Footer)); 


const List = props => (
  <svg {...props} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M9.484 15.696l-.711-.696-2.552 2.607-1.539-1.452-.698.709 2.25 2.136 3.25-3.304zm0-5l-.711-.696-2.552 2.607-1.539-1.452-.698.709 2.25 2.136 3.25-3.304zm0-5l-.711-.696-2.552 2.607-1.539-1.452-.698.709 2.25 2.136 3.25-3.304zm10.516 11.304h-8v1h8v-1zm0-5h-8v1h8v-1zm0-5h-8v1h8v-1zm4-5h-24v20h24v-20zm-1 19h-22v-18h22v18z"/></svg>
)

const Wrench = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.272 24c-.993 0-1.927-.387-2.629-1.089l-8.858-8.858c-.397-.397-.963-.61-1.522-.563-1.969.152-3.904-.56-5.303-1.96-1.519-1.52-1.96-3.282-1.96-4.806 0-1.649.517-3.018.743-3.496l4.389 4.389c.558-.044 1.329-.54 1.87-1.21.355-.44.575-.909.605-1.267l-4.39-4.39c.992-.422 2.344-.75 3.54-.75 1.801 0 3.494.699 4.765 1.969 1.397 1.399 2.111 3.332 1.958 5.304-.043.565.162 1.12.563 1.521l8.859 8.859c.732.733 1.098 1.681 1.098 2.63 0 .934-.355 1.869-1.07 2.599-.705.721-1.649 1.118-2.658 1.118zm-12.859-12.514c1.036 0 2.051.421 2.785 1.154l8.858 8.858c.656.656 1.799.649 2.447-.013.326-.333.5-.774.49-1.241-.01-.444-.189-.864-.504-1.179l-8.858-8.858c-.814-.812-1.23-1.938-1.143-3.087.108-1.391-.395-2.752-1.379-3.737-.759-.759-1.733-1.229-2.786-1.353l2.163 2.163c.231 1.065.137 2.147-.929 3.468-1.105 1.37-2.59 2.222-4.373 1.833l-2.162-2.162c.125 1.039.589 2.023 1.352 2.786.985.986 2.347 1.491 3.736 1.38l.303-.012zm13.746 8.764c0 .507-.41.917-.917.917-.506 0-.917-.411-.917-.917s.41-.917.917-.917c.507.001.917.411.917.917z"/></svg>
)

