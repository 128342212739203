import React, { Component } from "react"
import ToastContextHOC from "../contexts/ToastContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import SearchIcon from "../orders/svg/Search"
import "./Menu.css"
import "./ModalSearch.css"

const Api = require("../api")
const Money =  require("../helpers/money")
const Timestamp = require("../helpers/timestamps")

class ModifierGroupModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      closing:false,
      modifierClasses:[],
      modifierClassesFiltered:[],
    }
  }

  componentDidMount(){
    window.addEventListener('mousedown', this.handleClickOutside)
    document.body.style.overflow = "hidden"
    this.loadModifierGroups()
  }
  componentWillUnmount(){
    window.removeEventListener('mousedown', this.handleClickOutside)
    document.body.style.overflow = "auto"
  }

  loadModifierGroups(){
    Api.callApi({
      endpoint:'/api/menu/modifier-groups-from-item/'+ this.props.history.location.state.item.menu_item_id,
      success:(data)=>{
        console.log("menu", data)
        this.setState({
          modifierClasses:data.modifier_classes,
          modifierClassesFiltered:data.modifier_classes
        })
      },
      failure:(err)=>{
        console.log(err)
        alert('Unknown error')
      }
    })
  }

  close() {
    let that = this
    this.setState({ closing: true }, () => {
      setTimeout(() => {
        this.props.history.goBack()
      }, 200)
    })
  }


  maybeClose(e){
    if (e.target.className === "menu-modal-background"){
      this.close()
    }
  }

  search(query){
    query = query.toLowerCase()
    let mcs = this.state.modifierClasses.filter((mc)=>{
      if(mc.internal_name){
        let internalName = mc.internal_name.toLowerCase()
        if(internalName.indexOf(query) > -1) return true
      }
      let displayText = mc.display_text.toLowerCase()
      if(displayText.indexOf(query) > -1) return true
      else return false
    })
    this.setState({modifierClassesFiltered:mcs})
  }

  render(){
    return (
      <div 
        className='menu-modal-background'
        onClick={(e)=>this.maybeClose(e)}
        id={this.state.closing ? "modal-background-exit" : null}
      >
        <div className='menu-modal-container'>
          <div className='menu-modal-title'>Modifier Groups</div>
          <div className='search-bar-container'>
            <SearchIcon className='search-bar-icon'/>
            <input
              ref={this.inputRef}
              value={this.state.query}
              onChange={(e)=>this.search(e.target.value)}
              placeholder='Search'
              style={{width:'100%'}}
              className='search-bar-input-search-page search-bar-input'
            />
          </div>

          <div className='menu-modal-items-container'>
            {this.state.modifierClassesFiltered.map(mc => (
              <div
                onClick={()=>{
                  this.props.history.push({
                    pathname:'/menu/modifier-class/'+mc.modifier_class_id
                  })
                }}
                className={classnames('menu-item-box',
                  {'menu-item-box-with-internal-name':mc.internal_name})}

              >
                {mc.internal_name && (
                  <div className='menu-box-internal-name'>
                    {mc.internal_name}
                  </div>
                )}
                <div className='menu-box-title'>{mc.display_text}</div>
                <div className='flexstart'>
                  <div className='modifier-class-options-circle'>
                    <div>{mc.modifier_count} options</div>
                  </div>
                  {mc.deactivated_count > 0 && (
                    <div className='modifier-class-options-circle-deactivated'>
                      <div>{mc.deactivated_count} deactivated </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>


        </div>
      </div>
    )
    
  }
}
      

export default withRouterAndRef(ModifierGroupModal) 


