import React from "react"

const DragAndDrop = (props) => {
  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    props.handleDragEnter(e)
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    props.handleDragLeave(e)
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    props.handleDragOver(e)
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    props.handleDrop(e)
  }
  return (
    <div
      className={"drag-drop-zone"}
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
    >
      {props.children}
    </div>
  )
}

DragAndDrop.defaultProps = {
  handleDragLeave: () => {},
  handleDragEnter: () => {},
  handleDragOver: () => {},
  handleDrop: () => {},
}
export default DragAndDrop
