import React, { Component } from "react"
import classnames from "classnames"
import UserContextHOC from "../contexts/UserContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import { Capacitor, Plugins } from '@capacitor/core'
import Loading from "../Loading/Loading"
import "./CreateCharge.css"
import CheckCircle from "../orders/svg/CheckCircleBig"
import Warning from "../orders/svg/Warning"
import CreditCard from "../orders/svg/CreditCard"
import MasterCard from "../orders/svg/MasterCard"
import Visa from "../orders/svg/Visa"
import Discover from "../orders/svg/Discover"
import Amex from "../orders/svg/Amex"
import Printer from "../orders/svg/Printer"
import LoadingSvg from "../orders/svg/Loading"
import CardCryptoSelector from "./CardCryptoSelector"
import {
  ElementsConsumer, 
  Elements, 
  PaymentElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const Api = require("../api")
const {StripeReaderPlugin, PrintReceiptPlugin} = Plugins;
const Money =  require("../helpers/money")
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


class CreateCharge extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:false,
      count:0,
    }
    this.listenForFinishedPrints = this.listenForFinishedPrints.bind(this)
    this.handleEnterKey = this.handleEnterKey.bind(this)
  }

  componentDidMount(){
    this.listenForReaderReady()
    window.addEventListener('receiptPrinted', this.listenForFinishedPrints)
    window.addEventListener("keydown", this.handleEnterKey)
  }

  componentWillUnmount(){
    window.removeEventListener("receiptPrinted", this.listenForFinishedPrints)
    window.removeEventListener("keydown", this.handleEnterKey)
  }
  
  count(){
    let count = this.state.count+1
    this.setState({count:count})
    if(count === 5){
      this.props.history.push("/stripe-pos/foodfight")
    }
  }




  handleEnterKey(e) {
    let that = this
    if(e.key === 'Enter'){
      window.document.activeElement.blur()
      this.startCollectPayment().catch(function(e) {
        that.postToSlack("Error collecting payment - " + e.message)
        alert('Unknown error occurred')
      })
    }
  }


  listenForFinishedPrints(data){
    if(data.unique_key == this.state.order_id){
      this.setState({printLoading:false})
    }
  }

  listenForReaderReady(){
    let that = this
    StripeReaderPlugin.addListener('reader_ready', (info: any) => {
      this.setState({
        loading:false,
        page:'pay'
      })
    });
  }

  reload(){
    window.location.reload()
  }

  filterInput(val){
    if(val){
      if(val.substr(0,1) === '.'){
        val = '0'+val
      }
      return val.match(/^-?\d+(?:\.\d{0,2})?/)[0]
    } else {
      return val
    }
  }

  scrollToBottom(){
    if(this.bottomRef && this.bottomRef.current){
      this.bottomRef.current.scrollIntoView()
    }
  }

  onFocus(){
    if(window.innerWidth < 600){
      setTimeout(()=>this.scrollToBottom(), 300)
      setTimeout(()=>this.scrollToBottom(), 500)
      setTimeout(()=>this.scrollToBottom(), 700)
      setTimeout(()=>this.scrollToBottom(), 1000)
    }
  }


  taxRate(){
    if(this.props.user.rest){ 
      let tax_rate = this.props.user.rest.tax_rate
      tax_rate = tax_rate * 100
      return '(' + tax_rate.toString() + '%' + ')'
    }
  }

  amount(){
    let amount = parseFloat(this.state.amount)
    if (!amount) return 0
    return amount * 100
  }

  printReceipt(){
    if(this.state.printLoading) return

    if(!this.props.user.printer || ! this.props.user.printer.ipAddress){
      alert('Printer is not set up')
      return
    }

    if(PrintReceiptPlugin){
      this.setState({printLoading:true})
      PrintReceiptPlugin.printInPersonReceipt({
        order_id:this.state.order_id.toString(),
        api_key:window.API_KEY,
        unique_key:this.state.order_id.toString(),
        ip_address:this.props.user.printer.ipAddress
      })
    }
  }

  // we need to see if they have a reader saved and if it is connected
 async startCollectPayment(){
    if(!this.amount()){
      this.postToSlack(`Clicked user terminal but did not enter an amount. 
        This.amount(): ${this.amount()}
        This.state.amount: ${this.state.amount}`)
      alert('Please enter an amount!')
      return
    }
    if(!this.props.user.stripeTerminal){
      alert('You must set up a terminal first. Click "Manage" and then "Stripe Terminal Settings".')
      return
    }

    // check to see if their terminal is connected, if not, try to connect
    this.setState({waitingForConnection:true})
    this.postToSlack('Trying to connect to reader to initial payment collection')
    let ret = await StripeReaderPlugin.isConnected()
    let connected = ret['connected']
    if(connected == 1){
        this.postToSlack('Reader Connected.')
        this.setState({waitingForConnection:false})
        this.getPaymentIntent()
    } else{
      //this.postToSlack('Reader not connected. Trying to connect to ' + this.props.user.stripeTerminal)
      this.postToSlack('Reader not connected - calling discover readers, waiting five seconds, then trying again.')
      await StripeReaderPlugin.discoverReaders()
      setTimeout(async () =>{
        let result = await StripeReaderPlugin.connectToReader({name:this.props.user.stripeTerminal})
        if (result['success'] == 1){ 
          this.postToSlack('Reader connected on second try!')
          this.setState({waitingForConnection:false})
          this.getPaymentIntent()
        } else {
          this.postToSlack(`Second connection attempt failed (${result.reason}). Alerting user`)
          alert("Could not connect to terminal. Please make sure bluetooth is enabled on the tablet and that the terminal is on")
          this.setState({waitingForConnection:false})
          // get readers, wait ten seconds and try again
        }
      }, 5000)
        
    }
  }

  completePayment(payment_intent_id){
    this.setState({page:'processing'})
    Api.callApi({
      endpoint:'/stripe-pos/capture-charge',
      method:'post',
      data:{
        payment_intent_id:payment_intent_id,
        is_terminal:true
      },
      success:(data)=>{
        this.setState({
          page:'paid',
          cc_brand:data.cc_brand,
          last4:data.last4,
          order_id:data.order_id,
          name:data.name
        })
      },
      failure:(err)=>{
        this.setState({
          page:'error',
          error:'Unknown error completing payment. Any charges are automatically refunded.'
        })
        this.postToSlack(":pager: 500 error completing terminal payment. Check Sentry")
      }
    })
  }

  completePaymentManual(){
    this.setState({loading:true})
    Api.callApi({
      endpoint:'/stripe-pos/capture-charge',
      method:'post',
      data:{
        payment_intent_id:this.state.payment_intent_id,
        is_manual_enter:true
      },
      success:(data)=>{
        this.setState({
          page:'paid',
          loading:false,
          cc_brand:data.cc_brand,
          last4:data.last4,
          order_id:data.order_id,
          name:data.name
        })
      },
      failure:(err)=>{
        this.setState({
          page:'error',
          loading:false,
          error:'Unknown error completing payment. Any charges are automatically refunded.'
        })
        this.postToSlack(":credit_card: 500 error completing credit card payment. Check Sentry")
      }
    })
  }


  getPaymentIntent(){
    this.setState({page:'waiting_for_reader'})
    let that = this
    Api.callApi({
      endpoint:'/stripe-pos/create-payment-intent',
      method:'post',
      data:{
        total:this.amount(),
      },
      success:(data)=>{
        StripeReaderPlugin.collectPayment({intent_secret:data.client_secret})
          .then(ret => {
            if(ret['success'] == 1 ){
              this.completePayment(data.payment_intent_id)
              /*
              this.setState({
                page:'paid'
              }) 
              */
            } else {
              this.setState({
                page:'error',
                error:ret['message']
              })
              this.postToSlack(":pager:Error while collecting terminal payment - " + ret['message'])
            }
          })
          .catch(err => {
            that.postToSlack("Error in collectPayment - " + err.message)
            alert("Unknown Error")
            this.reload()
          })
      },
      failure:(err)=>{
        this.setState({
          page:'error',
          error:'Unkown error. Any charges on card will automatically be dropped off'
        })
      }
    })
  }


  getPaymentIntentManual(){
    if(!this.amount()){
      alert('Please enter an amount!')
      return
    }

    this.setState({loading:true})
    Api.callApi({
      endpoint:'/stripe-pos/create-payment-intent-manual-enter',
      method:'post',
      data:{
        total:this.amount(),
      },
      success:(data)=>{
        this.setState({
          page:'collect_payment_info',
          loading:false,
          intent_secret:data.client_secret,
          payment_intent_id:data.payment_intent_id
        })
      },
      failure:(err)=>{
        console.log("error", err)
        alert('Error')
      }
    })
  }


  getCreditCardLogo(brand){
    if(brand === 'visa') return <Visa/>
    if(brand === 'mastercard') return <MasterCard/>
    if(brand === 'amex') return <Amex/>
    if(brand === 'discover') return <Discover/>
  }

  postToSlack(msg){
    Api.callApi({
      endpoint:'/stripe-pos/slack-message',
      method:'post',
      data:{
        msg:msg
      },
      success:(data)=>{
      },
      failure:(err)=>{
      }
    })
  }

  render(){
    let that = this

    if(this.state.loading){
      return (
        <div style={{width:'100vw', height:'80vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Loading/>
        </div>
      )
    }

    if(this.state.page === 'pay'){
        return (
          <div className='create-charge-pay-page'>
            <div className='create-charge-m2-image-container'>
              <img 
                className='create-charge-m2-image'
                src='https://cdn.hngr.co/captain/stripem2.png'
              />
            </div>
            <div className='create-charge-pay-container'>
              <div className='create-charge-pay-top-row'>
                <div className='create-charge-pay-amount'>
                  {Money.formatCents(this.amount())}
                </div>
                <div 
                  className='create-charge-pay-cancel'
                  onClick={()=>{ 
                    this.setState({loading:true}, async ()=>{
                      await StripeReaderPlugin.cancel()
                      this.reload()
                    })
                  }}
                >
                  Cancel
                </div>
              </div>
                
              <div className='create-charge-tap-or-swipe-loading-container'>
                <img src='https://cdn.hngr.co/captain/roller3.svg' />
              </div>
              <div className='create-charge-tap-or-swipe'>
                Tap, Insert or Swipe Card
              </div>
            </div>
          </div>
        )
    }


    if(this.state.page === 'waiting_for_reader'){
        return (
          <div className='create-charge-pay-page'>
            <div className='create-charge-m2-image-container'>
              <img 
                className='create-charge-m2-image'
                src='https://cdn.hngr.co/captain/stripem2.png'
              />
            </div>
            <div className='create-charge-pay-container'>
              <div className='create-charge-pay-top-row'>
                <div className='create-charge-pay-amount'>
                  {Money.formatCents(this.amount())}
                </div>
                <div 
                  className='create-charge-pay-cancel'
                  onClick={()=>{
                    this.reload()
                  }}
                >
                  Cancel
                </div>
              </div>
                
              <div className='create-charge-tap-or-swipe-loading-container'>
                <img src='https://cdn.hngr.co/captain/roller3.svg' />
              </div>
              <div className='create-charge-tap-or-swipe'>
                One Second, Please...
              </div>
            </div>
          </div>
        )
    }

    if(this.state.page === 'processing'){
        return (
          <div className='create-charge-pay-page'>
            <div className='create-charge-m2-image-container'>
              <img 
                className='create-charge-m2-image'
                src='https://cdn.hngr.co/captain/stripem2.png'
              />
            </div>
            <div className='create-charge-pay-container'>
              <div className='create-charge-pay-top-row'>
                <div className='create-charge-pay-amount'>
                  {Money.formatCents(this.amount())}
                </div>
              </div>
                
              <div className='create-charge-tap-or-swipe-loading-container'>
                <img src='https://cdn.hngr.co/captain/roller3.svg' />
              </div>
              <div className='create-charge-tap-or-swipe'>
                Processing
              </div>
            </div>
          </div>
        )
    }

    if(this.state.page === 'paid'){
        return (
          <div className='create-charge-paid-page'>
            <div className='create-page-top-portion-final'>
              <CheckCircle />
              <div className='create-charge-final-page-text'>
                Approved
              </div>
            </div>
            <div className='create-charge-pay-container'>
              <div className='create-charge-paid-toppest-row'>

                <div className='create-charge-paid-name-amount-container'>
                  {this.state.name && (
                    <div>{this.state.name}</div>
                  )}
                  <div>
                    {Money.formatCents(this.amount())}
                  </div>
                </div>

                <div className='create-charge-payment-successful'>
                  <CheckCircle/>
                  <div>Payment Successful</div>
                </div>
              </div>
              
              <div className='create-charge-paid-top-row'>
                <div className='create-charge-credit-card-type-container'>
                  {this.getCreditCardLogo(this.state.cc_brand)}
                </div>
                <div className='create-charge-last4'>
                  Card Ending in {this.state.last4}
                </div>
              </div>

              <div className='paid-bottom-button-row'>
                <div 
                  className='paid-print-receipt'
                  onClick={()=>this.printReceipt()}
                >
                  {this.state.printLoading ? (
                    <LoadingSvg/>
                  ) : (
                    <Printer />
                  )}
                  <div>Print Receipt</div>
                </div>

                <div 
                  className='paid-done-button'
                  onClick={()=>this.props.history.goBack()}
                >
                  <div>Done</div>
                </div>

              </div>

            </div>
          </div>
        )
    }

    if(this.state.page === 'error'){
        return (
          <div className='create-charge-error-page'>
            <div className='create-page-top-portion-final-error'>
              <Warning />
              <div className='create-charge-final-page-text'>
                Error
              </div>
            </div>
            <div className='create-charge-pay-container'>
              <div className='create-charge-paid-toppest-row'>

                <div className='create-charge-paid-name-amount-container'>
                  {this.state.name && (
                    <div>{this.state.name}</div>
                  )}
                  <div>
                    {Money.formatCents(this.amount())}
                  </div>
                </div>

                <div className='create-charge-payment-error'>
                  <Warning/>
                  <div>Payment Error</div>
                </div>

              </div>
              
              <div className='create-charge-error-message'>
                {this.state.error} 
              </div>

              <div className='paid-bottom-button-row' style={{justifyContent:'center'}}>
                <div 
                  className='paid-print-receipt'
                  onClick={()=>this.reload()}
                >
                  <div>Cancel</div>
                </div>
              </div>

            </div>
          </div>
        )
    }


    if(this.state.page === 'collect_payment_info'){

      return (
        <div style={{width:'100%'}}>
          <div className='collect-title-container'>
            <div>
              <div className='collect-title'>Manual Input</div>
              <div className='collect-subtitle'>Enter Card Information</div>
            </div>
            <div>
              <div className='collect-title-total'>Total</div>
              <div className='collect-title-total-amount'>
                  {Money.formatCents(this.amount())}
              </div>
            </div>
          </div>
          <Elements options={{clientSecret:this.state.intent_secret}} stripe={stripePromise}>
            <ElementsConsumer>
                  {({stripe, elements}) => (
                    <CheckoutForm  
                      reload={()=>this.reload()}
                      postToSlack={(msg)=>this.postToSlack(msg)}
                      completePaymentManual={()=>this.completePaymentManual()}
                      intent_secret={this.state.intent_secret} 
                      stripe={stripe} 
                      elements={elements} 
                    />
                  )}
            </ElementsConsumer>
          </Elements>
        </div>
      )
    }



    if(this.state.page === 'error'){
      return (
        <div className='orders-contant'>
          <div> error </div>
          <div>{this.state.error}</div>
        </div>
      )
    }


    return (
      <div className='orders-content'>
        <div className='create-charge-title'>
          Customer Payment
        </div>


        <div className='create-charge-first-page-content'>
          <div style={{width:'100%'}}>
            <div className='create-charge-amount-container'>
              <span className='create-charge-dollar-sign'>$</span> 
              <input 
                className='create-charge-input'
                type='number' 
                onChange={(e)=>this.setState({amount:this.filterInput(e.target.value)})}
                onFocus={()=>this.onFocus()}
                value={this.state.amount}
              />
            </div>

            {this.state.waitingForConnection === true ? (
              <div className='terminal-waiting-for-connection'>
                <LoadingSvg/>
                <div>Connecting...</div>
              </div>
            ) : (
              <>

                <div 
                  className='create-charge-collect-payment-button'
                  onClick={()=>{
                    this.startCollectPayment().catch(function(e) {
                      that.postToSlack("Error collecting payment - " + e.message)
                      alert('Unknown error occurred')
                    })
                  }}
                >
                  <div>Use Terminal</div>
                </div>

                <div 
                  onClick={()=>this.count()}
                  style={{marginTop:'30px', marginBottom:'30px', textAlign:'center'}}
                >
                  or
                </div>

                <div
                  className='create-charge-manual-card'
                  onClick={()=>this.getPaymentIntentManual()}
                >
                  <CreditCard />
                  <div>Enter Card Info</div>
                </div>
              
              </>
            )}
          
          </div>
        </div>
      </div>
    )
  }
}


class CheckoutForm extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      loading:true,
    }
  }

  componentDidMount(){
    setTimeout(()=>this.setState({loading:false}), 3000)
  }
  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const {stripe, elements} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    this.setState({loading:true, error:null})

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://mayo.hngr0.co"
      },
      redirect:'if_required'
    });


    console.log("trains", result)

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      this.setState({loading:false})
      if(result.error.type !== 'validation_error'){
        this.setState({error:result.error.message})
        this.props.postToSlack(":credit_card: Manual Enter Payment Error :" + result.error.message)
      }
    } else {
      this.props.completePaymentManual()
    }
  };

  render() {
    return (
      <div style={{width:'100%', boxSizing:'border-box', padding:'20px'}}>
        {this.state.error && (
          <div className='create-payment-manual-error'>
            <Warning/>
            <div>{this.state.error}</div>
          </div>
        )}
        <form onSubmit={this.handleSubmit}>
          {this.state.closing !== true && (
            <PaymentElement/>
          )}
          {this.state.loading ? (
            <div className='manual-input-loading-modal'> 
              <Loading/>
            </div>
          ) : (
            <>
              <button 
                disabled={!this.props.stripe}
                className='submit-payment-button'
              >
                <div>Submit</div>
              </button>
              <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
                <button
                  className='cancel-payment-button'
                  onClick={()=>{
                    this.setState({closing:true}, ()=>this.props.reload())
                  }}
                >
                  <div> Cancel </div>
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    );
  }
}
      
export default UserContextHOC(withRouterAndRef(CreateCharge))


