import React, { Component } from "react"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import UserContextHOC from "../contexts/UserContextHOC"
import classnames from "classnames"
import { withRouter } from "react-router-dom"
import "./Home.css"
import "./HeaderTabs.css"

const Copy = require("../helpers/copy")
const Api = require("../api")

class OrderNavigationPills extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPill:this.getPageFromPath(),
      pathname:this.props.location.pathname,
      snoozedOrders:Copy.copy(this.props.orders.snoozedOrders),
      activeOrders:Copy.copy(this.props.orders.activeOrders),
      awaitingOrders:Copy.copy(this.props.orders.awaitingOrders)
    }
  }

  componentDidUpdate(prevProps){
    if(this.state.pathname !== this.props.location.pathname){
      this.setState({
        currentPill:this.getPageFromPath(),
        pathname:this.props.location.pathname
      })
    }

    if(this.state.showAwaiting !== this.props.user.showAwaiting){
      this.setState({showAwaiting:this.props.user.showAwaiting})
    }

    if(JSON.stringify(this.state.snoozedOrders) !== JSON.stringify(this.props.orders.snoozedOrders)){
      this.setState({snoozedOrders:Copy.copy(this.props.orders.snoozedOrders)})
    }
    if(JSON.stringify(this.state.activeOrders) !== JSON.stringify(this.props.orders.activeOrders)){
      this.setState({activeOrders:Copy.copy(this.props.orders.activeOrders)})
    }
    if(JSON.stringify(this.state.awaitingOrders) !== JSON.stringify(this.props.orders.awaitingOrders)){
      this.setState({awaitingOrders:Copy.copy(this.props.orders.awaitingOrders)})
    }
  }

  // these routes are /orders/blah .... we want to get the blah
  getPageFromPath(){
    return this.props.location.pathname.split("/")[2]
  }

  totalActiveOrders(){
    let total = 0
    total += this.state.activeOrders['pastdue'].length
    total += this.state.activeOrders['zero-15'].length
    total += this.state.activeOrders['fifteen-30'].length
    total += this.state.activeOrders['thirty-45'].length
    total += this.state.activeOrders['45-plus'].length
    return total
  }

  totalAwaitingOrders(){
    console.log("awaiting", this.state)
    return this.state.awaitingOrders['pickup'].length + this.state.awaitingOrders['delivery'].length 
  }

  totalScheduledOrders(){
    let total = 0
    for(var i in this.state.snoozedOrders){
      total += this.state.snoozedOrders[i].length
    }
    return total
  }
 
  render(){
    if(this.state.pathname.indexOf('orders') === -1) return null
    return (
      <div className='orders-header-tabs'>
        <div 
          onClick={()=>{
            this.props.history.push({
              pathname:'/orders/kitchen',
              state:{
                scheduledCount:this.totalScheduledOrders(),
                activeCount:this.totalActiveOrders()
              }
            })
          }}
          className={classnames('orders-header-tab', 
            {'orders-header-tab-selected':this.state.currentPill === 'kitchen'},
            {'orders-header-tab-four':this.state.showAwaiting})}
        >
          <div>In Kitchen ({this.totalActiveOrders()})</div>
        </div>
        
        <div 
          onClick={()=>{
            this.props.history.push({
              pathname:'/orders/scheduled',
              state:{
                scheduledCount:this.totalScheduledOrders(),
                activeCount:this.totalActiveOrders()
              }
            })
          }}
          className={classnames('orders-header-tab', 'orders-header-tab-scheduled',
            {'orders-header-tab-selected':this.state.currentPill === 'scheduled'},
            {'orders-header-tab-four':this.state.showAwaiting})}
        >
          <div>Scheduled ({this.totalScheduledOrders()})</div>
        </div>

        {this.state.showAwaiting === true && (
          <div 
            onClick={()=>{
              this.props.history.push({
                pathname:'/orders/awaiting',
                state:{
                  scheduledCount:this.totalScheduledOrders(),
                  activeCount:this.totalActiveOrders()
                }
              })
            }}
            className={classnames('orders-header-tab', 'orders-header-tab-scheduled',
              {'orders-header-tab-selected':this.state.currentPill === 'awaiting'},
              {'orders-header-tab-four':this.state.showAwaiting})}

          >
            <div>Awaiting ({this.totalAwaitingOrders()})</div>
          </div>
        )}
      

        <div 
          onClick={()=>{
            this.props.history.push({
              pathname:'/orders/history',
              state:{
                scheduledCount:this.totalScheduledOrders(),
                activeCount:this.totalActiveOrders()
              }
            })
          }}
          className={classnames('orders-header-tab', 'orders-header-tab-scheduled',
            {'orders-header-tab-selected':this.state.currentPill === 'history'},
            {'orders-header-tab-four':this.state.showAwaiting})}
        >
          <div>History</div><History/>
        </div>

      </div>


    )
  }
}

export default withRouter(UserContextHOC(NewOrdersContextHOC(OrderNavigationPills)))


const History = props => (<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z"/></svg>)
