import React, { Component } from "react"
import UserContextHOC from '../contexts/UserContextHOC'
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import ActionButton from "./ActionButton"
import Cart from './Cart'
import OrderToast from './OrderToast'
import OrderDetailsSection from './OrderDetailsSection'
import DinerDetailsSection from './DinerDetailsSection'
import ButtonWithSpinner from "./ButtonWithSpinner"
import TabletSidebar from "./TabletSidebar"
import classnames from "classnames"
import "./OrderCard.css"
import { Capacitor, Plugins } from '@capacitor/core'
const { PrintReceiptPlugin, ReceiptPrinter } = Plugins;
const Timestamp = require("../helpers/timestamps")
const Api = require("../api")
const Money =  require("../helpers/money")


let DELIVERY_TIMES = [30,40,50,60,75,90]
let PICKUP_TIMES  = [5, 10,20,30,45, 60]
let SNOOZE_TIMES = [30, 60, 90, 120]

class OrderModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pathname:this.props.location.pathname,
      moreOptionsOpen:false,
      loading:false,
      closing:false,
      ...props.location.state,
      isMobile:window.innerWidth <= 599,
      isTablet:window.innerWidth > 599 && window.innerWidth < 1000,
      isDesktop:window.innerWidth > 1000

    }
    this.action = this.action.bind(this)
    this.close = this.close.bind(this)
    this.handleClickOutsideExpandedCircleOptions = this.handleClickOutsideExpandedCircleOptions.bind(this)
    this.toggleMoreOptions = this.toggleMoreOptions.bind(this)
    this.moreOptionsRef = React.createRef()

    let a = this
  }

  componentDidMount(){
    this.mounted = true
    this.refreshOrder(true)
    document.addEventListener('mousedown', this.handleClickOutsideExpandedCircleOptions)
    document.body.style.overflow = "hidden"
  }

  componentWillUnmount(){
    this.mounted = false
    document.removeEventListener('mousedown', this.handleClickOutsideExpandedCircleOptions)
    document.body.style.overflow = "auto"
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.location.pathname !== this.state.pathname){
      if(this.state.pathname.indexOf('adjust') > -1){
        if( 
          this.props.history &&
          this.props.history.location &&
          this.props.history.location.state &&
          this.props.history.location.state.toast
        ){
          this.refreshOrder()
          this.addToast(this.props.history.location.state.toast)
        }
      }
      this.setState({pathname:this.props.location.pathname})
    }


    // if we're on the uncofnrimed page and the order status_id
    // is cancelled, then we need to close and show a toast
    if(
      this.props.location.pathname.indexOf('unconfirmed') > -1 &&
      prevState.order.status === 'unconfirmed' &&
      this.state.order.status === 'cancelled'
    ){
       this.setState({ closing: true }, () => {
        setTimeout(() => {
          let path = this.props.location.pathname
          let split = path.split("/")
          let newPath = "/" + split[1] + "/"+ split[2]
          this.props.history.replace({
            pathname:newPath,
            state:{
              toast:{
                type:'expired',
                order:this.state.order
              }
            }
          })           
        }, 200)
      })
    }
  }

  addToast(toast){
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }
  }



  toggleMoreOptions(){
    this.setState({moreOptionsOpen:!this.state.moreOptionsOpen})
  }

  refreshOrder(setTimer){
    if(!this.mounted) return
    Api.callApi({
      endpoint:'refresh-order',
      order_id:this.state.order.order_id,
      success:(data)=>{
        this.setState({order:data.order})
        if(setTimer) setTimeout(()=>this.refreshOrder(true), 5000)
      },
      failure:()=>{
        if(setTimer) setTimeout(()=>this.refreshOrder(true), 5000)
      }
    })
  }

  handleClickOutsideExpandedCircleOptions(event){
    if(!this.state.moreOptionsOpen) return
    if(
      this.moreOptionsRef &&
      this.moreOptionsRef.current &&
      !this.moreOptionsRef.current.contains(event.target)
    ){
      this.setState({moreOptionsOpen:false})
    }
  }

  close() {
    let that = this
    this.setState({ closing: true }, () => {
      setTimeout(() => {
        let path = this.props.location.pathname
        let parts = path.split("/")
        let newPath = "/"+parts[1] + "/" + parts[2]
        this.props.history.replace({pathname:newPath})
      }, 200)
    })  
  }

  maybeClose(e){
    if (e.target.className === "modal-background"){ 
      this.close()
    } 
  }

  action(callback){
    if(this.state.page === 'unconfirmed' && !this.state.order.fulfill_order_at){
      let data = {
        order_id:this.state.order.order_id
      }
      if(this.state.prep_time){
        data['estimated_delivery_time'] = this.state.prep_time 
      } 
      Api.callApi({
        endpoint:'confirm_order',
        data:data,
        success:(data)=>{
          if(
            this.props.user.printTrigger === 'confirm' &&
            this.props.user.printer &&
            this.props.user.printer.ipAddress &&
            !this.props.user.rest.fax
          ){
            if(PrintReceiptPlugin){
              PrintReceiptPlugin.printReceipt({
                order_id:this.state.order.order_id.toString(),
                api_key:window.API_KEY,
                source:'confirmation',
                ip_address:this.props.user.printer.ipAddress,
                copies:this.props.user.receiptCopies.toString(),
              })
            } else {
              ReceiptPrinter.printReceipt({
                order_id:this.state.order.order_id.toString(),
                api_key:window.API_KEY,
                source:'confirmation',
                ip_address:this.props.user.printer.ipAddress,
                copies:this.props.user.receiptCopies.toString(),
              })
 
            }
          } 
          this.props.unconfirmedOrders.checkForOrders()
          this.props.orders.checkForActiveOrders()
          this.props.orders.checkForSnoozedOrders()
          // get back to /orders/unconfirmed
          this.setState({ closing: true }, () => {
            setTimeout(() => {
              let path = this.props.location.pathname
              let split = path.split("/")
              let newPath = "/" + split[1] + "/"+ split[2]
              this.props.history.replace({
                pathname:newPath,
                state:{
                  toast:{
                    type:'confirmed',
                    order:this.state.order,
                    prep_time:this.state.prep_time
                  }
                }
              })           
            }, 200)
          })  
        },
        failure:()=>{
          callback()
          this.props.history.push({
            pathname:this.props.location.pathname +'/error-modal',
            state:{
              message:'There was an error confirming this order.'
            }
          })
        }
      })
    } else if (this.state.page === 'unconfirmed' && this.state.order.fulfill_order_at){ 
       let data = {
        order_id:this.state.order.order_id
      }
      if(this.state.prep_time){
        data['snooze_until'] = this.state.prep_time 
      } 
      Api.callApi({
        endpoint:'snooze-order',
        data:data,
        success:(data)=>{
          if(
            this.props.user.printTrigger === 'confirm' &&
            this.props.user.printer &&
            this.props.user.printer.ipAddress &&
            !this.props.user.rest.fax
          ){
            PrintReceiptPlugin.printReceipt({
              order_id:this.state.order.order_id.toString(),
              api_key:window.API_KEY,
              source:'confirmation',
              ip_address:this.props.user.printer.ipAddress,
              copies:this.props.user.receiptCopies.toString(),
            })
          } 

          this.props.unconfirmedOrders.checkForOrders()
          // get back to /orders/unconfirmed
          let path = this.props.location.pathname
          let split = path.split("/")
          let newPath = "/" + split[1] + "/"+ split[2]
          this.props.history.replace({
            pathname:newPath,
            state:{
              toast:{
                type:'snoozed',
                order:this.state.order,
                prep_time:this.state.prep_time
              }
            }
          })
        },
        failure:()=>{
          callback()
          this.props.history.push({
            pathname:this.props.location.pathname +'/error-modal',
            state:{
              message:'There was an error confirming this order.'
            }
          })
        }
      })
    } else if (this.state.page === 'kitchen'){
      let order = this.state.order
      let endpoint = order.pickup_or_delivery === 'pickup' ?
        'ready-for-pickup' : 'out-for-delivery'
      let toastText = order.pickup_or_delivery === 'pickup' ? 
        'Ready for Pickup' : 'Out for Delivery'
      Api.callApi({
        endpoint:endpoint,
        data:{order_id:order.order_id},
        success:(data)=>{
          this.setState({loading:false})
          // go from /orders/unconfimred/ordermodal to /orders/unconfirmed
          let path = this.props.location.pathname
          let split = path.split("/")
          let newPath = "/" + split[1] + "/"+ split[2]
          this.props.history.replace({
            pathname:newPath,
            state:{
              toast:{
                type:'ready',
                text:toastText,
                order:this.state.order
              }
            }
          })
        },
        failure:()=>{
          if (callback){
            callback()
          }
          this.props.history.push({
            pathname:this.props.location.pathname +'/error-modal',
            state:{
              message:'There was an error updating this order.'
            }
          })

        } 
      })
    }
    

    //this.state.action(()=>this.setState({loading:false}))
  }

  formatOrderPlaced(ts){
    let time = Timestamp.getTimeOfDay(ts)
    let mm = Timestamp.getMonthInt(ts)
    let dd = Timestamp.getDayOfMonth(ts)
    let yy  = Timestamp.getPartialYear(ts)
    return `${mm}/${dd}/${yy} ${time}`
  }

  //Friday Mar, 27 3:00 PM
  formatScheduledDate(ts){
    let time = Timestamp.getTimeOfDay(ts)
    let dow = Timestamp.getDayOfWeekFull(ts)
    let dd = Timestamp.getDayOfMonth(ts)
    let month = Timestamp.getMonthNameShort(ts)
    return `${dow} ${month}, ${dd} ${time}`
  }


  openAdjust(type){
    this.props.history.push({
      pathname:this.props.location.pathname+"/adjust",
      state:{
        order:this.state.order,
        page:this.props.page,
        type:type
      }
    })
  }

  openCancel(){
    this.props.history.push({
      pathname:this.props.location.pathname+"/cancel",
      state:{
        order:this.state.order,
        originalPage:this.state.page
      }
    })
  }

  heightOffset(){
    if(this.state.isMobile) {
      if(Capacitor.getPlatform() === 'ios'){
        let insetTop = window.getComputedStyle(document.documentElement).getPropertyValue("--inset-top")
        insetTop = insetTop.replace("px", "")
        return 76 
      } else {
        return 76
      }
    } else if (this.state.isTablet){
      return 200
    } else if (this.state.isDesktop){
      return 76
    }


  }

  isMoreOptionsPage(){
    let pages = ['unconfirmed', 'kitchen', 'scheduled', 'history'] 
    return pages.indexOf(this.state.page) > -1
  }


  acceptTimes(){
    let times
     
    // scheduled orders 
    if(this.state.order.fulfill_order_at){
      times = JSON.parse(JSON.stringify(SNOOZE_TIMES))
      if(this.state.order.pickup_or_delivery === 'pickup'){
        if(times.indexOf(this.props.user.rest.default_pickup_snooze_time) == -1){
          if(this.props.user.rest.default_pickup_snooze_time){
            times.push(this.props.user.rest.default_pickup_snooze_time)
          }
        }
      } else if (this.state.order.pickup_or_delivery === 'delivery'){
        if(times.indexOf(this.props.user.rest.default_delivery_snooze_time) == -1){
          if(this.props.user.rest.default_delivery_snooze_time){
            times.push(this.props.user.rest.default_delivery_snooze_time)
          }
        }
      }
    }
    // non scheduled orders
    else {
      if(this.state.order.pickup_or_delivery === 'pickup'){
        times = JSON.parse(JSON.stringify(PICKUP_TIMES))
        if(times.indexOf(this.props.user.rest.default_pickup_time) == -1){
          if(this.props.user.rest.default_pickup_time){
            times.push(this.props.user.rest.default_pickup_time)
          }
        }
      } else if (this.state.order.pickup_or_delivery === 'delivery'){
        times = JSON.parse(JSON.stringify(DELIVERY_TIMES))
        if(times.indexOf(this.props.user.rest.default_delivery_time) == -1){
          if(times.indexOf(this.props.user.rest.default_delivery_time)){
            times.push(this.props.user.rest.default_delivery_time)
          }
        }
      }
    }
    times = times.sort(function(a, b){return a-b});
    return times
  }
 

  renderBottomButton(){
    if(
      this.state.page !== 'unconfirmed' &&
      this.state.page !== 'kitchen'
    ){
      return null   
    }

    let times = this.acceptTimes()
    return (
      <div className='full-page-card-mobile-bottom-button-container'>
        <div className='full-page-card-bottom-inner-container'>
         
          {this.state.expandedBottomButtons && (
            <div className='full-page-card-expanded-options'> 
              {times.map((mins,index) => (
                <div 
                  key={mins}
                  onClick={()=>{
                    // prevent jamming
                    if(this.actionButtonPressed) return
                    this.actionButtonPressed = true
                    this.setState({prep_time:mins, actionButtonPressed:true}, ()=>{
                      this.action(()=>this.actionButtonPressed=false)
                    })
                  }}
                  className={classnames('full-page-card-expanded-option',
                    {'expanded-option-border':index !== times.length -1})}
                >
                  {mins} minutes
                </div>
              ))}
            </div>
          )}

          <div 
            onClick={()=>{
              if(this.state.page === 'unconfirmed'){
                this.setState({expandedBottomButtons:true})
              } else if (this.state.page === 'kitchen'){
                 if(this.actionButtonPressed) return
                 this.actionButtonPressed = true
                 this.action(()=>this.actionButtonPressed=false)
              }
            }}
            className={classnames('full-page-card-mobile-bottom-button',
              {'full-page-card-mobile-bottom-button-open':this.state.expandedBottomButtons})}
          >
            {this.state.page === 'unconfirmed' &&  this.state.expandedBottomButtons  && (
              <div> Choose Prep Time</div>
            )}

            {this.state.page === 'unconfirmed' && !this.state.expandedBottomButtons  && (
              <div> Accept Order </div>
            )}

            {this.state.page === 'kitchen' && (
              <div> Order Ready</div>
            )}
        </div>
      </div>
    </div>
    )
  }

  renderInPersonOrderBlurb(){
    let paid_by
    if(this.state.order.origin == 'STRIPE_TERMINAL'){
      paid_by = 'TERMINAL'
    } else{
      paid_by = 'MANUALLY ENTERED CREDIT CARD'
    }
    return (
      <div className='in-person-blurb'>
        {paid_by} for {Money.formatCents(this.state.order.total)} 
      </div>
    )
  }

  render(){
    return (
      <div 
        className='modal-background'
        onClick={(e)=>this.maybeClose(e)}
        id={this.state.closing && !this.state.isDesktop ? "modal-background-exit" : null}
      >

                
          {this.state.moreOptionsOpen && (
            <div 
              className='order-modal-more-options-container'
              ref={this.moreOptionsRef}
            >
              
              <div 
                className='order-modal-more-options-option bottom-grey-border'
                onClick={()=>this.openAdjust('upcharge')}
              >
                <div>Upcharge</div>
              </div>

              <div 
                className={classnames('order-modal-more-options-option',
                  {'bottom-grey-border':this.state.page !== 'history'})}
                onClick={()=>this.openAdjust('refund')}
              >
                <div>Refund</div>
              </div>

              {this.state.page !== 'history' && (
                <div 
                  className='order-modal-more-options-option red'
                  onClick={()=>this.openCancel()}
                >
                  <div>Cancel</div>
                </div>
              )}

            </div>
          )}




        <div 
          key='wtf'
          style={this.state.expandedBottomButtons || this.state.moreOptionsOpen ? {overflowY:'hidden'} : {}}
          className={classnames('full-page-card', 
            {'full-page-card-disabled':this.state.expandedCircle})}
          ref={this.expandedCardRef}
          id={this.state.closing && !this.state.isDesktop ? "full-page-card-exit" : null}
        >
          {this.state.order.fulfill_order_at ? (
            <div className='full-page-card-header  scheduled-header'>
              <div 
                onClick={this.close} 
                className='full-page-card-close-inner-container'
              >
                <Close/> 
              </div>
              
              <div className='full-page-card-header-scheduled-container'>
                <div className='full-page-card-header-text'>
                  Scheduled
                </div>
                <div className='full-page-card-header-subtext'>
                  {this.formatScheduledDate(this.state.order.fulfill_order_at)}
                </div>
              </div>

              <div 
                onClick={this.toggleMoreOptions} 
                className={classnames('full-page-card-header-elipses-container',
                  {'invisible':!this.isMoreOptionsPage})}
              >
                {this.state.moreOptionsOpen ? (
                  <div className='more-options-close'>
                    <Close />
                  </div>
                ) : (
                  <div>•••</div>
                )}
              </div>

            </div>
          ) : (
            <div className='full-page-card-header '>
               <div 
                onClick={this.close} 
                className='full-page-card-close-inner-container'
              >
                <Close/> 
              </div>
              
              <div className='full-page-card-header-text'>ASAP</div>
              
              <div 
                onClick={this.toggleMoreOptions} 
                className={classnames('full-page-card-header-elipses-container',
                  {'invisible':!this.isMoreOptionsPage})}
              >
                {this.state.moreOptionsOpen ? (
                  <div className='more-options-close'>
                    <Close />
                  </div>
                ) : (
                  <div>•••</div>
                )}
              </div>
           </div>
          )}
        
          <div 
            className='full-page-card-content-container'
            style={{minHeight:window.innerHeight-this.heightOffset() + 'px'}}
          >
            {/* we need this container to take up the full height, even if the bottom
                is blank, so that the buttons are always stuck to the bottom */ }
            <div 
              className='full-page-card-content orders-content'
              style={{minHeight:window.innerHeight-this.heightOffset() + 'px', backgroundColor:'#F0F0F0'}}
            >
      
              {this.state.toast && (
                <OrderToast
                  toast={this.state.toast}
                  type={this.state.toast.type}
                  order={this.state.toast.order}
                />
              )}

              {this.state.expandedCircle && (
                <div className='expanded-circle-modal-background'></div>
              )}
            
              {this.state.isMobile && (
                <OrderDetailsSection page={this.state.page} order={this.state.order} />
              )}
              <DinerDetailsSection order={this.state.order} />

            {(this.state.order.origin !== 'STRIPE_TERMINAL' && this.state.order.origin !== 'MANUAL_ENTER_CC') ? (
              <Cart order={this.state.order}/>
            ) : (
              <>
                {this.renderInPersonOrderBlurb()} 
              </>
            )}
            </div> {/* .full-page-card-content */}
             

            {(this.state.isTablet || this.state.isDesktop) && (
              <TabletSidebar 
                updatePrepTime={(time) => this.setState({prep_time:time})}
                action={(cb)=>this.action(cb)}
                page={this.state.page}
                openCancel={()=>this.openCancel()} 
                {...this.props} 
                order={this.state.order} 
              />
            )}

          </div>  {/*full-page-card-content-container */}
          {this.state.isMobile && this.renderBottomButton()} 
          {this.state.expandedBottomButtons && (
            <div 
              className='expanded-buttons-overlay'
              onClick={()=>this.setState({expandedBottomButtons:false})}
            >
            </div>
          )}

          {this.state.moreOptionsOpen&& (
            <div 
              className='expanded-buttons-overlay'
              style={{zIndex:999}}
              onClick={()=>this.setState({moreOptionsOpen:false})}
            >
            </div>
          )}
 
          
        
        </div> {/* .full-page-card */}

      </div>
    )
  }
}
      

export default NewOrdersContextHOC(UserContextHOC(OrderModal))

const Clock = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.848 12.459c.202.038.202.333.001.372-1.907.361-6.045 1.111-6.547 1.111-.719 0-1.301-.582-1.301-1.301 0-.512.77-5.447 1.125-7.445.034-.192.312-.181.343.014l.985 6.238 5.394 1.011z"/></svg>)


const Calendar = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"/></svg>)

const Close = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>)

const Printer = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 18h-8v-1h8v1zm-2 1h-6v1h6v-1zm10-14v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 10h-12v7h12v-7zm4-8h-20v9h2v-3h16v3h2v-9zm-1.5 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z"/></svg>)

const UpCaret = props => (<svg {...props} viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clip-rule="evenodd"><path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z"/></svg>)

const DownCaret = props => (<svg {...props} viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clip-rule="evenodd"><path d="M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z"/></svg>)
