import React from "react"
import UserContextHOC from "../../../contexts/UserContextHOC"
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg"
import { Text, ButtonWrapper } from "../../components"
import BasicInfo from "./BasicInfo"
import EditUserModal from "./edituser"

const API = require("../../api.js")

class ProfilePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      userModalOpen: false,
    }
    // this.saveUser = this.saveUser.bind(this)
    this.loadUsers()
  }

  async loadUsers() {
    let rest = this.props.rest 
    this.rest = rest
    API.call(
      "users",
      (resp) => {
        this.setState({ users: resp.users })
      },
      (err) => {
        alert("Error loading users")
      },
      null,
      { rest_id: rest.restaurant_id }
    )
  }

  editUser(user) {
    this.setState({
      userModalOpen: true,
      userModalUser: user,
    })
    // this.setState({
    //   userModalOpen: true,
    //   userModalUser: user,
    //   userModalPhone: user.phone,
    //   userModalSMSAlert: user.sms_alert,
    //   userModalUserId: user.id,
    // })
  }

  resetState = () => {
    this.setState({
      userModalOpen: false,
      userModalUser: null,
      userModalPhone: null,
      userModalSMSAlert: null,
      userModalUserId: null,
    })
  }

  closeModal = () => {
    this.setState({ userModalOpen: false, userModalUser: null })
  }

  afterSave = () => {
    this.loadUsers()
    this.resetState()
  }

  render() {
    return (
      <div>
        {this.state.userModalOpen && (
          <EditUserModal
            afterSave={this.afterSave}
            restaurant_id={this.props.rest.restaurant_id}
            closeModal={this.closeModal}
            userModalUser={this.state.userModalUser}
          />
        )}
        <Text color="pink" size="header" weight="black">
          Restaurant Profile
        </Text>
        <BasicInfo
          rest={this.props.rest}
          refreshData={this.props.refreshData}
        />
        <div className="mt-16">
          <Text size="xl" weight="black" className="br-b br-blue">
            Users
          </Text>
          <table className="mt-4">
            <thead>
              <tr>
                <th className="left">Name</th>
                <th className="left">Email</th>
                <th className="left">Phone</th>
                <th className="left">SMS Alert</th>
                <th className="left"></th>
              </tr>
            </thead>
            <tbody>
              {this.state.users.map((user) => (
                <tr key={user.id}>
                  <td className="left">{user.name}</td>
                  <td className="left">{user.email}</td>
                  <td className="left">{user.phone}</td>
                  <td className="left">{user.sms_alert ? "yes" : ""}</td>
                  <th className="left" onClick={() => this.editUser(user)}>
                    <ButtonWrapper>
                      <Text color="pink" weight="bold">
                        Edit
                      </Text>
                    </ButtonWrapper>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
export default UserContextHOC(ProfilePage)
