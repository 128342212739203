import React, { Component } from "react"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import DesktopSidebar from "../menu/DesktopSidebar"
import DesktopTabs from "./DesktopTabs"
import DesktopIncomingOrders from "./DesktopIncomingOrders"
import UserContextHOC from "../contexts/UserContextHOC"
import OrderCard from "./OrderCard"
import OrderToast from "./OrderToast"
import classnames from "classnames"
import { withRouter } from "react-router-dom"
import "./Home.css"
import SearchBar from "./SearchBar"

const Copy = require("../helpers/copy")
const Api = require("../api")

class ScheduledOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      snoozedOrders:Copy.copy(this.props.orders.snoozedOrders),
      toast:null,
      isDesktop:window.innerWidth > 1000
    }
  }

  componentDidMount(){
    this.props.orders.checkForSnoozedOrders()
    if(
      this.props.history && 
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.toast
    ){
      this.addToast(this.props.history.location.state.toast)
    }
  }
  
  componentDidUpdate(prevProps){
    if(JSON.stringify(this.state.snoozedOrders) !== JSON.stringify(this.props.orders.snoozedOrders)){
      this.setState({snoozedOrders:Copy.copy(this.props.orders.snoozedOrders)})
    }
  }

  addToast(toast){
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }
  }

  totalScheduledOrders(){
    let total = 0
    for(var i in this.state.snoozedOrders){
      total += this.state.snoozedOrders[i].length
    }
    return total
  }
 
  
  renderSnoozed(){
    let to_ret = []
    let days = Object.keys(this.state.snoozedOrders)
    for(var i in days){
      let day = days[i]
      let orders = this.state.snoozedOrders[day]
      let html =(
        <div className='orders-bucket'>
          <div 
            className={classnames('orders-bucket-header',
              {'orders-bucket-header-today':day.indexOf('TODAY') > -1 })}
          >
            <div className='orders-bucket-header-left'> 
              <Calendar />
              {day}
            </div>
            <div className='orders-bucket-header-right'>({orders.length})</div>
          </div>

          {orders.map(order => (
            <OrderCard
              page='scheduled'
              key={order.order_id}
              order={order}
            />
            ))}
        </div>
      )
      to_ret.push(html)
    }
    return to_ret
  }

  renderContent(){
    return (
      <div className='orders-home-container'>
        {this.state.isDesktop && (
          <DesktopTabs />
        )}
        <div className='orders-content'>

          {this.state.isDesktop !== true && (
            <SearchBar />
          )}

          <div className='orders-home-current-page'>
            <div>Scheduled ({this.totalScheduledOrders()})</div>
            {this.state.isDesktop === true && (
              <SearchBar />
            )}
          </div>
          
          {this.state.toast && ( 
            <OrderToast
              type={this.state.toast.type}
              order={this.state.toast.order}
            />
          )}

          {this.totalScheduledOrders() === 0 && !this.state.toast && (
            <div className='no-orders-container'>
              <img
                className='no-orders-img-bottle'
                src='https://cdn.hngr.co/captain/NoOrdersMessageBottle2.png'
              />  
              <div className='no-orders-top-text'>
                No Orders Scheduled
              </div>
              <div className='no-orders-bottom-text'>
                When a customer orders for later, you can manage their orders here.
              </div>
            </div>
        )}
          <div className='orders-home-active-orders-container'>
            {this.renderSnoozed()}
          </div>
        </div> 
      </div>
    )
  }

  render(){
    if(this.state.isDesktop){
      return (
        <div className='desktop-orders-page-container'>
          <DesktopSidebar />
          <div className='desktop-orders-container-container'>
            {this.renderContent()}
          </div>
          <div className='desktop-orders-incoming-orders-container'>
            <DesktopIncomingOrders />
          </div>
        </div>
      )
    } else {
      return this.renderContent()
    }
  }

  
}
      

export default withRouter(UserContextHOC(NewOrdersContextHOC(ScheduledOrders))) 


const Calendar = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"/></svg>)
