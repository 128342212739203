import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import ToastContextHOC from "../contexts/ToastContextHOC"
import MenuItemBox from "./MenuItemBox"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import OrderToast from "../orders/OrderToast"
import Deactivate from "../orders/svg/Deactivate"
import Plus from "../orders/svg/PlusMedium"
import "./Menu.css"

const Api = require("../api")
const Money =  require("../helpers/money")

function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };  
}


class MenuCategories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile:window.innerWidth <= 599,
      isTablet:window.innerWidth > 599,
      tab:'items',
      sectionDropdownCategoryId:null,
      menu:null,
      toast:null,
      blackedOutItems:[],
      blackedOutModifiers:[]
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.tab){
      this.state['tab'] = params.tab
    }

    this.menu_uuid = this.props.match.params.menu_uuid
    this.sectionRefs = {} 
    this.sectionElipsesRefs = {}
    this.sectionRefs['Deactivated Items'] = React.createRef()

    this.modifierSectionRefs = {} 
    this.modifierSectionRefs['Deactivated Items'] = React.createRef()

    this.addSectionRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
    window.menu = this
  }

  componentDidMount(){
    this.getCentralTimestamp()
    window.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount(){
   window.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps){
    if(
      JSON.stringify(this.props.toast.toast) !== JSON.stringify(prevProps.toast.toast) &&
      this.props.toast.toast
    ){
      this.setState({menu:null})
      this.getCentralTimestamp()
      this.addToast(this.props.toast.toast)
    }
  }

  handleClickOutside(e){
    if(this.state.addSectionDropdownOpen){
      if(
        e.target.className !== 'add-section-dropdown' &&
        e.target.className !== 'menu-item-dropdown-option' &&
        e.target.className !== 'in-dropdown'
      ){
        this.setState({addSectionDropdownOpen:false})
      }
    }
    
    if(this.state.sectionDropdownCategoryId !== null){
      if(
        e.target.className !== 'add-section-dropdown' &&
        e.target.className !== 'menu-item-dropdown-option' &&
        e.target.className !== 'in-dropdown' &&
        e.target.className.indexOf('in-dropdown') < 1
      ){
        this.setState({sectionDropdownCategoryId:null})
      }
    }

  }

  addToast(toast){
    if(!this.loaded()){
      setTimeout(()=>this.addToast(toast), 100)
      return
    }
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }   
  }

  loaded(){
    if(this.state.menu && this.state.centralTimestamp) {
      return true
    } else {
      return false
    }
  }

  loadMenu(){
    Api.callApi({
      endpoint:'/api/menu-with-empty-categories/'+this.menu_uuid,
      success:(data)=>{
        console.log("menu", data)
        for(let category in data.menu){
          this.sectionRefs[category] = React.createRef()
          this.sectionElipsesRefs[data.menu[category].menu_category_id] = React.createRef()
          this.modifierSectionRefs[category] = React.createRef()
        }
        this.modifierSectionRefs['No Category'] = React.createRef()
        let blackedOutItems = []
        // loop over the menu and indicate if an item is blacked out
        // or not.... also compile the list of blacked out items this
        // way
        for(let category in data.menu){
          for(let i in data.menu[category].items){
            let item = data.menu[category].items[i]
            item.category_name = category
            if(
              item.blackout_until &&
              item.blackout_until_timestamp > this.state.centralTimestamp 
            ) {
              item.blacked_out = true
              item.blackout_until_local_ts = item.blackout_until_timestamp - this.props.user.rest.central_offset_seconds 
              blackedOutItems.push(JSON.parse(JSON.stringify(item)))
            }
          }
        }

        let blackedOutModifiers = []
        for(let i in data.menu_items){
          let item = data.menu_items[i]
          if(!item.is_orderable){
            let item = data.menu_items[i]
            if(
              item.blackout_until &&
              item.blackout_until_timestamp > this.state.centralTimestamp 
            ) {
              item.blacked_out = true
              item.blackout_until_local_ts = item.blackout_until_timestamp - this.props.user.rest.central_offset_seconds 
              blackedOutModifiers.push(JSON.parse(JSON.stringify(item)))
            }
          }
        }

        // we need to group modifiers by category
        let modifiersByCategory = {}
        for(let i in data.menu_items){
          let item = data.menu_items[i]
          if(!item.is_orderable){
            let category = item.category_name
            if(!category) category = 'No Category'
            if (!(category in modifiersByCategory)){
              modifiersByCategory[category] =  {'items':[], 'name':category}
            }
            modifiersByCategory[category].items.push(JSON.parse(JSON.stringify(item)))
          }
        }

        let disableEdits = false
        if(data.external_source === 'CHOWLY') disableEdits = true
        if(data.external_source === 'SQUARE') disableEdits = true
        this.setState({
          menu:data, 
          blackedOutItems:blackedOutItems,
          blackedOutModifiers:blackedOutModifiers,
          modifiersByCategory:modifiersByCategory,
          disableEdits:disableEdits
        })
      },
      failure:(err)=>{
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error loading the menu' 
          }
        })
      }
    })
  }

  getCentralTimestamp(){
    Api.callApi({
      endpoint:'central-timestamp',
      success:(data)=>{
        this.setState({
          centralTimestamp:data.ts,
          tomorrow5am:data.tomorrow_5am,
        }, ()=>this.loadMenu())
      }
    })
  }

  goToModifiers(){
    this.props.history.push({
      pathname:'/menu/modifiers/'+this.menu_uuid
    })
  }

  render(){
    if(!this.loaded()) return (<Loading />)

    let that = this

    let categories = this.state.tab === 'items' ?
      this.state.menu.menu :
      this.state.modifiersByCategory

    let blackedOutItems = this.state.tab === 'items' ?
      this.state.blackedOutItems :
      this.state.blackedOutModifiers

    return (
      <>
        <div className='menu-tabs'>
          <div 
            className={classnames('menu-tab', 
              {'menu-tab-selected':this.state.tab === 'items'})} 
            onClick={()=>this.updateTab('items')}
          >
            <div>Items</div>
          </div>
          <div 
            className={classnames('menu-tab', 
              {'menu-tab-selected':this.state.tab === 'modifiers'})} 
            onClick={()=>this.goToModifiers()}
          >
            <div>Modifiers</div>
          </div>
        </div>

        <div className='full-menu-mobile'>
          {this.state.toast && (
            <OrderToast
              toast={this.state.toast}
              message={this.state.toast.item ? this.state.toast.item.name : null}
              type={this.state.toast.type}
            />
          )}
          <div className='section-title'>{this.state.menu.name}</div>
          <div 
            style={{marginTop:'30px', fontSize:'18px'}}
            className='poppins-title'
          >
            Menu Sections
          </div>
          
          <div className='menu-sections-list-container-mobile'>
            {Object.values(categories).map(category => (
              <div 
                onClick={()=>{
                  this.props.history.push({
                    pathname:'/menu/menu/'+this.menu_uuid,
                    search:`?tab=${this.state.tab}`,
                    state:{
                      section:category.menu_category_id
                    }
                  })
                }}
                className='menu-section-box'
              >
                <div className='menu-box-title' >
                  {category.name}
                </div>
                <div className='menu-box-subtitle'>
                  {category.items.length} items
                </div>
              </div>
            ))}
            {blackedOutItems.length > 0 && (
              <>
                <div className='menu-section-hr'></div>
                <div 
                  onClick={()=>this.scrollToSection('Deactivated Items')}
                  className='menu-section-box'
                >
                  <div className='space-between'>
                    <div className='menu-box-title' >
                      Deactivated
                    </div>
                    <Deactivate style={{width:'25px', height:'25px', position:'relative', top:'-2px'}}/>
                  </div>
                  <div className='menu-box-subtitle'>
                    {blackedOutItems.length} items
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
   

      </>
    ) 
  }


}
      

export default withRouterAndRef(ToastContextHOC(UserContextHOC(MenuCategories))) 

