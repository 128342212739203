import React, { Component } from "react"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import DesktopSidebar from "../menu/DesktopSidebar"
import DesktopTabs from "./DesktopTabs"
import DesktopIncomingOrders from "./DesktopIncomingOrders"
import UserContextHOC from "../contexts/UserContextHOC"
import OrderCard from "./OrderCard"
import OrderToast from './OrderToast'
import classnames from "classnames"
import { withRouter } from "react-router-dom"
import Loading from '../Loading/Loading'
import SearchBar from "./SearchBar"
import Check from "./svg/Check"
import "./Home.css"
import "./DesktopOrders.css"

const Copy = require("../helpers/copy")
const Api = require("../api")

class ActiveOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeOrders:Copy.copy(this.props.orders.activeOrders),
      isDesktop:window.innerWidth >= 1000,
      toast:null,
    }
    window.ac = this
  }

  componentDidMount(){
    this.props.orders.checkForActiveOrders()
    if(
      this.props.history && 
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.toast
    ){
      this.addToast(this.props.history.location.state.toast)
    }
  }

  componentDidUpdate(prevProps){
    if(JSON.stringify(this.state.activeOrders) !== JSON.stringify(this.props.orders.activeOrders)){
      this.setState({activeOrders:Copy.copy(this.props.orders.activeOrders)})
    }
  }

  componentWillUnmount(){
  }

  addToast(toast){
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }
  }

  readyOrder(order, callback){
    let endpoint = order.pickup_or_delivery === 'pickup' ?
      'ready-for-pickup' : 'out-for-delivery'
    let toastText = order.pickup_or_delivery === 'pickup' ?
      'Ready for Pickup' : 'Out for Delivery'
    Api.callApi({
      endpoint:endpoint,
      data:{order_id:order.order_id},
      success:(data)=>{
        callback()
        this.props.orders.checkForActiveOrders()
        this.props.orders.checkForAwaitingOrders()
        this.addToast({
          type:'ready',
          text:toastText,
          order:order
        })
      },
      failure:()=>{
        callback()
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error updating this order.'
          }
        })
        this.props.orders.checkForActiveOrders(false)
        this.props.orders.checkForAwaitingOrders()
      }
    })
  }


  totalActiveOrders(){
    let total = 0
    total += this.state.activeOrders['pastdue'].length
    total += this.state.activeOrders['zero-15'].length
    total += this.state.activeOrders['fifteen-30'].length
    total += this.state.activeOrders['thirty-45'].length
    total += this.state.activeOrders['45-plus'].length
    return total
  }

  renderBucket(bucket){
    let bucketHeaderLookup = {
      'pastdue':'PAST DUE',
      'zero-15':'DUE IN 15 MINUTES',
      'fifteen-30':'15-30 MINUTES',
      'thirty-45':'30-45 MINUTES',
      '45-plus':'45+ MINUTES'
    }
    let orders = this.state.activeOrders[bucket]
    if(!orders.length) return null
    return (
      <div className={classnames('orders-bucket', 'active-orders-bucket',
       {'orders-bucket-warning':bucket === 'zero-15' || bucket === 'pastdue'})}>
        <div className='orders-bucket-header'>
          <div className='orders-bucket-header-left'> 
            {bucket === 'pastdue' ? <Warning/> : <Clock/>}
            {bucketHeaderLookup[bucket]}
          </div>
          <div className='orders-bucket-header-right'>({orders.length})</div>
        </div>
        {orders.map((order)=>(
          <OrderCard
            page='kitchen'
            key={order.order_id}
            action={(cb)=>this.readyOrder(order, cb)}
            order={order}
            clock={bucket == 'pastdue' ? 'pastdue': 'due'}
            text='Ready'
            svg={<Check className='ready-check'/>}
            loadingText='Ready'
          />
        ))}
      </div>
    )

  }

  renderBuckets(){
    let to_ret = []
    to_ret.push(this.renderBucket('pastdue'))
    to_ret.push(this.renderBucket('zero-15'))
    to_ret.push(this.renderBucket('fifteen-30'))
    to_ret.push(this.renderBucket('thirty-45'))
    to_ret.push(this.renderBucket('45-plus'))
    return to_ret
  }
 

  renderContent(){
    return (
      <div className='orders-home-container'>
        {this.state.isDesktop && (
          <DesktopTabs />
        )}
        
        <div className='orders-content desktop-orders-content'>
          {this.state.isDesktop !== true && (
            <SearchBar />
          )}

          <div className='orders-home-current-page'>
            <div>Kitchen ({this.totalActiveOrders()})</div>
            {this.state.isDesktop === true && (
              <SearchBar />
            )}
          </div>
          <div className='orders-home-active-orders-container'>
            {this.state.toast && (
              <OrderToast
                toast={this.state.toast}
                text={this.state.toast.text}
                type={this.state.toast.type}
                order={this.state.toast.order}
              />
            )}

            {this.totalActiveOrders() === 0 && !this.state.toast   && (
              <div className='no-orders-container'>
                <img
                  className='no-orders-img-bottle'
                  src='https://cdn.hngr.co/captain/NoOrdersMessageBottle2.png'
                />  
                <div className='no-orders-top-text'>
                  No Orders in Kitchen
                </div>
                <div className='no-orders-bottom-text'>
                  It’s quiet...too quiet. We’ll notify you when an order comes in.
                </div>
              </div>
            )}
            {this.renderBuckets()}
          </div>
        </div> 
      </div>
    )
  }


  render(){
    if(this.state.isDesktop){
      return (
        <div className='desktop-orders-page-container'>
          <DesktopSidebar />
          <div className='desktop-orders-container-container'>
            {this.renderContent()}
          </div>
          <div className='desktop-orders-incoming-orders-container'>
            <DesktopIncomingOrders />
          </div>
        </div>
      )
    } else {
      return this.renderContent()
    }
  }
}
      

export default withRouter(UserContextHOC(NewOrdersContextHOC(ActiveOrders))) 

const Clock = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.848 12.459c.202.038.202.333.001.372-1.907.361-6.045 1.111-6.547 1.111-.719 0-1.301-.582-1.301-1.301 0-.512.77-5.447 1.125-7.445.034-.192.312-.181.343.014l.985 6.238 5.394 1.011z"/></svg>)

const Warning = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg>)

const Calendar = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"/></svg>)

