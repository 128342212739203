function getDateObject(timestamp){
  var d = new Date(0)
  d.setUTCSeconds(timestamp)
  return d
}

function getHours(timestamp){
  let date = getDateObject(timestamp)
  return date.getUTCHours()
}

function getMonthInt(timestamp){
  let date = getDateObject(timestamp)
  return date.getUTCMonth() + 1
}

function getMonthName(timestamp){
  let months = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December']
  return months[getMonthInt(timestamp) - 1 ]
}

function getMonthNameShort(timestamp){
  let months = ['Jan', 'Feb', 'Mar', 'Ap', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return months[getMonthInt(timestamp) - 1 ]
}

function getFullYear(timestamp){
  let date = getDateObject(timestamp)
  return date.getUTCFullYear() 
}

function getPartialYear(timestamp){
  let date = getDateObject(timestamp)
  let year = date.getUTCFullYear().toString() 
  return year.slice(-2)
}

function getDayOfMonth(timestamp){
  let date = getDateObject(timestamp)
  return date.getUTCDate() 
}

function getDayOfWeek(timestamp){
  let dows = ['Sun','Mon','Tues','Wed','Thur','Fri','Sat']
  let date = getDateObject(timestamp)
  let dow_int = date.getUTCDay()
  return dows[dow_int]
}

function getDayOfWeekFull(timestamp){
  let dows = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
  let date = getDateObject(timestamp)
  let dow_int = date.getUTCDay()
  return dows[dow_int]
}

// given a timestamp, rown down to midnight of that day
function get12pm(timestamp){
  let date = getDateObject(timestamp)
  let secondsAfterMidnight = date.getUTCSeconds() 
    + date.getUTCMinutes() * 60
    + date.getUTCHours() * 60 * 60
  return timestamp - secondsAfterMidnight
}

//return 3:00pm
function getTimeOfDay(timestamp){
  let date = getDateObject(timestamp)
  let hour = getHours(timestamp)
  let ampm
  if (hour >= 12){
    ampm = 'PM'
  } else{
    ampm = 'AM'
  }

  let minute = date.getUTCMinutes()
  if (parseInt(minute) < 10){
    minute = '0' + minute
  }

  if (hour > 12){
    hour = hour-12
  }

  if(hour === 0){
    hour = 12
  }

  return `${hour}:${minute} ${ampm}`
}


module.exports = {
  getDateObject: getDateObject,
  getMonthInt: getMonthInt,
  getMonthName:getMonthName,
  getDayOfMonth: getDayOfMonth,
  getTimeOfDay: getTimeOfDay,
  getDayOfWeekFull: getDayOfWeekFull,
  getDayOfWeek: getDayOfWeek,
  getPartialYear: getPartialYear,
  get12pm: get12pm,
  getFullYear:getFullYear,
  getMonthNameShort:getMonthNameShort
}
