let formatDate = function(datetime) {
  let date = new Date(datetime)
  return date.getUTCMonth() + 1 + "/" + date.getUTCDate() + "/" + date.getUTCFullYear()
}

let formatTime = function(datetime) {
  let date = new Date(datetime)
  return date.getUTCHours() + ":" + ("0" + date.getUTCMinutes()).slice(-2)
}

let formatDateTime = function(datetime){
  return formatDate(datetime) + ' ' + formatTime(datetime)
}


function addZ(n){
  return n<10? '0'+n:''+n;
}

//returns mm/dd/yyyy
let yyyy_mm_dd = function(dt){
  let m = addZ(dt.getMonth() + 1)
  let d = addZ(dt.getDate())
  let y = dt.getFullYear()
  let str = y + '-' + m + '-' + d 
  return str
}

// returns 23:59
let timeString = function(datetime){
  let h = addZ(datetime.getUTCHours())
  let m = addZ(datetime.getMinutes())
  return h+':'+m
}

module.exports = {
  formatDate:formatDate,
  formatTime:formatTime,
  formatDateTime:formatDateTime,
  yyyy_mm_dd:yyyy_mm_dd,
  timeString:timeString
}
