import React from "react"
import classnames from "classnames"
import "./toggle.scss"

const Toggle = ({
  label = "toggle",
  isChecked = false,
  onToggle = () => {},
  children = () => {},
}) => {
  const [checked, setChecked] = React.useState(isChecked)
  const handleToggle = (e) => {
    setChecked(!checked)
    onToggle(e)
  }
  return (
    <>
      <label
        className={classnames("toggle-label", {
          "toggle-label--checked": checked,
        })}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={handleToggle}
          className="no-ryan-input"
        />
        <span
          className={classnames("toggle-switch", {
            "toggle-switch--checked": checked,
          })}
        ></span>
      </label>
      {children({ checked })}
    </>
  )
}

export default Toggle
