import React, { Component } from "react"
import DesktopSidebar from "../menu/DesktopSidebar"
import DesktopTabs from "./DesktopTabs"
import DesktopIncomingOrders from "./DesktopIncomingOrders"
import OrderCard from "./OrderCard"
import classnames from "classnames"
import "./OrderCard.css"
import "./Home.css"
import Loading from '../Loading/Loading'
import SearchBar from "./SearchBar"
import withRouterAndRef from "../withRouterAndRef"
import UserContextHOC from "../contexts/UserContextHOC"
const Api = require("../api")
const Timestamp = require("../helpers/timestamps")

class OrderHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:true,
      isDesktop:window.innerWidth > 1000
    }
  }

  componentDidMount(){
    this.loadHistory()
  }

  loadHistory(){
    Api.callApi({
      endpoint:'history',
      success:(data)=>{
        this.setState({
          orders:data.orders,
          loading:false
        })
      },  
      failure:()=>{
        this.props.history.push({
          pathname:this.props.location.pathname+"/error-modal",
          state:{
            message:'There was an error loading the orders'
          }
        })
      }   
    })  
  }

  renderContent(){
    if(this.state.loading) return (
      <div>
        <Loading/>
      </div>
    )

    return (
      <div className='orders-content desktop-orders-content' style={{position:'relative', top:'-5px'}}>
          <SearchBar sticky={true} />
          <div className='orders-home-current-page'> 
            Recent Order History
          </div>
          {this.state.orders.map(order =>(
            <OrderCard 
              key={order.order_id}
              order={order}
              page='history'
            />
          ))}
      </div>
    )
  }

  render(){
    if(this.state.isDesktop){
      return (
        <div className='desktop-orders-page-container'>
          <DesktopSidebar />
          <div className='desktop-orders-container-container'>
            <DesktopTabs />
            {this.renderContent()}
          </div>
          <div className='desktop-orders-incoming-orders-container'>
            <DesktopIncomingOrders />
          </div>
        </div>
      )
    } else {
      return this.renderContent()
    }
  }


}

export default withRouterAndRef(UserContextHOC((OrderHistory)))

