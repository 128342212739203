import React, { Component } from 'react';
import Loading from '../Loading/Loading'
import Transfer from './Transfer'
import './Transfers.css'

const API = require('../api')

class Transfers extends Component {

  constructor(props){
    super(props)
    this.state = {
      transfers:[],
      loaded:false,
      rest:null,
      expanded: null
    }
  }

  componentDidMount(){
    this.loadTransfers()
  }

  async loadTransfers(){
    let rest = await this.props.loadRest()
    API.call(
      'transfers',
      res=>this.setState({transfers:res.transfers, loaded:true}),
      err=>console.log('Error loading transfers',err),
      null,
      {payout_id:this.props.payout,
       rest_id:rest.restaurant_id}
    )   
  }

  renderExpansion(){
    if (this.state.expanded){
      return <Transfer transfer_id={this.state.expanded} {...this.props} closeExpansion={()=>this.setState({expanded:null})}/>
    }
  }

  render(){
    if (this.state.loaded){
      return (
        <tr style={{width:'100%'}}>
          <td colspan={2} className='pv32 ph32 w100 borderbox' style={{overflowX: 'auto'}}>
            {this.renderExpansion()}
            <h2 className='m0 left'>Transfers in this payout</h2>
            {this.state.transfers.length > 0 ? (
            <table style={{borderRadius:0}}>
              <thead>
                <tr>
                  <th id='Date' className='left fs12 fw900'>Date</th>
                  <th id='Totals' className='left fs12 fw900'>Totals</th>
                  <th id='Adjustments' className='left fs12 fw900'>Adjustments</th>
                  <th id='HNGRFees' className='left fs12 fw900'>HNGR Fees</th>
                  <th id='Processing' className='left fs12 f9500'>Processing</th>
                  <th id='Subscription' className='left fs12 fw900'>Subscription</th>
                  <th id='MiscFees' className='left fs12 fw900'>Misc Fees</th>
                  <th id='Net' className='left fs12 fw900'>Net</th>
                </tr>
              </thead>
              <tbody>
                {this.state.transfers.map(t => {
                  let months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
                  let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
                  let d = new Date(t.created_at)
                  let date = days[d.getDay()] + ' ' + months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear()
                  return(
                    <tr key={t.transfer_id} onClick={()=>this.setState({expanded:t.transfer_id})}>
                      <td headers='Date' className='left fs12 fw500' style={{paddingLeft:'8px'}}>{date}</td>
                      <td headers='Totals' className='left fs12 fw500' style={{paddingLeft:'8px'}}>{t.order_totals}</td>
                      <td headers='Adjustments' className='left fs12 fw500' style={{paddingLeft:'8px'}}>{t.order_adjustments}</td>
                      <td headers='HNGRFees' className='left fs12 fw500' style={{paddingLeft:'8px'}}>{t.application_fees}</td>
                      <td headers='Processing' className='left fs12 fw500' style={{paddingLeft:'8px'}}>{t.processing_fees}</td>
                      <td headers='Subscription' className='left fs12 fw500' style={{paddingLeft:'8px'}}>{t.subscription_fees}</td>
                      <td headers='MiscFees' className='left fs12 fw500' style={{paddingLeft:'8px'}}>{t.misc_fees}</td>
                      <td headers='Net' className='left fs12 fw500' style={{paddingLeft:'8px'}}>{t.amount}</td>
                    </tr>
                  )})}
                </tbody>
              </table> ) : <div> No data available </div> }
            </td>
          </tr>
      )
    } else return <Loading/>
  }
}

export default Transfers;
