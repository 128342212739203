import React from "react"
import cx from "classnames"
import "./input.scss"

const Input = (props) => {
  const handleChange = (e) => {
    props.onChange(e.target.value)
  }
  return (
    <div
      data-error-message={props.errorMessage}
      className={cx("input__container", {
        "input__container--error": props.hasError,
        [props.containerClassName]: props.containerClassName,
      })}
    >
      <label hidden>{props.label}</label>
      <input
        onChange={handleChange}
        placeholder={props.pace}
        value={props.value}
        className={cx("input--default", {
          "input--error": props.hasError,
          [props.className]: !!props.className,
        })}
      />
    </div>
  )
}

Input.defaultProps = {
  onChange: () => {},
  hasError: false,
  validation: () => {},
  value: "",
  label: "",
}

export default Input
