import React, { Component } from "react"
import {Capacitor} from '@capacitor/core';
import { Plugins } from '@capacitor/core'
const {StripeReaderPlugin} = Plugins;


const Api = require("../api")

class ConnectPOS extends Component {
  constructor(props) {
    super(props)
    this.state = {
      readers:[]
    }
  }

  componentDidMount(){
  }

  async getReaders(){
    let results = await StripeReaderPlugin.getReaders()
    alert(JSON.stringify(results))
    let readers = []
    for(let i in results){
      readers.push(i)
    }
    this.setState({readers:readers})
  }

  async connect(name){
    let result = await StripeReaderPlugin.connectToReader({name:name})
    alert(JSON.stringify(result))
  }

  async paymentIntent(){
    alert('yao0')
    let result = await StripeReaderPlugin.collectPayment()
    alert('yao')
  }
  
  render(){
    return (
      <div>
        <br/><br/><br/>
        hey scott and waffs
        <button
          onClick={()=>this.getReaders()}
        >
          CLICK ME
        </button>
        {this.state.readers.map(r => (
          <button style={{margin:'30px'}}onClick={()=>this.connect(r)} >{r}</button>
        ))}


        <button
          style={{marginTop:'50px', display:'block'}}
          onClick={()=>this.paymentIntent()}
        >
          PAYMENT INTENT BITCHES
        </button>
      </div>

    )
  }

}
      

export default ConnectPOS 


