import React, { Component } from "react"
import UserContextHOC from "../../contexts/UserContextHOC"
import Loading from "../Loading/Loading"
import Transfers from "../Transfers/Transfers"

const API = require("../api")
const formatPrice = require("../Helpers/formatPrice")

class Payouts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rest: this.props.user.rest,
      loading: false,
      start: null,
      start_time: "00:00",
      end_time: "00:00",
      end: null,
      apiKey: localStorage.getItem("api_key"),
      diners: [],
    }
    this.loadDiners = this.loadDiners.bind(this)
    window.y  = this
  }

  componentDidUpdate(prevProps){
    if(JSON.stringify(this.state.rest) !== JSON.stringify(this.props.user.rest)){
      this.setState({rest:this.props.user.rest})
    }
  }   

  componentDidMount() {
    this.setState({rest:this.props.rest})
  }

  async loadDiners() {
    if (!this.state.start) {
      alert("Please select a start date")
      return
    }
    if (!this.state.end) {
      alert("Please select an end date")
      return
    }
    let rest = this.props.rest 
    this.setState({ loading: true })
    API.call(
      "diners",
      (res) => {
        this.setState({ diners: res.diners, loading: false })
      },
      (err) => {
        alert("Error loading diners")
        this.setState({ loading: false })
      },
      null,
      {
        start: this.state.start,
        end: this.state.end,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        rest_id: rest.restaurant_id,
      }
    )
  }

  render() {
    return (
      <div className="w100 inline-block center">
        <div style={{ width: "100%", marginTop: "30px" }}>
          Start Date
          <input
            type="date"
            className="datepicker"
            value={this.state.start}
            onChange={(event) => this.setState({ start: event.target.value })}
          />
          &nbsp;&nbsp;Start Time
          <input
            type="time"
            className="datepicker"
            value={this.state.start_time}
            onChange={(event) =>
              this.setState({ start_time: event.target.value })
            }
          />
        </div>
        <br />
        <br />
        <div style={{ width: "100%" }}>
          End Date
          <input
            type="date"
            className="datepicker"
            value={this.state.end}
            onChange={(event) => this.setState({ end: event.target.value })}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;End Time
          <input className="datepicker"
            type="time"
            value={this.state.end_time}
            onChange={(event) =>
              this.setState({ end_time: event.target.value })
            }
          />
        </div>
        <button
          className="button"
          onClick={this.loadDiners}
          style={{ marginLeft: "20px" }}
        >
          Load Diners
        </button>
        <h1 className="left fw900 ph16 pv16 mv16">
          Diners
          <form
            method='post'
            style={{ display: "inline-block", marginLeft: "30px" }}
            action={`${process.env.REACT_APP_FOODCOIN_API_LOCATION}/member/captain/diners`}
          >
            <input
              type="hidden"
              name="key"
              value={window.API_KEY}
            />
            <input type="hidden" name="csv" value="1" />
            <input
              type="hidden"
              name="rest_id"
              value={this.state.rest.restaurant_id}
            />
            <input type="hidden" name="start" value={this.state.start} />
            <input
              type="hidden"
              name="start_time"
              value={this.state.start_time}
            />
            <input type="hidden" name="end" value={this.state.end} />
            <input type="hidden" name="end_time" value={this.state.end_time} />
            <button className="button">Export</button>
          </form>
        </h1>
        {this.state.loading ? (
          <Loading />
        ) : (
          <table
            cellSpacing="0"
            className="w100 center"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <td>Name</td>
                <th>Orders</th>
                <th>Pickup</th>
                <th>Delivery</th>
                <th>Promo</th>
                <th>Product</th>
                <th>Tax</th>
                <th>Tip</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {this.state.diners.map((diner) => (
                <tr>
                  <td>{diner.name}</td>
                  <td>{diner.orders}</td>
                  <td>{diner.pickup_orders}</td>
                  <td>{diner.delivery_orders}</td>
                  <td>{diner.promo}</td>
                  <td>{diner.product}</td>
                  <td>{diner.tax}</td>
                  <td>{diner.tip}</td>
                  <td>{diner.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  }
}

export default UserContextHOC(Payouts)
