import React, { Component } from "react"
import classnames from "classnames"
import UserContextHOC from "../contexts/UserContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import { Capacitor, Plugins } from '@capacitor/core'
import CheckmarkCircle from "../orders/svg/CheckmarkCircle"
import Loading from "../Loading/Loading"
import "./Connected.css"
const {StripeReaderPlugin} = Plugins;
const Api = require("../api")


class TerminalConnected extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:true
    }
  }

  componentDidMount(){
    this.getConnectedReader().catch(err =>{
      this.postToSlack(`Error connecting to reader inside already-connected-settings-page (${err.message$})`)
    })
  }

  postToSlack(msg){
    Api.callApi({
      endpoint:'/stripe-pos/slack-message',
      method:'post',
      data:{
        msg:msg
      },  
      success:(data)=>{
      },  
      failure:(err)=>{
      }   
    })  
  }


  async getConnectedReader(){
    this.setState({loading:true})
    let results = await StripeReaderPlugin.getConnectedReader()
    if(results['reader'] == 0){
      this.postToSlack('Not connected reader when on terminal settings connection page - redirecting back to Choose Terminal')
      alert('Error connecting wtith reader')
      this.props.history.replace({
        pathname:'/stripe-pos/home'
      })
    } else{
      this.setState({
        loading:false,
        reader:results['reader']
      })
    }
  }

  async disconnect(){
    this.setState({loading:true})
    let results = await StripeReaderPlugin.disconnectReader()
    if (results['success'] == 1){
      this.props.user.disconectStripeTerminal( ()=> { 
        alert('Disconnected')
        this.props.history.replace({
          pathname:'/stripe-pos/home'
        })
      })
    } else {
      alert('Error disconnecting terminal')
      window.location.reload()
    }

  }

  render(){
    if(this.state.loading) {
      return (
        <div>
          <Loading/>
        </div>
      )
    }
    return (
      <div>
        <div className='terminal-connected'>
          <CheckmarkCircle />
          <div> Terminal Sucessfully Connected </div>
        </div>
        <div className='orders-content'>
          <div className='choose-reader-title'>
            Stripe Terminal Settings
          </div>
          
          <div className='choose-reader-reader'>
            <div className='choose-reader-name'>{this.state.reader}</div>
            <div
              className='choose-reader-disconnect'
              onClick={()=>this.disconnect()}
            >
              <div>Disconnect</div>
            </div>
          </div>

        </div>
      </div>
    )
  }

}
      
export default withRouterAndRef(UserContextHOC(TerminalConnected))


