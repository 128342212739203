import React from 'react';
import { UserContext } from './UserContext';

export default function UserContextHOC(Component) {
  return React.forwardRef((props, ref) => (
    <UserContext.Consumer>
      {context => <Component ref={ref} user={context} {...props} />}
    </UserContext.Consumer>
  ))
}
