function formatCents(cents){
  if(!cents) return '$0.00'
  // get cents to 
  let dollars = cents/100
  return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
}

module.exports = {
  formatCents: formatCents,
}
