import React, { Component } from "react"
import CartParsed from '../helpers/cart'
import classnames from "classnames"
import Loading from "../Loading/Loading"
import Utensils from "./svg/Utensils"
import UtensilsNo from "./svg/UtensilsNo"
import "./Cart.css"
const Api = require("../api")
const Money =  require("../helpers/money")


class Cart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded:false
    }
    window.c  = this
  }

  componentDidMount(){
    this.getCart()
  }

  getCart(){
    Api.callApi({
      endpoint:'get-cart',
      data:{order_id:this.props.order.order_id},
      success:((data)=>{
        this.setState({
          loaded:true,
          cart:new CartParsed(data.cart)
        })
      }),
      failure:()=>{
        this.setState({
          loaded:true,
          error:true
        })
      }
    })
  }

  renderModifiers(item){
    return item.childrenFlat().map((modifier, i) => {
      let indent = 2 + 8 * modifier.level + "px"
      return (
        <div
          key={i}
          className="cart-item-modifier"
          style={{ paddingLeft: indent }}
        >
          <div>• {modifier.displayName()}</div>
          <div>
            {modifier.receiptPrice && (
              <>{Money.formatCents(modifier.receiptPrice)}</>
            )}
          </div>
        </div>
      )
    })
  }

  renderItem(item, index , isLastItem){
    return (
      <div className='cart-item-container'>
        <div 
          className={classnames('cart-item-inner-container',
            {'cart-item-inner-container-last-item':isLastItem})}
            
        > 
          <div className='space-between'>
            <div className='cart-item-quantity-and-name-container'>
              <div 
                className={classnames('cart-item-quantity',
                  {'cart-item-quantity-multiple':item.quantity > 1})}
              >
                {item.quantity}
              </div>
              <div className='cart-item-name'>{item.name}</div>
            </div>

            <div className='cart-item-price'>
              {item.quantityPriceFormatted()}
            </div>
          </div>

          {item.specialInstructions && (
            <div className='cart-item-special-instructions'>
              <Warning className='special-instructions-warning'/>
              {item.specialInstructions}
            </div>
          )}
         
          {item.childrenFlat().length ? (
            <div className='cart-item-modifier-container'>
              {this.renderModifiers(item)}
            </div>
          ) : null}
        </div> 
      </div>
    )
  }

  renderPromoAmount(){
    let amount = 0 
    if(this.props.order.free_delivery){
      if(this.props.order.promo_amount_without_free_delivery){
        amount = this.props.order.promo_amount_without_free_delivery
      } else {
        return null
      }
    } else if(this.props.order.promo_amount){
      amount = this.props.order.promo_amount
    }

    if(amount){
      return (
        <div className='cart-finance-row'>
          <div className='cart-finance-row-left'>
            Coupon
          </div>
          <div className='cart-finance-row-right'>
            -{Money.formatCents(amount)}
          </div>
        </div>
      )
    }
  }

  render(){
    if(!this.state.loaded) return <Loading /> 
    if(this.state.error){
      return (
        <div className='error-loading-order'>Error loading order...</div>
      )
    }
    return (
      <div className='cart-container'>
        <div className='cart-first-row'>
          <div className='cart-first-row-inner'>
            <div className='cart-num-items'>
              {this.state.cart.itemsTree.length} Items
            </div>
            <div className='cart-order-hash'>
              {this.props.order.hash_id}
            </div>
          </div>
        </div>

        {this.props.order.special_instructions && (
          <div className='full-page-card-special-instructions-wrapper'>
            <div className='full-page-card-special-instructions'>
              <div className='full-page-card-special-instructions-header'>
                <Warning className='full-page-card-special-instructions-warning'/>
                <div className='full-page-card-special-instructions-header-text'>
                  SPECIAL INSTRUCTIONS
                </div>
              </div>
              <div className='full-page-card-special-instructions-text'>
                {this.props.order.special_instructions}
              </div>
            </div>
          </div>
        )}

        <div className='cart-items-container'>
          {this.state.cart.itemsQuantity.map((topLevelItem, idx) => (
            <div key={'item' + idx.toString()} >
              {this.renderItem(topLevelItem, idx, idx+1 === this.state.cart.itemsQuantity.length)}
            </div>
          ))}


        {this.props.order.utensils === false && (
          <div className='cart-utensils-row cart-item-container'>
            <div className='cart-utensils-left'>
              <div className='utensils-yes-no'>NO</div>
              <div className='cart-utensils-text cart-item-name'>
                Napkins &amp; Utensils
              </div>
            </div>
            <UtensilsNo style={{position:'relative', top:'-2px'}}/>
          </div>
        )}

        {this.props.order.utensils === true && (
          <div className='cart-utensils-row cart-item-container'>
            <div className='cart-utensils-left'>
              <div className='utensils-yes-no'>YES</div>
              <div className='cart-utensils-text cart-item-name'>
                Include Napkins &amp; Utensils
              </div>
            </div>
            <Utensils/>
          </div>
        )}

        </div> {/* cart-items-container */}

        <div className='cart-finance-container'>
          
          <div className='cart-finance-row'>
            <div className='cart-finance-row-left'>
              Subtotal
            </div>
            <div className='cart-finance-row-right'>
              {Money.formatCents(this.props.order.subtotal)}
            </div>
          </div>

          {this.props.order.delivery_small_order_fee !==  0 && (
            <div className='cart-finance-row'>
              <div className='cart-finance-row-left'>
                Small Order
              </div>
              <div className='cart-finance-row-right'>
                {Money.formatCents(this.props.order.delivery_small_order_fee)}
              </div>
            </div>
          )}

          {this.props.order.pickup_or_delivery === 'delivery' && (
            <div className='cart-finance-row'>
              <div className='cart-finance-row-left'>
               Delivery 
              </div>
              <div className='cart-finance-row-right'>
                {this.props.order.free_delivery ? (
                  <>$0.00</>
                ) : (
                  <>{Money.formatCents(this.props.order.delivery_fee)}</>
                )}
              </div>
            </div>
          )}


          {this.props.order.delivery_service_fee > 0 && (
            <div className='cart-finance-row'>
              <div className='cart-finance-row-left'>
                Taxes &amp; fees
              </div>
              <div className='cart-finance-row-right'>
                {Money.formatCents(this.props.order.tax + this.props.order.delivery_service_fee)}
              </div>
            </div> 
          )}

          {!this.props.order.delivery_service_fee && (
            <div className='cart-finance-row'>
              <div className='cart-finance-row-left'>
                Tax
              </div>
              <div className='cart-finance-row-right'>
                {Money.formatCents(this.props.order.tax)}
              </div>
            </div>
          )}

          <div className='cart-finance-row'>
            <div className='cart-finance-row-left'>
             Tip 
            </div>
            <div className='cart-finance-row-right'>
              {Money.formatCents(this.props.order.tip)}
            </div>
          </div>


          {this.renderPromoAmount()}

          
          {this.props.order.adjustment_total > 0 && (
            <div className='cart-finance-row'>
              <div className='cart-finance-row-left'>
                Upcharge
              </div>
              <div className='cart-finance-row-right'>
                {Money.formatCents(this.props.order.adjustment_total)}
              </div>
            </div>
          )}

          {this.props.order.adjustment_total < 0 && (
            <div className='cart-finance-row'>
              <div className='cart-finance-row-left red'>
                Refund
              </div>
              <div className='cart-finance-row-right'>
                - {Money.formatCents(this.props.order.adjustment_total * -1)}
              </div>
            </div>
          )}

          <div className='cart-finance-row total-row'>
            <div className='cart-finance-row-left'>
              Total
            </div>
            <div className='cart-finance-row-right'>
              {Money.formatCents(this.props.order.total + this.props.order.adjustment_total)}
            </div>
          </div>

          <div className='cart-paid'>
            {this.props.order.mmc_student_id ? 'MMC Student ID' : (
            this.props.order.is_cash_order ? 'Cash Payment' : 'Prepaid with Card')}
          </div>
        
        </div>
      
      </div>
    )
  }
}
      
export default Cart 

const Warning = props => (<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg>)

