import React, { Component } from "react"
import ToastContextHOC from "../contexts/ToastContextHOC"
import Calendar from "../orders/svg/Calendar"
import Clock from "../orders/svg/Clock"
import withRouterAndRef from "../withRouterAndRef"
import * as Sentry from "@sentry/browser";
import "./DeactivateModal.css"

const Api = require("../api")

class ArchiveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item:{},
      date:null,
      time:null,
    }
    if(
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.item
    ){
      this.state.item = this.props.history.location.state.item
      this.state.modifier = this.props.history.location.state.modifier
    }
    this.handleClickBackground = this.handleClickBackground.bind(this)
    window.d = this
  }

  componentDidMount(){
    window.addEventListener('mousedown', this.handleClickBackground)
  }

  componentWillUnmount(){
    window.removeEventListener('mousedown', this.handleClickBackground)
  }

  close(){
    this.props.history.goBack()
  }

  handleClickBackground(e){
   if (e.target.className === 'modal-background'){
    this.close()
   }
  }

  archive(){
  if (this.state.modifier) {
      Api.callApi({
        endpoint:'/api/tablet/archive-modifier',
        method:'post',
        data:{
          modifier_id:this.state.modifier.modifier_id
        },
        success:(data)=>{
          let toast = { 
            type:'generic',
            title:'Modifier Archived'
          }
          // we need to go back and toast. 
          this.props.toast.setToast(toast , true)
        },
        failure:(err)=>{
          console.log("error", err)
          alert('An unexpected error occured.')
        }
      })
    }  else if (this.state.item){
      Api.callApi({
        endpoint:'/api/tablet/archive-item',
        method:'post',
        data:{
          menu_item_id:this.state.item.menu_item_id
        },
        success:(data)=>{
          let toast = { 
            type:'generic',
            title:'Item Archived',
            message:this.state.item.name
          }
          // we need to go back and toast. 
          this.props.toast.setToast(toast , true)
        },
        failure:(err)=>{
          console.log("error", err)
          alert('An unexpected error occured.')
        }
      })
    } 

  }

  render() {
    return  (
      <div 
        className='modal-background' 
      >
        <div className='deactivate-modal-container'>
          <div className='deactivate-modal-title'>
            Archive {this.state.item.name}
          </div>

          <div className='deactivate-buttons-container'>
            <div 
              onClick={()=>{
                this.close()
              }}
              className='deactivate-button deactivate-cancel'
            >
              <div>Cancel</div>
            </div>
            <div 
              className='deactivate-button deactivate-yes'
              onClick={()=>this.archive()}
            >
              <div>Yes, Archive </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouterAndRef(ToastContextHOC(ArchiveModal))
