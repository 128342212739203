import React, { Component } from "react"
import ToastContextHOC from "../contexts/ToastContextHOC"
import Calendar from "../orders/svg/Calendar"
import Clock from "../orders/svg/Clock"
import withRouterAndRef from "../withRouterAndRef"
import classnames from "classnames"
import Loading from "../Loading/Loading"
import ModifierModalContextHOC from "../contexts/ModifierModalContextHOC"
import * as Sentry from "@sentry/browser";
import "./CreateModifierModal.css"

const Api = require("../api")

class CreateModifierModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name:null,
      price:null
    }
    this.handleClickBackground = this.handleClickBackground.bind(this)
    window.d = this
  }

  componentDidMount(){
    window.addEventListener('mousedown', this.handleClickBackground)
  }

  componentWillUnmount(){
    window.removeEventListener('mousedown', this.handleClickBackground)
  }

  handleClickBackground(e){
   if (e.target.className === 'modal-background'){
    this.props.history.goBack()
   }
  }

  save(name=null, price=null, modifier_class_id=null, confirm_change_ok=false){
    this.setState({loading:true})
    Api.callApi({
      endpoint:'/api/tablet/create-modifier',
      method:'post',
      data:{
        modifier_class_id:modifier_class_id ? modifier_class_id : this.props.history.location.state.modifierClass.modifier_class_id,
        name:name ?  name : this.state.name,
        price:price ? price : this.price(),
        confirm_change_ok:confirm_change_ok
      },
      failure:(err)=>{
        this.setState({loading:false})
        alert('Unexected error')
      },
      success:(data)=>{
        if(data.needs_confirmation){
          let pathname = this.props.location.pathname
          pathname = pathname.replace('create-modifier-modal', '')
          let name = this.state.name
          let price = this.price()
          let modifier_class_id=this.props.history.location.state.modifierClass.modifier_class_id
          this.props.modal.setSave(()=>{
            this.save(name, price, modifier_class_id, true)
          })
          this.props.history.replace({
            pathname:pathname + '/modifier-class-confirm-modal',
            state:{
              items:data.impacted_items
            }
          })
          return
        }
        this.setState({loading:false})
        let toast = { 
          type:'generic',
          title:'Modifier Created',
          message:this.state.name
        }
        this.props.toast.setToast(toast, true)
      }
    })
  }

  filterMoneyInput(val){
    if(val){
      return val.match(/^-?\d+(?:\.\d{0,2})?/)[0]
    } else {
      return val 
    }   
  }

  price(){
    let price = parseInt(parseFloat(this.state.price)*100)
    if (!price) return 0
    return price
  }

  render() {
    return  (
      <div 
        className='modal-background' 
      >
        <div className='deactivate-modal-container'>
          <div className='deactivate-modal-title'>
            Create New Modifier
          </div>

          <div 
            className='edit-item-field-wrapper'
            style={{
              width:'100%',
              marginTop:'25px',
              marginBottom:'15px'
            }}
          > 
            <div className='edit-item-label'>Modifier Name*</div>
            <input 
              className='edit-item-input'
              value={this.state.name}
              onChange={(e)=>this.setState({name:e.target.value, edited:true})}
            />
          </div>

          <div 
            className='edit-item-field-wrapper' 
            style={{
              width:'100%',
              marginTop:'0px',
              marginBottom:'0px'
            }}
          >
            <div className='edit-item-label'>Modifier Price</div>
            <input 
              type='number'
              className='edit-item-input'
              onChange={(e)=>this.setState({price:this.filterMoneyInput(e.target.value), edited:true})}
              value={this.state.price}
            />
          </div>

          <div className='deactivate-buttons-container'>
            <div 
              onClick={()=>{
                this.props.history.goBack()
              }}
              className='deactivate-button deactivate-cancel'
            >
              <div>Cancel</div>
            </div>

            <div 
              className={classnames('deactivate-button', {'deactivate-yes':this.state.name},
                {'greyed-out-button':!this.state.name})}
              onClick={()=>this.save()}
            >
              {this.state.loading === true ? (
                <div><Loading/></div>
              ) : (
                <div>Create Modifier</div>
              )}
            </div>
          </div>
        
        
        
        </div>
      </div>
    )
  }
}

export default ModifierModalContextHOC(withRouterAndRef(ToastContextHOC(CreateModifierModal)))
