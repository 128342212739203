//var exports = module.exports = {};

function formatPrice(cents){
  var dollars = cents / 100 
  return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
}

function rowOrderSort(a, b) {
  if (a.row_order < b.row_order) return -1; 
  else if (a.row_order > b.row_order) return 1;
  return 0;
}

class StringModifier {
  constructor(rawModifier, level){
    this.level = level
    this.name = rawModifier.modifier_value
    this.modifierType = 'string'
  }

  displayName(){
    return this.name
  }

  receiptPriceFormatted(){
    return  ''
  }
}

class Item {
  
  constructor(rawItem, level){
    
    this.modifierType = 'item'
    this.level = level
    this.name = rawItem.item.name
    this.specialInstructions = rawItem.special_instructions
    this.menu_uuid = rawItem.menu_uuid
    this.price = rawItem.item.price
    this.priceWithModifiers = rawItem.price
    this.receiptPrice = rawItem.item.receipt_price
    this.halfSelection = rawItem.half_selection
    this.cart_item_id = rawItem.cart_item_id
    this.cart_item_ids = [] // used for itemsQuantity only
    this.origItem = rawItem.item

    this.quantity = null
    this.children = []
    this._childrenFlat = []
  }

  childrenFlat(){
    if (this.level !== 0){
      throw Error("Children flat is only valid for top level items")
    } else {
      return this._childrenFlat
    }
  }

  displayName(){
    if (this.halfSelection){
      return `${this.name} (${this.halfSelection} half)` 
    } else {
      return this.name
    }
  }

  priceFormatted(){
    return formatPrice(this.price) 
  }

  priceWithModifiersFormatted(){
    return formatPrice(this.priceWithModifiers) 
  }

  receiptPriceFormatted(){
    return formatPrice(this.receiptPrice)
  }

  quantityPriceFormatted(){
    return formatPrice(this.quantityPrice)
  }


}

//exports.Cart = class Cart {
export default class Cart {

  constructor(cart){
    this.cartRaw = cart
    this.itemsTree = []
    this.itemsFlat = []
    this.itemsQuantity = []
    
    this.parseFinance()
    this.parseItemsTree()
    this.flattenTopLevelChildren()
    this.parseItemsFlat()
    this.parseItemsQuantity()
  }

  subtotalFormatted(){
    return formatPrice(this.subtotal)
  }

  taxFormatted(){
    return formatPrice(this.tax)
  }
  
  tipFormatted(){
    return formatPrice(this.tip)
  }
  
  totalFormatted(){
    return formatPrice(this.total)
  }

  deliveryFeeFormatted(){
    return formatPrice(this.deliveryFee)
  }

  promoFormatted(){
    return formatPrice(this.promo)
  }


  parseFinance(){
    let finance = this.cartRaw.finance
    this.subtotal = finance.subtotal
    this.tax = finance.tax
    this.tip = finance.tip
    this.total = finance.total
    this.deliveryFee = finance.deliveryFee
    this.productTotal = finance.productTotal
    this.promo = finance.promo
  }

  parseItemsTreeRecurse(parentItem, rawItem, level){
    let modifierClasses = []
    for (let i in rawItem.cart_modifier_classes){
      modifierClasses.push(rawItem.cart_modifier_classes[i])
    }
    modifierClasses.sort(rowOrderSort)

    for (let i in modifierClasses){
      let modifierClassOfItems = modifierClasses[i]
      let modifiers = modifierClassOfItems.modifiers
      if (!modifiers){
        continue
      }
      modifiers.sort(rowOrderSort)
      for(let j in modifiers){
        let modifier = modifiers[j]
        if(modifier.modifier_type === 'STRING'){
          let stringModifier = new StringModifier(modifier, level+1)
          parentItem.children.push(stringModifier)
        } else{
          let item = new Item(modifier, level +1)
          parentItem.children.push(item)
          this.parseItemsTreeRecurse(item, modifier, level+1)
        }
      }
    }
  }

  parseItemsTree(){
    for (let i in this.cartRaw.items){
      let rawItem = this.cartRaw.items[i]
      let item = new Item(rawItem, 0)
      this.parseItemsTreeRecurse(item, rawItem, 0)
      this.itemsTree.push(item)
    }
  }

  flattenTopLevelChildrenRecurse(topLevelItem, item){
    topLevelItem._childrenFlat.push(item)
    for (let j in item.children){
      this.flattenTopLevelChildrenRecurse(topLevelItem, item.children[j])
    }
  }

  flattenTopLevelChildren(){
    for (let i in this.itemsTree){
      let topLevelItem = this.itemsTree[i]
      for (let j in topLevelItem.children){
        this.flattenTopLevelChildrenRecurse(topLevelItem, topLevelItem.children[j])
      }
    }
  }

  parseItemsFlatRecurse(item){
    this.itemsFlat.push(item)
    for (let i in item.children){
      this.parseItemsFlatRecurse(item.children[i])
    }
  }

  parseItemsFlat(){
    for (let i in this.itemsTree){
      this.parseItemsFlatRecurse(this.itemsTree[i])
    }
  }

  // to get a hash of an item, we want to hash all its properites
  // except for cart_item_id
  itemHash(item){
    var copy = JSON.parse(JSON.stringify(item))
    copy.cart_item_id = null
    return JSON.stringify(copy)
  }
  
  parseItemsQuantity(){
    var keyCount = {}
    var keyItemIndex = {}
    var keyCartItemIds = {}
    var length = this.itemsTree.length
    for(var i=0; i<length; i++){
      var topLevelItem = this.itemsTree[i]
      var key = this.itemHash(topLevelItem) 
      keyItemIndex[key] = i
      if (!(key in keyCount)){
        keyCount[key] = 0
        keyCartItemIds[key] = []
      }
      keyCartItemIds[key].push(topLevelItem.cart_item_id)
      keyCount[key] += 1
    }

    for (key in keyCount){
      var count = keyCount[key]
      var item = this.itemsTree[keyItemIndex[key]]
      item.quantity = count
      item.quantityPrice = count * item.price
      item.cart_item_ids = JSON.parse(JSON.stringify(keyCartItemIds[key]))
      this.itemsQuantity.push(item)
    }

  }


}

