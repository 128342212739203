import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import ToastContextHOC from "../contexts/ToastContextHOC"
import MenuItemBox from "./MenuItemBox"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import OrderToast from "../orders/OrderToast"
import Deactivate from "../orders/svg/Deactivate"
import "./Menu.css"

const Api = require("../api")
const Money =  require("../helpers/money")

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu:null,
      tab:'items',
      toast:null,
      blackedOutItems:[],
      blackedOutModifiers:[]
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.tab){
      this.state['tab'] = params.tab
    }

    this.menu_uuid = this.props.match.params.menu_uuid
    this.sectionRefs = {} 
    this.sectionRefs['Deactivated Items'] = React.createRef()

    this.modifierSectionRefs = {} 
    this.modifierSectionRefs['Deactivated Items'] = React.createRef()
    window.M = this
  }

  componentDidMount(){
    this.getCentralTimestamp()
    // scrolll to section
  }

  componentDidUpdate(prevProps){
    if(
      JSON.stringify(this.props.toast.toast) !== JSON.stringify(prevProps.toast.toast) &&
      this.props.toast.toast
    ){
      this.setState({menu:null})
      this.getCentralTimestamp()
      this.addToast(this.props.toast.toast)
    }
  }

  addToast(toast){
    if(!this.loaded()){
      setTimeout(()=>this.addToast(toast), 100)
      return
    }
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }   
  }

  loaded(){
    if(this.state.menu && this.state.centralTimestamp) {
      return true
    } else {
      return false
    }
  }

  loadMenu(){
    Api.callApi({
      endpoint:'/api/menu/'+this.menu_uuid,
      success:(data)=>{
        console.log("menu", data)
        for(let category in data.menu){
          this.sectionRefs[category] = React.createRef()
          this.modifierSectionRefs[category] = React.createRef()
        }
        this.modifierSectionRefs['No Category'] = React.createRef()
        let blackedOutItems = []
        // loop over the menu and indicate if an item is blacked out
        // or not.... also compile the list of blacked out items this
        // way
        for(let category in data.menu){
          for(let i in data.menu[category].items){
            let item = data.menu[category].items[i]
            item.category_name = category
            if(
              item.blackout_until &&
              item.blackout_until_timestamp > this.state.centralTimestamp 
            ) {
              item.blacked_out = true
              item.blackout_until_local_ts = item.blackout_until_timestamp - this.props.user.rest.central_offset_seconds 
              blackedOutItems.push(JSON.parse(JSON.stringify(item)))
            }
          }
        }

        let blackedOutModifiers = []
        for(let i in data.menu_items){
          let item = data.menu_items[i]
          if(!item.is_orderable){
            let item = data.menu_items[i]
            if(
              item.blackout_until &&
              item.blackout_until_timestamp > this.state.centralTimestamp 
            ) {
              item.blacked_out = true
              item.blackout_until_local_ts = item.blackout_until_timestamp - this.props.user.rest.central_offset_seconds 
              blackedOutModifiers.push(JSON.parse(JSON.stringify(item)))
            }
          }
        }

        // we need to group modifiers by category
        let modifiersByCategory = {}
        for(let i in data.menu_items){
          let item = data.menu_items[i]
          if(!item.is_orderable){
            let category = item.category_name
            if(!category) category = 'No Category'
            if (!(category in modifiersByCategory)){
              modifiersByCategory[category] =  {'items':[], 'name':category}
            }
            modifiersByCategory[category].items.push(JSON.parse(JSON.stringify(item)))
          }
        }

        this.setState({
          menu:data, 
          blackedOutItems:blackedOutItems,
          blackedOutModifiers:blackedOutModifiers,
          modifiersByCategory:modifiersByCategory
        }, () => {
          // scroll to category
          if( 
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.section
          ){  
            this.scrollToSection(this.props.history.location.state.section)
          }  


        })
      },
      failure:(err)=>{
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error loading the menu' 
          }
        })
      }
    })
  }

  getCentralTimestamp(){
    Api.callApi({
      endpoint:'central-timestamp',
      success:(data)=>{
        this.setState({
          centralTimestamp:data.ts,
          tomorrow5am:data.tomorrow_5am,
        }, ()=>this.loadMenu())
      }
    })
  }

  scrollToSection(section){
    let sectionRefs = this.state.tab === 'items' ? this.sectionRefs:
      this.modifierSectionRefs
    if(
      sectionRefs[section] &&
      sectionRefs[section].current
    ){
      let el = sectionRefs[section].current
      const yOffset = -150;
      let y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'})
      return
    }
  }

  openDeactivateModal(item){
    this.toastKey = this.props.toast.generateKey()
    this.props.history.push({
      pathname:this.props.location.pathname + "/deactivate-modal",
      state:{
        item:item
      }
    })
  }


  // change query string, state and scroll to top
  updateTab(tab){
    this.props.history.replace({
      pathname:this.props.location.pathname,
      search:`?tab=${tab}`
    })
    this.setState({tab:tab}, ()=>window.scrollTo(0,0))
  }


  render(){

    if(!this.loaded()) return (<Loading />)

    let that = this

    let categories = this.state.tab === 'items' ?
      this.state.menu.menu :
      this.state.modifiersByCategory

    let blackedOutItems = this.state.tab === 'items' ?
      this.state.blackedOutItems :
      this.state.blackedOutModifiers

    let sectionRefs = this.state.tab === 'items' ?
      this.sectionRefs :
      this.modifierSectionRefs

    return (
      <>
        <div className='menu-tabs'>
          <div 
            className={classnames('menu-tab', 
              {'menu-tab-selected':this.state.tab === 'items'})} 
            onClick={()=>this.updateTab('items')}
          >
            <div>Items</div>
          </div>
          <div 
            className={classnames('menu-tab', 
              {'menu-tab-selected':this.state.tab === 'modifiers'})} 
            onClick={()=>{
              this.props.history.replace({
                pathname:'/menu/modifiers/'+this.menu_uuid
              })
            }}
          >
            <div>Modifiers</div>
          </div>
        </div>
        <div className='full-menu-container'>
          {this.state.toast && (
            <OrderToast
              toast={this.state.toast}
              message={this.state.toast.item.name}
              type={this.state.toast.type}
            />
          )}
          <div className='menu-items-list-mobile'>
            {Object.values(categories).map(category => (
              <div className='menu-section-container'>
                <div className='menu-section-title' ref={sectionRefs[category.name]} >
                  {category.name}
                </div>
                {category.description && (
                  <div className='menu-section-description'>
                    {category.description}
                  </div>
                )}
                {category.items.map(item => (
                  <MenuItemBox parent={that} item={item} tab={this.state.tab}/>
                ))}
              </div>
            ))}

            {blackedOutItems.length > 0  && (
              <div className='menu-section-container'>
                <div className='menu-section-title' ref={sectionRefs['Deactivated Items']}>
                  Deactivated Items
                </div>
                {blackedOutItems.map(item => (
                  <MenuItemBox item={item} />
                ))}
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

}
      

export default withRouterAndRef(ToastContextHOC(UserContextHOC(Menu))) 


