import React from "react"
import classnames from "classnames"
import moment from "moment"
import { Text, Toggle, Button, ButtonWrapper } from "../../components"
import { ReactComponent as Delete } from "../../assets/icons/delete.svg"
import Select from "react-select"
import { withMobile } from "../../hoc"
import { round5 } from "./helpers"
import Modal from "../../Modal/Modal"
import { BrowserRouter, Route, Prompt } from "react-router-dom"
const API = require("../../api.js")
const Helpers = require("./helpers")

const MIDNIGHT = 1439
const DAYS = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
}

const generateHours = () => {
  return [
    {
    },
  ]
}

const currentDay = moment().isoWeekday()
const today = moment()
const midnight = today.startOf("Day")

// dropdowns optoins for the timeselect. It needs
// 10:00am and minutes after midnight (10*60)
let TIMES = []
let date = new Date("2020-01-01T05:00:30+00:00")
for (let i = 0; i < 24 * 4; i++) {
  let hour = date.getUTCHours()
  let hours = date.getUTCHours()
  let hours24 = parseInt(hours)
  let minutes = date.getMinutes()
  let intMinutes = minutes
  let ampm = hours >= 12 ? "pm" : "am"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes
  let strTime = hours + ":" + minutes + " " + ampm
  let value = hours24 * 60 + intMinutes
  if (value === 0) value = 1439 //midnight
  TIMES.push({
    label: strTime,
    value: value,
    className: "hours-dropdown-item",
  })

  date.setMinutes(date.getMinutes() + 15)
}
// add in 11:59pm... aka 4:59am
TIMES.push({
  label: "4:59am",
  value: 299,
  className: "hours-dropdown-item",
})

// remove the 5am first option from the end select
let END_TIMES = JSON.parse(JSON.stringify(TIMES))
END_TIMES.shift()

class AdjustHours extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hours: this.props.hours,
      originalHours: JSON.parse(JSON.stringify(this.props.hours)),
      copyHoursOpen: false,
      isDesktop:window.innerWidth > 1000
    }
    this.resetHours = this.resetHours.bind(this)
    this.openCopyHours = this.openCopyHours.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state.hours) !== JSON.stringify(this.props.hours)) {
      this.setState({ hours: this.props.hours })
    }

    if (JSON.stringify(this.state.hours) !== JSON.stringify(this.state.originalHours) && !this.state.isDesktop) {
      window.DONT_NAVIGATE = true
      window.DONT_NAVIGATE_MESSAGE = 'You have unsaved changes. Leaving this page will discard these changes.'
    } 
  }

  componentWillUnmount(){
    window.DONT_NAVIGATE = false
  }

  handleToggle(e, day) {
    //remove all hours from the day
    if (!e.target.checked) {
      // this.state.hours[day] = []
      this.setState({ hours: { ...this.state.hours, [day]: [] } }, () => {
        this.props.updateHours(this.state.hours)
      })
    } else {
      const d = generateHours()
      this.setState({ hours: { ...this.state.hours, [day]: d } }, () => {
        this.props.updateHours(this.state.hours)
      })
    }
  }

  openCopyHours() {
    this.setState({ copyHoursOpen: true })
  }

  resetHours() {
    this.setState({
      hours: JSON.parse(JSON.stringify(this.state.originalHours)),
    })
  }

  formatTime = (time, type = "minutes") => {
    return midnight.clone().add(time, type).format("h:mm A")
  }

  shiftTime = (mins) => {
    // subtract 5 hours. If that gets us below midnight, then
    // subtract the dif from midnight
    let shifted = mins + 300
    if (shifted > 1440) {
      shifted = shifted - 1440
    }
    return shifted
  }

  unshiftTime = (mins) => {
    let shifted = mins - 300
    if (shifted < 0) {
      shifted = 1440 + shifted
    }
    return shifted
  }

  // assume this is in the 12:04pm type format
  reverseFormatTime = (s) => {
    if (s.indexOf("am") === -1 && s.indexOf("pm") === -1) return
    let hours = parseInt(s.split(":")[0])
    let minutes = parseInt(s.split(":")[1].substring(0, 2))
    let ampm = s.substring(s.length - 2, s.length)
  }

  handleSplitHours = (day) => {
    let hoursObject = [...this.state.hours[day], {}]
    this.setState({ hours: { ...this.state.hours, [day]: hoursObject } }, () =>
      this.props.updateHours(this.state.hours)
    )
  }

  renderScheduleDropdown() {
    let schedules = this.props.getSchedules()
    let options = []
    for (let i in schedules) {
      if (schedules[i].name !== this.props.schedule) {
        options.push({
          label: schedules[i].name,
          value: schedules[i].name,
          hours: schedules[i].hours,
        })
      }
    }

    return (
      <Select
        isSearchable={false}
        options={options}
        onChange={(e) => {
          // if its pickup, then we want to get pickup hours
          // if its delivery we want to copy the delivery hours
          this.setState({
            selectedSchedule: e.value, 
            copyHoursOpen: false
          })
          this.props.updateHours(Helpers.shiftHours(JSON.parse(JSON.stringify(e.hours))))
        }}
        value={this.state.selectedSchedule}
        placeholder="Select a schedule"
      />
    )
  }

  shouldPrompt(){
    if(
      !this.state.isDesktop &&
      ( JSON.stringify(this.state.hours) !==
        JSON.stringify(this.state.originalHours) )
    ){
      return true
    } else{
      return false
    }
  }

  render() {
    let { hours } = this.state
    const keys = Object.keys(hours)
    return (
      <div className="flex-col flex-align-start">

        <Prompt
          when={this.shouldPrompt()}
          message='You have unsaved changes. Leaving this page will discard these changes.'
        />

        {this.state.copyHoursOpen ? (
          <Modal
            close={() => this.setState({ copyHoursOpen: false })}
            style={
              window.innerWidth > 800
                ? { maxWidth: "64%", padding: "16px", height: "300px" }
                : {
                    maxWidth: "calc(92% - 32px)",
                    padding: "16px",
                    height: "300px",
                  }
            }
          >
            <h3>Copy Hours From</h3>
            <div>Schedule</div>
            {this.renderScheduleDropdown()}
          </Modal>
        ) : null}

        {this.state.isDesktop && (
          <ButtonWrapper onClick={this.openCopyHours}>
            <Text style={{ textDecoration: "underline" }}>Copy Hours From</Text>
          </ButtonWrapper>
        )}
        {Object.keys(DAYS).map((day) => {
          const hasMultipleHours = hours[day].length > 1
          const noHoursForDay = hours[day].length === 0
          return (
            <div
              className={classnames("flex-align-start mt-8", 
                {"flex-row": this.state.isDesktop},
                {"flex-wrap w100":  this.state.isDesktop}
              )}
              style={!this.state.isDesktop ? {marginBottom:'40px'} : {}}
            >
              <div
                className={classnames(
                  {"flex-row": true}, 
                  {"w100 flex-apart": this.state.isDesktop}
                )}
              >
                <Text
                  weight="black"
                  size="l"
                  className={classnames("hours-day", {
                    "rel hours__current-day":
                      today.isoWeekday() === parseInt(day),
                  })}
                >
                  {DAYS[day]}:
                </Text>
                <div className="flex-row">
                  <Toggle
                    isChecked={!noHoursForDay}
                    onToggle={(e) => this.handleToggle(e, day)}
                  >
                    {({ checked }) => {
                      return checked ? (
                        <Text weight="regular" className="mh-3">
                          Open
                        </Text>
                      ) : (
                        <Text weight="regular" className="mh-3">
                          Closed
                        </Text>
                      )
                    }}
                  </Toggle>
                </div>
              </div>
              <div
                className={
                  this.props.isMobile
                    ? "flex-col flex-align-start"
                    : "flex-row flex-align-cetner"
                }
              >
                <div className="flex-col">
                  {hours[day].map((hoursObject, index) => {
                    const isNewlyAdded = false //!hoursObject.created_at
                    return (
                      <HourRow
                        isNewlyAdded={isNewlyAdded}
                        len={hours[day].length}
                        updateOpen={(e) => {
                          hoursObject.open_at_min = this.unshiftTime(e.value)
                          this.props.updateHours(this.state.hours)
                        }}
                        updateClose={(e) => {
                          let unshifted = this.unshiftTime(e.value)
                          hoursObject.close_at_min = unshifted
                          this.props.updateHours(this.state.hours)
                        }}
                        trash={()=>{
                          hours[day].splice(index, 1)
                          this.props.updateHours(this.state.hours)
                        }}
                        open={this.shiftTime(hoursObject.open_at_min)}
                        close={this.shiftTime(hoursObject.close_at_min)}
                      />
                    )
                  })}
                </div>
                {!hasMultipleHours && !noHoursForDay && (
                  <button
                    className={classnames("button-default p-0", {
                      "mr-auto mt-4": this.props.isMobile,
                      "ml-auto mv-auto": !this.props.isMobile,
                    })}
                    onClick={() => this.handleSplitHours(day)}
                  >
                    <Text color="pink" className="br-b br-pink">
                      Split Hours
                    </Text>
                  </button>
                )}
              </div>
            </div>
          )
        })}
        <div className={classnames(
          {"mt-5 ml-auto":this.state.isDesktop}
        )}>

          {JSON.stringify(this.state.hours) !==
            JSON.stringify(this.state.originalHours) && this.state.isDesktop && (
            <Button type="secondary" textColor="blue" onClick={this.resetHours}>
              Cancel
            </Button>
          )}

          <Button
            color="blue"
            className={classnames({"ph-5 pv-3 ml-5":this.state.isDesktop})}
            type="primary"
            onClick={() => {
              window.DONT_NAVIGATE = false
              if(this.props.delivery){
                this.props.saveHours(()=>{
                  this.setState({originalHours:JSON.parse(JSON.stringify(this.state.hours))}, ()=>window.DONT_NAVIGATE = false)
                })
              } else {
                this.props.saveHours(this.state.hours, 0, ()=>{
                  this.setState({originalHours:JSON.parse(JSON.stringify(this.state.hours))}, ()=>window.DONT_NAVIGATE = false)
                })
              }
            }}
            disabled={
              JSON.stringify(this.state.hours) ===
              JSON.stringify(this.state.originalHours)
            }
          >
            Save Changes
          </Button>
        </div>
      </div>
    )
  }
}

// find which element of the dropdown should be preselected
const findTimeSelectOptionFromMinutes = (minutes) => {
  for (let i in TIMES) {
    if (TIMES[i].value === minutes) {
      return TIMES[i]
    }
  }
  return null
}

const HourRow = withMobile((props) => {
  const {
    len,
    open,
    close,
    isNewlyAdded,
    updateOpen,
    updateClose,
    isMobile,
    trash
  } = props
  return (
    <div
      className={classnames("flex-row flex-align-center mr-4", {
        "mt-4 w100": isMobile,
        "mb-2": len > 1,
      })}
    >
      <Select
        isSearchable={false}
        options={TIMES}
        onChange={updateOpen}
        className="hours-dropdown-item"
        value={findTimeSelectOptionFromMinutes(open)}
        placeholder="Select an option"
      />

      <Text color="grey" size="l" className="mh-3">
        to
      </Text>
      <Select
        isSearchable={false}
        options={END_TIMES}
        onChange={updateClose}
        className="hours-dropdown-item"
        value={findTimeSelectOptionFromMinutes(close)}
        placeholder="Select an option"
      />
      
      <div style={{display:'inline-block', marginLeft:'18px'}}>
        <ButtonWrapper onClick={trash}>
          <Delete />
        </ButtonWrapper>
      </div>

    </div>
  )
})

export default withMobile(AdjustHours)
