import React from "react"
import moment from "moment"
import classnames from "classnames"
import { withMobile } from "../../hoc"
import { Text, Button, ButtonWrapper } from "../../components"
import HoursBarChart from "./HoursBarChart"
import UserContextHOC from "../../../contexts/UserContextHOC"
import PrepTimePicker from './PrepTimePicker'
import { ReactComponent as StopOrdersIcon } from "../../assets/icons/stop-orders.svg"

const API = require("../../api.js")
const Helpers = require("./helpers")

class TodayHours extends React.Component {
  constructor(props) {
    super(props)
    let today = moment()
    let hours = this.props.hours
    // let's shift the hours THEN grab the current day
    let pickup = Helpers.shiftHours(hours.pickup_with_closures)
    let delivery = Helpers.shiftHours(hours.delivery_with_closures)
    this.state = {
      pickupPrepTime: null,
      deliveryPrepTime: null,
      deliveryHours: delivery[today.isoWeekday()],
      pickupHours: pickup[today.isoWeekday()],
      today: today,
      loaded: false,
    }
    this.closeEarly = this.closeEarly.bind(this)
    this.savePrepTime = this.savePrepTime
    this.loadClosedEarly()
    this.loadPrepTime()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.hours) !== JSON.stringify(this.props.hours)) {
      let today = moment()
      let hours = this.props.hours
      let pickup = Helpers.shiftHours(hours.pickup_with_closures)
      let delivery = Helpers.shiftHours(hours.delivery_with_closures)
      let state = {
        deliveryHours: delivery[today.isoWeekday()],
        pickupHours: pickup[today.isoWeekday()],
      }
      this.setState(state)
    }
  }

  async loadPrepTime() {
    if(!this.props.user.rest || !window.API_KEY){
      setTimeout(()=>this.loadPrepTime(), 10)
      return
    }   
    let rest = this.props.user.rest 
    this.rest = rest
    API.call(
      "default-prep-time-get",
      (resp) => {
        let delivery = resp.temp_default_delivery_time
          ? resp.temp_default_delivery_time
          : resp.default_delivery_time

        let pickup = resp.temp_default_pickup_time
          ? resp.temp_default_pickup_time
          : resp.default_pickup_time
        this.setState({ pickupPrepTime: pickup, deliveryPrepTime: delivery })
      },
      (err) => {
        alert("Error loading prep time.")
      },
      null,
      { rest_id: rest.restaurant_id }
    )
  }

  async savePrepTime(type) {
    let rest = this.rest
    let minutes
    if (type === "delivery") {
      minutes = this.state.deliveryPrepTime
    } else if (type === "pickup") {
      minutes = this.state.pickupPrepTime
    }

    API.call(
      "temp-default-prep-time-set",
      (resp) => {},
      (err) => {
        alert("Error saving prep time.")
        this.loadPrepTime()
      },
      null,
      { rest_id: rest.restaurant_id, type: type, minutes: minutes }
    )
  }

  async loadClosedEarly() {
    if(!this.props.user.rest || !window.API_KEY){
      setTimeout(()=>this.loadClosedEarly(), 10)
      return
    }   
    let rest = this.props.user.rest
    this.setState({ loaded: false })
    API.call(
      "is-closed-early",
      (resp) => {
        this.setState({
          pickupClosedEarly: resp.pickup_closed_early,
          deliveryClosedEarly: resp.delivery_closed_early,
          loaded: true,
        })
      },
      (err) => {
        alert("Error getting hours")
      },
      null,
      { rest_id: rest.restaurant_id }
    )
  }

  async closeEarly(pickup, delivery) {
    this.setState({ loaded: false })
    let rest = this.props.user.rest   

    API.call(
      "close-early",
      (resp) => {
        this.loadClosedEarly()
        this.props.loadHours()
      },
      (err) => {
        alert("Error getting hours")
      },
      null,
      { rest_id: rest.restaurant_id, pickup: pickup, delivery: delivery }
    )
  }

  async undoCloseEarly(pickup, delivery) {
    this.setState({ loaded: false })
    let rest = this.props.user.rest 
    API.call(
      "undo-close-early",
      (resp) => {
        this.loadClosedEarly()
        this.props.loadHours()
      },
      (err) => {
        alert("Error getting hours")
      },
      null,
      { rest_id: rest.restaurant_id, pickup: pickup, delivery: delivery }
    )
  }

  renderStopStartButton() {
    const cls = {
      className: classnames("mt-8 mb-4", {
        w100: this.props.isMobile,
      }),
    }
    if (this.state.pickupClosedEarly && this.state.deliveryClosedEarly) {
      return (
        <Button
          {...cls}
          style={!this.state.loaded ? {opacity:0} : null}
          color="blue"
          type="primary"
          onClick={() => this.undoCloseEarly(true, true)}
        >
          Resume Orders
        </Button>
      )
    } else {
      return (
        <Button
          color="pink"
          {...cls}
          type="primary"
          onClick={() => this.closeEarly(true, true)}
        >
          Stop All Orders
        </Button>
      )
    }
  }

  render() {
    return (
      <>
        <div className="flex-row mq__flex-wrap">
          <Text size="header" weight="black" color="pink" className="mr-4">
            Today
          </Text>
          <Text size="header" weight="black" color="blue">
            {this.state.today.format("dddd, MMMM D YYYY")}
          </Text>
        </div>
        {!this.props.isMobile && (
          <Section className="flex-align-start">
            <Text weight="black" size="xl">
              Today Summary
            </Text>

            <HoursBarChart
              hours={[this.state.pickupHours, this.state.deliveryHours]}
              labels={["Pickup", "Delivery"]}
            />
          </Section>
        )}

        <Section className="flex-col flex-align-start">
          <Text weight="black" size="xl" className="w100" align="left">
            Temporary Prep Time
          </Text>
          <PrepTimePicker
            pickupValue={this.state.pickupPrepTime}
            updatePickup={(val) =>
              this.setState({ pickupPrepTime: val }, () =>
                this.savePrepTime("pickup")
              )
            }
            deliveryValue={this.state.deliveryPrepTime}
            updateDelivery={(val) =>
              this.setState({ deliveryPrepTime: val }, () =>
                this.savePrepTime("delivery")
              )
            }
          />
        </Section>

        <Section className="flex-col flex-align-start mq__flex-align-center">
          <Text weight="black" size="xl" className="w100" align="left">
            Temporarily Stop Orders
          </Text>
          <div className="w100 flex-col flex-align-start mq__flex-align-center">
            {this.renderStopStartButton()}
              <div style={!this.state.loaded ? {opacity:0} : {}} className="flex-row w100 mq__flex-col mq__flex-wrap mq__flex-align-center">
                <div
                  className={classnames(
                    "hours-actions flex-col flex-align-center",
                    {
                      "mr-8": !this.props.isMobile,
                    }
                  )}
                >
                  <Text weight="black" color="blue" size="header">
                    Pickup
                  </Text>
                  {this.state.pickupClosedEarly ? (
                    <>
                      <span style={{opacity:0}}>
                        <StopOrdersIcon className="mv-4" />
                      </span>
                      <Button
                        type="primary"
                        color="blue"
                        className="mt-auto w100"
                        onClick={() => this.undoCloseEarly(true, false)}
                      >
                        Resume
                      </Button>
                    </>
                  ) : (
                    <>
                      <StopOrdersIcon className="mv-4" />
                      <Button
                        type="primary"
                        color="pink"
                        className=" w100"
                        onClick={() => this.closeEarly(true, false)}
                      >
                        Stop
                      </Button>
                    </>
                  )}
                </div>
                <div className="hours-actions flex-col flex-align-center">
                  <Text weight="black" color="blue" size="header">
                    Delivery
                  </Text>
                  {this.state.deliveryClosedEarly ? (
                    <>
                      <span style={{opacity:0}}>
                        <StopOrdersIcon className="mv-4" />
                      </span>
                      <Button
                        className="mt-auto"
                        type="primary"
                        color="blue"
                        className="mt-auto w100"
                        onClick={() => this.undoCloseEarly(false, true)}
                      >
                        Resume
                      </Button>
                    </>
                  ) : (
                    <>
                      <StopOrdersIcon className="mv-4" />
                      <Button
                        type="primary"
                        color="pink"
                        className=" w100"
                        onClick={() => this.closeEarly(false, true)}
                      >
                        Stop
                      </Button>
                    </>
                  )}
                </div>
              </div>
          </div>
        </Section>
      </>
    )
  }
}

const Section = ({ children, className }) => (
  <div className={classnames("mt-10 w100", { [className]: className })}>
    {children}
  </div>
)

export default UserContextHOC(withMobile(TodayHours))
