import React from "react"
import { Button } from "../../components"

const SaveActions = (props) => {
  const { ButtonText, onSave, onCancel, disabled } = props
  return (
    <div className="flex-row flex-justify-end">
      <Button type="secondary" onClick={onCancel} className="mr-6" fontSize="l">
        Cancel
      </Button>
      <Button type="primary" disabled={disabled} onClick={onSave}>
        {ButtonText}
      </Button>
    </div>
  )
}

SaveActions.defaultProps = {
  ButtonText: "Save",
}

export default SaveActions
