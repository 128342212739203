import React from 'react';
import { NewOrdersContext } from './NewOrdersContext';

export default function NewOrdersContextHOC(Component) {
  return React.forwardRef((props, ref) => (
    <NewOrdersContext.Consumer>
      {context => <Component ref={ref} orders={context} unconfirmedOrders={context} {...props} />}
    </NewOrdersContext.Consumer>
  ))
}
