import { PureComponent } from "react";
import { withRouter } from "react-router-dom";

const DONT_SCROLL = [
  'modal',
  'modifier-class-edit',
]

function doesNotContain(path){
  for(let i in DONT_SCROLL){
    if(path.indexOf(DONT_SCROLL[i]) > -1){
      return false
    }
  }
  return true
}

class ScrollIntoView extends PureComponent {
  componentDidMount = () => window.scrollTo(0, 0);

  componentDidUpdate = prevProps => {
    if (
      this.props.location !== prevProps.location &&
      doesNotContain(this.props.location.pathname) &&
      doesNotContain(prevProps.location.pathname)
    ) {
      window.scrollTo(0, 0);
    }
    
  };

  render = () => this.props.children;
}

export default withRouter(ScrollIntoView);
