import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import ActionButton from "./ActionButton"
import Cart from './Cart'
import Car from "./svg/Car"
import Check from "./svg/Check"
import CreditCard from "./svg/CreditCard"
import Terminal from "./svg/Terminal"
import classnames from "classnames"
import "./OrderCard.css"
import { Capacitor, Plugins } from '@capacitor/core'
const Timestamp = require("../helpers/timestamps")
const Money = require("../helpers/money")
const { UpdatePlugin, AppVersion } = Plugins;


/*
The problem we are trying to solve is that we need to know when the api call 
has returned, so that we can properly show the loading spinner. 

Easy enough, this component needs to get the API call passed as a prop and 
it can keep track of the loading state

*/

class OrderCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:false,
    }
    this.action = this.action.bind(this)
    this.expandOrder = this.expandOrder.bind(this)
  }

  action(e){
    e.stopPropagation()
    this.setState({loading:true})
    this.props.action(()=>this.setState({loading:false}))
  }

  formatOrderPlaced(ts){
    if(!ts){
      return ''
    }
    let time = Timestamp.getTimeOfDay(ts)
    let mm = Timestamp.getMonthInt(ts)
    let dd = Timestamp.getDayOfMonth(ts)
    let yy  = Timestamp.getPartialYear(ts)
    return `${mm}/${dd}/${yy} ${time}`
  }

  scheduledTime(ts){
    return Timestamp.getTimeOfDay(ts)
  }

  async expandOrder(){


    let page = this.props.page.replace('search-', '')
    //if "search-" is part of the page, just chop that off
    this.props.history.push({
      pathname:this.props.location.pathname + '/ordermodal',
      state:{
        order:this.props.order,
        clock:this.props.clock,
        page:page
      }
    })

  }

  renderHistoryStatus(){
    let statuses = {
      'ready_for_pickup':'Ready For Pickup',
      'out_for_delivery':'Out For Delivery',
      'cancelled':'Cancelled',
      'completed':'Completed'
    }
    return (
      <div className={classnames('order-card-history-status',
        {'red':this.props.order.status === 'cancelled'})}
      >
        {statuses[this.props.order.status]}
      </div>
    )
  }

  renderScheduledTag(){
    if(this.props.page === 'scheduled') return null
    if(this.props.page.indexOf('scheduled') > -1) return null
    return (
      <div className='order-card-tag order-card-scheduled-tag'>
        <Calendar />
        <div>SCHEDULED</div>
      </div>
    )
  }

  renderTerminalTag(){
    return (
      <div className='order-card-tag order-card-scheduled-tag'>
        <Terminal />
        <div>TERMINAL</div>
      </div>
    )
  }

  renderManualEnterTag(){
    return (
      <div className='order-card-tag order-card-scheduled-tag'>
        <CreditCard />
        <div>MANUAL ENTER</div>
      </div>
    )
  }


  render(){
    return (
      <>
        <div 
          className='unconfirmed-order order-card'
          onClick={this.expandOrder}
        >
          <div className={classnames(
            {'order-card-scheduled-unconfirmed':this.props.page !== 'scheduled' && this.props.order.fulfill_order_at})}
          >
            {this.props.order.fulfill_order_at && (
              <div className='order-card-tags'>
                {this.renderScheduledTag()}
              </div>
            )}

            {this.props.order.origin === 'STRIPE_TERMINAL' && (
              <div className='order-card-tags'>
                {this.renderTerminalTag()}
              </div>
            )}

            {this.props.order.origin === 'MANUAL_ENTER_CC' && (
              <div className='order-card-tags'>
                {this.renderManualEnterTag()}
              </div>
            )}

            <div className='space-between'>
              <div className='order-card-order-type'>
                {this.props.order.pickup_or_delivery.toUpperCase()}
                {this.props.order.pickup_or_delivery == 'delivery' && <Car/>}
              </div>
              <div className='order-card-clock-container'>
                {this.props.page.indexOf('unconfirmed') > -1  && (
                  <>
                    <span className='order-card-time'>Expires in {this.props.order.expires}</span>
                    <Clock/>
                  </>
                )}
                
                {this.props.clock === 'due' && (
                  <>
                    <span className='order-card-time'>Due in {this.props.order.due}</span>
                    <Clock/>
                  </>
                )}

                {this.props.clock === 'pastdue' && (
                  <>
                    <span className='order-card-time'>Past Due  {this.props.order.due}</span>
                    <Warning/>
                  </>
                )}

                {this.props.page === 'scheduled' && (
                  <div className='order-card-scheduled-time-of-day'>
                    {this.scheduledTime(this.props.order.fulfill_order_at)}
                  </div>
                )}

                {this.props.page.indexOf('history') > -1 && (
                  <div className='order-card-readied-at'>
                    {this.formatOrderPlaced(this.props.order.last_status_update_at)}
                  </div>
                )}

                {this.props.page === 'search-scheduled' && (
                  <div className='order-card-readied-at'>
                    Scheduled For {this.formatOrderPlaced(this.props.order.fulfill_order_at)}
                  </div>
                )}

                {this.props.page === 'awaiting' && (
                  <>
                    {this.props.order.pickup_or_delivery === 'pickup' && (
                      <div className='order-card-readied-at'>
                        Ready at {Timestamp.getTimeOfDay(this.props.order.readied_at)}
                      </div>
                    )}
                    
                    {this.props.order.pickup_or_delivery === 'delivery' && (
                      <div className='order-card-readied-at'>
                        En Route at {Timestamp.getTimeOfDay(this.props.order.readied_at)}
                      </div>
                    )}
                  </>
                )}



              </div>
            </div>
            
            <div className='order-card-diner'>{this.props.order.diner}</div>

            <div className='space-between order-card-bottom-row'>
              <div className='order-card-price'>
                <div className='order-card-price-inner-div'>
                  {this.props.page.indexOf('search') > -1 ? (
                    <span>{this.props.order.hash_id}</span>
                  ) : (
                    <span>{Money.formatCents(this.props.order.total)}</span>
                  )}
                </div>
              </div>

              {this.props.page === 'history' && this.renderHistoryStatus()}

              {this.props.action  && (
                <ActionButton
                  color={this.props.order.fulfill_order_at ? '#000CCF' : null}
                  text={this.state.loading ? this.props.loadingText : this.props.text}
                  svg={this.props.svg}
                  loading={this.state.loading}
                  onClick={this.action}
                />
              )}

              {this.props.page === 'scheduled' && (
                <div className='order-card-active-at'>
                  Active at {Timestamp.getTimeOfDay(this.props.order.snooze_until)}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}
      

export default withRouter(OrderCard)

const Clock = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.848 12.459c.202.038.202.333.001.372-1.907.361-6.045 1.111-6.547 1.111-.719 0-1.301-.582-1.301-1.301 0-.512.77-5.447 1.125-7.445.034-.192.312-.181.343.014l.985 6.238 5.394 1.011z"/></svg>)

const Warning = props => (<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg>)

const Calendar = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"/></svg>)

const Close = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>)

const Printer = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 18h-8v-1h8v1zm-2 1h-6v1h6v-1zm10-14v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 10h-12v7h12v-7zm4-8h-20v9h2v-3h16v3h2v-9zm-1.5 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z"/></svg>)

