import React from "react"
import QRCodeData from "./QRCodeData"

class QRCodeList extends React.Component {

  constructor(props) {
    super(props)
    this.getQRImageLocation = this.getQRImageLocation.bind(this)
  }

  getQRImageLocation(qr_img_id, as_attachment=false) {
    if (as_attachment == true) {
      return `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/qr_routing/${qr_img_id}?as_attachment=1`
    } else {
      return `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/qr_routing/${qr_img_id}`
    }
  }

  renderItems() {
    return this.props.brand && this.props.qr_codes.map((qr) => {
      return <div className="flex-row mt-10">
        <div style={styles.leftSide}>
          <img style={styles.qrImg} 
            src={this.getQRImageLocation(qr.qr_code_id)} />
        </div>
        <div className="flex-column" style={styles.rightSide}>
          <QRCodeData qr={qr} 
            getQRImageLocation={this.getQRImageLocation}/>
        </div>
      </div>
    })
  }

  render() {
    return <div>{this.renderItems()}</div>
  }
}

const styles = {
  leftSide: {
    padding: '8px',
    height: '200px',
    width: '200px',
    border: '1px solid black'
  },
  rightSide: {
    padding: '8px',
    alignItems: 'left',
    textAlign: 'left',
  },
  hits: {
    fontFamily: 'monospace'
  },
  changeLink: {
    color: 'blue',
  },
  qrImg: {
    height: '180px',
    width: '180px'
  }
}

export default QRCodeList
