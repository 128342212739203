import React, { Component } from "react"
import withRouterAndRef from "../withRouterAndRef"
import * as Sentry from "@sentry/browser";
import Warning from "./svg/Warning"
import "./ErrorModal.css"

class ErrorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: 'Something went wrong. The action was not completed'
    }
    if(
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.message
    ){
      this.state.message = this.props.history.location.state.message
    }
  }

  render() {
    return  (
      <div 
        className='modal-background' 
        onClick={
          (e)=>{
            e.stopPropagation()
            this.props.history.goBack() 
          }
        }>
        <div className='error-modal-container'>
          <Warning className='error-modal-warning' />
          <div className='error-modal-something-went-wrong'>
            {this.state.message}
          </div>
          <div 
            className='error-modal-back-to-kitchen-button'
            onClick={(e)=>{
              e.stopPropagation()
              this.props.history.goBack()
            }}
          >
            <div>
              Close Window
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouterAndRef(ErrorModal)
