import React, { Component } from "react"
import Loading from "../Loading/Loading"
import Orders from "./OrdersModal"
import "./Finance.css"

const API = require("../api")

class Transactions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      start: null,
      start_time: "00:00",
      end_time: "00:00",
      end: null,
    }
    this.handleStartChange = this.handleStartChange.bind(this)
    this.loadOrders = this.loadOrders.bind(this)
  }

  async loadOrders() {
    if (!this.state.start) {
      alert("Please select a start date")
      return
    }
    if (!this.state.end) {
      alert("Please select an end date")
      return
    }
    this.setState({ loading: true })

    let rest = this.props.rest 
    this.setState({ rest: rest })
    API.call(
      "transactions",
      (res) => {
        this.setState({ rows: res.rows, loading: false })
      },
      (err) => {
        alert("Error loading tranactions")
        this.setState({ loading: false })
      },
      null,
      {
        start: this.state.start,
        end: this.state.end,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        rest_id: rest.restaurant_id,
      }
    )
  }

  handleStartChange(date) {
    alert(date)
    this.setState({ start: date })
  }

  renderExpansion(payout_id) {
    if (this.state.rows.length) {
      return (
        <Orders
          closeExpansion={() => this.setState({ rows: [] })}
          rows={this.state.rows}
          rest_id={this.state.rest.restaurant_id}
          start={this.state.start}
          end={this.state.end}
          start_time={this.state.start_time}
          end_time={this.state.end_time}
          transactions={true}
          {...this.props}
        />
      )
    }
  }

  render() {
    if (this.state.loading) return <Loading />

    return (
      <div style={{ marginTop: "30px" }}>
        <div style={{ width: "100%" }}>
          Start Date
          <input
            type="date"
            className="datepicker"
            value={this.state.start}
            onChange={(event) => this.setState({ start: event.target.value })}
          />
          &nbsp;&nbsp;Start Time
          <input
            type="time"
            className="datepicker"
            value={this.state.start_time}
            onChange={(event) =>
              this.setState({ start_time: event.target.value })
            }
          />
        </div>
        <br />
        <br />
        <div style={{ width: "100%" }}>
          End Date
          <input
            type="date"
            className="datepicker"
            value={this.state.end}
            onChange={(event) => this.setState({ end: event.target.value })}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;End Time
          <input
            className="datepicker"
            type="time"
            value={this.state.end_time}
            onChange={(event) =>
              this.setState({ end_time: event.target.value })
            }
          />
        </div>
        <button
          className="button"
          onClick={this.loadOrders}
          style={{ marginLeft: "20px" }}
        >
          Load Transactions
        </button>
        {this.renderExpansion()}
      </div>
    )
  }
}

export default Transactions
