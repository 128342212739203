import React, { Component } from "react"
import OrderDetailsSection from "./OrderDetailsSection"
import DinerDetailsSection from "./DinerDetailsSection"
import UserContextHOC from "../contexts/UserContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import LoadingActionButton from '../Loading/LoadingActionButton'
import ButtonWithSpinner from "./ButtonWithSpinner"
import ChevronLeft from "./svg/ChevronLeft"
import TabletSidebar from "./TabletSidebar"
import classnames from "classnames"
import withRouterAndRef from "../withRouterAndRef"
import "./OrderCard.css"
const Api = require("../api")
const Money = require("../helpers/money")

class CancelOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile:window.innerWidth < 600,
      isTablet:window.innerWidth >= 600,
      reason_id:null,
      ...props.location.state
    }
    this.close = this.close.bind(this)
    this.cancel = this.cancel.bind(this)
    window.shit = this
  }

  componentDidMount(){
    this.mounted = true
    this.refreshOrder()
  }

  componentWillUnmount(){
    this.mounted = false
  }

  refreshOrder(){
    if(!this.mounted) return
    Api.callApi({
      endpoint:'refresh-order',
      order_id:this.state.order.order_id,
      success:(data)=>{
        this.setState({order:data.order})
        setTimeout(()=>this.refreshOrder(), 5000)
      },
      failure:()=>{
        setTimeout(()=>this.refreshOrder(), 5000)
      }
    })
  }

  closeModal(e){
    if (e.target.className === "modal-background moda-background-from-right"){
      this.setState({ closing: true }, () => {
        setTimeout(() => {
          let path = this.props.location.pathname
          let parts = path.split("/")
          let newPath = "/"+parts[1] + "/" + parts[2] 
          this.props.history.replace({pathname:newPath})
        }, 200)
      })  
    }   
  }

  close(num_pages_back) {
    let that = this
    this.setState({ closing: true }, () => {
      setTimeout(() => {
        this.props.history.go(num_pages_back) 
      }, 200)
    })  
  }

  cancel(callback){
    // prevent double click
    if(this.state.loading) return
    this.setState({loading:true})
    Api.callApi({
      endpoint:'cancel-order',
      data:{
        order_id: this.state.order.order_id,
        reason_id:this.state.reason_id
      },
      success:()=>{
        // we want to go back to /orders/blah, so let's parse
        // that from the current pathname
        let path = this.props.location.pathname
        let parts = path.split("/")
        let newPath = "/"+parts[1] + "/" + parts[2]
        this.props.history.replace({
          pathname:newPath,
          state:{
            toast:{
              type:'cancelled',
              order:this.state.order
            }
          }
        })
      },
      failure:()=>{
        if(callback){
          callback()
        }
        this.setState({loading:false})
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error cancelling this order.'
          }
        })
      }
    })
  }

  render(){
    return (
      <div 
        className='modal-background moda-background-from-right'
        style={{background:null}}
        onClick={(e)=>this.closeModal(e)}
        id={this.state.closing ? "modal-background-exit-right" : null}
      >
        <div 
          key='wtf'
          className={classnames('full-page-card', 'full-page-card-from-right',
            {'full-page-card-disabled':this.state.expandedCircle})}
          id={this.state.closing ? "full-page-card-exit-right" : null}
        >
          <div className='full-page-card-header gray-header'>
            <ChevronLeft 
              onClick={()=>this.close(-1)}
              className='full-page-card-header-back-chevron'
            />
            <div 
              className='full-page-card-header-text gray-header-text'
              onClick={this.cancel}
            >
              Decline Order
            </div>
            <ChevronLeft style={{opacity:0}}/>
          </div>


          <div
            className='full-page-card-content-container white-background'
            style={{minHeight:window.innerHeight-96 + 'px'}}
          >
            <div 
              className='full-page-card-content orders-content white-background'
              style={{minHeight:window.innerHeight-96 + 'px'}}
            >

              <OrderDetailsSection 
                hideClock={this.state.isTablet}
                order={this.state.order}
                page={this.state.page}
              />
              <DinerDetailsSection order={this.state.order} />

              <div className='full-page-card-special-instructions'>
                <div className='full-page-card-special-instructions-header'>
                  <Warning className='full-page-card-special-instructions-warning'/>
                  <div className='full-page-card-special-instructions-header-text'>
                    Do you want to decline this order?
                  </div>
                </div>
                <div className='full-page-card-special-instructions-text'>
                  This customer will recieve an automated text. You will lose this
                  order of {Money.formatCents(this.state.order.total)}.
                </div>
              </div>


              {this.props.user.rest && (
                <div className='cancel-reasons-container'>
                  <div className='cancel-reason-header-text'>
                    Reason
                  </div>
                  <div className='cancel-reasons-inner-container'>
                    {this.props.user.rest.cancel_reasons.map((reason)=>(
                      <div 
                        className='cancel-reason-container'
                        onClick={()=>this.setState({reason_id:reason.id})}
                      >
                        <div className='cancel-reason-button-container'>
                          {reason.id === this.state.reason_id ? (
                            <ClosedCircle/>
                          ) : ( 
                            <OpenCircle/>
                          )}
                        </div>
                        <div className='cancel-reason-reason'>{reason.reason}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {this.state.isMobile && (
                <>
                  <div className='card-button keep-this-order mt-15'>
                    <div onClick={()=>this.close(-1)}>No, keep this order</div>
                  </div>

                  {this.state.reason_id ? (
                    <ButtonWithSpinner
                      onClick={(cb)=>this.cancel(cb)}
                      className='card-button decline-this-order'
                      spinnerColor='white'
                      text='Decline this order'
                    />
           
                  ) : (
                    <div 
                      className={classnames('card-button', 'decline-disabled')}
                    >
                      <div>Decline this order (choose a reason)</div>
                    </div>
                  )}       
                </>
              )}

            </div> {/* .full-page-card-content */}

            {this.state.isTablet && (
              <TabletSidebar
                close={()=>this.close(-1)}
                originalPage={this.state.originalPage}
                page='cancel'
                {...this.props}
                order={this.state.order}
                cancel={(cb)=>this.cancel(cb)}
                cancelReasonSelected={!!this.state.reason_id}
              />

            )}

          </div> {/*. full-page-card-content-container */}
          
        </div> {/* .full-page-card */}

      </div>
    )
  }
}
      
export default withRouterAndRef(NewOrdersContextHOC(UserContextHOC(CancelOrder))) 

const Warning = props => (<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg>)

const OpenCircle = props => (<svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11z"/></svg>)

const ClosedCircle = props => (<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>)


