import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = {
  CARD: 'card',
}

const style = {
    cursor: 'move',
};


export const DesktopSection = memo(function DesktopSection({ id, disableEdits, menuCategoryId, text, itemCount, moveCard, findCard, scrollToSection}) {
    const originalIndex = findCard(id).index;
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.CARD,
        item: { id, originalIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const { id: droppedId, originalIndex } = item;
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                moveCard(droppedId, originalIndex);
            }
        },
    }), [id, originalIndex, moveCard]);
    const [, drop] = useDrop(() => ({
        accept: ItemTypes.CARD,
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id);
                moveCard(draggedId, overIndex);
            }
        },
    }), [findCard, moveCard]);
    const opacity = isDragging ? 0 : 1;

    return(
      <div 
        className='menu-section-box-desktop'
        ref={(node) => {return disableEdits ? null : drag(drop(node))}} 
        style={{ ...style, opacity }}
        onClick={()=>scrollToSection(menuCategoryId)}
      >
        <div className='menu-section-box-title-desktop' >
         { text}
        </div>
        <div className='menu-section-box-subtitle-desktop'>
          {itemCount} items
        </div>
      </div>
    )
});

