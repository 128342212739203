import React, { Component } from "react"
import "./Slider.css"

class Slider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      on:!!this.props.on
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.on !== prevProps.on && this.state.on !== this.props.on){
      this.setState({on:this.state.on})
    }
  }

  word(){
    if(this.state.on){
      return (
        <div className='slider-on-word'>
          {this.props.onWord}
        </div>
      )
    } else {
      return (
        <div className='slider-off-word'>
          {this.props.offWord}
        </div>
      )
    }
  }

  onChange(){
    this.setState({on:!this.state.on},()=>{
      if(this.state.on) this.props.set()
      else this.props.unset()
    })
  }

  render(){
    return (
      <div className='slider-container'>
        <label class="switch">
          <input type="checkbox" checked={this.state.on} onChange={()=>this.onChange()}/>
          <span class="slider round"></span>
        </label>
        {this.word()}
      </div>
    )
          
  }
}
      

export default Slider 


