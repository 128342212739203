import React, { Component } from "react"
import withRouterAndRef from '../withRouterAndRef'
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import DesktopSidebar from "../menu/DesktopSidebar"
import DesktopTabs from "./DesktopTabs"
import DesktopIncomingOrders from "./DesktopIncomingOrders"
import OrderCard from "./OrderCard"
import classnames from "classnames"
import SearchIcon from "./svg/Search"
import OrderToast from "./OrderToast"
import "./OrderCard.css"
import "./Home.css"
import "./Search.css"
import Loading from '../Loading/Loading'
const Api = require("../api")
const Timestamp = require("../helpers/timestamps")

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDesktop:window.innerWidth > 1000,
      query:'',
      scheduledMatches:[],
      activeMatches:[],
      unconfirmedMatches:[],
      historyMatches:[],
      toasts:[],
      pathname:this.props.location ?  this.props.location.pathname : null
    }
    this.inputRef = React.createRef()
    this.handleEnterKey = this.handleEnterKey.bind(this)
    window.search = this
  }

  componentDidMount(){
    this.inputRef.current.focus()
    this.mounted = true
    window.addEventListener("keydown", this.handleEnterKey)
    if(
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.toast
    ){
      this.addToast(this.props.history.location.state.toast)
    }
  }

  componentWillUnmount(){
    window.addEventListener("keydown", this.handleEnterKey)
    this.mounted = false
  }

  componentDidUpdate(prevProps){
    if(this.props.location && this.props.location.pathname !== this.state.pathname){
      if(prevProps.location.pathname.indexOf('modal') > -1){
      }
      this.setState({pathname:this.props.location.pathname})
    }
  }

  handleEnterKey(e) {
    if(e.key === 'Enter'){
      window.document.activeElement.blur()
    }   
  }


  addToast(toast){
    let toasts = this.state.toasts
    toasts.push(toast)
    this.setState({toasts:toasts})
    setTimeout(()=>this.removeToast(toast), 8000)
  }

  removeToast(toast){
    let toasts = this.state.toasts
    let index = null
    for(var i in toasts){
      if(toasts[i].order.order_id === toast.order.order_id){
        index = i
      }
    }
    toasts.splice(i, 1)
    this.setState({toasts:toasts})
  }


  noMatches(){
    return this.state.query &&
      !this.state.unconfirmedMatches.length &&
      !this.state.historyMatches.length &&
      !this.state.activeMatches.length &&
      !this.state.scheduledMatches.length 
  }

  processResults(data){
    console.log("results", data)
    let historyMatches = []
    let unconfirmedMatches = []
    let scheduledMatches = []
    let activeMatches = []

    for(let i in data.orders){
      let o = data.orders[i]
      if (o.status === 'unconfirmed'){
        unconfirmedMatches.push(o)
      } else if (o.status === 'confirmed'){
        activeMatches.push(o)
      } else if (o.status === 'snoozed'){
        scheduledMatches.push(o)
      } else if (o.status === 'cancelled') {
        historyMatches.push(o)
      } else if (o.status === 'completed'){
        historyMatches.push(o)
      }
    }

    // don't set the state if these are old results
    if(data.query === this.state.loading_query){
      this.setState({
        loading:false,
        unconfirmedMatches:unconfirmedMatches,
        scheduledMatches:scheduledMatches,
        activeMatches:activeMatches,
        historyMatches:historyMatches
      })
   
    
    }
  }

  input(query){
    if(query === ''){
      this.setState({
        scheduledMatches:[],
        activeMatches:[],
        unconfirmedMatches:[],
        historyMatches:[],
        loading:false,
        query:''
      })
      return
    }

    this.setState({query:query, loading_query:query, loading:true})
    Api.callApi({
      endpoint:'search',
      data:{
        query:query
      },
      success:(data)=>{
        this.processResults(data)
      },
      failure:(err)=>{
        this.props.history.push({
            pathname:this.props.location.pathname +'/error-modal',
            state:{
              message:'There was an error searching for orders.'
            }
          })
      }
    })
   
  }

  numMatches(){
    return this.state.scheduledMatches.length + this.state.activeMatches.length +
      this.state.unconfirmedMatches.length + this.state.historyMatches.length
  }

  renderContent(){
    return (
      <div className='orders-content desktop-orders-content'>

        <div className='search-bar-container'>
          <SearchIcon className='search-bar-icon'/>
          <input 
            ref={this.inputRef}
            value={this.state.query}
            onChange={(e)=>this.input(e.target.value)}
            placeholder='Search orders'
            className='search-bar-input-search-page search-bar-input'
          />  
          <div 
            className='search-cancel'
            onClick={()=>this.props.history.goBack()}
          >
            Cancel
          </div>
        </div>


        {this.state.loading ? (
          <Loading />
        ) : (
          
          <>

          <div className='page-header space-between'>
            <div>Search Orders</div>
            {!this.noMatches() && this.state.query && (
              <div> {this.numMatches()} Results </div>
            )}
          </div>

          {this.state.toasts.map((toast)=>(
            <OrderToast
              type={toast.type}
              order={toast.order}
              {...toast}
            />
          ))}

          {!this.state.query && (
            <div className='search-what-you-looking-for'>
              <div className='search-what-you-looking-for-header'>
                What are you looking for?
              </div>
              <div className='search-what-you-looking-for-text'>
                Search for orders or customers by name, phone number or order number
              </div>
            </div>
          )}

          {this.noMatches() && (
            <div className='search-what-you-looking-for input-error'>
              <div className='search-what-you-looking-for-header'>
                No Orders Found
              </div>
              <div className='search-what-you-looking-for-text'>
                We coundn’t find what you were looking for
              </div>
            </div>
          )}

          {this.state.unconfirmedMatches.length > 0  && (
            <div className='orders-bucket search-bucket'>
              <div className='orders-bucket-header'>
                <div className='orders-bucket-header-left'> 
                  Incoming
                </div>
                <div className='orders-bucket-header-right'>({this.state.unconfirmedMatches.length})</div>
              </div>
              {this.state.unconfirmedMatches.map((order)=>(
                <OrderCard
                  page='search-unconfirmed'
                  key={order.order_id}
                  order={order}
                />
              ))}
            </div>
          )}

          {this.state.activeMatches.length > 0  && (
            <div className='orders-bucket search-bucket'>
              <div className='orders-bucket-header'>
                <div className='orders-bucket-header-left'> 
                  Active
                </div>
                <div className='orders-bucket-header-right'>({this.state.activeMatches.length})</div>
              </div>
              {this.state.activeMatches.map((order)=>(
                <OrderCard
                  page='search-kitchen'
                  key={order.order_id}
                  order={order}
                />
              ))}
            </div>
          )}

          {this.state.scheduledMatches.length > 0  && (
            <div className='orders-bucket search-bucket'>
              <div className='orders-bucket-header'>
                <div className='orders-bucket-header-left'> 
                  Scheduled
                </div>
                <div className='orders-bucket-header-right'>({this.state.scheduledMatches.length})</div>
              </div>
              {this.state.scheduledMatches.map((order)=>(
                <OrderCard
                  page='search-scheduled'
                  key={order.order_id}
                  order={order}
                />
              ))}
            </div>
          )}

          {this.state.historyMatches.length > 0  && (
            <div className='orders-bucket search-bucekt'>
              <div className='orders-bucket-header'>
                <div className='orders-bucket-header-left'> 
                  Past Orders
                </div>
                <div className='orders-bucket-header-right'>({this.state.historyMatches.length})</div>
              </div>
              {this.state.historyMatches.map((order)=>(
                <OrderCard
                  page='search-history'
                  key={order.order_id}
                  order={order}
                />
              ))}
            </div>
          )}
          </>
        )}
      </div>
    )
  }

  render(){
    if(this.state.isDesktop){
      return (
        <div className='desktop-orders-page-container'>
          <DesktopSidebar />
          <div className='desktop-orders-container-container'>
            <DesktopTabs />
            {this.renderContent()}
          </div>
          <div className='desktop-orders-incoming-orders-container'>
            <DesktopIncomingOrders />
          </div>
        </div>
      )
    } else {
      return this.renderContent()
    }
  }


}

export default withRouterAndRef(NewOrdersContextHOC(Search)) 

