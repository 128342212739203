import React, { Component } from "react"
import ToastContextHOC from "../contexts/ToastContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import classnames from 'classnames'
import Loading from "../Loading/Loading"
import "./Menu.css"
import DeleteCircle from "../orders/svg/DeleteCircle"
import Check from "../orders/svg/Check"

const Api = require("../api")
const Money =  require("../helpers/money")
const Timestamp = require("../helpers/timestamps")

class ModifierClassBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded:false,
    }
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.containerRef = React.createRef()
  }

  componentDidMount(){
   window.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount(){
   window.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event){
    if(!this.state.expanded) return
    if( 
      this.containerRef &&
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target)
    ){  
      this.setState({expanded:false})
    }   
  }

  render(){
    let mc = this.props.mc
    if(this.state.expanded){
      return (
        <div key='expanded' className='menu-item-box-expanded'>


          <div className={classnames('menu-item-box-expanded-left')}>

            {mc.internal_name && (
              <div className='menu-box-internal-name'>
                {mc.internal_name}
              </div>
            )}
            <div className='menu-box-title'>{mc.display_text}</div>
            <div className='flexstart'>
              <div className='modifier-class-options-circle'>
                <div>{mc.modifier_count} options</div>
              </div>
              {mc.deactivated_count > 0 && (
                <div className='modifier-class-options-circle-deactivated'>
                  <div>{mc.deactivated_count} deactivated </div>
                </div>
              )}
            </div>
 
          
          </div>

          <div
            ref={this.containerRef}
            className='menu-option-container'
            onClick={()=>{
              if(this.state.loading) return
              if(this.props.remove){
                this.props.remove()
                return
              } else {
                this.setState({loading:true})
                Api.callApi({
                  endpoint:'/api/tablet/remove-modifier-class',
                  method:'post',
                  data:{
                    modifier_class_id:mc.modifier_class_id,
                    menu_item_id:this.props.item.menu_item_id
                  },
                  failure:(err)=>{
                    alert('Unexpected error')
                    this.setState({loading:false})
                  },
                  success:(data)=>{
                    this.setState({loading:false})
                    this.props.toast.setToast({
                      type:'deleted',
                      message:mc.internal_name ? mc.internal_name : mc.display_text 
                    })
                  }
                })
              }
              
            }}
          >
            <div 
              className='delete-x-container'
            >
              {this.state.loading === true ? ( 
                <Loading style={{transform:'scale(0.5)', left:'-13px'}}/> 
              ) : ( 
                <DeleteCircle/>
              )}
            </div>
            <div className='menu-option-label red'>
              Remove
            </div>
          </div>
          
        </div>
      )
    } else {
      return (
        <div 
          style={this.props.style}
          onClick={(e)=>{
            if(e.target.className === 'menu-item-elipses') return
            
            if(this.props.onClick){
              this.props.onClick(mc)
            } else if(this.props.page === 'menu'){
              this.props.history.push({
               pathname:'/menu/modifier-class-from-menu-page/'+this.props.menu_uuid+'/'+mc.modifier_class_id
              })
            }
            else if(this.props.page === 'item'){
              this.props.history.push({
                pathname:'/menu/modifier-class-from-menu-item/' + this.props.menu_uuid +'/'+ this.props.item.menu_item_id + '/' + mc.modifier_class_id
              })
            } else if (this.props.page === 'search'){
              this.props.history.push({
                pathname:'/menu/modifier-class-from-search/' + mc.modifier_class_id
              })
            }
          }}
          
          className={classnames('menu-item-box',
            {'menu-item-box-with-internal-name':mc.internal_name},
            {'inside-box':this.props.insideBox})}
          

          >

          {this.props.insideBox !== true && this.props.disableEdits !== true && this.props.hideElipses !== true && (
            <div 
              className='menu-item-elipses'
              onClick={()=>this.setState({expanded:true})}
            >
              •••
            </div>
          )}

          {mc.internal_name && (
            <div className='menu-box-internal-name'>
              {mc.internal_name}
            </div>
          )}
          <div className='menu-box-title'>{mc.display_text}</div>
          <div className='flexstart'>
            <div className='modifier-class-options-circle'>
              <div>{mc.modifier_count} options</div>
            </div>
            {mc.deactivated_count > 0 && (
              <div className='modifier-class-options-circle-deactivated'>
                <div>{mc.deactivated_count} deactivated </div>
              </div>
            )}
            {this.props.page === 'search' && (
              <>
                {mc.matching_items.map(item => (
                  <div className='modifier-class-option-search-result-item'>
                    <div>{item}</div>
                  </div>
                ))}
              </>
            )}

          </div>
        </div>
      )
    }
  }
}
      

export default withRouterAndRef(ToastContextHOC(ModifierClassBox))


