import React from "react"
import { withToasts } from "../../toasts"
import TodayHours from "./TodayHours"
import PickupHours from "./PickupHours"
import DeliveryHours from "./DeliveryHours"
import SpecialHours from "./SpecialHours"
import UserContextHOC from "../../../contexts/UserContextHOC"
import { Tabs } from "../../components"
import DesktopSidebar from "../../../menu/DesktopSidebar"
import "./hours.scss"
import "../../App.css"
import "../../helpers.scss"

const API = require("../../api.js")
const Helpers = require("./helpers")

const T = {
  TODAY: "Today",
  PICKUP: "Pickup Hours",
  DELIVERY: "Delivery Hours",
  SPECIAL: "Special Hours",
}

const DAYS = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
}

class Hours extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hours: null,
      isDesktop:window.innerWidth > 1000
    }
    this.saveHours = this.saveHours.bind(this)
    this.loadHours = this.loadHours.bind(this)
    this.getSchedules = this.getSchedules.bind(this)
    this.loadHours()

    this.tabMap = this.tabMap.bind(this)
    window.h = this
  }

  saveHours = async (shiftedHours, delivery_zone_id, callback) => {
    let hours = Helpers.unshiftHours(shiftedHours)
    // verify that no end times are after start times
    for(let i in hours){
      for(let j in hours[i]){
        if(hours[i][j].close_at_min < hours[i][j].open_at_min){
          let day = DAYS[i]
          let msg = `Hours on ${day} are invalid`
          alert(msg)
          return
        }
      }
    }
    let rest = this.props.user.rest
    API.call(
      "save-hours",
      (resp) => {
        this.setState({ hours: resp.hours }, () => {
          /*
          this.props.toasts.add({
            message: "Hours sucessfully saved!",
            type: "success",
          })*/
          if(callback){
            callback()
          }
          alert('Hours saved!')
        })
      },
      (err) => {
        alert("Error saving hours")
      },
      null,
      {
        rest_id: rest.id,
        delivery_zone_id: delivery_zone_id,
        hours: hours,
      }
    )
  }

  loadHours = async () => {
    if(!this.props.user.rest || !window.API_KEY){
      setTimeout(()=>this.loadHours(), 100)
      return
    }
    let rest = this.props.user.rest 
    console.log("REST", rest)
    API.call(
      "hours",
      (resp) => {
        this.setState({ hours: resp.hours })
      },
      (err) => {
        alert("error getting hours")
      },
      null,
      { rest_id: rest.id }
    )
  }

  handleTabChange = (tab) => {
    this.setState({ activeTab: T[tab] })
  }

  getSchedules = () => {
    let schedules = [
      {
        name: "Pickup",
        hours: this.state.hours.pickup,
      },
    ]
    for (let i in this.state.hours.delivery) {
      schedules.push({
        name: this.state.hours.delivery[i].name,
        hours: this.state.hours.delivery[i].hours,
      })
    }
    return schedules
  }

  tabMap = () => ({
    Today: (
      <TodayHours
        loadRest={this.props.loadRest}
        hours={this.state.hours}
        loadHours={this.loadHours}
      />
    ),
    "Pickup Hours": (
      <PickupHours
        hours={this.state.hours.pickup}
        getSchedules={this.getSchedules}
        saveHours={this.saveHours}
      />
    ),

    "Delivery Hours": (
      <DeliveryHours
        hours={this.state.hours.delivery}
        getSchedules={this.getSchedules}
        saveHours={this.saveHours}
      />
    ),
    "Special Hours": <SpecialHours loadRest={this.props.loadRest} />,
  })


  tabMapMobile = () => ({
    "Pickup Hours": (
      <PickupHours
        hours={this.state.hours.pickup}
        getSchedules={this.getSchedules}
        saveHours={this.saveHours}
      />
    ),

    "Delivery Hours": (
      <DeliveryHours
        hours={this.state.hours.delivery}
        getSchedules={this.getSchedules}
        saveHours={this.saveHours}
      />
    ),
  })

  render() {
    if(this.state.isDesktop){
      return (
        this.state.hours && (
          <div className='desktop-menu-item-page-container'>
            <DesktopSidebar
              menu_uuid={this.props.match.params.menu_uuid ? this.props.match.params.menu_uuid : this.state.menu_uuid  }
            />
            <div className='AppBody'>
              <div className='fluid-content--body'>
                <div>
                  <Tabs
                    tabMap={this.tabMap()}
                    initialTab="Today"
                    childClass="flex-col p-3"
                  />
                </div>
              </div>
            </div>
          </div>
        )
      ) 
    } else {
      return (
        this.state.hours && (
          <div className='AppBody-tablet'>
            <div className='fluid-content--body'>
              <div>
                <Tabs
                  tabMap={this.tabMapMobile()}
                  initialTab="Pickup Hours"
                  childClass="flex-col p-3"
                />
              </div>
            </div>
          </div>
        )
      )
    }
  }
}

// const Tabs = withMobile(({ isMobile, activeTab, handleTabChange }) => {
//   return (
//     <div
//       className={classnames("tabs-container flex-row flex-justify-center", {
//         "flex-justify-start x-auto": isMobile,
//       })}
//     >
//       {Object.keys(T).map((tab) => (
//         <button onClick={() => handleTabChange(tab)} className="button-default">
//           <Text
//             color="blue"
//             weight="black"
//             className={classnames("no-wrap", {
//               "border-b-pink border-2": activeTab === T[tab],
//               "ph-8 pv-4": !isMobile,
//               "ph-4 pv-2": isMobile,
//             })}
//             size="l"
//           >
//             {T[tab]}
//           </Text>
//         </button>
//       ))}
//     </div>
//   )
// })

export default withToasts(UserContextHOC(Hours))
