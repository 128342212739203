import React, { Component } from "react"
import classnames from 'classnames'
import UserContextHOC from "../contexts/UserContextHOC"
import LoginBackHeader from "./LoginBackHeader"
import ButtonWithSpinner from "../orders/ButtonWithSpinner"
import PhoneSVG from "../orders/svg/Phone"
import ChevronLeft from "../orders/svg/ChevronLeft"
import "./Login.css"
const Api = require("../api")
const Phone = require("../helpers/phone")

class EnterCode extends Component {
  constructor(props) {
    super(props)
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.name &&
      this.props.history.location.state.phone
    ){
      this.state = {...this.props.history.location.state}
      this.state.code = ''
    } else {
      this.props.history.replace({
        pathname:'/login'
      })
    }
    this.buttonRef = React.createRef()
    this.handleEnterKey = this.handleEnterKey.bind(this)
  }

  componentDidMount(){
    this.mounted = true
    window.addEventListener("keydown", this.handleEnterKey)
  }

  componentWillUnmount(){
    window.addEventListener("keydown", this.handleEnterKey)
    this.mounted = false
  }

  handleEnterKey(e) {
    if(e.key === 'Enter'){
      if(
        this.buttonRef && 
        this.buttonRef.current &&
        this.state.code.length === 4
      ){
        window.document.activeElement.blur()
        this.buttonRef.current.click()
      }
    }
  }


  
  checkCode(cb){
    Api.callApi({
      endpoint:'check-login-code',
      data:{
        auth_uuid:this.state.auth_uuid,
        code:this.state.code
      },
      success:(data)=>{
        if(data.success){
          if(data.is_captain_admin){
            this.props.user.loginAsAdmin(data.captain_admin_api_key)
          } else {
            this.props.history.push({
              pathname:'/login/choose-rest',
              state:{
                name:this.state.name,
                phone:this.state.phone,
                rests:data.rests
              }
            })
          }
        } else{
          cb()
          this.setState({error:data.error})
        }
      },
      failure:(err)=>{
        cb()
        this.setState({error:'There was an unexpected error'})
      }
    })
  }

   resendCode(cb){
    if(this.resendingCode) return
    
    this.resendingCode = true
    
    Api.callApi({
      endpoint:'get-login-code',
      data:{phone:this.state.phone},
      success:(data)=>{
        if(data.success){
          this.setState({auth_uuid:data.auth_uuid})
          this.resendingCode = false
          alert('Code resent')
        } else{
          alert('Error resending code')
          this.resendingCode = false
        }
      },
      failure:(err)=>{
        this.resendingCode = false
        alert('Unexpected error resending code')
      }

    })
  }

  render(){
    return (
      <div className='login-page'>
       <LoginBackHeader/>
        <div className='content login-content'> 
          <div className='login-phone-icon-container'>
            <PhoneSVG/>
          </div>
          <div className='login-header'>
            Hey {this.state.name}
          </div>
          <div className='login-subheader'>
            Please enter the 4-digit code we sent to {Phone.format(this.state.phone)}
          </div>

          <div className='login-phone-row'>
            <input 
              type='tel'
              className={classnames('login-phone-input',
                {'input-error':this.state.error})}
              placeholder='Enter 4-digit code'
              value={this.state.code}
              onChange={(e)=>this.setState({code:e.target.value})}
            />
          </div>
          
          {this.state.error && (
          <div className='login-phone-error'>
            {this.state.error}
          </div>

          )}

          {this.state.code.length !== 4 && (
            <div className='login-phone-button login-phone-button-disabled'>
              <div>Next</div>
            </div>
          )}

          {this.state.code.length === 4 && (
            <ButtonWithSpinner
              ref={this.buttonRef}
              onClick={(cb)=>this.checkCode(cb)}
              className='login-phone-button login-phone-button-enabled'
              text='Next'
            />
          )}

          <div 
            className='resend-code-link'
            onClick={()=>this.resendCode()}
          >
            Resend Code in :15
          </div>
        </div>
      </div>
    )
  }
}
      

export default UserContextHOC(EnterCode) 


