import React, { Component } from "react"
import Loading from "../Loading/Loading"
import Orders from "./OrdersModal"

const API = require("../api")

class Summaries extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: "daily",
      rest:this.props.rest
    }
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount() {
    this.loadSummaries()
  }

  formatPrice(num) {
    return (
      "$" +
      (num / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    )
  }

  closeModal() {
    this.setState({
      expanded: false,
      expandedOrders: null,
    })
  }

  async loadSummaries() {
    API.call(
      "summaries",
      (res) => {
        let splitTips = false
        for(let i in res['daily']){
          if(res['daily'][i].restaurant_tip || res['daily'][i].driver_tip) splitTips = true
        }
        for(let i in res['weekly']){
          if(res['weekly'][i].restaurant_tip || res['weekly'][i].driver_tip) splitTips = true
        }
        for(let i in res['monthly']){
          if(res['monthly'][i].restaurant_tip || res['monthly'][i].driver_tip) splitTips = true
        }
        this.setState({ summaries: res, splitTips:splitTips })
      },
      (err) => console.log("Error loading sales", err),
      { rest_id: this.state.rest.restaurant_id }
    )
  }

  renderToggles() {
    return (
      <div className="left">
        {["daily", "weekly", "monthly"].map((t, i) => (
          <button
            key={i}
            style={{
              margin: "16px 8px",
              fontSize: "12px",
              color: t === this.state.toggle ? "#fff" : "#4284f4",
              borderColor: t === this.state.toggle ? "#fff" : "#4284f4",
            }}
            className={
              t === this.state.toggle ? "button mh16" : "button secondary mh16"
            }
            onClick={() => this.setState({ toggle: t })}
          >
            {t}
          </button>
        ))}
      </div>
    )
  }

  expand(key) {
    this.setState({
      expanded: true,
      expandedOrders: null,
      key: key,
    })
    API.call(
      "summary",
      (res) => {
        this.setState({ expandedOrders: res.orders })
      },
      (err) => {},
      null,
      {
        rest_id: this.state.rest.restaurant_id,
        toggle: this.state.toggle,
        key: key,
      }
    )
  }

  renderSummaries() {
    let rows = []
    for (let i in this.state.summaries[this.state.toggle]) {
      let summary = this.state.summaries[this.state.toggle][i]
      let style = {}
      if (this.state.expandedOrders) {
        style["opacity"] = 0.3
      }
      rows.push(
        <tr key={i} style={style} onClick={() => this.expand(summary.key)}>
          <td className="left member-first-cell">{summary.key}</td>
          <td className="left">{summary.orders}</td>
          <td className="left">{this.formatPrice(summary.product)}</td>
          <td className="left">{this.formatPrice(summary.promo)}</td>
          <td className="left">{this.formatPrice(summary.subtotal)}</td>
          <td className="left">{this.formatPrice(summary.delivery)}</td>
          <td className="left">{this.formatPrice(summary.tax)}</td>
          {this.state.splitTips && (
            <>
              <td className="left">{this.formatPrice(summary.driver_tip)}</td>
              <td className="left">{this.formatPrice(summary.restaurant_tip)}</td>
            </>
          )}
          <td className="left">{this.formatPrice(summary.tip)}</td>
          <td className="left member-last-cell">{this.formatPrice(summary.total)}</td>
        </tr>
      )
      if (
        this.state.expanded &&
        !this.state.expandedOrders &&
        summary.key === this.state.key
      ) {
        rows.push(
          <tr>
            <Loading />
          </tr>
        )
      }
    }
    return rows
  }

  render() {
    if (this.state.summaries) {
      return (
        <div className="w100 inline-block center">
          <h1 className="left fw900 ph16 m0">Summaries</h1>
          {this.renderToggles()}
          <div
            style={{ width: "100%", overflowX: "auto", paddingBottom: "32px" }}
          >
            <table className='member-orders-table'>
              <thead className="thead">
                <tr>
                  <th className="left member-first-cell" >
                    <b>Date</b>
                  </th>
                  <th className="left" >
                    <b>Orders</b>
                  </th>
                  <th className="left" >
                    Product
                  </th>
                  <th className="left">
                    Promo
                  </th>
                  <th className="left">
                    Subtotal
                  </th>
                  <th className="left" >
                    Delivery
                  </th>
                  <th className="left">
                    Tax
                  </th>
                  {this.state.splitTips && (
                    <>
                      <th className="left" >
                        Driver Tip
                      </th>
                      <th className="left" >
                        Restaurant Tip
                      </th>
                    </>
                  )}
                  <th className="left">
                    Tip {this.state.splitTips && <span> Total </span>}
                  </th>
                  <th className="left member-last-cell">
                    <b>Total</b>
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderSummaries()}</tbody>
            </table>
          </div>
          {this.state.expandedOrders ? (
            <Orders
              closeExpansion={this.closeModal}
              rows={this.state.expandedOrders}
              rest_id={this.state.rest.restaurant_id}
              summary={true}
              key_={this.state.key}
              toggle={this.state.toggle}
              {...this.props}
            />
          ) : null}
        </div>
      )
    }
    return <Loading />
  }
}

export default Summaries
