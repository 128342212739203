/*
  Params:
    endpoint -> name of the endpoint
    pin -> the rest pin, used for authneticated API calls
    success -> success callback, receieves the json decoded response from endpoint
    failure -> error call back, receives the error
    data -> post data (should be oejct)
    url_uparams -> url parameters as object for GET requests
*/

const callApi = params => {
  let endpoint = params.endpoint;
  const success = params.success ? params.success : () => {};
  const failure = params.failure ? params.failure : () => {};
  const data = params.data ? params.data : {};
  // const url_params = data.url_params ? data.url_params : {};
  const order_id = params.order_id ? params.order_id : null
  const resource_id = params.resource_id ? params.resource_id : ''
  let apiContent = 'json'

  let method = params.method ? params.method : 'get'


  if (endpoint === 'add-modifier-to-modifier-group'){
    method = 'post' 
    endpoint = '/api/tablet/add-menu-item-to-modifier-group'
  }

  if (endpoint === 'save-modifier-class'){
    method = 'post'
    endpoint = '/api/tablet/edit-modifier-class'
  }

  if (endpoint === 'search'){
    method = 'post'
    endpoint = '/api/tablet/v2/search'
  }

  if (endpoint === 'update-item'){
    method = 'post'
    endpoint = '/api/tablet/update-item'
  }

  if (endpoint === 'reload-item'){
    method = 'post'
    endpoint = '/api/tablet/load-item'
  }

  if (endpoint === 'deactivate'){
    method = 'post'
    endpoint = '/api/tablet/deactivate-item'
  }

  if (endpoint === 'reactivate'){
    method = 'post'
    endpoint = '/api/tablet/reactivate-item'
  }

  if (endpoint === 'central-timestamp'){
    method = 'get'
    endpoint = '/api/tablet/central-timestamp'
  }

  if (endpoint === 'local-timestamp'){
    method = 'get'
    endpoint = '/api/tablet/local-timestamp'
  }
 
  if (endpoint === 'rest_info') {
    method = 'post';
    endpoint = '/api/tablet/rest-info';
  }

  if (endpoint === 'heartbeat') {
    endpoint = '/api/tablet/heartbeat';
    method = 'post';
  }

  if (endpoint === 'check_for_new_orders'){
    method = 'post'
    endpoint = '/api/tablet/v2/unconfirmed-orders'
  }

  if (endpoint === 'confirm_order'){
    method = 'post'
    endpoint = '/api/tablet/confirm-order'
  }

  if (endpoint === 'active_orders'){
    method = 'get'
    endpoint = '/api/tablet/v2/active-orders'
  }

  if (endpoint === 'ready-for-pickup'){
    method = 'post'
    endpoint = '/api/tablet/ready-for-pickup'
  }

  if (endpoint === 'out-for-delivery'){
    method = 'post'
    endpoint = '/api/tablet/out-for-delivery'
  }

  if (endpoint === 'get-cart'){
    method = 'post'
    endpoint = '/api/tablet/v2/get-cart'
  }

  if (endpoint === 'cancel-order'){
    method = 'post'
    endpoint = '/api/tablet/cancel-order'
  }

  if (endpoint === 'snoozed-orders'){
    method = 'get'
    endpoint = '/api/tablet/v2/snoozed-orders'
  }

  if (endpoint === 'snooze-order'){
    method = 'post'
    endpoint = '/api/tablet/snooze-order'
  }

  if (endpoint === 'history'){
    method = 'get'
    endpoint = '/api/tablet/v2/history'
  } 

  if (endpoint === 'refresh-order'){
    method = 'get'
    endpoint = '/api/tablet/v2/refresh-order'
  }

  if (endpoint === 'adjust-order'){
    method = 'post'
    endpoint = '/api/tablet/adjust-order'
  }

  if (endpoint === 'print-log'){
    method = 'post'
    endpoint = '/api/tablet/receipt-log'
  }

  if (endpoint === 'is-closed-early'){
    method = 'post'
    endpoint = '/api/tablet/is-closed-early'
  }

  if (endpoint === 'close-early'){
    method = 'post'
    endpoint = '/api/tablet/close-early'
  }

  if (endpoint === 'undo-close-early'){
    method = 'post'
    endpoint = '/api/tablet/undo-close-early'
  }

  if (endpoint === 'fax'){
    endpoint='/api/tablet/resend-fax'
    method='post'
  }

  if (endpoint === 'get-login-code'){
    endpoint='/tablet/get-login-code'
    method='post'
  } 

  if (endpoint === 'get-login-code-robocall'){
    endpoint='/tablet/get-login-code-robocall'
    method='post'
  } 

  if (endpoint === 'check-login-code'){
    endpoint='/tablet/check-login-code'
    method='post'
  } 

  if (endpoint === 'check-login-code-robocall'){
    endpoint='/tablet/check-login-code-robocall'
    method='post'
  } 

  if (endpoint === 'get-version'){
    endpoint = '/api/tablet/current-version'
    method = 'get'
  }

  if (endpoint === 'save-push-token'){
    endpoint = '/api/tablet/v2/save-push-token'
    method = 'post'
  }

  if (endpoint === 'update-prep-time'){
    endpoint = '/api/tablet/update-prep-time'
    method = 'post'
  }

  if (endpoint === 'get-menus'){
    endpoint = '/api/tablet/get-menus'
    method = 'get'
  }

  const url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}${endpoint}`;
  if (method === 'post') {
    let headers = {
      'Content-Type': 'application/json',
      'X-HNGR-API-KEY': window.API_KEY,
    }
    if(order_id){
      headers['X-HNGR-ORDER-ID'] = order_id
    }

    if (window.IS_MEMBER_LOGIN){
      headers['X-CAPTAIN-MEMBER_LOGIN'] = 1 
      if(window.MEMBER_REST_ID){
        headers['X-CAPTAIN-RESTAURANT-ID'] = window.MEMBER_REST_ID
      }
    }
    console.log("HEADERS - " + url, headers)

    if (apiContent === 'json'){
      fetch(url, { method: 'post', headers, body: JSON.stringify(data) })
        .then(jsonresponse => {
          return jsonresponse.json()
        })
        .then(response => {
          success(response);
        })
        .catch(err => {
          console.log(err);
          failure(err);
        });
    } else if (apiContent === 'blob'){
      fetch(url, { method: 'post', headers, body: JSON.stringify(data) })
        .then(response => response.blob())
        .then(response => {
          success(response);
        })
        .catch(err => {
          console.log(err);
          failure(err);
        });
    }
  }

  if (method === 'get') {
    let headers = {
      'X-HNGR-API-KEY': window.API_KEY
    }

    if (window.IS_CAPTAIN_ADMIN){
      headers['X-CAPTAIN-ADMIN'] = 1
    }

    if (window.CAPTAIN_ADMIN_RESTAURANT_ID){
      headers['X-CAPTAIN-ADMIN-RESTAURANT-ID'] = window.CAPTAIN_ADMIN_RESTAURANT_ID
    }

    if (window.IS_MEMBER_LOGIN){
      headers['X-CAPTAIN-MEMBER_LOGIN'] = 1 
      if(window.MEMBER_REST_ID){
        headers['X-CAPTAIN-RESTAURANT-ID'] = window.MEMBER_REST_ID
      }
    }

    if(order_id){
      headers['X-HNGR-ORDER-ID'] = order_id
    }

    if (apiContent === 'json'){
      fetch(url, { method: 'get', headers })
        .then(jsonresponse => jsonresponse.json())
        .then(response => {
          success(response);
        })
        .catch(err => {
          console.log(err);
          failure(err);
        });
    } else if (apiContent === 'blob'){
      fetch(url, { method: 'get', headers })
        .then(response => response.blob())
        .then(response => {
          success(response);
        })
        .catch(err => {
          console.log(err);
          failure(err);
        });
    }
  }
};

module.exports = {
  callApi,
};
