import React, { Component } from "react"
import withRouterAndRef from "../withRouterAndRef"
import Search from "../orders/svg/SearchBold"
import { Link }  from 'react-router-dom';
import "./Breadcrumbs.css"

const Api = require("../api")

class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return  (
      <div className='breadcrumbs-container'>
        <div className='breadcrumbs-inner-container'>
          {this.props.crumbs.map(crumb => (
            <div className='breadcrumb'>
              <Link style={{marginRight:'5px'}} to={crumb.path}>{crumb.text}</Link>
              <span>/</span>
            </div>
          ))}
          <div className='breadcrumb-current'>{this.props.current}</div>
        </div>
        {!this.props.hideSearch && (
          <Search 
            onClick={()=>this.props.history.push({pathname:'/menu/search'})}
            className='breadcrumbs-search'
          />
        )}
      </div>
    )
  }
}

export default withRouterAndRef(Breadcrumbs)
