import { memo } from 'react';
import withRouterAndRef from "../withRouterAndRef"
import { useDrag, useDrop } from 'react-dnd';
import MenuItemBox from "./MenuItemBox"

const style = {
    cursor: 'move',
};




export const DesktopMenuItemDraggable = withRouterAndRef(memo(function DesktopMenuItemDraggable({ history, id, menu_category_id, text, item, parent, menu_uuid, moveCard, findCard, disableEdits}) {
    console.log("id", id)
    const originalIndex = findCard(id).index;
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'item'+menu_category_id,
        item: { id, originalIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const { id: droppedId, originalIndex } = item;
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                moveCard(droppedId, originalIndex);
            }
        },
    }), [id, originalIndex, moveCard]);
    const [, drop] = useDrop(() => ({
        accept: 'item'+menu_category_id, 
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id);
                moveCard(draggedId, overIndex);
            }
        },
    }), [findCard, moveCard]);
    const opacity = isDragging ? 0 : 1;

    if(id === 3){
    return (
      <div ref={(node) => drag(drop(node))} style={{display:'none'}}>
      </div>)
    }

    return (
      <div 
        ref={(node) => {return disableEdits ? null : drag(drop(node))}}
        style={{width:'48%', opacity}}
      > 
        <MenuItemBox 
          clickable={disableEdits === true ? false : true}
          noArchive={disableEdits}
          onClick={()=>{
            history.push({
              pathname:'/menu/menu-item-edit/'+item.menu_item_id
            })  
          }}  
          
          style={{ ...style, opacity }}
          menu_uuid={menu_uuid} 
          parent={parent} 
          item={item} 
          tab='items'
        />  
      </div>
    )
}));

