import React, { Component } from 'react';
import withRouterAndRef from "../withRouterAndRef"

export const ToastContext = React.createContext()

class ToastProvider extends Component {

  constructor(props){
    super(props)
    this.state = {
      toast:null,
      prevousId:null,
      generateKey:()=>this.generateKey(),
      setToast:(toast, goback)=>{
        if(goback){
          this.setState({toast:toast}, ()=>this.props.history.goBack())
        } else{
          this.setState({toast:toast})
        }
        setTimeout(()=>{
          if(JSON.stringify(this.state.toast) === JSON.stringify(toast)){
            this.setState({toast:null})
          }
        }, 4000)
      },
      goBackThenToast:(toast)=>{
        this.props.history.goBack()
        setTimeout(()=>this.setState({toast:toast}), 50)
        setTimeout(()=>this.setState({toast:toast}), 100)
        setTimeout(()=>this.setState({toast:toast}), 500)
        setTimeout(()=>this.setState({toast:toast}), 1000)
        setTimeout(()=>this.setState({toast:toast}), 2000)
        setTimeout(()=>{
          if(JSON.stringify(this.state.toast) === JSON.stringify(toast)){
            this.setState({toast:null})
          }
        }, 4000)
      },
      navigateThenToast:(path, toast, state)=>{
        if(state){
          this.props.history.replace({
            pathname:path,
            state:state
          })
        } else {
          this.props.history.replace(path)
        }
        // do multiple times cuz we dont know when the page will be loaded
        setTimeout(()=>this.setState({toast:toast}), 50)
        setTimeout(()=>this.setState({toast:toast}), 100)
        setTimeout(()=>this.setState({toast:toast}), 500)
        setTimeout(()=>this.setState({toast:toast}), 1000)
        setTimeout(()=>this.setState({toast:toast}), 2000)
        setTimeout(()=>{
          if(JSON.stringify(this.state.toast) === JSON.stringify(toast)){
            this.setState({toast:null})
          }
        }, 4000)

      }
    }
    window.Toast = this
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
       charactersLength));
   }
   return result;
  }

  generateKey(){
    let key = this.makeid(10)
    this.setState({key:key})
    return key
  }
  
  // if the Toast is not logged in, render the <Login />
  render() {
    return  (
      <ToastContext.Provider value={this.state}>
        {this.props.children}
      </ToastContext.Provider>
    )
  }
}

export default withRouterAndRef(ToastProvider);
