import React from "react"

import { ChromePicker } from "react-color"
import { Text } from "../../../components"
import Color from "./Color"
import { call } from "../../../api"
import "./brand.scss"

const DEFAULT_COLOR = "#01035e"

class BrandColor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      primary: this.props.rest.hex_color_primary,
      secondary: this.props.rest.hex_color_secondary,
      isOpen: {},
    }

    this.primaryPicker = React.createRef()
    this.secondaryPicker = React.createRef()

    this.pickers = {
      primary: this.primaryPicker,
      secondary: this.secondaryPicker,
    }

    this.open = []
  }
  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick)
  }

  handleOutsideClick = (e) => {
    if (e.target.id === "opener") {
      return
    }
    const a = this.open.some((picker) => picker.contains(e.target))
    if (!a) {
      this.setState({ isOpen: {} })
    }

    // console.log(this.primaryPicker.current)
    // console.log(this.primaryPicker.current.contains(e.target))
  }

  toggleOpen = (target) => {
    this.setState(
      (prevState) => {
        return {
          isOpen: { ...this.state.isOpen, [target]: !prevState.isOpen[target] },
        }
      },
      () => {
        // If the picker was JUST opened, append it to the list of opened pickers
        if (
          this.state.isOpen[target] &&
          !this.open.includes(this.pickers[target].current)
        ) {
          this.open.push(this.pickers[target].current)
        }

        if (!this.state[target]) {
          this.setState({ [target]: DEFAULT_COLOR })
        }
      }
    )
  }

  handleColorChange = (target, color) => {
    this.setState({ [target]: color.hex })
  }

  //This is called when the color is SET, not moving, so call api to update color here
  updateColor = () => {
    console.log(this.state)
    call(
      "save-color",
      () => {
        //this.props.refreshData()
        alert('Saved')
        // this.props.toasts.add({
        //   message: "Color changed!",
        //   type: "success",
        // })
      },
      () => {},
      null,
      {
        primary: this.state.primary,
        secondary: this.state.secondary,
        rest_id: this.props.rest.restaurant_id,
      }
    )
  }

  render() {
    return (
      <div>
        <Text size="xl" weight="black">
          Color
        </Text>
        <div className="brand__colors">
          <div className="brand__color-wrapper">
            <Color
              header="Primary Color"
              color={this.state.primary}
              isOpen={this.state.isOpen.primary}
              toggleOpen={() => this.toggleOpen("primary")}
            />
            {this.state.isOpen.primary && (
              <div ref={this.primaryPicker}>
                <ChromePicker
                  color={this.state.primary ? this.state.primary : ""}
                  onChange={(color) => this.handleColorChange("primary", color)}
                  onChangeComplete={() => this.updateColor("secondary")}
                />
              </div>
            )}
          </div>
          <div className="brand__color-wrapper">
            <Color
              header="Secondary Color"
              color={this.state.secondary}
              isOpen={this.state.isOpen.secondary}
              toggleOpen={() => this.toggleOpen("secondary")}
            />
            {this.state.isOpen.secondary && (
              <div ref={this.secondaryPicker}>
                <ChromePicker
                  color={this.state.secondary ? this.state.secondary : ""}
                  onChange={(color) =>
                    this.handleColorChange("secondary", color)
                  }
                  onChangeComplete={() => this.updateColor("secondary")}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default BrandColor
